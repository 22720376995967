import React from "react";
import { createStyles, Theme, withStyles, WithStyles, withTheme } from "@material-ui/core/styles";
import classnames from "classnames";
import { MetricReport, Query } from "../lib/api/charts";
import { Breakdown } from "../lib/api/charts";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { MeasuresContext } from "../contexts/MeasuresContext";
import { MetricGuidance } from "../components/MetricGuidance";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import { translation } from "../translations/Translations";
import { FormattedMessage } from "react-intl";
import { LanguageContext } from "../contexts/LanguageContext";
import { add } from "date-fns";

const monthDict = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export type MetricTableProps = {
  title: string;
  report: MetricReport;
  query: Query;
  breakdown: Breakdown;
  heading: string;
} & WithStyles<typeof styles>;

enum TableRows {
  Space = "Empty Space",
  Header = "Empty Measure",
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      flex: 1,
      borderRadius: "3px",
    },
    table: {
      border: "1px solid #e0e0e0",
      backgroundColor: "#FFF",
      "& th": {
        border: "solid #e0e0e0",
        borderWidth: "0px 0px 1px 0px",
      },
      "& td": {
        border: "solid #e0e0e0",
        borderWidth: "0px 0px 1px 0px",
      },
      tableLayout: "fixed",
    },
    tableHeader: {
      whiteSpace: "nowrap",
      padding: `${theme.spacing.unit}px`,
      fontSize: "10px",
      textAlign: "right",
      color: "#2f2f2f",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      fontWeight: "bold",
      width: "140px",
    },
    tableSubheader: {
      whiteSpace: "nowrap",
      padding: `${theme.spacing.unit}px`,
      fontSize: "10px",
      textAlign: "right",
      color: "#2f2f2f",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    tableCell: {
      padding: `${theme.spacing.unit}px`,
      fontSize: "10px",
      whiteSpace: "nowrap",
      textAlign: "right",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      width: "140px",
    },
    tableHeading: {
      padding: `${theme.spacing.unit}px`,
      textAlign: "left",
      color: "#2f2f2f",
      fontSize: "10px",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      fontWeight: "bold",
    },
    tableGuidance: {
      padding: "8px !important",
      maxWidth: "34px",
      width: "34px",
      minWidth: "34px",
      textAlign: "left",
      whiteSpace: "nowrap",
      backgroundColor: "#FFF",
      position: "sticky",
      left: "0px",
    },
    tableCellSubHeader: {
      textAlign: "left",
      width: "400px",
      minWidth: "400px",
      maxWidth: "400px",
      whiteSpace: "nowrap",
      position: "sticky",
      left: "34px",
      backgroundColor: "#f3f3f3",
    },
    ruleBold: {
      fontWeight: "bold",
    },
    green: {
      color: green[500],
    },
    red: {
      color: red[500],
    },
    good: {
      background: "#92D050 !important",
      color: "white",
      textAlign: "center",
    },
    bad: {
      background: "#FF0000 !important",
      color: "white",
      textAlign: "center",
    },
    note: {
      color: "#191919",
      marginLeft: "25px",
    },
  });

export const rooaHeaders = (query: Query) => {
  switch (query.period) {
    case "Monthly": {
      return ["MTD", "MTD-1", "Variance"];
    }
    case "TMRA": {
      return ["TMRA", "TMRA-1", "Variance"];
    }
    case "Calendar Year": {
      return ["CYTD", "CYTD-1", "Variance"];
    }
    case "Financial Year": {
      return ["FYTD", "FYTD-1", "Variance"];
    }
  }
};

const MetricTableUnstyled: React.FunctionComponent<MetricTableProps> = ({ classes, title, report, query, breakdown, heading }) => {
  const { getMeasures } = React.useContext(MeasuresContext);

  const { language } = React.useContext(LanguageContext);

  const getGuidanceStr = (metric: string) => {
    const currentMeasure = getMeasures().find(dbmeasure => dbmeasure.title == metric) || { description: "" };
    if (language != "en" && !!currentMeasure[`${language}_description`]) {
      return currentMeasure[`${language}_description`];
    }
    return currentMeasure.description;
  };

  const ruleMeasures = [
    "G > Gross Profit",
    "TNS > Net Sales",
    "TE > Total Expenses",
    "TNS > Projected Annualised Sales",
    "STAT > Average Operational Assets",
    "PBT > PBT % of Sales",
  ];

  const rooa = {
    gp: breakdown["G > Gross Profit"],
    revenue: breakdown["TNS > Net Sales"],
    expenses: breakdown["TE > Total Expenses"],
    projectedSales: breakdown["TNS > Projected Annualised Sales"],
    assets: breakdown["STAT > Average Operational Assets"],
    pbt: breakdown["PBT > PBT % of Sales"],
  };

  const measureRows: string[] = Object.keys(breakdown).filter((row: string) => !ruleMeasures.find(rule => rule == row));

  const month = (monthStr: string) => {
    return monthDict[parseInt(monthStr)];
  };

  const currentDate = new Date(query.date);
  const prevDate = add(currentDate, { years: -1 });

  const rooaSubHeaders = [
    `${month(currentDate.getMonth().toString())} ${currentDate
      .getFullYear()
      .toString()
      .slice(-2)}`,
    `${month(prevDate.getMonth().toString())} ${prevDate
      .getFullYear()
      .toString()
      .slice(-2)}`,
    "",
  ];
  const measures = Object.keys(breakdown).map(b => breakdown[b].actualTitle);
  const tableTitle = breakdown[Object.keys(breakdown)[0]].tableTitle;

  return (
    <div className={classes.root}>
      {heading}
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classes.tableHeading}>
              {tableTitle && (translation[tableTitle] ? <FormattedMessage id={translation[tableTitle]} /> : tableTitle)}
            </TableCell>
            {rooaHeaders(query).map((header: string, indx: number) => (
              <TableCell variant={"body"} key={header} className={classes.tableHeader}>
                {header}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classes.tableHeading} />
            {rooaSubHeaders.map((header: string, indx: number) => (
              <TableCell variant={"body"} key={header} className={classes.tableSubheader}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {measureRows.map((measure: string) => {
            const guidanceHTML = getGuidanceStr(breakdown[measure].actualTitle);
            return measure == TableRows.Header ? (
              <TableRow>
                <TableCell className={classes.tableGuidance} />
                <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
                  <span
                    style={
                      breakdown[measure].style
                        ? {
                            fontWeight: breakdown[measure].style.bold ? "bold" : "normal",
                            paddingLeft: 10 * (breakdown[measure].style.depth || 0),
                          }
                        : {}
                    }
                  >
                    {breakdown[measure].title}
                  </span>
                </TableCell>
                <TableCell className={classes.tableCell} />
                <TableCell className={classes.tableCell} />
                <TableCell className={classes.tableCell} />
              </TableRow>
            ) : (
              <TableRow>
                <TableCell className={classes.tableGuidance}>
                  {guidanceHTML && (
                    <MetricGuidance
                      guidanceHTML={guidanceHTML}
                      name={translation[breakdown[measure].title] ? <FormattedMessage id={translation[breakdown[measure].title]} /> : breakdown[measure].title}
                    />
                  )}
                </TableCell>
                <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
                  <span
                    style={
                      breakdown[measure].style
                        ? {
                            fontWeight: breakdown[measure].style.bold ? "bold" : "normal",
                            paddingLeft: 10 * (breakdown[measure].style.depth || 0),
                          }
                        : {}
                    }
                  >
                    {translation[breakdown[measure].title] ? <FormattedMessage id={translation[breakdown[measure].title]} /> : breakdown[measure].title}
                  </span>
                </TableCell>
                <TableCell className={classnames(classes.tableCell)}>{breakdown[measure].actual.formatted}</TableCell>
                <TableCell className={classnames(classes.tableCell)}>{breakdown[measure].previous.formatted}</TableCell>
                <TableCell className={classnames(classes.tableCell, { [classes.red]: breakdown[measure].variance.value < 0 })}>
                  {breakdown[measure].variance.formatted}
                </TableCell>
              </TableRow>
            );
          })}

          {/* EMPTY SPACE */}
          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classes.tableCell} />
            <TableCell className={classes.tableCell} />
            <TableCell className={classes.tableCell} />
            <TableCell className={classes.tableCell} />
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader, classes.ruleBold)}>
              <FormattedMessage id={translation["Rule 1_Grow GP faster than Revenue"]} />
            </TableCell>
            <TableCell className={classnames(classes.tableCell, rooa.gp.variance.value > rooa.revenue.variance.value ? classes.good : classes.bad)}>
              {rooa.gp.variance.value > rooa.revenue.variance.value ? (
                <FormattedMessage id={translation["Yes"]} />
              ) : (
                <FormattedMessage id={translation["No"]} />
              )}
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[0]) && (
                <MetricGuidance guidanceHTML={getGuidanceStr(measures[0])} name={<FormattedMessage id={translation["Gross Profit"]} />} />
              )}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>
                <FormattedMessage id={translation["Gross Profit"]} />
              </span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.gp.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.gp.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.gp.variance.value < 0 })}>{rooa.gp.variance.formatted}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[1]) && (
                <MetricGuidance guidanceHTML={getGuidanceStr(measures[1])} name={<FormattedMessage id={translation["Revenue"]} />} />
              )}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>
                <FormattedMessage id={translation["Revenue"]} />
              </span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.revenue.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.revenue.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.revenue.variance.value < 0 })}>
              {rooa.revenue.variance.formatted}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader, classes.ruleBold)}>
              <FormattedMessage id={translation["Rule 2_Grow GP faster than Expenses"]} />
            </TableCell>
            <TableCell className={classnames(classes.tableCell, rooa.gp.variance.value > rooa.expenses.variance.value ? classes.good : classes.bad)}>
              {rooa.gp.variance.value > rooa.expenses.variance.value ? (
                <FormattedMessage id={translation["Yes"]} />
              ) : (
                <FormattedMessage id={translation["No"]} />
              )}
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[0]) && (
                <MetricGuidance guidanceHTML={getGuidanceStr(measures[0])} name={<FormattedMessage id={translation["Gross Profit"]} />} />
              )}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>
                <FormattedMessage id={translation["Gross Profit"]} />
              </span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.gp.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.gp.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.gp.variance.value < 0 })}>{rooa.gp.variance.formatted}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[2]) && (
                <MetricGuidance guidanceHTML={getGuidanceStr(measures[2])} name={<FormattedMessage id={translation["Expenses"]} />} />
              )}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>
                <FormattedMessage id={translation["Expenses"]} />
              </span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.expenses.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.expenses.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.expenses.variance.value > 0 })}>
              {rooa.expenses.variance.formatted}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader, classes.ruleBold)}>
              <FormattedMessage id={translation["Rule 3_Grow Sales faster than Op.Assets"]} />
            </TableCell>
            <TableCell className={classnames(classes.tableCell, rooa.projectedSales.variance.value > rooa.assets.variance.value ? classes.good : classes.bad)}>
              {rooa.projectedSales.variance.value > rooa.assets.variance.value ? (
                <FormattedMessage id={translation["Yes"]} />
              ) : (
                <FormattedMessage id={translation["No"]} />
              )}
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[3]) && (
                <MetricGuidance guidanceHTML={getGuidanceStr(measures[3])} name={<FormattedMessage id={translation["Annualised Sales"]} />} />
              )}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>
                <FormattedMessage id={translation["Annualised Sales"]} />
              </span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.projectedSales.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.projectedSales.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.projectedSales.variance.value < 0 })}>
              {rooa.projectedSales.variance.formatted}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[4]) && (
                <MetricGuidance guidanceHTML={getGuidanceStr(measures[4])} name={<FormattedMessage id={translation["Average Operational Assets"]} />} />
              )}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>
                <FormattedMessage id={translation["Average Operational Assets"]} />
              </span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.assets.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.assets.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.assets.variance.value < 0 })}>{rooa.assets.variance.formatted}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.ruleBold, classes.tableCellSubHeader)}>
              <FormattedMessage id={translation["Rule 4_ROS must be positive"]} />
            </TableCell>
            <TableCell className={classnames(classes.tableCell, rooa.pbt.actual.value >= 0 ? classes.good : classes.bad)}>
              {rooa.pbt.actual.value >= 0 ? <FormattedMessage id={translation["Yes"]} /> : <FormattedMessage id={translation["No"]} />}
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[5]) && <MetricGuidance guidanceHTML={getGuidanceStr(measures[5])} name={<FormattedMessage id={translation["ROS"]} />} />}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>
                <FormattedMessage id={translation["ROS"]} />
              </span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.pbt.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.pbt.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.pbt.variance.value < 0 })}>{rooa.pbt.variance.formatted}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader, classes.note)}>
              <FormattedMessage id={translation["Note_MRA model has a multiplier effect, for the ROOA to improve you must at least have a positive ROS."]} />
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export const ROOATable = withTheme()(withStyles(styles)(MetricTableUnstyled));
