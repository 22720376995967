import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import grey from "@material-ui/core/colors/grey";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, withStyles, WithStyles, withTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";
import classnames from "classnames";
import React, { useContext } from "react";
import { Bar as ChartBar, CartesianGrid, Cell, ComposedChart, Line, ResponsiveContainer, Tooltip, Text, XAxis, YAxis, Legend } from "recharts";
import { Loading } from "../components/Loading";
import { Query, Report, Series } from "../lib/api/charts";
import { ChartMenu } from "./ChartMenu";
import { OemContext } from "../contexts/OemContext";
import numeral from "numeral";
import { BenchmarkContext } from "../contexts/BenchmarkContext";
import { CustomReportSelector } from "./CustomReportSelector";
import { CustomChartContext } from "../contexts/Custom/ChartContext";
import { useIntl, FormattedMessage } from "react-intl";
import { useMediaQuery } from "react-responsive";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import History from "@material-ui/icons/History";
import { ChartDialog } from "../lib/shared/ChartDialog";
import { KeyboardArrowDown } from "@material-ui/icons";
import { CustomizedXAxisTick } from "../components/CustomXAxisTick";
import { ClickAwayListener } from "@material-ui/core";
import { DealerContext } from "../contexts/DealerContext";
import { BenchmarkSelection } from "../Benchmark";
import { getBenchmarkColor, getChartColors } from "./GroupSeriesChart";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: "1",
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
    chartPaperRoot: {
      display: "flex",
      flexDirection: "column",
      padding: `${theme.spacing.unit * 2}px`,
      flex: "1",
      backgroundColor: "#FFF",
      borderRadius: "5px",
      // boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06) !important",
      border: "1px #DADADA solid",
      ["@media (max-width:450px)"]: {
        padding: "0px",
      },
    },
    headingContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    chart: {
      flex: "1",
    },
    menu: {
      // margin: "-0.85rem"
      display: "flex",
    },
    generalNews: {
      background: "#800080",
    },
    dataQualityNews: {
      background: "#ADD8E6",
    },
    dealerPerformanceNews: {
      background: "#003366",
    },
    mediumInput: {
      minWidth: "200px",
      maxWidth: "300px",
    },
    benchmarkSelection: {
      gridColumn: "6/8",
      "@media(max-width: 1230px)": {
        gridColumn: "4/6",
      },
    },
    searchInput: {
      "@media(max-width: 900px)": {
        marginBottom: "20px",
      },
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
      borderRadius: theme.shape.borderRadius,
      flex: "0 0 auto",
      fontSize: "12px",
      maxWidth: "140px",
      height: "auto",
      color: "#333",
      fontWeight: "bold",
      // marginTop: theme.spacing.unit,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      marginRight: "0px",
    },
  });

export type ChartProps = {
  barColor?: string;
  lineColor?: string;
  query: Query;
  title?: string;
  report: Report;
  theme: Theme;
  handleSelection?: (key: Report) => void;
  showBenchmark?: boolean;
  showMedian?: boolean;
  showTarget?: boolean;
  showTrend?: boolean;
  measureAgainst?: string;
  forcedHeight?: string;
  renderMenu?: () => React.ReactElement<any>;
  custom?: any;
  openDialog?: boolean;
  setOpenDialog?: (value: boolean) => void;
  isDesktopOrLaptop?: boolean;
  chartType?: "bar" | "line";
  handleChartType?: (type: "bar" | "line") => void;
} & WithStyles<typeof styles>;

const BarTooltip = ({ active, payload, query, oems, extraBenchmarks }: any) => {
  if (active && payload && payload[0]) {
    return (
      <Paper style={{ padding: "0.5rem" }}>
        <Typography variant="caption" component="span">
          {payload[0].payload.month}
        </Typography>
        <Typography variant="caption" component="span">
          <b>Actual:</b> {payload[0].payload.actualFormatted}
        </Typography>
        <Typography variant="caption" component="span">
          <b>Trend:</b> {payload[0].payload.trend}
        </Typography>
        {payload[0].payload.benchmarkFormatted && (
          <Typography variant="caption" component="span">
            <b>Benchmark:</b> {payload[0].payload.benchmarkFormatted}
          </Typography>
        )}
        {payload[0].payload.medianFormatted && (
          <Typography variant="caption" component="span">
            <b>Median:</b> {payload[0].payload.medianFormatted}
          </Typography>
        )}
        {extraBenchmarks
          ? extraBenchmarks.map(b => {
              return (
                <Typography variant="caption" component="span">
                  <b>{b}</b> {payload[0].payload[`extra_${b}`]}
                </Typography>
              );
            })
          : null}
        {query.dealer.hasTargets && oems.filter(oem => query.dealer.oems.includes(oem.code)).find(oem => oem.hasTargets) && (
          <>
            <Typography variant="caption" component="span">
              <b>Target:</b> {payload[0].payload.targetFormatted}
            </Typography>
          </>
        )}
      </Paper>
    );
  }
  return null;
};

const renderLegend = (props: any) => {
  const { payload } = props;
  // These styles have been obtained from inspecting the default rechart rendering.
  return (
    <ul className="recharts-default-legend" style={{ padding: "0px", margin: "0px", textAlign: "center" }}>
      {payload.map((entry: any, index: any) => {
        if (entry.value == "Actual") return null; // We never want to display the legend for Actual, this was requested by the Customer.

        if (entry.dataKey == "benchmark" || entry.dataKey == "median" || entry.dataKey.includes("extra_")) {
          return (
            <li
              key={`recharts-legend-item legend-item-${index}`}
              className={`recharts-legend-item legend-item-${index}`}
              style={{ display: "inline-block", marginRight: "10px" }}
            >
              <svg
                className="recharts-surface"
                width="14"
                height="14"
                viewBox="0 0 32 32"
                version="1.1"
                style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
              >
                {entry.type == "line" ? (
                  <path strokeWidth="6" fill="none" stroke={entry.color} d="M 10 16 L 30 16" className="recharts-legend-icon" strokeDasharray="3 3" />
                ) : (
                  <path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
                )}
              </svg>
              <span className={"recharts-legend-item-text"}>{entry.value}</span>
            </li>
          );
        }

        return (
          <li
            key={`recharts-legend-item legend-item-${index}`}
            className={`recharts-legend-item legend-item-${index}`}
            style={{ display: "inline-block", marginRight: "10px" }}
          >
            <svg
              className="recharts-surface"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              version="1.1"
              style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
            >
              {entry.type == "line" ? (
                <path
                  strokeWidth="4"
                  fill="none"
                  stroke={entry.color}
                  d="M0,16h10.666666666666666
  A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
  H32M21.333333333333332,16
  A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                  className="recharts-legend-icon"
                />
              ) : (
                <path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
              )}
            </svg>
            <span className={"recharts-legend-item-text"}>{entry.value}</span>
          </li>
        );
      })}
    </ul>
  );
};

export type ChartInnerProps = {
  theme: Theme;
  series: Series;
  report: Report;
  title: string;
  handleSelection?(key: Report): void;
  showBenchmark?: boolean;
  showMedian?: boolean;
  showTarget?: boolean;
  showTrend?: boolean;
  measureAgainst?: string;
  menu: React.ReactElement<any>;
  forcedHeight: string;
  query: Query;
  chartType?: "bar" | "line";
  extraBenchmarks?: string[];
} & WithStyles<typeof styles>;

const actualColour = (series: Series, value) => {
  if (series.classification == "income") {
    if (value.actual < value.median) {
      return red[500];
    }

    if (value.actual <= value.benchmark && value.actual >= value.median) {
      return orange[500];
    }

    return grey[500];
  }

  if (value.actual > value.median) {
    return red[500];
  }

  if (value.actual >= value.benchmark && value.actual <= value.median) {
    return orange[500];
  }

  return grey[500];
};

// Setting the width to the responsive container to 99% as per this issue that is found with recharts
// https://github.com/recharts/recharts/issues/172
const ChartInner: React.FunctionComponent<ChartInnerProps> = ({
  theme,
  series,
  classes,
  report,
  handleSelection,
  measureAgainst,
  showBenchmark,
  showMedian,
  showTarget,
  showTrend,
  menu,
  forcedHeight,
  title,
  query,
  chartType,
  extraBenchmarks,
}) => {
  const intl = useIntl();
  const { oems } = useContext(OemContext);
  const { customSelected: benchmarks, benchmarks: available } = useContext(BenchmarkContext);

  const seriesRows = series.rows.reduce((arr, row) => {
    if (["yearly", "quarter", "fullyear"].includes(query.meta && query.meta.variant)) {
      const [start, end] = row.month.split("-");
      const [endMonth, endYear] = [intl.formatMessage({ id: `month.${end.split(" ")[0].toLowerCase()}.sub` }), end.split(" ")[1]];
      const startDate = start.includes(" ") ? start.split(" ") : start;
      const starMonth = start.includes(" ")
        ? intl.formatMessage({ id: `month.${startDate[0].toLowerCase()}.sub` })
        : intl.formatMessage({ id: `month.${start.toLowerCase()}.sub` });
      const startYear = start.includes(" ") ? startDate[1] : endYear;

      arr.push({
        ...row,
        month: `${starMonth}${startYear == endYear ? "" : ` ${startYear}`}-${endMonth} ${endYear}`,
      });
      return arr;
    }

    const [month, year] = row.month.split(" ");

    const formattedMonth = ["yearly", "quarter", "fullyear"].includes(query.meta && query.meta.variant)
      ? month
      : intl.formatMessage({ id: `month.${month.toLowerCase()}.sub` });
    arr.push({
      ...row,
      month: !!year ? `${formattedMonth} ${year}` : `${formattedMonth}`,
    });
    return arr;
  }, []);

  const benchmarkLine = "#20639B";
  const medianLine = "#3cAEA3";
  const targetsLine = "#F6D55C";
  const trendLine = "#9494b8";

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1280px)",
  });

  const checkChartType = chartType ? chartType : "bar";
  return (
    <Paper className={classnames(classes.chartPaperRoot)} style={{ height: forcedHeight }}>
      <div className={classnames(classes.headingContainer)}>
        <CustomReportSelector handleSelection={handleSelection} report={report} title={series.name} />
        <div className={classnames(classes.menu)}>{menu || null}</div>
      </div>
      <div className={classes.chart}>
        <ResponsiveContainer width={"99%"} minHeight={300} className={classes.root}>
          <ComposedChart data={seriesRows} margin={{ bottom: 20, top: 10, left: 30 }}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis height={70} dataKey="month" type="category" interval={0} axisLine={false} tickLine={false} tick={CustomizedXAxisTick} />

            <YAxis
              width={40}
              axisLine={false}
              tickLine={false}
              tick={({ x, y, payload }) => {
                const value = numeral(payload.value);
                return (
                  <Text fill="#636463" x={x} y={y} textAnchor="end" verticalAnchor="middle">
                    {value.value() > 0
                      ? value.format(value.value() < 1000 ? series.formats.pos : "0.0a")
                      : value.format(value.value() > -1000 ? series.formats.neg : "0.0a")}
                  </Text>
                );
              }}
            />

            <Tooltip
              content={<BarTooltip oems={oems} query={query} extraBenchmarks={extraBenchmarks} />}
              position={isDesktopOrLaptop ? { x: 300, y: 0 } : { x: 0, y: 0 }}
            />
            {checkChartType === "bar" ? (
              <ChartBar name="Actual" fill={grey[500]} barSize={20} dataKey="actual">
                {seriesRows.map((value: any, key: number) => (
                  <Cell key={key} fill={actualColour(series, value)} />
                ))}
              </ChartBar>
            ) : (
              <Line name={"Actual"} stroke={grey[500]} fill={grey[500]} strokeWidth={2} dataKey="actual" dot={false} />
            )}
            {showTrend && (
              <Line
                name={intl.formatMessage({ id: "metric.trend.label" })}
                stroke={trendLine}
                fill={trendLine}
                strokeWidth={2}
                dataKey="trend"
                dot={false}
                strokeLinejoin="round"
              />
            )}
            {showBenchmark && (
              <Line
                name={benchmarks[0]}
                stroke={benchmarkLine}
                fill={benchmarkLine}
                strokeDasharray="5, 5"
                strokeWidth={2}
                dataKey="benchmark"
                dot={false}
                strokeLinejoin="round"
              />
            )}
            {showMedian && (
              <Line
                name={benchmarks[1]}
                stroke={medianLine}
                fill={medianLine}
                strokeDasharray="5, 5"
                strokeWidth={2}
                dataKey="median"
                dot={false}
                strokeLinejoin="round"
              />
            )}

            {extraBenchmarks
              ? extraBenchmarks.map((b, i) => {
                  const benchmarkColor = getBenchmarkColor(b, available, series.rows.length);
                  return (
                    <Line
                      name={b}
                      stroke={benchmarkColor}
                      fill={benchmarkColor}
                      strokeDasharray="5, 5"
                      strokeWidth={2}
                      dataKey={`extra_${b}`}
                      dot={false}
                      strokeLinejoin="round"
                    />
                  );
                })
              : null}
            {showTarget && (query.dealer.hasTargets && oems.filter(oem => query.dealer.oems.includes(oem.code)).find(oem => oem.hasTargets)) && (
              <Line
                name={intl.formatMessage({ id: "metric.target.label" })}
                stroke={targetsLine}
                fill={targetsLine}
                strokeWidth={3}
                dataKey="target"
                dot={false}
                strokeLinejoin="round"
              />
            )}
            <Legend content={renderLegend} verticalAlign="top" height={36} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};

type ReportMenuProps = {
  query: Query;
  handleShowToggle: (attr: string) => (e: React.SyntheticEvent<any>) => void;
  state: {
    showBenchmark: boolean;
    showMedian: boolean;
    showTarget: boolean;
    showTrend: boolean;
    forcedHeight: string;
  };
  hide?: boolean;
  classes: any;
  extraBenchmarks?: string[];
  setExtraBenchmarks?: any;
};

const ReportMenu: React.FunctionComponent<ReportMenuProps> = ({ query, handleShowToggle, state, hide, classes, setExtraBenchmarks, extraBenchmarks }) => {
  const toggleBenchmark = handleShowToggle("showBenchmark");
  const toggleMedian = handleShowToggle("showMedian");
  const toggleTarget = handleShowToggle("showTarget");
  const toggleTrend = handleShowToggle("showTrend");
  const { oems } = useContext(OemContext);
  const { customSelected: benchmarks } = useContext(BenchmarkContext);
  const dealer = useContext(DealerContext);
  const [showSelection, setShowSelection] = React.useState<boolean>(false);
  const setBenches = benchies => {
    const add = benchies.filter(t => !benchmarks.includes(t));
    setExtraBenchmarks([...add]);
  };

  return (
    <div hidden={hide}>
      <ChartMenu showLeft={true}>
        {benchmarks.filter(bm => bm.length > 0).length > 0 && (
          <>
            <MenuItem onClick={toggleBenchmark}>
              <Checkbox onChange={toggleBenchmark} checked={state.showBenchmark} disableRipple />
              {benchmarks[0]}
            </MenuItem>
            {benchmarks.length > 1 && (
              <MenuItem onClick={toggleMedian}>
                <Checkbox onChange={toggleMedian} checked={state.showMedian} disableRipple />
                {benchmarks[1]}
              </MenuItem>
            )}
          </>
        )}
        <MenuItem onClick={toggleTrend}>
          <Checkbox onChange={toggleTrend} checked={state.showTrend} disableRipple />
          <FormattedMessage id="metric.trend.label" />
        </MenuItem>
        {query.dealer.hasTargets && oems.filter(oem => query.dealer.oems.includes(oem.code)).find(oem => oem.hasTargets) && (
          <>
            <MenuItem onClick={toggleTarget}>
              <Checkbox onChange={toggleTarget} checked={state.showTarget} disableRipple />
              <FormattedMessage id="metric.target.label" />
            </MenuItem>
          </>
        )}
        <hr />
        <MenuItem>
          <Button
            onClick={() => {
              setShowSelection(true);
            }}
          >
            Add Other Benchmarks
          </Button>
        </MenuItem>
        <div>
          <ClickAwayListener
            onClickAway={() => {
              setShowSelection(false);
            }}
          >
            {showSelection && (
              <BenchmarkSelection
                allowEmptyValue={false}
                dealers={[dealer.selected]}
                setBenchmark={setBenches}
                benchmark={[...benchmarks, ...extraBenchmarks]}
                hidePeriods={false}
                allowMultiple={true}
                className={classnames(classes.searchInput, classes.benchmarkSelection, classes.mediumInput)}
                allowMany={true}
              />
            )}
          </ClickAwayListener>
        </div>
      </ChartMenu>
    </div>
  );
};
const ChartUnstyled: React.FunctionComponent<ChartProps> = ({
  classes,
  theme,
  report,
  title,
  query,
  showBenchmark,
  handleSelection,
  showMedian,
  showTarget,
  showTrend,
  measureAgainst,
  renderMenu,
  forcedHeight,
  custom,
  openDialog,
  setOpenDialog,
  isDesktopOrLaptop,
  chartType,
  handleChartType,
}) => {
  const [extraBenchmarks, setExtraBenchmarks] = React.useState<string[]>([]);
  const { getCustomSeries } = React.useContext(CustomChartContext);
  const series = getCustomSeries(report, { ...query, department: custom.department, benchmarks: [...query.benchmarks, ...extraBenchmarks] }, custom);

  // const seriesStacked = getCustomSeriesStacked(report, { ...query, department: custom.department }, custom);

  const [state, setState] = React.useState({
    showMedian: showMedian || false,
    showTarget: showTarget || false,
    showBenchmark: showBenchmark || false,
    showTrend: showTrend || true,
    forcedHeight: forcedHeight,
  });
  const [chartTypeMenuOpen, setChartTypeMenuOpen] = React.useState(false);
  const [chartTypeMenuAnchor, setChartTypeMenuAnchor] = React.useState(null);

  const handleShowToggle = attr => event => {
    setState({
      ...state,
      [attr]: !state[attr],
    });
  };

  if (!series.loading.loaded) {
    return <Loading />;
  }

  return (
    <ChartInner
      extraBenchmarks={extraBenchmarks}
      measureAgainst={measureAgainst}
      showBenchmark={state.showBenchmark}
      showMedian={state.showMedian}
      showTarget={state.showTarget}
      showTrend={state.showTrend}
      handleSelection={handleSelection}
      report={report}
      theme={theme}
      chartType={chartType}
      title={title || series.series.name}
      series={series.series}
      classes={classes}
      menu={
        <>
          {isDesktopOrLaptop && !openDialog && (
            <Button onClick={() => setOpenDialog(openDialog ? false : true)}>{openDialog ? <ZoomOut /> : <ZoomIn />}</Button>
          )}
          <Button
            onClick={e => {
              setChartTypeMenuOpen(true);
              setChartTypeMenuAnchor(e.currentTarget);
            }}
          >
            {chartType == "bar" && <span>Bar</span>}
            {chartType == "line" && <span>Line</span>}
          </Button>
          {renderMenu && renderMenu()}
          <ReportMenu
            state={state}
            handleShowToggle={handleShowToggle}
            query={query}
            hide={series.series.name == "Loss Dealers"}
            setExtraBenchmarks={setExtraBenchmarks}
            extraBenchmarks={extraBenchmarks}
            classes={classes}
          />
          <Menu anchorEl={chartTypeMenuAnchor} open={chartTypeMenuOpen} onClose={() => setChartTypeMenuOpen(false)}>
            <MenuItem
              selected={chartType === "bar"}
              onClick={() => {
                setChartTypeMenuOpen(false);
                handleChartType("bar");
              }}
            >
              Bar Chart
            </MenuItem>
            <MenuItem
              selected={chartType === "line"}
              onClick={() => {
                setChartTypeMenuOpen(false);
                handleChartType("line");
              }}
            >
              Line Chart
            </MenuItem>
          </Menu>
        </>
      }
      forcedHeight={state.forcedHeight}
      query={query}
    />
  );
};

export const CustomChart = withTheme()(withStyles(styles)(ChartUnstyled));

export type SelectableChartProps = {
  query: Query;
  title?: string;
  report: Report;
  handleSelection?(key: Report): void;
  period?: string;
  custom?: any;
  handleChartType?(type: "bar" | "line"): void;
  chartType?: "bar" | "line";
  customHeight?: string;
};

export const SelectableChart: React.FC<SelectableChartProps> = ({
  handleSelection,
  report,
  title,
  query,
  period = "Monthly",
  custom,
  chartType = "bar",
  handleChartType,
  customHeight = "375px", // Allow custom chart height for adhoc layouts.
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedChart, setSelectedChart] = React.useState(period);
  const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1280px)",
  });

  const variantMap = {
    Monthly: "monthly",
    Quarterly: "quarter",
    Yearly: "yearly",
    "Full Year": "fullyear",
    YTD: "ytd",
  };

  return (
    <>
      <CustomChart
        handleSelection={handleSelection}
        report={report}
        title={title}
        custom={custom}
        forcedHeight={customHeight}
        query={{ ...query, meta: variantMap[selectedChart] ? { ...query.meta, variant: variantMap[selectedChart] } : { ...query.meta } }}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        isDesktopOrLaptop={isDesktopOrLaptop}
        chartType={chartType}
        handleChartType={handleChartType}
        renderMenu={() => (
          <Button
            onClick={e => {
              setMenuOpen(true);
              setMenuAnchor(e.currentTarget);
            }}
          >
            <History /> ({selectedChart})
          </Button>
        )}
      />
      {
        <Menu anchorEl={menuAnchor} open={menuOpen} onClose={() => setMenuOpen(false)}>
          <MenuItem
            selected={selectedChart == "YTD"}
            onClick={() => {
              setMenuOpen(false);
              setSelectedChart("YTD");
            }}
          >
            YTD
          </MenuItem>
          <MenuItem
            selected={selectedChart == "Monthly"}
            onClick={() => {
              setMenuOpen(false);
              setSelectedChart("Monthly");
            }}
          >
            Monthly
          </MenuItem>
          <MenuItem
            selected={selectedChart == "Quarterly"}
            onClick={() => {
              setMenuOpen(false);
              setSelectedChart("Quarterly");
            }}
          >
            Quarterly
          </MenuItem>
          <MenuItem
            selected={selectedChart == "Yearly"}
            onClick={() => {
              setMenuOpen(false);
              setSelectedChart("Yearly");
            }}
          >
            Yearly
          </MenuItem>
          <MenuItem
            selected={selectedChart == "Full Year"}
            onClick={() => {
              setMenuOpen(false);
              setSelectedChart("Full Year");
            }}
          >
            Full Year
          </MenuItem>
        </Menu>
      }
      {isDesktopOrLaptop && (
        <ChartDialog
          handleSelection={handleSelection}
          report={report}
          title={title}
          custom={custom}
          query={{ ...query, meta: variantMap[selectedChart] ? { ...query.meta, variant: variantMap[selectedChart] } : { ...query.meta } }}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          isDesktopOrLaptop={isDesktopOrLaptop}
          renderMenu={() => (
            <Button
              onClick={e => {
                setMenuOpen(true);
                setMenuAnchor(e.currentTarget);
              }}
            >
              <History /> ({selectedChart})
            </Button>
          )}
          chart={props => (
            <CustomChart
              handleSelection={handleSelection}
              report={report}
              title={title}
              custom={custom}
              query={{ ...query, meta: variantMap[selectedChart] ? { ...query.meta, variant: variantMap[selectedChart] } : { ...query.meta } }}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              isDesktopOrLaptop={isDesktopOrLaptop}
              renderMenu={() => (
                <Button
                  onClick={e => {
                    setMenuOpen(true);
                    setMenuAnchor(e.currentTarget);
                  }}
                >
                  <History /> ({selectedChart})
                </Button>
              )}
            />
          )}
        />
      )}
    </>
  );
};
