export enum translation {
  "LATE" = "submissions.table.status.late",
  "COMPLETED" = "submissions.table.status.completed",
  "RESET SUBMISSION" = "submissions.table.status.reset",
  "Monthly" = "dashboard.period.selector.mtd",
  "Calendar Year" = "dashboard.period.selector.cytd",
  "Financial Year" = "dashboard.period.selector.fytd",
  "Reporting Dealers" = "metric.card.reporting.dealers.label",
  "% Loss Dealers" = "metric.card.percentage.loss.dealers.label",
  "GP %" = "metric.card.gp",
  "SALES `000" = "metric.card.sales.label",
  "PBT `000" = "metric.card.pbt.label",
  "ROS" = "metric.card.ros.label",
  "ROOA" = "metric.card.rooa.label",
  "FOA" = "metric.card.foa.label",
  "Toyota TOA" = "metric.card.toyotatoa.label",
  "Risk" = "metric.card.risk.label",
  "UNITS SOLD (NV + UV)" = "metric.card.unit.sales.label",
  "Revenue `000 - Total Dealership Sales" = "metric.card.revenue.label",
  "REVENUE `000" = "metric.chart.revenue.thousand.label",
  "Revenue '000" = "metric.chart.revenue.thousand.label",
  "MIX" = "metric.card.mix.label",
  "NV GP Incl F&I %" = "metric.card.nvmixdriver.label",
  "UV GP Incl F&I %" = "metric.card.uvmixdriver.label",
  "NV Variable Exp % GP" = "metric.card.nvretaineddriver.label",
  "UV Variable Exp % GP" = "metric.card.uvretaineddriver.label",
  "Service Variable Exp % GP" = "metric.card.serviceretaineddriver.label",
  "Service WIP value % Sales" = "metric.card.serviceactivitydriver.label",
  "B.Shop GP %" = "metric.card.bodyshopmixdriver.label",
  "B.Shop Variable Exp % GP" = "metric.card.bodyshopretaineddriver.label",
  "B.Shop WIP value % Sales" = "metric.card.bodyshopactivitydriver.label",
  "ACTIVITY" = "metric.card.activity.label",
  "RETAINED" = "metric.card.retained.label",
  "SALES - Units" = "metric.card.sales.units.label",
  "NV Sales - Units" = "metric.card.sales.nv.units.label",
  "UV Sales - Units" = "metric.card.sales.uv.units.label",
  "ACTIVITY - Inventory Days" = "metric.card.activity.inventory.days.label",
  "PRODUCTIVITY" = "metric.card.productivity.label",
  "PRODUCTIVITY - SC Productivity" = "metric.card.productivity.sc.productivity.label",
  "NV Productivity - SC Productivity" = "metric.card.productivity.nv.sc.productivity.label",
  "UV Productivity - SC Productivity" = "metric.card.productivity.uv.sc.productivity.label",
  "PRODUCTIVITY - Total Parts Sales / Prod. / Month" = "metric.card.productivity.total.parts.sales.label",
  "Parts Productivity - Total Parts Sales / Prod. / Month" = "metric.card.parts.productivity.total.parts.sales.label",
  "Service PRODUCTIVITY - Total Parts Sales / Prod. / Month" = "metric.card.service.productivity.total.parts.sales.label",
  "Body Shop Productivity - Labour Sales / Prod. / Month" = "metric.card.body.shop.productivity.total.parts.sales.label",
  "Service Productivity - Labour Sales / Prod. / Month" = "metric.card.productivity.labour.sales.label",
  "Productivity - Total GP / Prod. / Month" = "metric.card.productivity.total.gp.prod.month.label",
  "Body Shop Labour - Labour Sales mix of Total BS Sales" = "metric.card.body.shop.labour",
  "OPERATIONAL KPI - Technician Productivity" = "metric.card.operational.kpi",
  "NV Achievement - % Units Target Achievement" = "metric.card.retail.achievement.label",
  "RETAIL & FLEET - % Units Target Achievement" = "metric.card.retail.fleet.label",
  "NV Retail & Fleet - % Units Target Achievement" = "metric.card.retail.fleet.label",
  "NV Retail & Fleet" = "metric.card.nv.retail.fleet.label",
  "UV RETAIL & FLEET - % Units Target Achievement" = "metric.card.uv.retail.fleet.label",
  "Parts Workshop - Parts Workshop Sales % Total Sales" = "metric.card.parts.workshop.label",
  "SERVICE LABOUR - Labour Sales mix of Total Service Sales" = "metric.card.service.labour.label",
  "UV RETAIL - NV : UV Ratio" = "metric.card.uv.retail.label",
  "CURRENT RATIO" = "metric.card.current.ratio.label",
  "WORKING CAPITAL DAYS" = "metric.card.working.capital.days.label",
  "WORKING CAPITAL AMOUNT" = "metric.card.working.capital.amount.label",
  "Cash Conversion Cycle (CCC)" = "metric.card.cash.conversion.cycle.label",
  "CCC (CASH CONVERSION CYCLE)" = "metric.card.cash.conversion.cycle.label",
  "CCC (Cash Conversion Cycle)" = "metric.card.cash.conversion.cycle.label",
  "CASH CONVERSION CYCLE" = "metric.chart.cash.conversion.cycle.label",
  "TOTAL DEBTOR DAYS" = "metric.card.total.debtor.days.label",
  "TOTAL INVENTORY DAYS" = "metric.card.total.inventory.days.label",
  "Debt To Equity" = "metric.card.debt.to.equity.label",
  "ROI - (with full BS)" = "metric.card.roi.label",
  "Total Dealers in network" = "metric.table.total.dealers",
  "Reporting volume %" = "metric.table.reporting.volume",
  "Avg Revenue / Dealer Total" = "metric.table.avg.revenue.total",
  "Avg Revenue / Dealer New" = "metric.table.avg.revenue.new",
  "Avg Revenue / Dealer Used" = "metric.table.avg.revenue.used",
  "Avg Revenue / Dealer Parts" = "metric.table.avg.revenue.parts",
  "Avg Revenue / Dealer Service" = "metric.table.avg.revenue.service",
  "Avg Revenue / Dealer Body Shop" = "metric.table.avg.revenue.body.shop",
  "Avg Revenue / Dealer Other" = "metric.table.avg.revenue.other",
  "Avg PBT / Dealer New" = "metric.table.avg.pbt.new",
  "Avg PBT / Dealer Used" = "metric.table.avg.pbt.used",
  "Avg PBT / Dealer Parts" = "metric.table.avg.pbt.parts",
  "Avg PBT / Dealer Service" = "metric.table.avg.pbt.service",
  "Avg PBT / Dealer Body Shop" = "metric.table.avg.pbt.body.shop",
  "Avg PBT / Dealer Other" = "metric.table.avg.pbt.other",
  "ROS Total" = "metric.table.ros.total",
  "ROS New" = "metric.table.ros.new",
  "ROS Used" = "metric.table.ros.used",
  "ROS Parts" = "metric.table.ros.parts",
  "ROS Service" = "metric.table.ros.service",
  "ROS Body Shop" = "metric.table.ros.body.shop",
  "ROS Other" = "metric.table.ros.other",
  "Rule 1_Grow GP faster than Revenue" = "metric.table.rooa.rule_1",
  "Rule 2_Grow GP faster than Expenses" = "metric.table.rooa.rule_2",
  "Rule 3_Grow Sales faster than Op.Assets" = "metric.table.rooa.rule_3",
  "Rule 4_ROS must be positive" = "metric.table.rooa.rule_4",
  "Gross Profit" = "metric.table.gross.profit",
  "Revenue" = "metric.table.revenue",
  "Expenses" = "metric.table.expenses",
  "Annualised Sales" = "metric.table.annualised.sales",
  "Average Operational Assets" = "metric.table.average.operational.assets",
  "Note_MRA model has a multiplier effect, for the ROOA to improve you must at least have a positive ROS." = "metric.table.rooa.note",
  "Avg After Sales GP / Dealer" = "metric.table.avg.after.sales.gp",
  "Avg Parts GP / Dealer" = "metric.table.avg.parts.gp",
  "Avg Service GP / Dealer" = "metric.table.avg.service.gp",
  "Avg Body Shop GP / Dealer" = "metric.table.avg.body.shop.gp",
  "Avg Overhead Expenses / Dealer" = "metric.table.avg.overhead.expenses.gp",
  "Avg Variable Expenses (Non Vehicle) / Dealer" = "metric.table.avg.variable.expenses.nv.gp",
  "Avg Personnel Expenses / Dealer" = "metric.table.avg.personnel.expenses.gp",
  "Avg Other Semi Variable Expenses / Dealer" = "metric.table.avg.other.semi.variabel.expenses.gp",
  "Avg Fixed Expenses / Dealer" = "metric.table.avg.fixed.expenses.gp",
  "Avg Interest Expenses / Dealer" = "metric.table.avg.interest.expenses.gp",
  "Avg Admin Fees / Dealer" = "metric.table.avg.admin.fees.gp",
  "Total Dealership PBT" = "metric.table.total.dealership.pbt",
  "NV ROS" = "metric.table.nv.ros",
  "UV ROS" = "metric.table.uv.ros",
  "Parts ROS" = "metric.table.parts.ros",
  "Service ROS" = "metric.table.service.ros",
  "Body Shop ROS" = "metric.table.body.shop.ros",
  "Others ROS" = "metric.table.others.ros",
  "UNITS (NV + UV)" = "metric.table.units",
  "NV Units" = "metric.table.nv.units",
  "NV Retail Units" = "metric.table.nv.retail.units",
  "NV Fleet Units" = "metric.table.nv.fleet.units",
  "UV Units" = "metric.table.uv.units",
  "UV Retail Units" = "metric.table.uv.retail.units",
  "UV Trade Units" = "metric.table.uv.trade.units",
  "Total Dealership Sales" = "metric.table.total.dealership.sales",
  "NV Sales incl. F&I & Aftercare" = "metric.table.nv.sales.incl.f.and.i.and.aftercare",
  "NV GP incl. F&I & Aftercare" = "metric.table.nv.gp.f.and.i.and.aftercare",
  "GP / Unit Analysis" = "metric.table.gp.unit.analysis",
  "NV Front End Gross / Unit" = "metric.table.nv.front.end.gross.unit",
  "NV First Gross / Unit" = "metric.table.nv.first.gross.unit",
  "NV GP excl. F&I / Unit" = "metric.table.nv.gp.excl.f.and.i.unit",
  "NV F&I GP / Unit" = "metric.table.nv.f.and.i.gp.unit",
  "NV GP incl. F&I / Unit" = "metric.table.nv.gp.incl.f.and.i.unit",
  "NV Aftercare GP / Unit" = "metric.table.nv.aftercare.gp.unit",
  "NV GP incl. F&I & Aftercare / Unit" = "metric.table.nv.gp.incl.f.and.i.and.aftercare.unit",
  "Finance Penetration Retail" = "metric.table.finance.penetration.retail",
  "Insurance Penetration Retail" = "metric.table.insurance.penetration.retail",
  "GP % Analysis" = "metric.table.gp.analysis",
  "NV Sales" = "metric.table.nv.sales",
  "NV SALES" = "metric.table.nv.sales",
  "UV Sales" = "metric.table.uv.sales",
  "UV SALES" = "metric.table.uv.sales",
  "Parts Sales" = "metric.table.parts.sales",
  "Service Sales" = "metric.table.service.sales",
  "Body Shop Sales" = "metric.table.body.shop.sales",
  "Others Sales" = "metric.table.others.sales",
  "NV MIX" = "metric.table.nv.mix",
  "UV MIX" = "metric.table.uv.mix",
  "Parts MIX" = "metric.table.parts.mix",
  "Service MIX" = "metric.table.service.mix",
  "Body Shop MIX" = "metric.table.body.shop.mix",
  "Other Departments MIX%" = "metric.table.other.departments.mix",
  "Total Dealership GP" = "metric.table.total.dealership.gp",
  "Total Dealership Expenses" = "metric.table.total.dealership.expenses",
  "Total Expenses % TGP" = "metric.table.total.expenses.tgp",
  "Variable Expenses % TGP" = "metric.table.variable.expenses.tgp",
  "Personnel Expenses % TGP" = "metric.table.personnel.expenses.tgp",
  "Other Semi Var. Expenses % TGP" = "metric.table.other.semi.var.expenses.tgp",
  "Fixed Expenses % TGP" = "metric.table.fixed.expenses.tgp",
  "Interest Expenses % TGP" = "metric.table.interest.expenses.tgp",
  "Others Expenses % TGP" = "metric.table.others.expenses.tgp",
  "AVG Inventories" = "metric.table.avg.inventories",
  "AVG Receivables" = "metric.table.avg.receivables",
  "AVG Cash on Hand" = "metric.table.avg.cash.on.hand",
  "AVG Other Current Assets" = "metric.table.avg.other.current.assets",
  "AVG Equipment and Other Fixed Assets" = "metric.table.avg.equipment.and.other.fixed.assets",
  "Debtors Days" = "metric.table.debtors.days",
  "Debtor Days" = "metric.table.debtors.days",
  "NV Stock Days" = "metric.table.nv.stock.days",
  "UV Stock Days" = "metric.table.uv.stock.days",
  "Parts Stock Days" = "metric.table.parts.stock.days",
  "After Sales GP" = "metric.table.after.sales.gp",
  "Parts GP" = "metric.table.parts.gp",
  "Service GP" = "metric.table.service.gp",
  "Body Shop GP" = "metric.table.body.shop.gp",
  "Overhead Expenses" = "metric.table.overhead.expenses",
  "Personnel Expenses" = "metric.table.personnel.expenses",
  "Other Semi Variable Expenses" = "metric.table.other.semi.variable.expenses",
  "Fixed Expenses" = "metric.table.fixed.expenses",
  "Interest Expenses" = "metric.table.interest.expenses",
  "Admin Fees" = "metric.table.admin.fees",
  "Grow A.S GP faster than Overhead Exp." = "metric.table.fooa.rule",
  "Breakeven Days" = "metric.table.breakeven.days",
  "Breakeven Units" = "metric.table.breakeven.units",
  "Total Units" = "metric.table.total.units",
  "Retail Units" = "metric.table.retail.units",
  "Fleet Units" = "metric.table.fleet.units",
  "NV Total Sales" = "metric.table.nv.total.sales",
  "NV F&I Sales" = "metric.table.nv.f.and.i.sales",
  "NV Aftercare Sales" = "metric.table.nv.aftercare.sales",
  "SC Productivity" = "metric.table.sc.productivity",
  "Number of SC(Avg)" = "metric.table.number.of.sc.avg",
  "Net Sales / Unit (excl. Acc)" = "metric.table.net.sales.unit.excl.acc",
  "Accessories Net Sales / Unit" = "metric.table.accessories.net.sales.unit",
  "NV Total GP" = "metric.table.nv.total.gp",
  "NV GP" = "metric.table.nv.gp",
  "NV F&I GP" = "metric.table.nv.f.and.i.gp",
  "NV Aftercare GP" = "metric.table.nv.aftercare.gp",
  "Sales Effort % Gross Sales" = "metric.table.sales.effort.gross.sales",
  "FEG % Gross Sales" = "metric.table.feg.gross.sales",
  "First Gross % Net Sales" = "metric.table.first.gross.net.sales",
  "NV GP % excl. F&I Aftercare" = "metric.table.nv.gp.excl.f.and.i.aftercare",
  "Accessories GP / Unit" = "metric.table.accessories.gp.unit",
  "NV GP / Unit excl. F&I Aftercare" = "metric.table.nv.gp.unit.excl.f.and.i.aftercare",
  "F&I GP / Unit" = "metric.table.f.and.i.gp.unit",
  "NV GP / Unit incl. F&I Aftercare" = "metric.table.nv.gp.unit.incl.f.and.i.aftercare",
  "NV Total Expenses" = "metric.table.nv.total.expenses",
  "Total Expenses % GP" = "metric.table.total.expenses.gp",
  "Variable Expenses % GP" = "metric.table.variable.expenses.gp",
  "Variable Expenses / Unit" = "metric.table.variable.expenses.unit",
  "Sales Commission / Unit" = "metric.table.sales.commission.unit",
  "Direct Advertising / Unit" = "metric.table.direct.advertising.unit",
  "PDI & Free Service / Unit" = "metric.table.pdi.and.free.service.unit",
  "Other Variable Exp. / Unit" = "metric.table.other.variable.exp.unit",
  "Personnel Expenses % GP" = "metric.table.personnel.expenses.gp",
  "Fixed Expenses % GP" = "metric.table.fixed.expenses.gp",
  "Allocation of Admin Expenses % GP" = "metric.table.allocation.of.admin.expenses.gp",
  "NV Inventory Days" = "metric.table.nv.inventory.days",
  "NV Average Operational Assets" = "metric.table.nv.average.operational.assets",
  "NV Inventory Units (ending)" = "metric.table.nv.inventory.units.ending",
  "NV Inventory Value (ending)" = "metric.table.nv.inventory.value.ending",
  "NV Inventory Value 0-30 days mix" = "metric.table.nv.inventory.value.0.30.days.mix",
  "NV Inventory Value 31-60 days mix" = "metric.table.nv.inventory.value.31.60.days.mix",
  "NV Inventory Value 61-90 days mix" = "metric.table.nv.inventory.value.61.90.days.mix",
  "NV Inventory Value > 90 days mix" = "metric.table.nv.inventory.value.greater.90.days.mix",
  "Total Staff (Avg.)" = "metric.table.total.staff.avg",
  "Productive Staff (Avg.)" = "metric.table.productive.staff.avg",
  "Non-Productive Staff (Avg.)" = "metric.table.non.productive.staff.avg",
  "PDI Staff (Avg.)" = "metric.table.pdi.staff.avg",
  "GP / Productive / Month" = "metric.table.gp.productive.month",
  "GP / Employee / Month" = "metric.table.gp.employee.month",
  "Variable Selling Gross Net of Pers. Exp. / Unit" = "metric.table.variable.selling.gross.net.of.pers.exp.unit",
  "Variable Selling Gross Net of Pers. Exp. % GP" = "metric.table.variable.selling.gross.net.of.pers.exp.gp",
  "Sales" = "metric.table.sales",
  "SALES" = "metric.table.sales",
  "Variable Expenses" = "metric.table.variable.expenses",
  "Allocation of Admin Expenses" = "metric.table.allocation.of.admin.expenses",
  "PBT" = "metric.table.pbt",
  "Trade Units" = "metric.table.trade.units",
  "Wholesale Units" = "metric.table.wholesale.units",
  "UV Total Sales" = "metric.table.uv.total.sales",
  "UV F&I Sales" = "metric.table.uv.f.and.i.sales",
  "UV Aftercare Sales" = "metric.table.uv.aftercare.sales",
  "New Vehicle : Used Vehicle Ratio" = "metric.table.new.vehicle.used.vehicle.ratio",
  "Number of SC (Average)" = "metric.table.number.of.sc.average",
  "UV Total GP" = "metric.table.uv.total.gp",
  "UV GP" = "metric.table.uv.gp",
  "UV F&I GP" = "metric.table.uv.f.and.i.gp",
  "UV Aftercare GP" = "metric.table.uv.aftercare.gp",
  "UV GP % excl. F&I Aftercare" = "metric.table.uv.gp.excl.f.and.i.aftercare",
  "Net Sales / Unit" = "metric.table.net.sales.unit",
  "Reconditioning / Unit" = "metric.table.reconditioning.unit",
  "UV GP / Unit excl. F&I Aftercare" = "metric.table.uv.gp.unit.excl.f.and.i.aftercare",
  "UV GP / Unit incl. F&I Aftercare" = "metric.table.uv.gp.unit.incl.f.and.i.aftercare",
  "UV RETAINED" = "metric.table.uv.retained",
  "UV Total Expenses" = "metric.table.uv.total.expenses",
  "Other Variable / Unit" = "metric.table.other.variable.unit",
  "UV Inventory Days" = "metric.table.uv.inventory.days",
  "UV Average Operational Assets" = "metric.table.uv.average.operational.assets",
  "UV Inventory Units (ending)" = "metric.table.uv.inventory.units.ending",
  "UV Inventory Value (ending)" = "metric.table.uv.inventory.value.ending",
  "UV Inventory Value 0-30 days mix" = "metric.table.uv.inventory.value.0.30.days.mix",
  "UV Inventory Value 31-60 days mix" = "metric.table.uv.inventory.value.31.60.days.mix",
  "UV Inventory Value 61-90 days mix" = "metric.table.uv.inventory.value.61.90.days.mix",
  "Floor Plan Interest % GP" = "metric.table.floor.plan.interest.gp",
  "Floor Plan Interest / Unit" = "metric.table.floor.plan.interest.unit",
  "UV ROSI" = "metric.table.uv.rosi",
  "Breakeven Sales" = "metric.table.breakeven.sales",
  "Parts Sales per Service RO" = "metric.table.parts.sales.per.service.ro",
  "Parts Sales per Body Shop RO" = "metric.table.parts.sales.per.body.shop.ro",
  "Parts Total Sales" = "metric.table.parts.total.sales",
  "Parts Service Workshop Sales" = "metric.table.parts.service.workshop.sales",
  "Parts Body Shop Sales" = "metric.table.parts.body.shop.sales",
  "Parts Accessories Sales" = "metric.table.parts.accessories.sales",
  "Parts Intercompany Sales" = "metric.table.parts.intercompany.sales",
  "Parts Oil & Lubricant Sales" = "metric.table.parts.oil.and.lubricant.sales",
  "Parts Counter Retail Sales" = "metric.table.parts.counter.retail.sales",
  "Parts Wholesale Sales" = "metric.table.parts.wholesale.sales",
  "Parts Other Sales" = "metric.table.parts.other.sales",
  "Parts Total GP" = "metric.table.parts.total.gp",
  "Parts First GP" = "metric.table.parts.first.gp",
  "Parts Adj. (Pricing Adj. & OEM Inc. & Obsolescence)" = "metric.table.parts.adj.pricing.adj.and.oem.inc.obsolescence",
  "Parts 1st GP %" = "metric.table.parts.1st.gp",
  "Parts Service Workshop GP %" = "metric.table.parts.service.workshop.gp",
  "Parts Body Shop GP %" = "metric.table.parts.body.shop.gp",
  "Parts Accessories GP %" = "metric.table.parts.accessories.gp",
  "Parts Intercompany GP %" = "metric.table.parts.intercompany.gp",
  "Parts Oil & Lubricant GP %" = "metric.table.parts.oil.and.lubricant.gp",
  "Parts Counter Retail GP %" = "metric.table.parts.counter.retail.gp",
  "Parts Wholesale GP %" = "metric.table.parts.wholesale.gp",
  "Parts Other GP %" = "metric.table.parts.other.gp",
  "Parts Adjustments % Sales" = "metric.table.parts.adjustments.sales",
  "PARTS RETAINED" = "metric.table.parts.retained",
  "Parts Total Expenses" = "metric.table.parts.total.expenses",
  "PARTS Total Expenses" = "metric.table.parts.total.expenses.big",
  "Other Semi Variable Expenses % GP" = "metric.table.other.semi.variable.expenses.gp",
  "Interest Expenses % GP" = "metric.table.interest.expenses.gp",
  "Parts Inventory Days" = "metric.table.parts.inventory.days",
  "Parts Average Operational Assets" = "metric.table.parts.average.operational.assets",
  "PARTS Average Operational Assets" = "metric.table.parts.average.operational.assets.big",
  "Parts Inventory Value (ending)" = "metric.table.parts.inventory.value.ending",
  "Parts Inventory Value (average)" = "metric.table.parts.inventory.value.average",
  "Parts Inventory Age . Days mix" = "metric.table.parts.inventory.age.0.90.days.mix",
  "Parts Inventory Age 91-180 Days mix" = "metric.table.parts.inventory.age.91.180.days.mix",
  "Parts Inventory Age 181-365 Days mix" = "metric.table.parts.inventory.age.181.365.days.mix",
  "Total Parts Sales / Productive / Month" = "metric.table.total.parts.sales.productive.month",
  "Total RO" = "metric.table.total.ro",
  "Hours Sold / RO" = "metric.table.hours.sold.ro",
  "Effective Labour Rate / Hour" = "metric.table.effective.labour.rate.hour",
  "Service Total Sales" = "metric.table.service.total.sales",
  "Service Labour Sales" = "metric.table.service.labour.sales",
  "Service Sublet Sales" = "metric.table.service.sublet.sales",
  "Service Oil & Lubricant Sales" = "metric.table.service.oil.and.lubricant.sales",
  "Service Extended Warranty Sales" = "metric.table.service.extended.warranty.sales",
  "Service Other Sales" = "metric.table.service.other.sales",
  "Service Total GP" = "metric.table.service.total.gp",
  "Labour GP%" = "metric.table.labour.gp",
  "Labour FEG%" = "metric.table.labour.feg",
  "Labour Retail GP%" = "metric.table.labour.retail.gp",
  "Labour Warranty GP%" = "metric.table.labour.warranty.gp",
  "Labour Internal GP%" = "metric.table.labour.internal.gp",
  "Maint. Contract GP%" = "metric.table.maint.contract.gp",
  "Labour Quick Service GP%" = "metric.table.labour.quick.service.gp",
  "Sublet GP%" = "metric.table.sublet.gp",
  "Oil & Lub GP%" = "metric.table.oil.and.lub.gp",
  "Other GP%" = "metric.table.other.gp",
  "SERVICE RETAINED" = "metric.table.service.retained",
  "Service Total Expenses" = "metric.table.service.total.expenses",
  "Productivity" = "metric.table.productivity",
  "Efficiency" = "metric.table.efficiency",
  "Effectiveness" = "metric.table.effectiveness",
  "Workbay Utilization" = "metric.table.workbay.utilization",
  "Workbay Occupancy" = "metric.table.workbay.occupancy",
  "Number of Workbay" = "metric.table.number.of.workbay",
  "Number of Technicians (Average)" = "metric.table.number.of.technicians.average",
  "Labour Sales / Productive / Month" = "metric.table.labour.sales.productive.month",
  "Technicians (Avg.)" = "metric.table.technicians.avg",
  "Support Staff incl. SA (Avg.)" = "metric.table.support.staff.incl.sa.avg",
  "Support Advisor (Avg.)" = "metric.table.support.advisor.avg",
  "Body Shop Sales / RO" = "metric.table.body.shop.sales.ro",
  "Body Shop Total Sales" = "metric.table.body.shop.total.sales",
  "Body Shop Labour Sales" = "metric.table.body.shop.labour.sales",
  "Body Shop Paint & Material Sales" = "metric.table.body.shop.paint.and.material.sales",
  "Body Shop Sublet Sales" = "metric.table.body.shop.sublet.sales",
  "Body Shop Oil & Lubricant Sales" = "metric.table.body.shop.oil.and.lubricant.sales",
  "Body Shop Extended Warranty Sales" = "metric.table.body.shop.extended.warranty.sales",
  "Body Shop Other Sales" = "metric.table.body.shop.other.sales",
  "BODY SHOP MIX" = "metric.table.body.shop.mix.big",
  "Body Shop Total GP" = "metric.table.body.shop.total.gp",
  "Paint & Material GP %" = "metric.table.paint.and.material.gp",
  "Oil & Lubricant GP %" = "metric.table.oil.and.lubricant.gp",
  "Body Shop Total Expenses" = "metric.table.body.shop.total.expenses",
  "Tech. Produc" = "metric.table.tech.produc",
  "PARTS MIX" = "metric.table.parts.mix.big",
  "SERVICE MIX" = "metric.table.service.mix.big",
  "Loss Dealers" = "metric.chart.loss.dealers.label",
  "REVENUE" = "metric.chart.revenue.label",
  "Profit & Loss Dealers" = "metric.chart.profit.and.loss.dealers",
  "Total Dealership Sales Avg. / Month" = "metric.chart.total.dealership.sales.avg.month",
  "Sales mix Avg." = "metric.chart.sales.mix.avg",
  "PBT > PBT (Profit Before Tax)" = "metric.chart.pbt.profit.before.tax",
  "ROS (By Region / Tier)" = "metric.chart.ros.by.region.tier",
  "ROOA (By Region / Tier)" = "metric.chart.rooa.by.region.tier",
  "FOA MIX" = "metric.chart.foa.mix",
  "STAT > Debt to Equity" = "metric.chart.stat.debt.to.equity",
  "ROOA Drivers" = "metric.rooa.drivers.header",
  "UNITS SOLD Drivers" = "metric.chart.unit.sales.drivers",
  "REVENUE Drivers" = "metric.chart.revenue.drivers",
  "MIX Drivers" = "metric.chart.mix.drivers",
  "RETAINED Drivers" = "metric.chart.retained.drivers",
  "NV SALES Drivers" = "metric.chart.nv.sales.drivers",
  "NV MIX Drivers" = "metric.chart.nv.mix.drivers",
  "NV RETAINED Drivers" = "metric.chart.nv.retained.drivers",
  "NV ACTIVITY Drivers" = "metric.chart.nv.activity.drivers",
  "NV PRODUCTIVITY Drivers" = "metric.chart.nv.productivity.drivers",
  "NV ROS Drivers" = "metric.chart.nv.ros.drivers",
  "Units mix Avg. / Month" = "metric.units.mix.avg.month",
  "Net Sales / U" = "metric.net.sales",
  "Sales mix Avg. / Month" = "metric.sales.mix.avg.month",
  "PBT Avg. / Month" = "metric.pbt.avg.month",
  "Gross Profit mix Avg. / Month" = "metric.gross.profit.mix.avg.month",
  "Expenses % TGP" = "metric.expenses.tgp",
  "Expenses mix" = "metric.expenses.mix",
  "AVG Operational Assets MIX" = "metric.avg.operational.assets.mix",
  "NV Units vs Target" = "metric.nv.units.vs.target",
  "Top 5 Retail Units mix Avg. / Month" = "metric.top.5.retail.units.mix.avg.month",
  "NV Floor Plan % GP" = "metric.nv.floor.plan.label",
  "NV Var %GP" = "metric.nv.var.gp",
  "NV GP %" = "metric.nv.gp",
  "NV GP per Unit" = "metric.nv.gp.per.unit",
  "NV Variable Expenses vs NV Variable Selling Gross" = "metric.nv.variable.expenses.vs.nv.variable.selling.gross",
  "NV Variable Selling Gross net of Personnel Expenses" = "metric.nv.variable.selling.gross.net.of.personnel.expenses",
  "Floor Plan Interest % NV GP" = "metric.floor.plan.interest.nv.gp",
  "SC Productivity vs AVG Number of SC" = "metric.sc.productivity.vs.avg.number.of.sc",
  "GP / Productive / Month & GP / Employee / Month" = "metric.gp.productive.month.and.gp.employee.month",
  "ROS Trend" = "metric.ros.trend",
  "UV Units mix" = "metric.uv.units.mix",
  "UV GP %" = "metric.uv.gp",
  "UV GP per Unit" = "metric.uv.gp.per.unit",
  "UV Variable Expenses vs UV Variable Selling Gross" = "metric.uv.variable.expenses.vs.uv.variable.selling.gross",
  "UV Variable Selling Gross net of Personnel Expenses" = "metric.uv.variable.selling.gross.net.of.personnel.expenses",
  "Floor Plan Interest % UV GP" = "metric.floor.plan.interest.uv.gp",
  "PARTS SALES Drivers" = "metric.chart.parts.sales.drivers",
  "PARTS MIX Drivers" = "metric.chart.parts.mix.drivers",
  "PARTS RETAINED drivers" = "metric.chart.parts.retained.drivers",
  "PARTS ACTIVITY Drivers" = "metric.chart.parts.activity.drivers",
  "PARTS PRODUCTIVITY Drivers" = "metric.chart.parts.productivity.drivers",
  "PARTS ROS Drivers" = "metric.chart.parts.ros.drivers",
  "Parts Sales mix Avg. / Month" = "metric.chart.parts.sales.mix.avg.month",
  "Parts Sales per Retail RO" = "metric.chart.parts.sales.per.retail.ro",
  "Total Parts GP%" = "metric.chart.total.parts.gp",
  "Parts GP% by chanel of sales" = "metric.chart.parts.gp.by.chanel.of.sales",
  "Parts Expenses mix Avg. / Month" = "metric.chart.parts.expenses.mix.avg.month",
  "Parts Total Expense % GP" = "metric.chart.parts.total.expense.gp",
  "Parts Inventory mix vs Parts Inventory Days" = "metric.chart.parts.inventory.mix.vs.parts.inventory.days",
  "Parts ROSI" = "metric.chart.parts.rosi",
  "PARTS ROSI" = "metric.chart.parts.rosi.big",
  "Parts Sales / Prod. / Month" = "metric.chart.parts.sales.prod.month",
  "Parts GP / Prod. / Month" = "metric.chart.parts.gp.prod.month",
  "Service Sales mix Avg. / Month" = "metric.chart.service.sales.mix.avg.month",
  "Service RO and Sales per RO" = "metric.chart.service.ro.and.sales.per.ro",
  "Total Service GP%" = "metric.chart.total.service.gp",
  "Service GP% by channel of sales" = "metric.chart.service.gp.by.channel.of.sales",
  "Service Expenses mix Avg. / Month" = "metric.chart.service.expenses.mix.avg.month",
  "Service Total Expenses % GP" = "metric.chart.service.total.expenses.gp",
  "Productivity vs Efficiency" = "metric.chart.productivity.vs.efficiency",
  "Hours Sold / RO vs Effective Labour Rate / Hour" = "metric.chart.hours.sold.ro.vs.effective.labour.rate.hour",
  "Service Labour Sales / Prod. / Month" = "metric.chart.service.labour.sales.prod.month",
  "Service GP / Prod. / Month" = "metric.chart.service.gp.prod.month",
  "UV SALES Drivers" = "metric.chart.uv.sales.drivers",
  "UV MIX Drivers" = "metric.chart.uv.mix.drivers",
  "UV RETAINED Drivers" = "metric.chart.uv.retained.drivers",
  "UV ACTIVITY Drivers" = "metric.chart.uv.activity.drivers",
  "UV PRODUCTIVITY Drivers" = "metric.chart.uv.productivity.drivers",
  "UV ROS Drivers" = "metric.chart.uv.ros.drivers",
  "SERVICE SALES Drivers" = "metric.chart.service.sales.drivers",
  "SERVICE MIX Drivers" = "metric.chart.service.mix.drivers",
  "SERVICE RETAINED Drivers" = "metric.chart.service.retained.drivers",
  "SERVICE OPERATIONAL KPIs Drivers" = "metric.chart.service.operational.kpis.drivers",
  "SERVICE PRODUCTIVITY Drivers" = "metric.chart.service.productivity.drivers",
  "SERVICE ROS Drivers" = "metric.chart.service.ros.drivers",
  "BODY SHOP SALES Drivers" = "metric.chart.body.shop.sales.drivers",
  "BODY SHOP MIX Drivers" = "metric.chart.body.shop.mix.drivers",
  "BODY SHOP RETAINED Drivers" = "metric.chart.body.shop.retained.drivers",
  "BODY SHOP OPERATIONAL KPIs Drivers" = "metric.chart.body.shop.operational.kpis.drivers",
  "BODY SHOP PRODUCTIVITY Drivers" = "metric.chart.body.shop.productivity.drivers",
  "BODY SHOP ROS Drivers" = "metric.chart.body.shop.ros.drivers",
  "Body Shop Sales mix Avg. / Month" = "metric.chart.body.shop.sales.mix.avg.month",
  "Body Shop RO and Sales per RO" = "metric.chart.body.shop.ro.and.sales.per.ro",
  "Total Body Shop GP%" = "metric.chart.total.body.shop.gp",
  "Body Shop GP %" = "metric.chart.body.shop.gp",
  "Body Shop Expenses mix Avg. / Month" = "metric.chart.body.shop.expenses.mix.avg.month",
  "Body Shop Total Expenses % GP" = "metric.chart.body.shop.total.expenses.gp",
  "RO / Technician / Day" = "metric.chart.ro.technician.day",
  "Body Shop Labour Sales / Prod. / Month" = "metric.chart.body.shop.labour.sales.prod.month",
  "Body Shop GP / Prod. / Month" = "metric.chart.body.shop.gp.prod.month",
  "TGP" = "metric.chart.tgp",
  "NV MIX (NV GP %)" = "metric.chart.nv.mix.nv.gp",
  "DB > New > PBT > PBT % of TGPRETAINED" = "metric.chart.db.new.pbt.pbt.of.tgp.retained",
  "DB > New > STAT > Inventory days" = "metric.chart.db.new.stat.inventory.days",
  "DB > New > PBT > PBT % of Sales" = "metric.chart.db.new.pbt.pbt.of.sales",
  "UV MIX (UV GP %)" = "metric.chart.uv.mix.uv.gp",
  "DB > Used > PBT > PBT % of TGPRETAINED" = "metric.chart.db.used.pbt.pbt.of.tgp.retained",
  "DB > Used > STAT > Inventory days" = "metric.chart.db.used.stat.inventory.days",
  "DB > Used > PBT > PBT % of Sales" = "metric.chart.db.used.pbt.pbt.of.sales",
  "Loss" = "metric.chart.loss",
  "MIX (Parts GP %)" = "metric.chart.mix.parts.gp",
  "DB > Parts > PBT > PBT % of TGPRETAINED" = "metric.chart.db.parts.pbt.pbt.of.tgp.retained",
  "DB > Parts > STAT > Inventory days" = "metric.chart.db.parts.stat.inventory.days",
  "DB > Parts > PBT > PBT % of Sales" = "metric.chart.db.parts.pbt.pbt.of.sales",
  "Parts Sales Avg. / Month" = "metric.chart.parts.sales.avg.month",
  "MIX (Service GP %)" = "metric.chart.mix.service.gp",
  "DB > Service > PBT > PBT % of TGPRETAINED" = "metric.chart.db.service.pbt.pbt.of.tgp.retained",
  "DB > Service > PBT > PBT % of Sales" = "metric.chart.db.service.pbt.pbt.of.sales",
  "Service Sales Avg. / Month" = "metric.chart.service.sales.avg.month",
  "MIX (Body Shop GP %)" = "metric.chart.mix.body.shop.gp",
  "DB > BodyShop > PBT > PBT % of TGPRETAINED" = "metric.chart.db.bodyshop.pbt.pbt.of.tgp.retained",
  "DB > BodyShop > PBT > PBT % of Sales" = "metric.chart.db.bodyshop.pbt.pbt.of.sales",
  "Body Shop Sales Avg. / Month" = "metric.chart.body.shop.sales.avg.month",
  "Fleet Units Avg" = "metric.fleet.units.avg",
  "Retail Units Avg" = "metric.retail.units.avg",
  "Target Units Avg" = "metric.target.units.avg",
  "% Achievement" = "metric.achievement",
  "Retail Units Avg. / Month" = "metric.retail.units.avg.month",
  "Trade Units Avg. / Month" = "metric.trade.units.avg.month",
  "Wholesale Units Avg. / Month" = "metric.wholesale.units.avg.month",
  "NV Retail Net Sales / U" = "metric.nv.retail.net.sales.u",
  "NV Fleet Net Sales / U" = "metric.nv.fleet.net.sales.u",
  "UV Retail Net Sales / U" = "metric.uv.retail.net.sales.u",
  "UV Trade Net Sales / U" = "metric.uv.trade.net.sales.u",
  "Profit Dealers" = "metric.profit.dealers",
  "Total Reporting" = "metric.total.reporting",
  "Inventories" = "metric.inventories",
  "Receivables" = "metric.receivables",
  "Cash on Hand" = "metric.cash.on.hand",
  "Other Current Assets" = "metric.other.current.assets",
  "Equipment and Other Fixed Assets" = "metric.equipment.and.other.fixed.assets",
  "Parts Serv Workshop" = "metric.parts.serv.workshop",
  "Parts BDS Workshop" = "metric.parts.bds.workshop",
  "Parts Accessories" = "metric.parts.accessories",
  "Parts Intercompany" = "metric.parts.intercompany",
  "Parts Oil & Lubricant" = "metric.parts.oil.and.lubricant",
  "Parts Counter Retail" = "metric.parts.counter.retail",
  "Parts Wholesale" = "metric.parts.wholesale",
  "Labour Sales" = "metric.labour.sales",
  "Sublet Sales" = "metric.sublet.sales",
  "Oil & Lub Sales" = "metric.oil.and.lub.sales",
  "Other Sales" = "metric.other.sales",
  "Service RO Avg. / Month" = "metric.service.ro.avg.month",
  "Service Sales / RO" = "metric.service.sales.ro",
  "Paint & Material Sales" = "metric.paint.and.material.sales",
  "Body Shop RO Avg. / Month" = "metric.body.shop.ro.avg.month",
  "Service GP%" = "metric.service.gp",
  "Total Labour GP%" = "metric.total.labour.gp",
  "Labour FEG %" = "metric.labour.feg",
  "UV Sales incl. F&I & Aftercare" = "metric.table.uv.sales.incl.f.and.i.and.aftercare",
  "UV GP incl. F&I & Aftercare" = "metric.table.uv.gp.incl.f.and.i.and.aftercare",
  "Retail GP/ Unit" = "metric.table.retail.gp.unit",
  "Demo GP / Unit" = "metric.table.demo.gp.unit",
  "First Gross / Unit" = "metric.table.first.gross.unit",
  "UV GP excl. F&I / Unit" = "metric.table.uv.gp.excl.f.and.i.unit",
  "UV F&I GP / Unit" = "metric.table.uv.f.and.i.gp.unit",
  "UV GP incl. F&I / Unit" = "metric.table.uv.gp.incl.f.and.i.unit",
  "UV Aftercare GP / Unit" = "metric.table.uv.aftercare.gp.unit",
  "UV GP incl. F&I & Aftercare / Unit" = "metric.table.uv.gp.incl.f.and.i.and.aftercare.unit",
  "UV GP % incl. F&I Aftercare" = "metric.table.uv.gp.incl.f.and.i.aftercare",
  "UV F&I Penetration Analysis" = "metric.table.uv.f.and.i.penetration.analysis",
  "UV Finance Penetration" = "metric.table.uv.finance.penetration",
  "UV Insurance Penetration" = "metric.table.uv.insurance.penetration",
  "Parts Adj. (Pricing & Buying Penalties & Obs.)" = "metric.table.parts.adj.pricing.and.buying.penalties.and.obs",
  "Parts OEM Incentives" = "metric.table.parts.oem.incentives",
  "Parts GP Analysis" = "metric.table.parts.gp.analysis",
  "Parts Service Workshop Retail GP %" = "metric.table.parts.service.workshop.retail.gp",
  "Parts Service Workshop Warranty GP %" = "metric.table.parts.service.workshop.warranty.gp",
  "Parts Service Workshop Internal GP %" = "metric.table.parts.service.workshop.internal.gp",
  "Parts Quick Service GP %" = "metric.table.parts.quick.service.gp",
  "Parts Body Shop Retail GP %" = "metric.table.parts.body.shop.retail.gp",
  "Parts Body Shop Warranty GP %" = "metric.table.parts.body.shop.warranty.gp",
  "Parts Body Shop Internal GP %" = "metric.table.parts.body.shop.internal.gp",
  "Parts Adjustment Analysis" = "metric.table.parts.adjustment.analysis",
  "Parts OEM Incentives % Sales" = "metric.table.parts.oem.incentives.sales",
  "Non-Labour Sales" = "metric.table.non.labour.sales",
  "Non-Labour GP" = "metric.table.non.labour.gp",
  "Service GP Analysis" = "metric.table.service.gp.analysis",
  "Labour Maint. Contract GP%" = "metric.table.labour.maint.contract.gp",
  "Extended Warranty GP%" = "metric.table.extended.warranty.gp",
  "Body Shop GP Analysis" = "metric.table.body.shop.gp.analysis",
  "Total Dealership GP% excl. Manufacturer Incentives" = "metric.table.total.dealership.gp.excl.manufacturer.incentives",
  "MIX By Departments" = "metric.table.mix.by.departments",
  "NV MIX (NV Only)" = "metric.table.nv.mix.nv.only",
  "UV MIX (UV Only)" = "metric.table.uv.mix.uv.only",
  "F&I MIX (NV & UV)" = "metric.table.f.and.i.mix.nv.and.uv",
  "Aftercare MIX (NV & UV)" = "metric.table.aftercare.mix.nv.and.uv",
  "NV MIX incl. F&I & Aftercare" = "metric.table.nv.mix.incl.f.and.i.and.aftercare",
  "UV MIX incl. F&I & Aftercare" = "metric.table.uv.mix.incl.f.and.i.and.aftercare",
  "Quick Service MIX" = "metric.table.quick.service.mix",
  "Service & Quick Service MIX" = "metric.table.service.and.quick.service.mix",
  "NV RETAINED" = "metric.table.nv.retained",
  "NV Total PBT" = "metric.table.nv.total.pbt",
  "NV RETAINED Drivers (SPLIT Analysis)" = "metric.table.nv.retained.drivers.split.analysis",
  "UV incl. F&I & Aftercare" = "metric.table.uv.incl.f.and.i.and.aftercare",
  "UV Total PBT" = "metric.table.uv.total.pbt",
  "UV RETAINED Drivers (SPLIT Analysis)" = "metric.table.uv.retained.drivers.split.analysis",
  "Expenses / Unit Analysis" = "metric.table.expenses.unit.analysis",
  "PARTS Total PBT" = "metric.table.parts.total.pbt",
  "PARTS RETAINED Drivers (SPLIT Analysis)" = "metric.table.parts.retained.drivers.split.analysis",
  "SERVICE Total PBT" = "metric.table.service.total.pbt",
  "SERVICE RETAINED Drivers (SPLIT Analysis)" = "metric.table.service.retained.drivers.split.analysis",
  "BODY SHOP RETAINED" = "metric.table.body.shop.retained",
  "BODY SHOP Total PBT" = "metric.table.body.shop.total.pbt",
  "BODY SHOP RETAINED Drivers (SPLIT Analysis)" = "metric.table.body.shop.retained.drivers.split.analysis",
  "RETAINED Drivers (SPLIT Analysis)" = "metric.table.retained.drivers.split.analysis",
  "Admin Fees Payable % TGP" = "metric.table.admin.fees.payable.tgp",
  "Deduction / (Addition) % TGP" = "metric.table.deduction.addition.tgp",
  "Parts Sales Analysis" = "metric.table.parts.sales.analysis",
  "Parts Workshop Sales / Service RO" = "metric.table.parts.workshop.sales.service.ro",
  "Parts Body Shop Sales / Body Shop RO" = "metric.table.parts.body.shop.sales.body.shop.ro",
  "Parts Workshop Sales % Labour Sales" = "metric.table.parts.workshop.sales.labour.sales",
  "Breakeven Analysis" = "metric.table.breakeven.analysis",
  "Breakeven ROs" = "metric.table.breakeven.ros",
  "Parts Sales Breakdown" = "metric.table.parts.sales.breakdown",
  "Parts Service Workshop Retail Sales" = "metric.table.parts.service.workshop.retail.sales",
  "Parts Service Workshop Warranty Sales" = "metric.table.parts.service.workshop.warranty.sales",
  "Parts Service Workshop Internal Sales" = "metric.table.parts.service.workshop.internal.sales",
  "Parts Quick Service Workshop Sales" = "metric.table.parts.quick.service.workshop.sales",
  "Parts Body Shop Retail Sales" = "metric.table.parts.body.shop.retail.sales",
  "Parts Body Shop Warranty Sales" = "metric.table.parts.body.shop.warranty.sales",
  "Parts Body Shop Internal Sales" = "metric.table.parts.body.shop.internal.sales",
  "RO and Hours Analysis" = "metric.table.ro.and.hours.analysis",
  "Service Sales Breakdown" = "metric.table.service.sales.breakdown",
  "Service Total Sals" = "metric.table.service.total.sals",
  "Service Labour Retail Sales" = "metric.table.service.labour.retail.sales",
  "Service Labour Warranty Sales" = "metric.table.service.labour.warranty.sales",
  "Service Labour Internal Sales" = "metric.table.service.labour.internal.sales",
  "Service Labour Maint. Contaract Sales" = "metric.table.service.labour.maint.contaract.sales",
  "Service Labour Quick Service Sales" = "metric.table.service.labour.quick.service.sales",
  "Breakeven RO" = "metric.table.breakeven.ro",
  "Body Shop Sales Breakdown" = "metric.table.body.shop.sales.breakdown",
  "Body Shop Labour Retail Sales" = "metric.table.body.shop.labour.retail.sales",
  "Body Shop Labour Warranty Sales" = "metric.table.body.shop.labour.warranty.sales",
  "Body Shop Labour Internal Sales" = "metric.table.body.shop.labour.internal.sales",
  "Total Dealership Breakeven NV Units" = "metric.table.total.dealership.breakeven.nv.units",
  "Total Dealership Breakeven Days" = "metric.table.total.dealership.breakeven.days",
  "NV Target Achievement (Factory)" = "metric.table.nv.target.ahcievment.factory",
  "Demo Vehicle Units" = "metric.table.demo.vehicle.units",
  "Vehicle Sales" = "metric.table.vehicle.sales",
  "F&I Sales" = "metric.table.f.and.i.sales",
  "Fixed Ops Sales" = "metric.table.fixed.ops.sales",
  "Quick Service Sales" = "metric.table.quick.service.sales",
  "Service & Quick Service Sales" = "metric.table.service.and.quick.service.sales",
  "NV ACTIVITY" = "metric.table.nv.activity",
  "NV Annualised Sales" = "metric.table.nv.annualised.sales",
  "NV Inventory Analysis" = "metric.table.nv.inventory.analysis",
  "NV Floor Plan Interest Analysis" = "metric.table.nv.floor.plan.interest.analysis",
  "UV ACTIVITY" = "metric.table.uv.activity",
  "UV Annualised Sales" = "metric.table.uv.annualised.sales",
  "UV Inventory Analysis" = "metric.table.uv.inventory.analysis",
  "UV Floor Plan Interest Analysis" = "metric.table.uv.floor.plan.interest.analysis",
  "Parts ACTIVITY" = "metric.table.parts.activity",
  "PARTS Annualised Sales" = "metric.table.parts.annualised.sales",
  "Parts Inventory Analysis" = "metric.table.parts.inventory.analysis",
  "Parts Inventory Age 365+ Days mix" = "metric.table.parts.inventory.age.365.days.mix",
  "UV Inventory Value >90 days mix" = "metric.table.uv.inventory.value.90.days.mix",
  "Service ACTIVITY" = "metric.table.service.activity",
  "SERVICE Annualised Sales" = "metric.table.service.annualised.sales",
  "SERVICE Average Operational Assets" = "metric.table.service.average.operational.assets",
  "Service WIP Analysis" = "metric.table.service.wip.analysis",
  "Service WIP Value" = "metric.table.service.wip.value",
  "Service WIP RO" = "metric.table.service.wip.ro",
  "Body Shop ACTIVITY" = "metric.table.body.shop.activity",
  "BODY SHOP Annualised Sales" = "metric.table.body.shop.annualised.sales",
  "BODY SHOP Average Operational Assets" = "metric.table.body.shop.average.operational.assets",
  "Body Shop WIP Analysis" = "metric.table.body.shop.wip.analysis",
  "Body Shop WIP Value" = "metric.table.body.shop.wip.value",
  "Body Shop WIP RO" = "metric.table.body.shop.wip.ro",
  "ACTIVITY Drivers" = "metric.table.activity.drivers",
  "Demo Stock Days" = "metric.table.demo.stock.days",
  "Service WIP Days" = "metric.table.service.wip.days",
  "Units Sold" = "metric.table.units.sold",
  "Retail Units Sold" = "metric.table.retail.units.sold",
  "Fleet Units Sold" = "metric.table.fleet.units.sold",
  "Total Units Sold" = "metric.table.total.units.sold",
  "Total Units Target" = "metric.table.total.units.target",
  "Target Achievement (Factory)" = "metric.table.target.achievement.factory",
  "SC Productivity Analysis" = "metric.table.sc.productivity.analysis",
  "NV Sales Analysis" = "metric.table.nv.sales.analysis",
  "NV Net Sales / Unit (excl. Acc)" = "metric.table.nv.net.sales.unit.excl.acc",
  "NV Retail Sales / NV Retail Unit" = "metric.table.nv.retail.sales.nv.retail.unit",
  "NV Fleet Sales / NV Fleet Unit" = "metric.table.nv.fleet.sales.nv.fleet.unit",
  "NV Accessories Net Sales / Unit" = "metric.table.nv.accessories.net.sales.unit",
  "NV F&I Penetration Analysis" = "metric.table.nv.f.and.i.penetration.analysis",
  "NV Finance Penetration Retail" = "metric.table.nv.finance.penetration.retail",
  "NV Insurance Penetration Retail" = "metric.table.nv.insurance.penetration.retail",
  "NV Aftercare Sales Analysis" = "metric.table.nv.aftercare.sales.analysis",
  "NV Extended Warranty Sales / Contract" = "metric.table.nv.extended.warranty.sales.contract",
  "Demo Vehicle Units Sold" = "metric.table.demo.vehicle.units.sold",
  "Trade Units Sold" = "metric.table.trade.units.sold",
  "UV Sales Analysis" = "metric.table.uv.sales.analysis",
  "UV Sales / UV Unit (excl. Trade)" = "metric.table.uv.sales.uv.unit.excl.trade",
  "UV Retail Sales / UV Retail Unit" = "metric.table.uv.retail.sales.uv.retail.unit",
  "Demo Sales / Demo Unit" = "metric.table.demo.sales.demo.unit",
  "UV Aftercare Sales Analysis" = "metric.table.uv.aftercare.sales.analysis",
  "UV Extended Warranty Sales / Contract" = "metric.table.uv.extended.warranty.sales.contract",
  "NV Sales / NV Unit" = "metric.table.nv.sales.nv.unit",
  "Dept. Profit % Net Sales" = "metric.table.dept.profit.net.sales",
  "EBIT% (PBIT%)" = "metric.table.ebit.pbit",
  "Selling Gross Analysis" = "metric.table.selling.gross.analysis",
  "Variable Selling Gross % Gorss Profit" = "metric.table.variable.selling.gross.gorss.profit",
  "Selling Gross % Gross Profit" = "metric.table.selling.gross.gross.profit",
  "Selling Gross % Net Sales" = "metric.table.selling.gross.net.sales",
  "Departmental Income Statement" = "metric.table.departmental.income.statement",
  "Departmental Profit" = "metric.table.departmental.profit",
  "EBIT" = "metric.table.ebit",
  "Operational Income % Sales" = "metric.table.opertational.income.sales",
  "Variable Selling Gross % Gross Profit" = "metric.table.variable.selling.gross.gross.profit",
  "Total Dealership Income Statement" = "metric.table.total.dealership.income.statement",
  "Total Expenses" = "metric.table.total.expenses",
  "Total Variable Expenses" = "metric.table.total.variable.expenses",
  "Total Personnel Expenses" = "metric.table.total.personnel.expenses",
  "Total Other Semi Variable Expenses" = "metric.table.total.other.semi.variable.expenses",
  "Total Fixed Expenses" = "metric.table.total.fixed.expenses",
  "Total Interest Expenses" = "metric.table.total.interest.expenses",
  "Total Admin Fees Payable" = "metric.table.total.admin.fees.payable",
  "Total Deduction / (Addition)" = "metric.table.total.deduction.addition",
  "Total Operational Income" = "metric.table.total.operational.income",
  "Total EBIT" = "metric.table.total.ebit",
  "Total PBT" = "metric.table.total.pbt",
  "FOA Drivers" = "metric.table.foa.drivers",
  "Variable Expenses (Non Vehicle)" = "metric.table.variable.expenses.non.vehicle",
  "NV Unit Sold" = "metrics.standard.view.nv.units.sold",
  "STAFF (Average)" = "metric.table.staff.average",
  "Total Staff" = "metric.table.total.staff",
  "NV Productive Staff" = "metric.table.nv.productive.staff",
  "NV Non-Productive Staff" = "metric.table.nv.non.productive.staff",
  "NV PDI Staff" = "metric.table.nv.pdi.staff",
  "F&I Staff Allocation" = "metric.table.f.and.i.staff.allocation",
  "Aftercare Staff Allocation" = "metric.table.aftercare.staff.allocation",
  "UV Units Sold" = "metric.table.uv.units.sold",
  "UV Productive Staff" = "metric.table.uv.productive.staff",
  "UV Non-Productive Staff" = "metric.table.uv.non.productive.staff",
  "Afterstaff Staff Allocation" = "metric.table.afterstaff.staff.allocation",
  "Total Parts Sales" = "metric.table.total.parts.sales",
  "Parts Productive Staff" = "metric.table.parts.productive.staff",
  "Parts Workshop Sales / Productive / Month" = "metric.table.parts.workshop.sales.productive.month",
  "Parts Sales / RO Analysis" = "metric.table.parts.sales.ro.analysis",
  "Productive Staff" = "metric.table.productive.staff",
  "Non-Productive Staff" = "metric.table.non.productive.staff",
  "Total Labour Sales" = "metric.table.total.labour.sales",
  "Labour Sales / RO" = "metric.table.labour.sales.ro",
  "Support Staff incl. SA" = "metric.table.support.staff.incl.sa",
  "Support Service Advisor" = "metric.table.support.service.advisor",
  "Total Gross Profit / Productive / Month" = "metric.table.total.gross.profit.productive.month",
  "Total PBT / Employee / Month" = "metric.table.total.pbt.employee.month",
  "PRODUCTIVITY by Departments" = "metric.table.productivity.by.departments",
  "GP / Productive / Month NV only" = "metric.table.gp.productive.month.nv.only",
  "GP / Productive / Month UV only" = "metric.table.gp.productive.month.uv.only",
  "GP / Productive / Month F&I" = "metric.table.gp.productive.month.f.and.i",
  "GP / Productive / Month Aftercare" = "metric.table.gp.productive.month.aftercare",
  "GP / Productive / Month NV Incl. F&I & Aftercare" = "metric.table.gp.productive.month.nv.incl.f.and.i.and.aftercare",
  "GP / Productive / Month UV Incl. F&I & Aftercare" = "metric.table.gp.productive.month.uv.incl.f.and.i.and.aftercare",
  "GP / Productive / Month Parts" = "metric.table.gp.productive.month.parts",
  "GP / Productive / Month Service" = "metric.table.gp.productive.month.service",
  "GP / Productive / Month Quick Service" = "metric.table.gp.productive.month.quick.service",
  "GP / Productive / Month Serv. & Quick Service" = "metric.table.gp.productive.month.serv.and.quick.service",
  "GP / Productive / Month Body Shop" = "metric.table.gp.productive.month.body.shop",
  "GP / Productive / Month Others" = "metric.table.gp.productive.month.others",
  "STAFF" = "metric.table.staff",
  "Total Staff Productives" = "metric.table.total.staff.productives",
  "Total Staff Non Productives" = "metric.table.total.staff.non.productives",
  "Ratio Productive Non Productive" = "metric.table.ratio.productive.non.productive",
  "NV Inventory Value >90 days mix" = "metric.table.nv.inventory.value.90.days.mix",
  "Per Retail Unit Analysis" = "metric.table.per.retail.unit.analysis",
  "Retail Net Sales / Unit (excl. Acc)" = "metric.table.retail.net.sales.unit.excl.acc",
  "Retail Give Away / Unit" = "metric.table.retail.give.away.unit",
  "Retail Front End Gross / Unit" = "metric.table.retail.front.end.gross.unit",
  "Retail Direct Incentives / Unit" = "metric.table.retail.direct.incentives.unit",
  "Retail First Gross / Unit" = "metric.table.retail.first.gross.unit",
  "Retail GP % Analysis" = "metric.table.retail.gp.analysis",
  "Retail Sales Effort % Gross Sales" = "metric.table.retail.sales.effort.gross.sales",
  "Retail FEG % Gross Sales" = "metric.table.retail.feg.gross.sales",
  "Retail First Gross % Net Sales" = "metric.table.retail.first.gross.net.sales",
  "FLEET" = "metric.table.fleet",
  "Per Fleet Unit Analysis" = "metric.table.per.fleet.unit.analysis",
  "Fleet Net Sales / Unit (excl. Acc)" = "metric.table.fleet.net.sales.unit.excl.acc",
  "Fleet Give Away / Unit" = "metric.table.fleet.give.away.unit",
  "Fleet Front End Gross / Unit" = "metric.table.fleet.front.end.gross.unit",
  "Fleet Direct Incentives / Unit" = "metric.table.fleet.direct.incentives.unit",
  "Fleet First Gross / Unit" = "metric.table.fleet.first.gross.unit",
  "Fleet GP % Analysis" = "metric.table.fleet.gp.analysis",
  "Fleet Sales Effort % Gross Sales" = "metric.table.fleet.sales.effort.gross.sales",
  "Fleet FEG % Gross Sales" = "metric.table.fleet.feg.gross.sales",
  "Fleet First Gross % Net Sales" = "metric.table.fleet.first.gross.net.sales",
  "NV UV Ratio" = "metric.table.nv.uv.ratio",
  "UV Retail Analysis" = "metric.table.uv.retail.analysis",
  "UV Retail Reconditioning / UV Retail Unit" = "metric.table.uv.retail.reconditioning.uv.retail.unit",
  "UV Retail First Gross / UV Retail Unit" = "metric.table.uv.retail.first.gross.uv.retail.unit",
  "Size (New Units)" = "metric.table.size.new.units",
  "# Service RO" = "metric.table.service.ro",
  "Retail RO % Total RO" = "metric.table.retail.ro.total.ro",
  "Parts Sales / Service RO" = "metric.table.parts.sales.service.ro",
  "Service Productivity" = "metric.table.service.productivity",
  "Service Efficiency" = "metric.table.service.efficiency",
  "No. of FGE implementations - Service" = "metric.table.no.of.fge.implementations.service",
  "JD Power Score - CSI" = "metric.table.jd.power.score.csi",
  "CVP - Service" = "metric.table.cvp.service",
  "Same Day Delivery %" = "metric.table.same.day.delivery",
  "Express Service Promise %" = "metric.table.express.service.promise",
  "FRFT%" = "metric.table.frft",
  "Service Advisor Competence Mix" = "metric.table.service.advisor.competence.mix",
  "Average  % Of Dealer Staff Attrition" = "metric.table.average.of.dealer.staff.attrition",
  "Training Day %" = "metric.table.training.day",
  "Working Capital in Days of Turnover" = "metric.table.working.capital.in.days.of.turnover",
  "Average Total Current Assets" = "metric.table.average.total.current.assets",
  "Average Total Current Liabilities" = "metric.table.average.total.current.liabilities",
  "Working Capital / Unit" = "metric.table.working.capital.unit",
  "Current Ratio" = "metric.table.current.ratio",
  "Current Assets (Average)" = "metric.table.current.assets.average",
  "Cash and Cash equivalents" = "metric.table.cash.and.cash.equivalents",
  "Inventory" = "metric.table.inventory",
  "Current Liabilities (Average)" = "metric.table.current.liabilities.average",
  "Creditors & Other Current Liabilities" = "metric.table.creditors.and.other.current.liabilities",
  "Deferred Tax" = "metric.table.deferred.tax",
  "Deposits" = "metric.table.deposits",
  "Overdraft" = "metric.table.overdraft",
  "Provisions" = "metric.table.provisions",
  "S/T Loan Inter Company" = "metric.table.s.t.loan.inter.company",
  "S/T Loan Other" = "metric.table.s.t.loan.other",
  "Total Shareholders Fund" = "metric.table.total.shareholders.fund",
  "DEBT to Assets Drivers" = "metric.table.debt.to.assets.drivers",
  "DEBT to ASSET" = "metric.table.debt.to.asset",
  "Total Liabilities" = "metric.table.total.liabilities",
  "TOTAL LIABILITIES" = "metric.table.total.liabilities.big",
  "Liabilities" = "metric.table.liabilities",
  "LIABILITIES" = "metric.table.liabilities.big",
  "Current Liabilities" = "metric.table.current.liabilities",
  "Non-Current Liabilities" = "metric.table.non.current.liabilities",
  "Shareholders Fund" = "metric.table.shareholders.fund",
  "Directors Accounts" = "metric.table.directors.accounts",
  "Issued Share Capital" = "metric.table.issued.share.capital",
  "Distributable Reserves" = "metric.table.distributable.reserves",
  "Non Distributable Reserves" = "metric.table.non.distributable.reserves",
  "Retained Earnings" = "metric.table.retained.earnings",
  "Revaluation of Fixed Assets" = "metric.table.revaluation.of.fixed.assets",
  "Share Premium" = "metric.table.share.premium",
  "Total Assets excl. Goodwill & Parent Child Balance" = "metric.table.total.assets.excl.goodwill.and.parent.child.balance",
  "Current Assets" = "metric.table.current.assets",
  "Land & Buildings" = "metric.table.land.and.buildings",
  "Investments excl. Parent Child Balance" = "metric.table.investments.excl.parent.child.balance",
  "ROI" = "metric.table.roi",
  "Annualised PBT" = "metric.table.annualised.pbt",
  "Average Total Shareholders Fund" = "metric.table.average.total.shareholders.fund",
  "BALANCE SHEET" = "metric.table.balance.sheet",
  "ASSETS" = "metric.table.assets",
  "Receivables Non Vehicle" = "metric.table.receivables.non.vehicle",
  "Receivables Vehicle" = "metric.table.receivables.vehicle",
  "Receivables Other" = "metric.table.receivables.other",
  "NV Inventory" = "metric.table.nv.inventory",
  "UV Inventory" = "metric.table.uv.inventory",
  "Parts Inventory" = "metric.table.parts.inventory",
  "Other Inventory" = "metric.table.other.inventory",
  "Non Current Assets" = "metric.table.non.current.assets",
  "Goodwill" = "metric.table.goodwill",
  "Investments" = "metric.table.investments",
  "Total Assets" = "metric.table.total.assets",
  "SHAREHOLDER FUNDS" = "metric.table.shareholder.funds",
  "Total Shareholder Funds" = "metric.table.total.shareholder.funds",
  "L/T Loan Shareholders" = "metric.table.l.t.loan.shareholders",
  "L/T Loan Inter Company" = "metric.table.l.t.loan.inter.company",
  "L/T Loan Mortgage" = "metric.table.l.t.loan.mortgage",
  "L/T Loan Other" = "metric.table.l.t.loan.other",
  "Total Equity & Liabilities" = "metric.table.total.equity.and.liabilities",
  "CCC = DIO + DSO - DPO" = "metric.table.ccc.dio.dso.dpo",
  "DIO (Total Inventory Days)" = "metric.table.dio.total.inventory.days",
  "DSO (Total Debtor Days)" = "metric.table.dso.total.debtor.days",
  "DPO (Total Payable Days)" = "metric.table.dpo.total.payable.days",
  "Inventory Analysis" = "metric.table.inventory.analysis",
  "NV Inventory value mix (% Total Inv.)" = "metric.table.nv.inventory.value.mix.total.inv",
  "UV Inventory value mix (% Total Inv.)" = "metric.table.uv.inventory.value.mix.total.inv",
  "Parts Inventory value mix (% Total Inv.)" = "metric.table.parts.inventory.value.mix.total.inv",
  "Parts Workshop Sales (Service & B. Shop) % Total Sales" = "metric.table.parts.workshop.sales.service.and.b.shop.total.sales",
  "Parts Workshop Sales" = "metric.table.parts.workshop.sales",
  "Parts Workshop Sales Breakdown" = "metric.table.parts.workshop.sales.breakdown",
  "Parts Sales per Service RO Retail" = "metric.table.parts.sales.per.service.ro.retail",
  "Parts Sales per Service RO Warranty" = "metric.table.parts.sales.per.service.ro.warranty",
  "Parts Sales per Service RO Internal" = "metric.table.parts.sales.per.service.ro.internal",
  "Parts Sales per Body Shop RO Retail" = "metric.table.parts.sales.per.body.shop.ro.retail",
  "Parts Sales per Body Shop RO Warranry" = "metric.table.parts.sales.per.body.shop.ro.warranry",
  "Parts Sales per Body Shop RO Internal" = "metric.table.parts.sales.per.body.shop.ro.internal",
  "Labour Sales mix of Total Service Sales" = "metric.table.labour.sales.mix.of.total.service.sales",
  "Total Service Sales" = "metric.table.total.service.sales",
  "Service Labour Sales Breakdown" = "metric.table.service.labour.sales.breakdown",
  "Service Labour Sales mix (% TSS)" = "metric.table.service.labour.sales.mix.tss",
  "Service Labour Retail Sales mix (%TLS)" = "metric.table.service.labour.retail.sales.mix.tls",
  "Service Labour Warranty Sales mix (%TLS)" = "metric.table.service.labour.warranty.sales.mix.tls",
  "Service Labour Internal Sales mix (%TLS)" = "metric.table.service.labour.internal.sales.mix.tls",
  "Service Labour Maint. Contract Sales mix (%TLS)" = "metric.table.service.labour.maint.contract.sales.mix.tls",
  "Service Labour Quick Service Sales mix (%TLS)" = "metric.table.service.labour.quick.service.sales.mix.tls",
  "Service RO Retail" = "metric.table.service.ro.retail",
  "Service RO Warranty" = "metric.table.service.ro.warranty",
  "Service RO Internal" = "metric.table.service.ro.internal",
  "Service RO Maintenance Plan" = "metric.table.service.ro.maintenance.plan",
  "Service RO Quick Service" = "metric.table.service.ro.quick.service",
  "Service Hours Sold per Service RO Retail" = "metric.table.service.hours.sold.per.service.ro.retail",
  "Service Hours Sold per Service RO Warranty" = "metric.table.service.hours.sold.per.service.ro.warranty",
  "Service Hours Sold per Service RO Internal" = "metric.table.service.hours.sold.per.service.ro.internal",
  "Service Hours Sold per Service RO Maintenance Plan" = "metric.table.service.hours.sold.per.service.ro.maintenance.plan",
  "Service Hours Sold per Service RO Quick Service" = "metric.table.service.hours.sold.per.service.ro.quick.service",
  "Effective Labour Rate / Hour Retail" = "metric.table.effective.labour.rate.hour.retail",
  "Effective Labour Rate / Hour Warranty" = "metric.table.effective.labour.rate.hour.warranty",
  "Effective Labour Rate / Hour Internal" = "metric.table.effective.labour.rate.hour.internal",
  "Effective Labour Rate / Hour Maintenance Plan" = "metric.table.effective.labour.rate.hour.maintenance.plan",
  "Effective Labour Rate / Hour Quick Service" = "metric.table.effective.labour.rate.hour.quick.service",
  "Service Labour GP Analysis" = "metric.table.service.labour.gp.analysis",
  "Service Idle Time % TLS" = "metric.table.service.idle.time.tls",
  "Service Prod. Benefits & Incentives" = "metric.table.service.prod.benefits.and.incentives",
  "Total Body Shop Sales" = "metric.table.total.body.shop.sales",
  "Body Shop Labour Sales Breakdown" = "metric.table.body.shop.labour.sales.breakdown",
  "Body Shop RO Retail" = "metric.table.body.shop.ro.retail",
  "Body Shop RO Warranty" = "metric.table.body.shop.ro.warranty",
  "Body Shop RO Internal" = "metric.table.body.shop.ro.internal",
  "Working Capital Amount" = "metric.table.working.capital.amount",
  "Total Debtor Days (DSO)" = "metric.table.total.debtor.days.dso",
  "Total Receivable" = "metric.table.total.receivable",
  "Total Receivables (Average)" = "metric.table.total.receivables.average",
  "Total Receivables (average)" = "metric.table.total.receivables.average",
  "Total Inventory Days (DIO)" = "metric.table.total.inventory.days.dio",
  "Total Inventory" = "metric.table.total.inventory",
  "Total Cost of Goods Sold (COGS)" = "metric.table.total.cost.of.goods.sold.cogs",
  "Empty Space" = "empty.space",
  "Empty Measure" = "empty.measure",
  "Other Departments MIX" = "metric.table.other.departments.mix",
  "Ratio Productive : Non Productive" = "metric.table.ratio.productive.non.productive",
  "ROOA NV incl. F&I & Aftercare" = "metric.table.rooa.nv.incl.f.and.i.and.aftercare",
  "ROOA NV only" = "metric.table.rooa.nv.only",
  "ROOA UV only" = "metric.table.rooa.uv.only",
  "NV incl. F&I & Aftercare" = "metric.table.nv.incl.f.and.i.and.aftercare",
  "RETAIL" = "metric.table.retail",
  "ROOA UV incl. F&I & Aftercare" = "metric.table.uv.incl.f.and.i.and.aftercare",
  "NV : UV Ratio" = "metric.table.nv.uv.ratio",
  "PARTS Total Sales" = "metric.table.parts.total.sales",
  "PARTS Total GP" = "metric.table.parts.total.gp",
  "Parts Inventory Age 0-90 Days mix" = "metric.table.parts.inventory.age.0.90.days.mix",
  "Labour GP" = "metric.table.labour.gp",
  "SERVICE Total GP" = "metric.table.service.total.gp.big",
  "SERVICE Total Expenses" = "metric.table.service.total.expenses.big",
  "Labour Retail GP %" = "metric.table.labour.retail.gp.perc",
  "Labour Warranty GP %" = "metric.table.labour.warranty.gp.perc",
  "Labour Internal GP %" = "metric.table.labour.internal.gp.perc",
  "Sublet GP %" = "metric.table.sublet.gp.perc",
  "Other GP %" = "metric.table.other.gp.perc",
  "BODY SHOP Total GP" = "metric.table.body.shop.total.gp.big",
  "BODY SHOP Total Expenses" = "metric.table.body.shop.total.expenses.big",
  "Aftercare Sales" = "metric.table.aftercare.sales",
  "SALES Units" = "metric.chart.sales.units",
  "RETAIL & FLEET" = "metric.chart.retail.and.fleet",
  "Retail & Fleet" = "metric.chart.retail.and.fleet",
  "UV RETAIL" = "metric.chart.uv.retail",
  "Parts Workshop" = "metric.chart.parts.workshop",
  "SERVICE LABOUR" = "metric.chart.service.labour",
  "Body Shop Labour" = "metric.chart.body.shop.labour",
  "Retained" = "metric.chart.retained.label",
  "Mix" = "metric.chart.mix",
  "Service Labour" = "metric.chart.service.labour.small",
  "Working Capital Days" = "metric.chart.working.capital.days.label",
  "Total Debtors Days" = "metric.chart.total.debtors.days",
  "Cash Conversion Cycle" = "metric.chart.cash.conversion.cycle",
  "Total Inventory Days" = "metric.chart.total.inventory.days",
  "DEBT : EQUITY" = "metric.chart.debt.to.equity",
  "Debt : Equity" = "metric.chart.debt.to.equity",
  "Activity" = "metric.chart.activity.label",
  "NV Retained" = "metric.chart.nv.retained",
  "Total Current Assests vs Total Current Liability" = "metric.chart.total.current.assets.vs.total.current.liability",
  "Total Current Assests vs Total Current Liabilities" = "metric.chart.total.current.assets.vs.total.current.liabilities",
  "Total Inv. Days - Total Debtor Days - Total Payable Days" = "metric.chart.total.inv.days.total.debtor.days.total.payable.days",
  "CCC" = "metric.chart.cash.conversion.cycle.abbr",
  "Total Debtor Days" = "metric.chart.total.debtor.days",
  "Receivable mix (Vehicle, Non Veh, Other)" = "metric.chart.receivable.mix",
  "Inventory Value mix (NV, UV, Parts)" = "metric.chart.inventory.value.mix",
  "Debt : Asset" = "metric.chart.debit.to.asset",
  "Avg. Shareholders Fund & Annualized PBT" = "metric.chart.avg.shareholders.fund.and.annualized.pbt",
  "TNS > Net Sales (excl Acc) pu" = "metric.chart.tns.net.sales.pu",
  "Others GP" = "metric.chart.others.gp",
  "Other Semi Var. Expenses" = "metric.chart.other.semi.var.expenses",
  "Others Expenses" = "metric.chart.others.expenses",
  "Rule" = "rule.label",
  "Inventory Days" = "metric.chart.inventory.days",
  "Payables Days" = "metric.chart.payables.days",
  "Receivables Non-Vehicles Total" = "metric.chart.receivables.non.vehicles.total",
  "Receivables Vehicles" = "metric.chart.receivables.vehicles",
  "PARTS Dept. Inventory % Total Inventory" = "metric.chart.parts.dept.inventory.total.inventory",
  "NV Dept. Inventory % Total Inventory" = "metric.chart.nv.dept.inventory.total.inventory",
  "UV Dept. Inventory % Total Inventory" = "metric.chart.uv.dept.inventory.total.inventory",
  "Projected Annualised PBT" = "metric.chart.projected.annualised.pbt",
  "Capital and Reserves" = "metric.chart.capital.and.reserves",
  "NV GP % incl. F&I Aftercare" = "metric.table.nv.gp.incl.f.and.i.aftercare",
  "NV First Gross per Unit" = "metric.table.nv.first.gross.per.unit",
  "NV Back End Gross per Unit" = "metric.table.nv.back.end.gross.per.unit",
  "NV Variable Expenses % NV GP" = "metric.table.nv.variable.expenses.nv.gp",
  "NV Variable Selling Gross % NV GP" = "metric.table.nv.variable.selling.gross.nv.gp",
  "NV Variable Selling Gross net of Personnel Expenses / Unit" = "metric.table.nv.variable.selling.gross.net.of.personnel.expenses.unit",
  "NV Variable Selling Gross net of Personnel Expenses % GP" = "metric.table.nv.variable.selling.gross.net.of.personnel.expenses.gp",
  "Retail Units Avg." = "metric.table.retail.units.avg",
  "Fleet Units Avg." = "metric.table.fleet.units.avg",
  "Target Units Avg." = "metric.table.target.units.avg",
  "RANGER" = "metric.chart.ranger",
  "Unknown" = "metric.chart.unknown",
  "MONDEO" = "metric.chart.mondeo",
  "FOCUS" = "metric.chart.focus",
  "FIESTA" = "metric.chart.fiesta",
  "Number of SC (Avg.)" = "metric.chart.number.sc.avg",
  "Other" = "metric.chart.body.other",
  "BodyShop" = "metric.chart.bodyshop",
  "UV Variable Expenses % UV GP" = "metric.chart.uv.variable.expenses.uv.gp",
  "PBT Avg. / Dealer / Month" = "metric.chart.pbt.avg.dealer.month",
  "NAVARA" = "metric.chart.navara",
  "ALMERA" = "metric.chart.almera",
  "MARCH" = "metric.chart.march",
  "NOTE" = "metric.chart.note",
  "SLYPHY" = "metric.chart.slyphy",
  "UV Variable Selling Gross net of Personnel Expenses / Unit" = "metric.chart.uv.variable.selling.gross.net.of.personnel.expenses.unit",
  "UV Variable Selling Gross % UV GP" = "metric.chart.uv.variable.selling.gross.uv.gp",
  "Parts GP %" = "metric.chart.parts.gp",
  "Parts Inventory Age 180-365 Days mix" = "metric.chart.parts.inventory.age.180.365.days.mix",
  "Parts Sales per Service Retail RO" = "metric.chart.parts.sales.per.service.retail.ro",
  "Parts Sales per Body Shop Retail RO" = "metric.chart.parts.sales.per.body.shop.retail.ro",
  "TNS > Net Sales" = "metric.chart.tns.net.sales",
  "Service GP %" = "metric.chart.service.gp",
  "TNS > Labour Sales % Of TSS" = "metric.chart.tns.labour.sales.of.tss",
  "TNS > Labour Sales (TLS)" = "metric.chart.tns.labour.sales.tls",
  "Total Dealers in network " = "metric.chart.total.dealers.in.network",
  "MTD" = "metric.table.header.mtd",
  "MTD-1" = "metric.table.header.mtd.1",
  "MTD-2" = "metric.table.header.mtd.2",
  "MTD-3" = "metric.table.header.mtd.3",
  "MTD-4" = "metric.table.header.mtd.4",
  "MTD-5" = "metric.table.header.mtd.5",
  "MTD-6" = "metric.table.header.mtd.6",
  "MTD-7" = "metric.table.header.mtd.7",
  "MTD-8" = "metric.table.header.mtd.8",
  "MTD-9" = "metric.table.header.mtd.9",
  "MTD-10" = "metric.table.header.mtd.10",
  "MTD-11" = "metric.table.header.mtd.11",
  "MTD-12" = "metric.table.header.mtd.12",
  "Q" = "metric.table.header.q",
  "Q-1" = "metric.table.header.q.1",
  "Q-2" = "metric.table.header.q.2",
  "Q-3" = "metric.table.header.q.3",
  "Q-4" = "metric.table.header.q.4",
  "Q-5" = "metric.table.header.q.5",
  "Q-6" = "metric.table.header.q.6",
  "Q-7" = "metric.table.header.q.7",
  "TMRA" = "metric.table.header.tmra",
  "YTD" = "metric.table.header.ytd",
  "YTD-1" = "metric.table.header.ytd.1",
  "CYTD" = "metric.table.header.cytd",
  "CYTD-1" = "metric.table.header.cytd.1",
  "CYTD-2" = "metric.table.header.cytd.2",
  "CYTD-3" = "metric.table.header.cytd.3",
  "CYTD-4" = "metric.table.header.cytd.4",
  "CYTD-5" = "metric.table.header.cytd.5",
  "FYTD" = "metric.table.header.fytd",
  "FYTD-1" = "metric.table.header.fytd.1",
  "FYTD-2" = "metric.table.header.fytd.2",
  "FYTD-3" = "metric.table.header.fytd.3",
  "FYTD-4" = "metric.table.header.fytd.4",
  "FYTD-5" = "metric.table.header.fytd.5",
  "Var" = "metric.table.header.var",
  "Var." = "metric.table.header.var.v2",
  "Total Admin Fees Payable " = "metric.table.total.admin.fees.payable.space",
  "ROS Drivers" = "metric.table.space.ros.drivers",
  "CURRENT RATIO Drivers" = "metric.table.current.ratio.drivers",
  "WORKING CAPITAL DAYS Drivers" = "metric.table.working.capital.days.drivers",
  "WORKING CAPITAL AMOUNT Drivers" = "metric.table.working.capital.amount.drivers",
  "CCC (Cash Conversion Cycle) Drivers" = "metric.table.cash.conversion.cycle.drivers",
  "DEBTOR DAYS Drivers" = "metric.table.total.debtor.days.drivers",
  "TOTAL INVENTORY DAYS Drivers" = "metric.table.total.inventory.days.drivers",
  "DEBT to EQUITY Drivers" = "metric.table.debt.to.equity.drivers",
  "ROI Drivers" = "metric.table.roi.drivers",
  "NV ROOA Drivers" = "metric.table.nv.rooa.drivers",
  "UV ROOA Drivers" = "metric.table.uv.rooa.drivers",
  "PARTS ROOA Drivers" = "metric.table.parts.rooa.drivers",
  "NV RETAIL  &  NV FLEET Drivers" = "metric.table.nv.retail.nv.fleet.drivers.space",
  "Aftercare Sales " = "metric.table.aftercare.sales.space",
  "UV RETAIL Drivers" = "metric.table.uv.retail.drivers",
  "PARTS Workshop Sales Drivers" = "metric.table.parts.workshop.sales.drivers",
  "SERVICE ACTIVITY Drivers" = "metric.table.service.activity.drivers",
  "SERVICE ROOA Drivers" = "metric.table.service.rooa.drivers",
  "BODY SHOP ROOA Drivers" = "metric.table.body.shop.rooa.drivers",
  "SERVICE LABOUR SALES Drivers" = "metric.table.service.labour.sales.drivers",
  "SERVICE LABOUR Drivers" = "metric.table.service.labour.sales.drivers",
  "BODY SHOP LABOUR SALES Drivers" = "metric.table.body.shop.labour.sales.drivers",
  "PRODUCTIVITY Drivers" = "metric.table.productivity.drivers",
  "NMT CYTD" = "benchmarks.nmt.cytd",
  "NMT CYTD - Median" = "benchmarks.nmt.cytd.median",
  "DEBT TO EQUITY Drivers" = "metric.table.debt.to.equity.drivers.cap",
  "NV RETAIL & NV FLEET Drivers" = "metric.table.nv.retail.nv.fleet.drivers.space",
  "PARTS RETAINED Drivers" = "metric.table.parts.retained.drivers",
  "BODY SHOP ACTIVITY Drivers" = "metric.table.body.shop.activity.drivers",
  "MEA Dashboard" = "custom.tabs.mea.dashboard",
  "New Dashboard City" = "custom.tabs.new.dashboard.city",
  "SA MArket" = "custom.tabs.sa.market",
  "2x4 metrics" = "number.one.label",
  "3x3 metrics" = "number.two.label",
  "2x2 graphs" = "number.three.label",
  "4x3 metrics" = "number.four.label",
  "2 graphs, 4x2" = "number.five.label",
  "Table, 2 graphs" = "number.six.label",
  "2x3 graphs" = "number.eight.label",
  "2x2 bar charts" = "2x2 bar charts",
  "3x4 graphs" = "3x4 graphs",
  "3x2 gauge" = "number.eleven.label",
  "4x2 gauge" = "number.nine.label",
  "gauge 3x2 trend" = "number.ten.label",
  "3x4 metrics" = "number.twelve.label",
  "2 graphs, 2x4" = "number.13.label",
  "Departments" = "departments.label",
  "Categories" = "categories.label",
  "Labels" = "labels.label",
  "Overall Dealership" = "custom.metric.department.overall.dealership",
  "New Vehicle" = "custom.metric.department.new.vehicle",
  "Used Vehicle" = "custom.metric.department.used.vehicle",
  "F&I" = "custom.metric.department.f.and.i",
  "After Sales" = "custom.metric.department.after.sales",
  "AFTERSALES" = "custom.metric.department.after.sales",
  "AFTER-SALES" = "custom.metric.department.after.sales",
  "AFTER SALES" = "custom.metric.department.after.sales",
  "ROS (Income Statement)" = "custom.metric.rosincome.label",
  "OEM Supports Analysis" = "custom.metric.oemsupport.label",
  "Staff Productivity" = "custom.metric.category.staff.productivity",
  "Headcount" = "custom.metric.category.headcount",
  "Dealership Expenses" = "custom.metric.category.dealership.expenses",
  "Risk ratio" = "custom.metric.category.risk.ratio",
  "New Vehicle per unit" = "custom.metric.category.new.vehicle.per.unit",
  "New Vehicle %" = "custom.metric.category.new.vehicle.",
  "New Vehicle Operational" = "custom.metric.category.new.vehicle.operational",
  "New Vehicle Inventory" = "custom.metric.category.new.vehicle.inventory",
  "New Vehicle Retail per unit" = "custom.metric.category.new.vehicle.retail.per.unit",
  "New Vehicle Retail %" = "custom.metric.category.new.vehicle.retail.",
  "New Vehicle Retail Operational" = "custom.metric.category.new.vehicle.retail.operational",
  "New Vehicle Fleet per unit" = "custom.metric.category.new.vehicle.fleet.per.unit",
  "New Vehicle Fleet %" = "custom.metric.category.new.vehicle.fleet.",
  "New Vehicle Fleet Operational" = "custom.metric.category.new.vehicle.fleet.operational",
  "Used Vehicle per unit" = "custom.metric.category.used.vehicle.per.unit",
  "Used Vehicle %" = "custom.metric.category.used.vehicle.",
  "Used Vehicle Operational" = "custom.metric.category.used.vehicle.operational",
  "Used Vehicle Retail per unit" = "custom.metric.category.used.vehicle.retail.per.unit",
  "Used Vehicle Retail %" = "custom.metric.category.used.vehicle.retail.",
  "Used Vehicle Retail Operational" = "custom.metric.category.used.vehicle.retail.operational",
  "New Vehicle F&I" = "custom.metric.category.new.vehicle.f.and.i",
  "Used Vehicle F&I" = "custom.metric.category.used.vehicle.f.and.i",
  "After Sales / RO" = "custom.metric.category.after.sales.ro",
  "After Sales %" = "custom.metric.category.after.sales.",
  "Parts Sales Mix" = "custom.metric.category.parts.sales.mix",
  "Parts / RO" = "custom.metric.category.parts.ro",
  "Parts Operational" = "custom.metric.category.parts.operational",
  "Service Sales Mix" = "custom.metric.category.service.sales.mix",
  "Service per RO" = "custom.metric.category.service.per.ro",
  "Service Operational" = "custom.metric.category.service.operational",
  "Body Shop Sales Mix" = "custom.metric.category.body.shop.sales.mix",
  "Body Shop %" = "custom.metric.category.body.shop.",
  "Body Shop per RO" = "custom.metric.category.body.shop.per.ro",
  "Body Shop Operational" = "custom.metric.category.body.shop.operational",
  "Gross profit" = "custom.metric.label.gross.profit",
  "Total GP / Productive" = "custom.metric.label.total.gp.productive",
  "Total PBT / Employee" = "custom.metric.label.total.pbt.employee",
  "GP / Productive - New Vehicle" = "custom.metric.label.gp.productive.new.vehicle",
  "GP / Productive - Used Vehicle" = "custom.metric.label.gp.productive.used.vehicle",
  "GP / Productive - F&I" = "custom.metric.label.gp.productive.f.and.i",
  "GP / Productive - Aftercare" = "custom.metric.label.gp.productive.aftercare",
  "Sales / Productive - Parts" = "custom.metric.label.sales.productive.parts",
  "GP / Productive - Parts" = "custom.metric.label.gp.productive.parts",
  "Sales / Productive - Service Labour" = "custom.metric.label.sales.productive.service.labour",
  "GP / Productive - Service" = "custom.metric.label.gp.productive.service",
  "Sales / Productive - Body Shop Labour" = "custom.metric.label.sales.productive.body.shop.labour",
  "GP / Productive - Body Shop" = "custom.metric.label.gp.productive.body.shop",
  "Admin Fees % GP" = "custom.metric.label.admin.fees.gp",
  "Additions to Income % GP" = "custom.metric.label.additions.to.income.gp",
  "Deductions from Income % GP" = "custom.metric.label.deductions.from.income.gp",
  "Debt to Asset Ratio" = "custom.metric.label.debt.to.asset.ratio",
  "Working Capital" = "custom.metric.label.working.capital",
  "WORKING CAPITAL" = "custom.metric.label.working.capital",
  "Debt to Equity" = "custom.metric.label.debt.to.equity",
  "NV Net sales per unit" = "custom.metric.label.nv.net.sales.per.unit",
  "NV First Gross per unit" = "custom.metric.label.nv.first.gross.per.unit",
  "NV Accessory GP per unit" = "custom.metric.label.nv.accessory.gp.per.unit",
  "NV GP excl. F&I per unit" = "custom.metric.label.nv.gp.excl.f.and.i.per.unit",
  "NV F&I GP per unit" = "custom.metric.label.nv.f.and.i.gp.per.unit",
  "NV GP incl. F&I per unit" = "custom.metric.label.nv.gp.incl.f.and.i.per.unit",
  "NV Aftercare GP per unit" = "custom.metric.label.nv.aftercare.gp.per.unit",
  "NV GP incl. F&I & Aftercare per unit" = "custom.metric.label.nv.gp.incl.f.and.i.and.aftercare.per.unit",
  "Total Variable Exp. per unit" = "custom.metric.label.total.variable.exp.per.unit",
  "Sales Commission per unit" = "custom.metric.label.sales.commission.per.unit",
  "Direct Advertising per unit" = "custom.metric.label.direct.advertising.per.unit",
  "PDI & Free Service per unit" = "custom.metric.label.pdi.and.free.service.per.unit",
  "Other Variable Exp. per unit" = "custom.metric.label.other.variable.exp.per.unit",
  "Variable Selling Gross per unit" = "custom.metric.label.variable.selling.gross.per.unit",
  "Floor Plan Interest per unit" = "custom.metric.label.floor.plan.interest.per.unit",
  "NV Sales Effort % Gross Sales" = "custom.metric.label.nv.sales.effort.gross.sales",
  "NV FEG % Gross Sales" = "custom.metric.label.nv.feg.gross.sales",
  "NV First Gross % Net Sales" = "custom.metric.label.nv.first.gross.net.sales",
  "NV GP% Net Sales (excl. F&I)" = "custom.metric.label.nv.gp.net.sales.excl.f.and.i",
  "NV GP% Net Sales (incl. F&I)" = "custom.metric.label.nv.gp.net.sales.incl.f.and.i",
  "NV GP% Net Sales (incl. F&I & Aftercare)" = "custom.metric.label.nv.gp.net.sales.incl.f.and.i.and.aftercare",
  "Variable Expenses % NV GP" = "custom.metric.label.variable.expenses.nv.gp",
  "Variable Selling Gross % NV GP" = "custom.metric.label.variable.selling.gross.nv.gp",
  "NV Breakeven Units" = "custom.metric.label.nv.breakeven.units",
  "NV Breakeven Days" = "custom.metric.label.nv.breakeven.days",
  "NV Fleet : Retail Ratio" = "custom.metric.label.nv.fleet.retail.ratio",
  "NV SC Productivity" = "custom.metric.label.nv.sc.productivity",
  "NV Number of SC (Average)" = "custom.metric.label.nv.number.of.sc.average",
  "NV Inventory Days (average)" = "custom.metric.label.nv.inventory.days.average",
  "Net sales / Unit Retail" = "custom.metric.label.net.sales.unit.retail",
  "Front End Gross / Unit Retail" = "custom.metric.label.front.end.gross.unit.retail",
  "First Gross / Unit Retail" = "custom.metric.label.first.gross.unit.retail",
  "Sales Effort % Gross Sales Retail" = "custom.metric.label.sales.effort.gross.sales.retail",
  "FEG % Gross Sales Retail" = "custom.metric.label.feg.gross.sales.retail",
  "First Gross % Net Sales Retail" = "custom.metric.label.first.gross.net.sales.retail",
  "Units Sold Retail" = "custom.metric.label.units.sold.retail",
  "Units Sold mix Retail" = "custom.metric.label.units.sold.mix.retail",
  "Gross Sales / Unit Fleet" = "custom.metric.label.gross.sales.unit.fleet",
  "Discount / Unit Fleet" = "custom.metric.label.discount.unit.fleet",
  "Net sales / Unit Fleet" = "custom.metric.label.net.sales.unit.fleet",
  "Front End Gross / Unit Fleet" = "custom.metric.label.front.end.gross.unit.fleet",
  "First Gross / Unit Fleet" = "custom.metric.label.first.gross.unit.fleet",
  "Sales Effort % Gross Sales Fleet" = "custom.metric.label.sales.effort.gross.sales.fleet",
  "FEG % Gross Sales Fleet" = "custom.metric.label.feg.gross.sales.fleet",
  "First Gross % Net Sales Fleet" = "custom.metric.label.first.gross.net.sales.fleet",
  "Units Sold Fleet" = "custom.metric.label.units.sold.fleet",
  "Units Sold mix Fleet" = "custom.metric.label.units.sold.mix.fleet",
  "UV Net Sales per unit" = "custom.metric.label.uv.net.sales.per.unit",
  "UV First Gross per unit" = "custom.metric.label.uv.first.gross.per.unit",
  "UV Accessory GP per unit" = "custom.metric.label.uv.accessory.gp.per.unit",
  "UV GP (excl. F&I) per unit" = "custom.metric.label.uv.gp.excl.f.and.i.per.unit",
  "UV GP (incl. F&I & Aftercare) per unit" = "custom.metric.label.uv.gp.incl.f.and.i.and.aftercare.per.unit",
  "UV FEG % Gross Sales" = "custom.metric.label.uv.feg.gross.sales",
  "UV First Gross % Net Sales" = "custom.metric.label.uv.first.gross.net.sales",
  "UV GP% Net Sales (excl. F&I)" = "custom.metric.label.uv.gp.net.sales.excl.f.and.i",
  "UV GP% Net Sales (incl. F&I)" = "custom.metric.label.uv.gp.net.sales.incl.f.and.i",
  "UV GP% Net Sales (incl. F&I & Aftercare)" = "custom.metric.label.uv.gp.net.sales.incl.f.and.i.and.aftercare",
  "Variable Expenses % UV GP" = "custom.metric.label.variable.expenses.uv.gp",
  "Variable Selling Gross % UV GP" = "custom.metric.label.variable.selling.gross.uv.gp",
  "UV Total Units" = "custom.metric.label.uv.total.units",
  "UV Breakeven Units" = "custom.metric.label.uv.breakeven.units",
  "UV Breakeven Days" = "custom.metric.label.uv.breakeven.days",
  "UV SC Productivity" = "custom.metric.label.uv.sc.productivity",
  "UV Number of SC (Average)" = "custom.metric.label.uv.number.of.sc.average",
  "Gross Sales / Unit Retail" = "custom.metric.label.gross.sales.unit.retail",
  "Discount & OA / Unit Retail" = "custom.metric.label.discount.and.oa.unit.retail",
  "Reconditioning Cost / Unit Retail" = "custom.metric.label.reconditioning.cost.unit.retail",
  "Marketing Incentive / Unit Retail" = "custom.metric.label.marketing.incentive.unit.retail",
  "Gross Sales / Unit Trade" = "custom.metric.label.gross.sales.unit.trade",
  "Discount & OA / Unit Trade" = "custom.metric.label.discount.and.oa.unit.trade",
  "Net sales / Unit Trade" = "custom.metric.label.net.sales.unit.trade",
  "Reconditioning Cost / Unit Trade" = "custom.metric.label.reconditioning.cost.unit.trade",
  "Marketing Incentive / Unit Trade" = "custom.metric.label.marketing.incentive.unit.trade",
  "First Gross / Unit Trade" = "custom.metric.label.first.gross.unit.trade",
  "First Gross % Net Sales Trade" = "custom.metric.label.first.gross.net.sales.trade",
  "Units Sold Trade" = "custom.metric.label.units.sold.trade",
  "New Vehicle Finance Penetration" = "custom.metric.label.new.vehicle.finance.penetration",
  "New Vehicle Insurance Penetration" = "custom.metric.label.new.vehicle.insurance.penetration",
  "New Vehicle Finance GP per unit sold" = "custom.metric.label.new.vehicle.finance.gp.per.unit.sold",
  "New Vehicle Insurance GP per unit sold" = "custom.metric.label.new.vehicle.insurance.gp.per.unit.sold",
  "Used Vehicle Finance Penetration" = "custom.metric.label.used.vehicle.finance.penetration",
  "Used Vehicle Insurance Penetration" = "custom.metric.label.used.vehicle.insurance.penetration",
  "Used Vehicle Finance GP per unit sold" = "custom.metric.label.used.vehicle.finance.gp.per.unit.sold",
  "Used Vehicle Insurance GP per unit sold" = "custom.metric.label.used.vehicle.insurance.gp.per.unit.sold",
  "Parts and Service Sales per Service RO" = "custom.metric.label.parts.and.service.sales.per.service.ro",
  "Service Sales per Service RO" = "custom.metric.label.service.sales.per.service.ro",
  "Parts and Body Shop Sales per Body Shop RO" = "custom.metric.label.parts.and.body.shop.sales.per.body.shop.ro",
  "Body Shop Sales per Body Shop RO" = "custom.metric.label.body.shop.sales.per.body.shop.ro",
  "After Sales GP%" = "custom.metric.label.after.sales.gp.perc",
  "Parts GP%" = "custom.metric.label.parts.gp.perc",
  "Body Shop GP%" = "custom.metric.label.body.shop.gp.perc",

  "Body Shop Workshop - Body Shop Workshop Sales % Total Sales" = "metric.label.body.shop.workshop.sales",
  "Body Shop Revenue '000" = "metric.label.body.shop.revenue",
  "Body Shop Retail RO" = "metric.label.body.shop.retail.rol",

  "Fixed Operation Absorption" = "custom.metric.label.fixed.operation.absorption",
  "Parts Service Workshop Sales mix" = "custom.metric.label.parts.service.workshop.sales.mix",
  "Parts Service Workshop Retail Sales mix" = "custom.metric.label.parts.service.workshop.retail.sales.mix",
  "Parts Service Workshop Warranty Sales mix" = "custom.metric.label.parts.service.workshop.warranty.sales.mix",
  "Parts Service Workshop Internal Sales mix" = "custom.metric.label.parts.service.workshop.internal.sales.mix",
  "Parts Quick Service Sales mix" = "custom.metric.label.parts.quick.service.sales.mix",
  "Parts Body Shop Sales mix" = "custom.metric.label.parts.body.shop.sales.mix",
  "Parts Body Shop Retail Sales mix" = "custom.metric.label.parts.body.shop.retail.sales.mix",
  "Parts Body Shop Warranty Sales mix" = "custom.metric.label.parts.body.shop.warranty.sales.mix",
  "Parts Body Shop Internal Sales mix" = "custom.metric.label.parts.body.shop.internal.sales.mix",
  "Parts Accessories Sales mix" = "custom.metric.label.parts.accessories.sales.mix",
  "Parts Intercompany Sales mix" = "custom.metric.label.parts.intercompany.sales.mix",
  "Parts Oil & Lubricant Sales mix" = "custom.metric.label.parts.oil.and.lubricant.sales.mix",
  "Parts Counter Retail Sales mix" = "custom.metric.label.parts.counter.retail.sales.mix",
  "Parts Wholesale Sales mix" = "custom.metric.label.parts.wholesale.sales.mix",
  "Parts Breakeven Days" = "custom.metric.label.parts.breakeven.days",
  "Parts Inventory Days (average)" = "custom.metric.label.parts.inventory.days.average",
  "Service Sublet Sales mix (% TSS)" = "custom.metric.label.service.sublet.sales.mix.tss",
  "Service Oil & Lubricant Sales mix (% TSS)" = "custom.metric.label.service.oil.and.lubricant.sales.mix.tss",
  "Service Extended Warranty Sales mix (% TSS)" = "custom.metric.label.service.extended.warranty.sales.mix.tss",
  "Service Labour GP %" = "custom.metric.label.service.labour.gp.",
  "Service Labour FEG %" = "custom.metric.label.service.labour.feg.",
  "Service Labour Retail GP %" = "custom.metric.label.service.labour.retail.gp.",
  "Service Labour Warranty GP %" = "custom.metric.label.service.labour.warranty.gp.",
  "Service Labour Internal GP %" = "custom.metric.label.service.labour.internal.gp.",
  "Service Labour Maint. Contract GP %" = "custom.metric.label.service.labour.maint.contract.gp.",
  "Service Labour Quick Service GP %" = "custom.metric.label.service.labour.quick.service.gp.",
  "Service Sublet GP %" = "custom.metric.label.service.sublet.gp.",
  "Service Oil & Lubricant GP %" = "custom.metric.label.service.oil.and.lubricant.gp.",
  "Service Extended Warranty GP %" = "custom.metric.label.service.extended.warranty.gp.",
  "Service Total Number of RO" = "custom.metric.label.service.total.number.of.ro",
  "Service Breakeven RO" = "custom.metric.label.service.breakeven.ro",
  "Service Total Number of Units Repaired" = "custom.metric.label.service.total.number.of.units.repaired",
  "Service RO / Technician / Day" = "custom.metric.label.service.ro.technician.day",
  "Service Hours Sold per Service RO" = "custom.metric.label.service.hours.sold.per.service.ro",
  "Service Breakeven Days" = "custom.metric.label.service.breakeven.days",
  "Service Effective Labour Rate" = "custom.metric.label.service.effective.labour.rate",
  "Service Effectiveness" = "custom.metric.label.service.effectiveness",
  "Service Workbay Utilization" = "custom.metric.label.service.workbay.utilization",
  "Service Workbay Occupancy" = "custom.metric.label.service.workbay.occupancy",
  "Body Shop Labour Sales mix" = "custom.metric.label.body.shop.labour.sales.mix",
  "Body Shop Labour Retail Sales mix" = "custom.metric.label.body.shop.labour.retail.sales.mix",
  "Body Shop Labour Warranty Sales mix" = "custom.metric.label.body.shop.labour.warranty.sales.mix",
  "Body Shop Labour Internal Sales mix" = "custom.metric.label.body.shop.labour.internal.sales.mix",
  "Body Shop Paint & Material Sales mix" = "custom.metric.label.body.shop.paint.and.material.sales.mix",
  "Body Shop Sublet Sales mix" = "custom.metric.label.body.shop.sublet.sales.mix",
  "Body Shop Oil & Lubricant Sales mix" = "custom.metric.label.body.shop.oil.and.lubricant.sales.mix",
  "Body Shop Labour GP %" = "custom.metric.label.body.shop.labour.gp.",
  "Body Shop Labour Retail GP %" = "custom.metric.label.body.shop.labour.retail.gp.",
  "Body Shop Labour Warranty GP %" = "custom.metric.label.body.shop.labour.warranty.gp.",
  "Body Shop Labour Internal GP %" = "custom.metric.label.body.shop.labour.internal.gp.",
  "Body Shop Idle Time % TLS" = "custom.metric.label.body.shop.idle.time.tls",
  "Body Shop Paint & Material GP %" = "custom.metric.label.body.shop.paint.and.material.gp.",
  "Body Shop Sublet GP %" = "custom.metric.label.body.shop.sublet.gp.",
  "Body Shop Oil & Lubricant GP %" = "custom.metric.label.body.shop.oil.and.lubricant.gp.",
  "Body Shop Breakeven RO" = "custom.metric.label.body.shop.breakeven.ro",
  "Body Shop Total Number of RO" = "custom.metric.label.body.shop.total.number.of.ro",
  "Body Shop RO per technician per day" = "custom.metric.label.body.shop.ro.per.technician.per.day",
  "Body Shop Hours Sold per Body Shop RO" = "custom.metric.label.body.shop.hours.sold.per.body.shop.ro",
  "Body Shop Breakeven Days" = "custom.metric.label.body.shop.breakeven.days",
  "Body Shop Effective Labour Rate" = "custom.metric.label.body.shop.effective.labour.rate",
  "Body Shop Productivity" = "custom.metric.label.body.shop.productivity",
  "Body Shop Efficiency" = "custom.metric.label.body.shop.efficiency",
  "Body Shop Effectiveness" = "custom.metric.label.body.shop.effectiveness",
  "Inbox" = "notifications.inbox.label",
  "General" = "notifications.message.type.general",
  "Dealer Performance" = "notifications.message.type.dealer.performance",
  "Dealer Data Submission & Quality" = "notifications.message.type.dealer.data.submission.quality",
  "Dealer Principle" = "notifications.user.type.dealer.principle",
  "Financial Controller" = "notifications.user.type.financial.controller",
  "Data Clerk" = "notifications.user.type.data.clerk",
  "User Search" = "notifications.user.search.label",
  "Confirm adding" = "notifications.user.search.confirmation.message.a",
  "users as recipients?" = "notifications.user.search.confirmation.message.b",
  "UV Variable Selling Gross net of Personnel Expenses % GP" = "metric.chart.uv.variable.selling.gross.net.of.personnel.expenses.gp",
  "Top 20 Worst Dealers" = "oem.dashboard.metric.table.top.20.worst.dealers",
  "% Reporting Volume" = "oem.dashboard.metric.table.reporting.volume",
  "Profit & Loss Dealer " = "oem.dasboard.metric.chart.profit.loss.dealer",
  "PBT $" = "oem.dashboard.metric.table.pbt",
  "Sales `000" = "oem.dashboard.report.selector.sales",
  "RISK" = "oem.dashboard.report.selector.risk",
  "% Loss Making Dealers" = "oem.ford.academy.chart.title.loss.making.dealers",
  "N/A" = "not.available.label",
  "Financial Health Summary" = "metric.table.heading.financial.health.summary",
  "Sales Health Summary" = "metric.table.heading.sales.health.summary",
  "Service Health Summary" = "metric.table.heading.service.health.summary",
  "Total Dealers in Network" = "metric.table.total.dealers.in.network",
  "Avg. PBT / Dealership / Month (US $)" = "metric.table.avg.pbt.dealership.month",
  "PIP performance (FOA) *new" = "metric.table.pip.performance.foa.new",
  "Training day-YTD (Actual)" = "metric.table.training.day.ytd.actual",
  "Training Day % " = "metric.table.training.day",
  "Y-1" = "metric.table.header.y.1",
  "Service Efficiency " = "oem.ford.academy.service.efficiency",
  "Workbay Utilization " = "oem.ford.academy.workbay.utilization",
  "Volume Achievement" = "oem.ford.academy.volume.achievment",
  "GP%" = "oem.ford.academy.gp",
  "No. of FGE implementations - Sales" = "oem.ford.academy.no.of.fge.implementations.sales",
  "JD Power Score - SSI" = "oem.ford.academy.jd.power.score.ssi",
  "CVP - Sales" = "oem.ford.academy.cvp.sales",
  "CVP - Delivery Process" = "oem.ford.academy.cvp.delivery.process",
  "Prospect Achievement MTD%" = "oem.ford.academy.prospect.achievement.mtd",
  "TEST DRIVE Achievement MTD %" = "oem.ford.academy.test.drive.achievement.mtd",
  "Closing Ratio" = "oem.ford.academy.closing.ratio",
  "Avg Sales per Sales Consultant per Mth" = "oem.ford.academy.avg.sales.per.sales.consultant.per.mth",
  "Sales Consultant Competence Mix" = "oem.ford.academy.sales.consultant.competence.mix",
  "- L3%" = "oem.ford.academy.l3",
  "- L2%" = "oem.ford.academy.l2",
  "- L1%" = "oem.ford.academy.l1",
  "Sales Manager Competence Mix" = "oem.ford.academy.sales.manager.competence.mix",
  "Training day-YTD (Actual) " = "oem.ford.academy.training.dayytd.actual",
  "MIX and ROS" = "oem.ford.academy.mix.and.ros",
  "Debt: Equity and ROS" = "oem.ford.academy.debt.equity.and.ros",
  "RETAINED and Avg. PBT / Dealership / Month (US $)" = "oem.ford.academy.retained.and.avg.pbt.dealership.month.us",
  "Debt: Equity" = "oem.ford.academy.debt.equity",
  "Dashboard" = "dashboard.dashboard.header",
  "Group Dashboard" = "dashboard.group.dashboard.header",
  "OEM Dashboard" = "dashboard.oem.dashboard.header",
  "Custom Dashboard" = "dashboard.custom.dashboard.header",
  "Reports" = "dashboard.reports.header",
  "Submission" = "dashboard.submissions.header",
  "Tutorials" = "dashboard.tutorials.header",
  "Action Plan" = "dashboard.action.plan.header",
  "Home" = "dashboard.home.subheader",
  "OEM" = "dashboard.oem.subheader",
  "Executive" = "dashboard.executive.subheader",
  "Balance Sheet" = "dashboard.balance.sheet.subheader",
  "NV F&I" = "dashboard.nv.f.and.i.subheader",
  "UV F&I" = "dashboard.uv.f.and.i.subheader",
  "Parts" = "dashboard.parts.subheader",
  "Service" = "dashboard.service.subheader",
  "Body Shop" = "dashboard.body.shop.subheader",
  "PARTS" = "dashboard.parts.subheader",
  "SERVICE" = "dashboard.service.subheader",
  "BODY SHOP" = "dashboard.body.shop.subheader",
  "AFTERCARE" = "custom.metric.department.aftercare",
  "aftercare" = "custom.metric.department.aftercare",
  "after care" = "custom.metric.department.aftercare",
  "Other Dept." = "custom.metric.department.otherdept",
  "Other Dept" = "custom.metric.department.otherdept",
  "OTHER DEPT" = "custom.metric.department.otherdept",
  "Custom" = "dashboard.custom.subheader",
  "Reporting Month" = "data.submissions.reporting.month.subheader",
  "Deadline" = "data.submissions.deadline.subheader",
  "Upload Date" = "data.submissions.upload.date.subheader",
  "Status" = "data.submissions.status.subheader",
  "Upload Schedule" = "submissions.upload.schedule.subheader",
  "Submission Status" = "submissions.status.subheader",
  "Standard Reports" = "reports.standard.reports.subheader",
  "OEM Reports" = "reports.oem.reports.subheader",
  "Performance Analysis" = "reports.performance.analysis.subheader",
  "Dealer Analysis" = "reports.dealer.analysis.subheader",
  "Data Preparation" = "tutorials.data.preparation.subheader",
  "Data Interpretation" = "tutorials.data.interpretation.subheader",
  "BM Process" = "tutorials.bm.process.subheader",
  "New" = "action.plan.new.subheader",
  "Used" = "action.plan.used.subheader",
  "Messages" = "notifications.messages.subheader",
  "Reply to message" = "notifications.reply.to.message",
  "MSXI BM Solution" = "about.msxi.bm.solution.subheader",
  "Meet the Team" = "about.meet.the.team.subheader",
  "User Profile" = "profile.page.user.profile.subheader",
  "Mappings" = "admin.mappings.subheader",
  "To-do list" = "todolist.subheader",
  "Deadlines" = "submissions.deadline.subheader",
  "Download Templates" = "submissions.download.templates.subheader",
  "Submit Data" = "submissions.submit.data.subheader",
  "Dealer" = "custom.dealer.subheader",
  "Group" = "custom.group.subheader",
  "Network Analysis" = "reports.network.analysis.subheader",
  "Other BM Tools" = "reports.other.bm.tools.subheader",
  "Project ROS" = "reports.project.ros.subheader",
  "Opportunity Report" = "reports.opportunity.report.subheader",
  "Insights" = "insights.label",
  "Fixed" = "dashboard.oem.dashboard.subheader.fixed",
  "Dynamic" = "dashboard.oem.dashboard.subheader.dynamic",
  "Ford Academy" = "dashboard.oem.dashboard.subheader.ford.academy",
  "Integrated Report" = "dashboard.oem.dashboard.subheader.integrated.report",
  "Risk Dashboard" = "dashboard.oem.dashboard.subheader.risk.dashboard",
  "Forecasts" = "admin.forecasts.subheader",
  "Automated Consolidation" = "admin.automated.consolidation.subheader",
  "Dealer Insight" = "reports.dealer.insight.subheader",
  "Network Insight" = "reports.network.insight.subheader",
  "Performance Insight" = "reports.performance.insight.subheader",
  "Upload Media" = "upload.media.button",
  "Create Grouping" = "create.grouping.button",
  "Master Data" = "admin.master.data.subheader",
  "Input templates" = "admin.input.templates.subheader",
  "Industry Insights" = "tutorials.industry.insights.subheader",
  "Active Dealers" = "dealers.active.label",
  "Video" = "tutorials.videos.label",
  "Available KPI's for New department" = "action.plan.available.kpis.for.new.department",
  "Available KPI's for Parts department" = "action.plan.available.kpis.for.parts.department",
  "Available KPI's for Used department" = "action.plan.available.kpis.for.used.department",
  "Available KPI's for Service department" = "action.plan.available.kpis.for.service.department",
  "Available KPI's for Body Shop department" = "action.plan.available.kpis.for.body.shop.department",
  "Yes" = "metric.table.yes.label",
  "No" = "metric.table.no.label",
  "Total Receivable (average)" = "metric.table.total.receivable.average.label",
  "Total Inventory (average)" = "metric.table.total.inventory.average.label",
  "PBIT" = "metric.table.pbit.label",
  "Annualised PBIT" = "metric.table.annualised.pbit.label",
  "BALANCE SHEET (AVG)" = "metric.table.balance.sheet.average.label",
  "NV GP % incl. F&I & Aftercare" = "metric.table.nv.gp.incl.f.and.i.and.aftercare",
  "Change PDF Export Password" = "settings.pdf.change.password",
  "NV GP mix (NV Only)" = "custom.dashboard.kpis.nv.gp.mix.nv.only",
  "UV GP mix (UV Only)" = "custom.dashboard.kpis.uv.gp.mix.uv.only",
  "F&I GP mix (NV & UV)" = "custom.dashboard.kpis.fi.gp.mix.nv.uv",
  "Aftercare GP mix (NV & UV)" = "custom.dashboard.kpis.aftercare.gp.mix.nv.uv",
  "NV GP mix incl. F&I & Aftercare" = "custom.dashboard.kpis.nv.gp.mix.incl.fi.aftercare",
  "UV GP mix incl. F&I & Aftercare" = "custom.dashboard.kpis.uv.gp.mix.incl.fi.aftercare",
  "Parts GP mix" = "custom.dashboard.kpis.parts.gp.mix",
  "Service GP mix" = "custom.dashboard.kpis.service.gp.mix",
  "Quick Service GP mix" = "custom.dashboard.kpis.quick.service.gp.mix",
  "Service & Quick Service GP mix" = "custom.dashboard.kpis.service.quick.service.gp.mix",
  "Body Shop GP mix" = "custom.dashboard.kpis.body.shop.gp.mix",
  "Driveway GP mix" = "custom.dashboard.kpis.driveway.gp.mix",
  "Other Departments GP mix" = "custom.dashboard.kpis.other.departments.gp.mix",
  "UV Retail & Demo Units" = "custom.dashboard.kpis.uv.retail.demo.units",
  "Variable Expenses% Total Expenses" = "custom.dashboard.kpis.variable.expenses.total.expenses",
  "Personnel Expenses% Total Expenses" = "custom.dashboard.kpis.personnel.expenses.total.expenses",
  "Other Semi Var.Exp.% Total Expenses" = "custom.dashboard.kpis.other.semi.var.exptotal.expenses",
  "Fixed Expenses% Total Expenses" = "custom.dashboard.kpis.fixed.expenses.total.expenses",
  "Interest Expenses% Total Expenses" = "custom.dashboard.kpis.interest.expenses.total.expenses",
  "Admin Fees% Total Expenses" = "custom.dashboard.kpis.admin.fees.total.expenses",
  "Ded. & Add. to income% Total Expenses" = "custom.dashboard.kpis.dedadd.to.income.total.expenses",
  "Retail Stock Days" = "custom.dashboard.kpis.retail.stock.days",
  "GP / Productive / Month Driveway" = "custom.dashboard.kpis.gp.productive.month.driveway",
  "Total Support from OEM" = "custom.dashboard.kpis.total.support.from.oem",
  "OEM Incentives" = "custom.dashboard.kpis.oem.incentives",
  "OEM Direct Incentives" = "custom.dashboard.kpis.oem.direct.incentives",
  "OEM Indirect Incentives" = "custom.dashboard.kpis.oem.indirect.incentives",
  "OEM Expenses Subsidy" = "custom.dashboard.kpis.oem.expenses.subsidy",
  "OEM Direct Advertising Subsidy" = "custom.dashboard.kpis.oem.direct.advertising.subsidy",
  "OEM Indirect Advertising Subsidy" = "custom.dashboard.kpis.oem.indirect.advertising.subsidy",
  "OEM Floor Plan Interest Subsidy" = "custom.dashboard.kpis.oem.floor.plan.interest.subsidy",
  "OEM Incentives % TGP" = "custom.dashboard.kpis.oem.incentivestgp",
  "OEM Incentives % Sales" = "custom.dashboard.kpis.oem.incentivessales",
  "OEM Expenses Subsidy % Sales" = "custom.dashboard.kpis.oem.expenses.subsidysales",
  "OEM All Supports % Sales" = "custom.dashboard.kpis.oem.all.supportssales",
  "MIX (excl Incentive)" = "custom.dashboard.kpis.mix.excl.incentive",
  "RETAINED (excl All Supports from OEM)" = "custom.dashboard.kpis.retained.excl.all.supports.from.oem",
  "RETAINED (excl Incentives)" = "custom.dashboard.kpis.retained.excl.incentives",
  "RETAINED (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.retained.excl.oem.expenses.subsidy",
  "Expenses % TGP (excl All Supports from OEM)" = "custom.dashboard.kpis.expensestgp.excl.all.supports.from.oem",
  "Expenses % TGP (excl Incentives)" = "custom.dashboard.kpis.expensestgp.excl.incentives",
  "Expenses % TGP (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.expensestgp.excl.oem.expenses.subsidy",
  "Operational Income % Net Sales" = "custom.dashboard.kpis.operational.incomenet.sales",
  "Operational Income % Net Sales (excl All Supports from OEM)" = "custom.dashboard.kpis.operational.incomenet.sales.excl.all.supports.from.oem",
  "Operational Income % Net Sales (excl Incentives)" = "custom.dashboard.kpis.operational.incomenet.sales.excl.incentives",
  "Operational Income % Net Sales (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.operational.incomenet.sales.excl.oem.expenses.subsidy",
  "ROS (excl All Supports from OEM)" = "custom.dashboard.kpis.ros.excl.all.supports.from.oem",
  "ROS (excl Incentives)" = "custom.dashboard.kpis.ros.excl.incentives",
  "ROS (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.ros.excl.oem.expenses.subsidy",
  "Total Sales" = "custom.dashboard.kpis.total.sales",
  "Total GP" = "custom.dashboard.kpis.total.gp",
  "Total GP (excl Incentives)" = "custom.dashboard.kpis.total.gp.excl.incentives",
  "Total Expenses (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.total.expenses.excl.oem.expenses.subsidy",
  "Operational Income" = "custom.dashboard.kpis.operational.income",
  "Operational Income (excl All Supports from OEM)" = "custom.dashboard.kpis.operational.income.excl.all.supports.from.oem",
  "Operational Income (excl Incentives)" = "custom.dashboard.kpis.operational.income.excl.incentives",
  "Operational Income (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.operational.income.excl.oem.expenses.subsidy",
  "PBT (excl All Supports from OEM)" = "custom.dashboard.kpis.pbt.excl.all.supports.from.oem",
  "PBT (excl Incentives)" = "custom.dashboard.kpis.pbt.excl.incentives",
  "PBT (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.pbt.excl.oem.expenses.subsidy",
  "Total Units Target " = "custom.dashboard.kpis.total.units.target",
  "NV GP % incl. F&I & Aftercare (excluding OEM Incentives)" = "custom.dashboard.kpis.nv.gp.incl.f&i.aftercare.excluding.oem.incentives",
  "NV OEM Incentives % GP" = "custom.dashboard.kpis.nv.oem.incentives.gp",
  "Variable Expenses  % GP" = "custom.dashboard.kpis.variable.expenses.gp",
  "Allocation of Admin Expenses% Total Expenses" = "custom.dashboard.kpis.allocation.of.admin.expenses%.total.expenses",
  "NV Floor Plan Interest / Unit" = "custom.dashboard.kpis.nv.floor.plan.interest.unit",
  "NV Ratio Productive : Non Productive" = "custom.dashboard.kpis.nv.ratio.productive.non.productive",
  "NV Ratio Productive : Non Productive (excl. F&I Aftercare)" = "custom.dashboard.kpis.nv.ratio.productive.non.productive.excl.f&i.aftercare",
  "NV Support from OEM" = "custom.dashboard.kpis.nv.support.from.oem",
  "NV OEM Incentives" = "custom.dashboard.kpis.nv.oem.incentives",
  "NV OEM Direct Incentives" = "custom.dashboard.kpis.nv.oem.direct.incentives",
  "NV OEM Indirect Incentives" = "custom.dashboard.kpis.nv.oem.indirect.incentives",
  "NV OEM Expenses Subsidy" = "custom.dashboard.kpis.nv.oem.expenses.subsidy",
  "NV OEM Direct Advertising Subsidy" = "custom.dashboard.kpis.nv.oem.direct.advertising.subsidy",
  "NV OEM Indirect Advertising Subsidy" = "custom.dashboard.kpis.nv.oem.indirect.advertising.subsidy",
  "NV OEM Floor Plan Interest Subsidy" = "custom.dashboard.kpis.nv.oem.floor.plan.interest.subsidy",
  "NV OEM Incentives % TGP" = "custom.dashboard.kpis.nv.oem.incentives.tgp",
  "NV OEM Incentives % Sales" = "custom.dashboard.kpis.nv.oem.incentives.sales",
  "NV OEM Expenses Subsidy % Sales" = "custom.dashboard.kpis.nv.oem.expenses.subsidy.sales",
  "NV OEM All Supports % Sales" = "custom.dashboard.kpis.nv.oem.all.supports.sales",
  "NV MIX (excl Incentive)" = "custom.dashboard.kpis.nv.mix.excl.incentive",
  "NV RETAINED (excl All Supports from OEM)" = "custom.dashboard.kpis.nv.retained.excl.all.supports.from.oem",
  "NV RETAINED (excl Incentives)" = "custom.dashboard.kpis.nv.retained.excl.incentives",
  "NV RETAINED (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.nv.retained.excl.oem.expenses.subsidy",
  "NV Expenses % TGP" = "custom.dashboard.kpis.nv.expenses.tgp",
  "NV Expenses % TGP (excl All Supports from OEM)" = "custom.dashboard.kpis.nv.expenses.tgp.excl.all.supports.from.oem",
  "NV Expenses % TGP (excl Incentives)" = "custom.dashboard.kpis.nv.expenses.tgp.excl.incentives",
  "NV Expenses % TGP (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.nv.expenses.tgp.excl.oem.expenses.subsidy",
  "NV ROS (excl All Supports from OEM)" = "custom.dashboard.kpis.nv.ros.excl.all.supports.from.oem",
  "NV ROS (excl Incentives)" = "custom.dashboard.kpis.nv.ros.excl.incentives",
  "NV ROS (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.nv.ros.excl.oem.expenses.subsidy",
  "NV GP (excl Incentives)" = "custom.dashboard.kpis.nv.gp.excl.incentives",
  "NV Expenses" = "custom.dashboard.kpis.nv.expenses",
  "NV Expenses (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.nv.expenses.excl.oem.expenses.subsidy",
  "NV PBT" = "custom.dashboard.kpis.nv.pbt",
  "NV PBT (excl All Supports from OEM)" = "custom.dashboard.kpis.nv.pbt.excl.all.supports.from.oem",
  "NV PBT (excl Incentives)" = "custom.dashboard.kpis.nv.pbt.excl.incentives",
  "NV PBT (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.nv.pbt.excl.oem.expenses.subsidy",
  "UV (Retail & Demo) : NV Ratio" = "custom.dashboard.kpis.uv.retail.demo.nv.ratio",
  "UV Net Sales / Unit (excl. Acc)" = "custom.dashboard.kpis.uv.net.sales.unit.excl.acc.",
  "Retail & Demo Sales / Retail & Demo Unit" = "custom.dashboard.kpis.retail.demo.sales.retail.demo.unit",
  "Retail Sales / Retail Unit" = "custom.dashboard.kpis.retail.sales.retail.unit",
  "Trade Sales / Trade Unit" = "custom.dashboard.kpis.trade.sales.trade.unit",
  "Reconditioning Retail / Retail Unit" = "custom.dashboard.kpis.reconditioning.retail.retail.unit",
  "Direct Incentives / Retail & Demo Unit" = "custom.dashboard.kpis.direct.incentives.retail.demo.unit",
  "Retail & Demo 1st GP/ Retail & Demo Unit" = "custom.dashboard.kpis.retail.demo.1st.gp/.retail.demo.unit",
  "Retail 1st GP / Retail Unit" = "custom.dashboard.kpis.retail.1st.gp.retail.unit",
  "Demo 1st GP / Demo Unit" = "custom.dashboard.kpis.demo.1st.gp.demo.unit",
  "Trade 1st GP / Trade Unit" = "custom.dashboard.kpis.trade.1st.gp.trade.unit",
  "UV 1st GP / Unit" = "custom.dashboard.kpis.uv.1st.gp.unit",
  "UV Accessory GP / Unit" = "custom.dashboard.kpis.uv.accessory.gp.unit",
  "Retail & Demo 1st GP % Retail & Demo Sales" = "custom.dashboard.kpis.retail.demo.1st.gp.retail.demo.sales",
  "Retail 1st GP % Retail Sales" = "custom.dashboard.kpis.retail.1st.gp.retail.sales",
  "Demo 1st GP % Demo Sales" = "custom.dashboard.kpis.demo.1st.gp.demo.sales",
  "Trade 1st GP % Trade Sales" = "custom.dashboard.kpis.trade.1st.gp.trade.sales",
  "UV 1st GP % Net Sales" = "custom.dashboard.kpis.uv.1st.gp.net.sales",
  "UV GP % incl. F&I & Aftercare (excluding OEM Incentives)" = "custom.dashboard.kpis.uv.gp.incl.f&i.aftercare.excluding.oem.incentives.",
  "UV OEM Incentives % GP" = "custom.dashboard.kpis.uv.oem.incentives.gp",
  "Personnel  Expenses % GP" = "custom.dashboard.kpis.personnel.expenses.gp",
  "Allocation of Admin  Expenses % GP" = "custom.dashboard.kpis.allocation.of.admin.expenses.gp",
  "Allocation of Admin Expenses % Total Expenses" = "custom.dashboard.kpis.allocation.of.admin.expenses.total.expenses",
  "Retail Inventory Days" = "custom.dashboard.kpis.retail.inventory.days",
  "Demo Inventory Days" = "custom.dashboard.kpis.demo.inventory.days",
  "ROOA incl. F&I & Aftercare" = "custom.dashboard.kpis.rooa.incl.f&i.aftercare",
  "UV Ratio Productive : Non Productive" = "custom.dashboard.kpis.uv.ratio.productivenon.productive",
  "UV Ratio Productive : Non Productive (excl. F&I Aftercare)" = "custom.dashboard.kpis.uv.ratio.productivenon.productive.excl.f&i.aftercare.",
  "UV Retail & Demo Units Sold" = "custom.dashboard.kpis.uv.retail.demo.units.sold",
  "UV GP (excl Incentives)" = "custom.dashboard.kpis.uv.gp.excl.incentives.",
  "UV Expenses" = "custom.dashboard.kpis.uv.expenses",
  "UV Expenses (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.uv.expenses.excl.oem.expenses.subsidy.",
  "UV PBT" = "custom.dashboard.kpis.uv.pbt",
  "UV PBT (excl All Supports from OEM)" = "custom.dashboard.kpis.uv.pbt.excl.all.supports.from.oem.",
  "UV PBT (excl Incentives)" = "custom.dashboard.kpis.uv.pbt.excl.incentives.",
  "UV PBT (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.uv.pbt.excl.oem.expenses.subsidy.",
  "UV ROS (excl All Supports from OEM)" = "custom.dashboard.kpis.uv.ros.excl.all.supports.from.oem.",
  "UV ROS (excl Incentives)" = "custom.dashboard.kpis.uv.ros.excl.incentives.",
  "UV ROS (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.uv.ros.excl.oem.expenses.subsidy.",
  "UV RETAINED (excl All Supports from OEM)" = "custom.dashboard.kpis.uv.retained.excl.all.supports.from.oem.",
  "UV RETAINED (excl Incentives)" = "custom.dashboard.kpis.uv.retained.excl.incentives.",
  "UV RETAINED (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.uv.retained.excl.oem.expenses.subsidy.",
  "UV Expenses % TGP" = "custom.dashboard.kpis.uv.expenses.tgp",
  "UV Expenses % TGP (excl All Supports from OEM)" = "custom.dashboard.kpis.uv.expenses.tgp.excl.all.supports.from.oem.",
  "UV Expenses % TGP (excl Incentives)" = "custom.dashboard.kpis.uv.expenses.tgp.excl.incentives.",
  "UV Expenses % TGP (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.uv.expenses.tgp.excl.oem.expenses.subsidy.",
  "UV MIX (excl Incentive)" = "custom.dashboard.kpis.uv.mix.excl.incentive.",
  "UV Support from OEM" = "custom.dashboard.kpis.uv.support.from.oem",
  "UV OEM Incentives" = "custom.dashboard.kpis.uv.oem.incentives",
  "UV OEM Direct Incentives" = "custom.dashboard.kpis.uv.oem.direct.incentives",
  "UV OEM Indirect Incentives" = "custom.dashboard.kpis.uv.oem.indirect.incentives",
  "UV OEM Expenses Subsidy" = "custom.dashboard.kpis.uv.oem.expenses.subsidy",
  "UV OEM Direct Advertising Subsidy" = "custom.dashboard.kpis.uv.oem.direct.advertising.subsidy",
  "UV OEM Indirect Advertising Subsidy" = "custom.dashboard.kpis.uv.oem.indirect.advertising.subsidy",
  "UV OEM Floor Plan Interest Subsidy" = "custom.dashboard.kpis.uv.oem.floor.plan.interest.subsidy",
  "UV OEM Incentives % TGP" = "custom.dashboard.kpis.uv.oem.incentives.tgp",
  "UV OEM Incentives % Sales" = "custom.dashboard.kpis.uv.oem.incentives.sales",
  "UV OEM Expenses Subsidy % Sales" = "custom.dashboard.kpis.uv.oem.expenses.subsidy.sales",
  "UV OEM All Supports % Sales" = "custom.dashboard.kpis.uv.oem.all.supports.sales",
  "Finance Contract" = "custom.dashboard.kpis.finance.contract",
  "Finance Penetration" = "custom.dashboard.kpis.finance.penetration",
  "Insurance Contract" = "custom.dashboard.kpis.insurance.contract",
  "Insurance Penetration" = "custom.dashboard.kpis.insurance.penetration",
  "UV Finance Contract" = "custom.dashboard.kpis.uv.finance.contract",
  "UV Insurance Contract" = "custom.dashboard.kpis.uv.insurance.contract",
  "Finance Sales" = "custom.dashboard.kpis.finance.sales",
  "Insurance Sales" = "custom.dashboard.kpis.insurance.sales",
  "F&I Total Sales" = "custom.dashboard.kpis.f&i.total.sales",
  "NV F&I GP / NV Unit" = "custom.dashboard.kpis.nv.f&i.gp.nv.unit",
  "Finance GP / NV Retail Unit" = "custom.dashboard.kpis.finance.gp.nv.retail.unit",
  "Insurance GP / NV Retail Unit" = "custom.dashboard.kpis.insurance.gp.nv.retail.unit",
  "F&I UV GP / Unit" = "custom.dashboard.kpis.f&i.uv.gp.unit",
  "Finance GP / Unit" = "custom.dashboard.kpis.finance.gp.unit",
  "Insurance GP / Unit" = "custom.dashboard.kpis.insurance.gp.unit",
  "F&I Total GP" = "custom.dashboard.kpis.f&i.total.gp",
  "F&I Total Expenses" = "custom.dashboard.kpis.f&i.total.expenses",
  "F&I Total PBT" = "custom.dashboard.kpis.f&i.total.pbt",
  "Interest Expenses% GP" = "custom.dashboard.kpis.interest.expenses.gp",
  "F&I ACTIVITY" = "custom.dashboard.kpis.f&i.activity",
  "F&I Annualised Sales" = "custom.dashboard.kpis.f&i.annualised.sales",
  "F&I Average Operational Assets" = "custom.dashboard.kpis.f&i.average.operational.assets",
  "Total F&I GP / Productive / Month" = "custom.dashboard.kpis.total.f&i.gp.productive.month",
  "Total F&I GP" = "custom.dashboard.kpis.total.f&i.gp",
  "Total Parts GP / Employee / Month" = "custom.dashboard.kpis.total.parts.gp.employee.month",
  "F&I Staff Allocation NV" = "custom.dashboard.kpis.f&i.staff.allocation.nv",
  "F&I Staff Allocation UV" = "custom.dashboard.kpis.f&i.staff.allocation.uv",
  "F&I OEM Expenses Subsidy" = "custom.dashboard.kpis.f&i.oem.expenses.subsidy",
  "F&I OEM Direct Advertising Subsidy" = "custom.dashboard.kpis.f&i.oem.direct.advertising.subsidy",
  "F&I OEM Indirect Advertising Subsidy" = "custom.dashboard.kpis.f&i.oem.indirect.advertising.subsidy",
  "F&I OEM Expenses Subsidy % Sales" = "custom.dashboard.kpis.f&i.oem.expenses.subsidy.sales",
  "F&I MIX" = "custom.dashboard.kpis.f&i.mix",
  "F&I RETAINED" = "custom.dashboard.kpis.f&i.retained",
  "F&I RETAINED (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.f&i.retained.excl.oem.expenses.subsidy",
  "F&I Expenses % TGP" = "custom.dashboard.kpis.f&i.expenses.tgp",
  "F&I Expenses % TGP (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.f&i.expenses.tgp.excl.oem.expenses.subsidy",
  "F&I ROS" = "custom.dashboard.kpis.f&i.ros",
  "F&I ROS (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.f&i.ros.excl.oem.expenses.subsidy",
  "F&I GP" = "custom.dashboard.kpis.f&i.gp",
  "F&I Expenses" = "custom.dashboard.kpis.f&i.expenses",
  "F&I Expenses (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.f&i.expenses.excl.oem.expenses.subsidy",
  "F&I PBT" = "custom.dashboard.kpis.f&i.pbt",
  "F&I PBT (excl All Supports from OEM)" = "custom.dashboard.kpis.f&i.pbt.excl.all.supports.from.oem",

  "Extended Warranty Contract NV" = "custom.dashboard.kpis.extended.warranty.contract.nv",
  "Extended Warranty Contract UV" = "custom.dashboard.kpis.extended.warranty.contract.uv",
  "Aftercare Total Sales" = "custom.dashboard.kpis.aftercare.total.sales",
  "Aftercare Extended Warranty Sales" = "custom.dashboard.kpis.aftercare.extended.warranty.sales",
  "Aftercare Protection, Window Tint Sales" = "custom.dashboard.kpis.aftercare.protection,.window.tint.sales",
  "Aftercare Other Sales" = "custom.dashboard.kpis.aftercare.other.sales",
  "AFTERCARE Total Sales" = "custom.dashboard.kpis.aftercare.total.sales",
  "NV Aftercare GP / NV Unit" = "custom.dashboard.kpis.nv.aftercare.gp.nv.unit",
  "NV Extended Warranty GP / NV Unit" = "custom.dashboard.kpis.nv.extended.warranty.gp.nv.unit",
  "NV Protection Window Tint GP / NV Unit" = "custom.dashboard.kpis.nv.protection.window.tint.gp.nv.unit",
  "NV Aftercare Other GP / NV Unit" = "custom.dashboard.kpis.nv.aftercare.other.gp.nv.unit",
  "UV Aftercare GP / UV Unit" = "custom.dashboard.kpis.uv.aftercare.gp.uv.unit",
  "UV Extended Warranty GP / UV Unit" = "custom.dashboard.kpis.uv.extended.warranty.gp.uv.unit",
  "UV Protection, Window Tint GP / UV Unit" = "custom.dashboard.kpis.uv.protection,.window.tint.gp.uv.unit",
  "UV Aftercare Other GP / UV Unit" = "custom.dashboard.kpis.uv.aftercare.other.gp.uv.unit",
  "AFTERCARE Total GP" = "custom.dashboard.kpis.aftercare.total.gp",
  "AFTERCARE Total Expenses" = "custom.dashboard.kpis.aftercare.total.expenses",
  "AFTERCARE Total PBT" = "custom.dashboard.kpis.aftercare.total.pbt",
  "Aftercare Total Expenses % GP" = "custom.dashboard.kpis.aftercare.total.expenses.gp",
  "Aftercare Variable Expenses  % GP" = "custom.dashboard.kpis.aftercare.variable.expenses.gp",
  "Aftercare Personnel  Expenses % GP" = "custom.dashboard.kpis.aftercare.personnel.expenses.gp",
  "Aftercare Other Semi Variable Expenses % GP" = "custom.dashboard.kpis.aftercare.other.semi.variable.expenses.gp",
  "Aftercare Fixed Expenses % GP" = "custom.dashboard.kpis.aftercare.fixed.expenses.gp",
  "Aftercare Interest Expenses % GP" = "custom.dashboard.kpis.aftercare.interest.expenses.gp",
  "Aftercare Allocation of Admin  Expenses % GP" = "custom.dashboard.kpis.aftercare.allocation.of.admin.expenses.gp",
  "Aftercare Variable Expenses% Total Expenses" = "custom.dashboard.kpis.aftercare.variable.expenses.total.expenses",
  "Aftercare Personnel Expenses% Total Expenses" = "custom.dashboard.kpis.aftercare.personnel.expenses.total.expenses",
  "Aftercare Other Semi Var.Exp.% Total Expenses" = "custom.dashboard.kpis.aftercare.other.semi.var.exp.total.expenses",
  "Aftercare Fixed Expenses% Total Expenses" = "custom.dashboard.kpis.aftercare.fixed.expenses.total.expenses",
  "Aftercare Interest Expenses % Total Expenses" = "custom.dashboard.kpis.aftercare.interest.expenses.total.expenses",
  "Aftercare Allocation of Admin Expenses % Total Expenses" = "custom.dashboard.kpis.aftercare.allocation.of.admin.expenses.total.expenses",
  "AFTERCARE Annualised Sales" = "custom.dashboard.kpis.aftercare.annualised.sales",
  "AFTERCARE Average Operational Assets" = "custom.dashboard.kpis.aftercare.average.operational.assets",
  "AFTERCARE ROOA" = "custom.dashboard.kpis.aftercare.rooa",
  "AFTERCARE MIX" = "custom.dashboard.kpis.aftercare.mix",
  "AFTERCARE RETAINED" = "custom.dashboard.kpis.aftercare.retained",
  "AFTERCARE ACTIVITY" = "custom.dashboard.kpis.aftercare.activity",
  "Aftercare PBT" = "custom.dashboard.kpis.aftercare.pbt",
  "Aftercare Dept. Profit % Net Sales" = "custom.dashboard.kpis.aftercare.dept.profit.net.sales",
  "Aftercare EBIT% (PBIT%)" = "custom.dashboard.kpis.aftercare.ebit.pbit",
  "Aftercare Variable Selling Gross % Gorss Profit" = "custom.dashboard.kpis.aftercare.variable.selling.gross.gorss.profit",
  "Aftercare Selling Gross % Gross Profit" = "custom.dashboard.kpis.aftercare.selling.gross.gross.profit",
  "Aftercare Selling Gross % Net Sales" = "custom.dashboard.kpis.aftercare.selling.gross.net.sales",
  "Aftercare Gross Profit" = "custom.dashboard.kpis.aftercare.gross.profit",
  "Aftercare Expenses" = "custom.dashboard.kpis.aftercare.expenses",
  "Aftercare Variable Expenses" = "custom.dashboard.kpis.aftercare.variable.expenses",
  "Aftercare Personnel Expenses" = "custom.dashboard.kpis.aftercare.personnel.expenses",
  "Aftercare Other Semi Variable Expenses" = "custom.dashboard.kpis.aftercare.other.semi.variable.expenses",
  "Aftercare Fixed Expenses" = "custom.dashboard.kpis.aftercare.fixed.expenses",
  "Aftercare Interest Expenses" = "custom.dashboard.kpis.aftercare.interest.expenses",
  "Aftercare Departmental Profit" = "custom.dashboard.kpis.aftercare.departmental.profit",
  "Aftercare Allocation of Admin Expenses" = "custom.dashboard.kpis.aftercare.allocation.of.admin.expenses",
  "Aftercare EBIT" = "custom.dashboard.kpis.aftercare.ebit",
  "Aftercare GP / Productive / Month" = "custom.dashboard.kpis.aftercare.gp.productive.month",
  "Aftercare Sales / Productive / Month" = "custom.dashboard.kpis.aftercare.sales.productive.month",
  "Aftercare GP / Employee / Month" = "custom.dashboard.kpis.aftercare.gp.employee.month",
  "Aftercare Total Staff" = "custom.dashboard.kpis.aftercare.total.staff",
  "Aftercare Productive Staff" = "custom.dashboard.kpis.aftercare.productive.staff",
  "Aftercare Non-Productive Staff" = "custom.dashboard.kpis.aftercare.non-productive.staff",
  "Aftercare Ratio Productive : Non Productive" = "custom.dashboard.kpis.aftercare.ratio.productive.:.non.productive",
  "Aftercare Staff Allocation NV" = "custom.dashboard.kpis.aftercare.staff.allocation.nv",
  "Aftercare Staff Allocation UV" = "custom.dashboard.kpis.aftercare.staff.allocation.uv",
  "After Sales Support from OEM" = "custom.dashboard.kpis.after.sales.support.from.oem",
  "After Sales OEM Incentives" = "custom.dashboard.kpis.after.sales.oem.incentives",
  "After Sales OEM Expenses Subsidy" = "custom.dashboard.kpis.after.sales.oem.expenses.subsidy",
  "After Sales OEM Direct Advertising Subsidy" = "custom.dashboard.kpis.after.sales.oem.direct.advertising.subsidy",
  "After Sales OEM Indirect Advertising Subsidy" = "custom.dashboard.kpis.after.sales.oem.indirect.advertising.subsidy",
  "After Sales OEM Incentives % TGP" = "custom.dashboard.kpis.after.sales.oem.incentives.tgp",
  "After Sales OEM Incentives % Sales" = "custom.dashboard.kpis.after.sales.oem.incentives.sales",
  "After Sales OEM Expenses Subsidy % Sales" = "custom.dashboard.kpis.after.sales.oem.expenses.subsidy.sales",
  "After Sales OEM All Supports % Sales" = "custom.dashboard.kpis.after.sales.oem.all.supports.sales",
  "After Sales MIX" = "custom.dashboard.kpis.after.sales.mix",
  "After Sales MIX (excl Incentive)" = "custom.dashboard.kpis.after.sales.mix.excl.incentive",
  "After Sales RETAINED" = "custom.dashboard.kpis.after.sales.retained",
  "After Sales RETAINED (excl All Supports from OEM)" = "custom.dashboard.kpis.after.sales.retained.excl.all.supports.from.oem",
  "After Sales RETAINED (excl Incentives)" = "custom.dashboard.kpis.after.sales.retained.excl.incentives",
  "After Sales RETAINED (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.after.sales.retained.excl.oem.expenses.subsidy",
  "After Sales Expenses % TGP" = "custom.dashboard.kpis.after.sales.expenses.tgp",
  "After Sales Expenses % TGP (excl All Supports from OEM)" = "custom.dashboard.kpis.after.sales.expenses.tgp.excl.all.supports.from.oem",
  "After Sales Expenses % TGP (excl Incentives)" = "custom.dashboard.kpis.after.sales.expenses.tgp.excl.incentives",
  "After Sales Expenses % TGP (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.after.sales.expenses.tgp.excl.oem.expenses.subsidy",
  "After Sales ROS (excl All Supports from OEM)" = "custom.dashboard.kpis.after.sales.ros.excl.all.supports.from.oem",
  "After Sales ROS (excl Incentives)" = "custom.dashboard.kpis.after.sales.ros.excl.incentives",
  "After Sales ROS (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.after.sales.ros.excl.oem.expenses.subsidy",
  "After Sales GP (excl Incentives)" = "custom.dashboard.kpis.after.sales.gp.excl.incentives",
  "After Sales Expenses (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.after.sales.expenses.excl.oem.expenses.subsidy",
  "After Sales PBT (excl All Supports from OEM)" = "custom.dashboard.kpis.after.sales.pbt.excl.all.supports.from.oem",
  "After Sales PBT (excl Incentives)" = "custom.dashboard.kpis.after.sales.pbt.excl.incentives",
  "After Sales PBT (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.after.sales.pbt.excl.oem.expenses.subsidy",
  "Total Parts Staff" = "custom.dashboard.kpis.total.parts.staff",
  "Total Service GP / Productive / Month" = "custom.dashboard.kpis.total.service.gp.productive.month",
  "Total Service GP" = "custom.dashboard.kpis.total.service.gp",
  "Service Productive Staff" = "custom.dashboard.kpis.service.productive.staff",
  "Total Service GP / Employee / Month" = "custom.dashboard.kpis.total.service.gp.employee.month",
  "Total Service Staff" = "custom.dashboard.kpis.total.service.staff",
  "Total Body Shop GP / Productive / Month" = "custom.dashboard.kpis.total.body.shop.gp.productive.month",
  "Total Body Shop GP" = "custom.dashboard.kpis.total.body.shop.gp",
  "Body Shop Productive Staff" = "custom.dashboard.kpis.body.shop.productive.staff",
  "Total Body Shop GP / Employee / Month" = "custom.dashboard.kpis.total.body.shop.gp.employee.month",
  "Total Body Shop Staff" = "custom.dashboard.kpis.total.body.shop.staff",
  "After Sales Total Staff" = "custom.dashboard.kpis.after.sales.total.staff",
  "After Sales Productive Staff" = "custom.dashboard.kpis.after.sales.productive.staff",
  "After Sales Support Staff incl. SA" = "custom.dashboard.kpis.after.sales.support.staff.incl.sa",
  "After Sales Support Service Advisor" = "custom.dashboard.kpis.after.sales.support.service.advisor",
  "Total Parts GP" = "custom.dashboard.kpis.total.parts.gp",
  "After Sales ROS" = "custom.dashboard.kpis.after.sales.ros",
  "After Sales PBT" = "custom.dashboard.kpis.after.sales.pbt",
  "After Sales Dept. Profit % Net Sales" = "custom.dashboard.kpis.after.sales.dept.profit.net.sales",
  "After Sales EBIT% (PBIT%)" = "custom.dashboard.kpis.after.sales.ebit.pbit",
  "After Sales Variable Selling Gross % Gorss Profit" = "custom.dashboard.kpis.after.sales.variable.selling.gross.gorss.profit",
  "After Sales Selling Gross % Gross Profit" = "custom.dashboard.kpis.after.sales.selling.gross.gross.profit",
  "After Sales Selling Gross % Net Sales" = "custom.dashboard.kpis.after.sales.selling.gross.net.sales",
  "After Sales Gross Profit" = "custom.dashboard.kpis.after.sales.gross.profit",
  "After Sales Expenses" = "custom.dashboard.kpis.after.sales.expenses",
  "After Sales Variable Expenses" = "custom.dashboard.kpis.after.sales.variable.expenses",
  "After Sales Personnel Expenses" = "custom.dashboard.kpis.after.sales.personnel.expenses",
  "After Sales Other Semi Variable Expenses" = "custom.dashboard.kpis.after.sales.other.semi.variable.expenses",
  "After Sales Fixed Expenses" = "custom.dashboard.kpis.after.sales.fixed.expenses",
  "After Sales Interest Expenses" = "custom.dashboard.kpis.after.sales.interest.expenses",
  "After Sales Departmental Profit" = "custom.dashboard.kpis.after.sales.departmental.profit",
  "After Sales Allocation of Admin Expenses" = "custom.dashboard.kpis.after.sales.allocation.of.admin.expenses",
  "After Sales EBIT" = "custom.dashboard.kpis.after.sales.ebit",
  "AFTER SALES ACTIVITY" = "custom.dashboard.kpis.after.sales.activity",
  "AFTER SALES Annualised Sales" = "custom.dashboard.kpis.after.sales.annualised.sales",
  "AFTER SALES Average Operational Assets" = "custom.dashboard.kpis.after.sales.average.operational.assets",
  "AFTER SALES RETAINED" = "custom.dashboard.kpis.after.sales.retained",
  "AFTER SALES Total GP" = "custom.dashboard.kpis.after.sales.total.gp",
  "AFTER SALES Total Expenses" = "custom.dashboard.kpis.after.sales.total.expenses",
  "AFTER SALES Total PBT" = "custom.dashboard.kpis.after.sales.total.pbt",
  "After Sales Total Expenses % GP" = "custom.dashboard.kpis.after.sales.total.expenses.gp",
  "After Sales Variable Expenses  % GP" = "custom.dashboard.kpis.after.sales.variable.expenses.gp",
  "After Sales Personnel  Expenses % GP" = "custom.dashboard.kpis.after.sales.personnel.expenses.gp",
  "After Sales Other Semi Variable Expenses % GP" = "custom.dashboard.kpis.after.sales.other.semi.variable.expenses.gp",
  "After Sales Fixed Expenses % GP" = "custom.dashboard.kpis.after.sales.fixed.expenses.gp",
  "After Sales Interest Expenses % GP" = "custom.dashboard.kpis.after.sales.interest.expenses.gp",
  "After Sales Allocation of Admin  Expenses % GP" = "custom.dashboard.kpis.after.sales.allocation.of.admin.expenses.gp",
  "After Sales Variable Expenses% Total Expenses" = "custom.dashboard.kpis.after.sales.variable.expenses.total.expenses",
  "After Sales Personnel Expenses% Total Expenses" = "custom.dashboard.kpis.after.sales.personnel.expenses.total.expenses",
  "After Sales Other Semi Var.Exp.% Total Expenses" = "custom.dashboard.kpis.after.sales.other.semi.var.exp.total.expenses",
  "After Sales Fixed Expenses% Total Expenses" = "custom.dashboard.kpis.after.sales.fixed.expenses.total.expenses",
  "After Sales Interest Expenses % Total Expenses" = "custom.dashboard.kpis.after.sales.interest.expenses.total.expenses",
  "After Sales Allocation of Admin Expenses % Total Expenses" = "custom.dashboard.kpis.after.sales.allocation.of.admin.expenses.total.expenses",
  "Parts Sales mix" = "custom.dashboard.kpis.parts.sales.mix",
  "Service Sales mix" = "custom.dashboard.kpis.service.sales.mix",
  "Body Sales mix" = "custom.dashboard.kpis.body.sales.mix",
  "Body GP mix" = "custom.dashboard.kpis.body.gp.mix",
  "AFTER SALES MIX" = "custom.dashboard.kpis.after.sales.mix",
  "After Sales Sales" = "custom.dashboard.kpis.after.sales.sales",
  "Interest Expenses % Total Expenses" = "custom.dashboard.kpis.interest.expenses.total.expenses",
  "Total Parts GP / Productive / Month" = "custom.dashboard.kpis.total.parts.gp.productive.month",
  "Parts GP (excl Incentives)" = "custom.dashboard.kpis.parts.gp.excl.incentives",
  "Parts Expenses" = "custom.dashboard.kpis.parts.expenses",
  "Parts Expenses (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.parts.expenses.excl.oem.expenses.subsidy",
  "Parts PBT" = "custom.dashboard.kpis.parts.pbt",
  "Parts PBT (excl All Supports from OEM)" = "custom.dashboard.kpis.parts.pbt.excl.all.supports.from.oem",
  "Parts PBT (excl Incentives)" = "custom.dashboard.kpis.parts.pbt.excl.incentives",
  "Parts PBT (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.parts.pbt.excl.oem.expenses.subsidy",
  "Parts ROS (excl All Supports from OEM)" = "custom.dashboard.kpis.parts.ros.excl.all.supports.from.oem",
  "Parts ROS (excl Incentives)" = "custom.dashboard.kpis.parts.ros.excl.incentives",
  "Parts ROS (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.parts.ros.excl.oem.expenses.subsidy",
  "Parts MIX (excl Incentive)" = "custom.dashboard.kpis.parts.mix.excl.incentive",
  "Parts RETAINED" = "custom.dashboard.kpis.parts.retained",
  "Parts RETAINED (excl All Supports from OEM)" = "custom.dashboard.kpis.parts.retained.excl.all.supports.from.oem",
  "Parts RETAINED (excl Incentives)" = "custom.dashboard.kpis.parts.retained.excl.incentives",
  "Parts RETAINED (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.parts.retained.excl.oem.expenses.subsidy",
  "Parts Expenses % TGP" = "custom.dashboard.kpis.parts.expenses.tgp",
  "Parts Expenses % TGP (excl All Supports from OEM)" = "custom.dashboard.kpis.parts.expenses.tgp.excl.all.supports.from.oem",
  "Parts Expenses % TGP (excl Incentives)" = "custom.dashboard.kpis.parts.expenses.tgp.excl.incentives",
  "Parts Expenses % TGP (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.parts.expenses.tgp.excl.oem.expenses.subsidy",
  "Parts OEM Expenses Subsidy % Sales" = "custom.dashboard.kpis.parts.oem.expenses.subsidy.sales",
  "Parts OEM All Supports % Sales" = "custom.dashboard.kpis.parts.oem.all.supports.sales",
  "Parts OEM Expenses Subsidy" = "custom.dashboard.kpis.parts.oem.expenses.subsidy",
  "Parts OEM Direct Advertising Subsidy" = "custom.dashboard.kpis.parts.oem.direct.advertising.subsidy",
  "Parts OEM Indirect Advertising Subsidy" = "custom.dashboard.kpis.parts.oem.indirect.advertising.subsidy",
  "Parts OEM Incentives % TGP" = "custom.dashboard.kpis.parts.oem.incentives.tgp",
  "Parts Support from OEM" = "custom.dashboard.kpis.parts.support.from.oem",
  "Service Support from OEM" = "custom.dashboard.kpis.service.support.from.oem",
  "Service OEM Incentives" = "custom.dashboard.kpis.service.oem.incentives",
  "Service OEM Expenses Subsidy" = "custom.dashboard.kpis.service.oem.expenses.subsidy",
  "Service OEM Direct Advertising Subsidy" = "custom.dashboard.kpis.service.oem.direct.advertising.subsidy",
  "Service OEM Indirect Advertising Subsidy" = "custom.dashboard.kpis.service.oem.indirect.advertising.subsidy",
  "Service OEM Incentives % TGP" = "custom.dashboard.kpis.service.oem.incentives.tgp",
  "Service OEM Incentives % Sales" = "custom.dashboard.kpis.service.oem.incentives.sales",
  "Service OEM Expenses Subsidy % Sales" = "custom.dashboard.kpis.service.oem.expenses.subsidy.sales",
  "Service OEM All Supports % Sales" = "custom.dashboard.kpis.service.oem.all.supports.sales",
  "Service MIX (excl Incentive)" = "custom.dashboard.kpis.service.mix.excl.incentive",
  "Service RETAINED" = "custom.dashboard.kpis.service.retained",
  "Service RETAINED (excl All Supports from OEM)" = "custom.dashboard.kpis.service.retained.excl.all.supports.from.oem",
  "Service RETAINED (excl Incentives)" = "custom.dashboard.kpis.service.retained.excl.incentives",
  "Service RETAINED (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.service.retained.excl.oem.expenses.subsidy",
  "Service Expenses % TGP" = "custom.dashboard.kpis.service.expenses.tgp",
  "Service Expenses % TGP (excl All Supports from OEM)" = "custom.dashboard.kpis.service.expenses.tgp.excl.all.supports.from.oem",
  "Service Expenses % TGP (excl Incentives)" = "custom.dashboard.kpis.service.expenses.tgp.excl.incentives",
  "Service Expenses % TGP (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.service.expenses.tgp.excl.oem.expenses.subsidy",
  "Service ROS (excl All Supports from OEM)" = "custom.dashboard.kpis.service.ros.excl.all.supports.from.oem",
  "Service ROS (excl Incentives)" = "custom.dashboard.kpis.service.ros.excl.incentives",
  "Service ROS (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.service.ros.excl.oem.expenses.subsidy",
  "Service GP (excl Incentives)" = "custom.dashboard.kpis.service.gp.excl.incentives",
  "Service Expenses" = "custom.dashboard.kpis.service.expenses",
  "Service Expenses (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.service.expenses.excl.oem.expenses.subsidy",
  "Service PBT" = "custom.dashboard.kpis.service.pbt",
  "Service PBT (excl All Supports from OEM)" = "custom.dashboard.kpis.service.pbt.excl.all.supports.from.oem",
  "Service PBT (excl Incentives)" = "custom.dashboard.kpis.service.pbt.excl.incentives",
  "Service PBT (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.service.pbt.excl.oem.expenses.subsidy",
  "NV Retail Units Sold" = "custom.dashboard.kpis.nv.retail.units.sold",
  "NV Fleet Units Sold" = "custom.dashboard.kpis.nv.fleet.units.sold",
  "NV Total Units Sold" = "custom.dashboard.kpis.nv.total.units.sold",
  "NV Total Units Target " = "custom.dashboard.kpis.nv.total.units.target.",
  "NV Direct Incentives / Unit" = "custom.dashboard.kpis.nv.direct.incentives.unit",
  "NV Accessory GP / Unit" = "custom.dashboard.kpis.nv.accessory.gp.unit",
  "NV Indirect Incentives / Unit" = "custom.dashboard.kpis.nv.indirect.incentives.unit",
  "NV GP / Unit" = "custom.dashboard.kpis.nv.gp.unit",
  "NV Total Expenses % GP" = "custom.dashboard.kpis.nv.total.expenses.gp",
  "NV Variable Expenses  % GP" = "custom.dashboard.kpis.nv.variable.expenses.gp",
  "NV Personnel Expenses % GP" = "custom.dashboard.kpis.nv.personnel.expenses.gp",
  "NV Other Semi Variable Expenses % GP" = "custom.dashboard.kpis.nv.other.semi.variable.expenses.gp",
  "NV Fixed Expenses % GP" = "custom.dashboard.kpis.nv.fixed.expenses.gp",
  "NV Interest Expenses % GP" = "custom.dashboard.kpis.nv.interest.expenses.gp",
  "NV Allocation of Admin Expenses % GP" = "custom.dashboard.kpis.nv.allocation.of.admin.expenses.gp",
  "NV Variable Expenses% Total Expenses" = "custom.dashboard.kpis.nv.variable.expenses.total.expenses",
  "NV Personnel Expenses% Total Expenses" = "custom.dashboard.kpis.nv.personnel.expenses.total.expenses",
  "NV Other Semi Var.Exp.% Total Expenses" = "custom.dashboard.kpis.nv.other.semi.var.exp.total.expenses",
  "NV Fixed Expenses% Total Expenses" = "custom.dashboard.kpis.nv.fixed.expenses.total.expenses",
  "NV Interest Expenses% Total Expenses" = "custom.dashboard.kpis.nv.interest.expenses.total.expenses",
  "NV Allocation of Admin Expenses% Total Expenses" = "custom.dashboard.kpis.nv.allocation.of.admin.expenses.total.expenses",
  "NV Variable Expenses / Unit" = "custom.dashboard.kpis.nv.variable.expenses.unit",
  "NV Sales Commission / Unit" = "custom.dashboard.kpis.nv.sales.commission.unit",
  "NV Direct Advertising / Unit" = "custom.dashboard.kpis.nv.direct.advertising.unit",
  "NV PDI & Free Service / Unit" = "custom.dashboard.kpis.nv.pdi.&.free.service.unit",
  "NV Other Variable Exp. / Unit" = "custom.dashboard.kpis.nv.other.variable.exp.unit",
  "NV Floor Plan Interest % NV GP" = "custom.dashboard.kpis.nv.floor.plan.interest.nv.gp",
  "NV Gross profit" = "custom.dashboard.kpis.nv.gross.profit",
  "NV Revenue '000" = "metric.card.nv.revenue",
  "UV Unit Sold" = "metric.card.uv.unit.sold",
  "UV Revenue '000" = "metric.card.uv.revenue",
  "UV Var %GP" = "metric.card.uv.var.gp",
  "UV Floor Plan % GP" = "metric.card.uv.floor.plan.gp",
  "NV Revenue" = "custom.dashboard.kpis.nv.revenue",
  "NV ROOA incl. F&I & Aftercare" = "custom.dashboard.kpis.nv.rooa.incl.f&i.&.aftercare",
  "NV Variable Expenses" = "custom.dashboard.kpis.nv.variable.expenses",
  "NV Personnel Expenses" = "custom.dashboard.kpis.nv.personnel.expenses",
  "NV Other Semi Variable Expenses" = "custom.dashboard.kpis.nv.other.semi.variable.expenses",
  "NV Fixed Expenses" = "custom.dashboard.kpis.nv.fixed.expenses",
  "NV Interest Expenses" = "custom.dashboard.kpis.nv.interest.expenses",
  "NV Departmental Profit" = "custom.dashboard.kpis.nv.departmental.profit",
  "NV Allocation of Admin Expenses" = "custom.dashboard.kpis.nv.allocation.of.admin.expenses",
  "NV EBIT" = "custom.dashboard.kpis.nv.ebit",
  "NV Dept. Profit % Net Sales" = "custom.dashboard.kpis.nv.dept.profit.net.sales",
  "NV EBIT% (PBIT%)" = "custom.dashboard.kpis.nv.ebit.pbit",
  "NV Variable Selling Gross % Gorss Profit" = "custom.dashboard.kpis.nv.variable.selling.gross.gorss.profit",
  "NV Selling Gross % Gross Profit" = "custom.dashboard.kpis.nv.selling.gross.gross.profit",
  "NV Selling Gross % Net Sales" = "custom.dashboard.kpis.nv.selling.gross.net.sales",
  "NV Gross Profit" = "custom.dashboard.kpis.nv.gross.profit",
  "NV GP / Productive / Month" = "custom.dashboard.kpis.nv.gp.productive.month",
  "NV GP / Employee / Month" = "custom.dashboard.kpis.nv.gp.employee.month",
  "NV Total Staff" = "custom.dashboard.kpis.nv.total.staff",
  "NV Retail Net Sales / Unit (excl. Acc)" = "custom.dashboard.kpis.nv.retail.net.sales.unit.excl.acc",
  "NV Retail Give Away / Unit" = "custom.dashboard.kpis.nv.retail.give.away.unit",
  "NV Retail Front End Gross / Unit" = "custom.dashboard.kpis.nv.retail.front.end.gross.unit",
  "NV Retail Direct Incentives / Unit" = "custom.dashboard.kpis.nv.retail.direct.incentives.unit",
  "NV Retail First Gross / Unit" = "custom.dashboard.kpis.nv.retail.first.gross.unit",
  "NV Retail Sales Effort % Gross Sales" = "custom.dashboard.kpis.nv.retail.sales.effort.gross.sales",
  "NV Retail FEG % Gross Sales" = "custom.dashboard.kpis.nv.retail.feg.gross.sales",
  "NV Retail First Gross % Net Sales" = "custom.dashboard.kpis.nv.retail.first.gross.net.sales",
  "NV Fleet Net Sales / Unit (excl. Acc)" = "custom.dashboard.kpis.nv.fleet.net.sales.unit.excl.acc",
  "NV Fleet Give Away / Unit" = "custom.dashboard.kpis.nv.fleet.give.away.unit",
  "NV Fleet Front End Gross / Unit" = "custom.dashboard.kpis.nv.fleet.front.end.gross.unit",
  "NV Fleet Direct Incentives / Unit" = "custom.dashboard.kpis.nv.fleet.direct.incentives.unit",
  "NV Fleet First Gross / Unit" = "custom.dashboard.kpis.nv.fleet.first.gross.unit",
  "NV Fleet Sales Effort % Gross Sales" = "custom.dashboard.kpis.nv.fleet.sales.effort.gross.sales",
  "NV Fleet FEG % Gross Sales" = "custom.dashboard.kpis.nv.fleet.feg.gross.sales",
  "NV Fleet First Gross % Net Sales" = "custom.dashboard.kpis.nv.fleet.first.gross.net.sales",
  "UV UV SC Productivity" = "custom.dashboard.kpis.uv.uv.sc.productivity",
  "UV UV Number of SC (Average)" = "custom.dashboard.kpis.uv.uv.number.of.sc.average",
  "UV Retail & Demo Sales / Retail & Demo Unit" = "custom.dashboard.kpis.uv.retail.&.demo.sales.retail.&.demo.unit",
  "UV Retail Sales / Retail Unit" = "custom.dashboard.kpis.uv.retail.sales.retail.unit",
  "UV Demo Sales / Demo Unit" = "custom.dashboard.kpis.uv.demo.sales.demo.unit",
  "UV Trade Sales / Trade Unit" = "custom.dashboard.kpis.uv.trade.sales.trade.unit",
  "UV Retail Units Sold" = "custom.dashboard.kpis.uv.retail.units.sold",
  "UV Demo Vehicle Units Sold" = "custom.dashboard.kpis.uv.demo.vehicle.units.sold",
  "UV Trade Units Sold" = "custom.dashboard.kpis.uv.trade.units.sold",
  "UV Total Units Sold" = "custom.dashboard.kpis.uv.total.units.sold",
  "UV Reconditioning Retail / Retail Unit" = "custom.dashboard.kpis.uv.reconditioning.retail.retail.unit",
  "UV Direct Incentives / Retail & Demo Unit" = "custom.dashboard.kpis.uv.direct.incentives.retail.&.demo.unit",
  "UV Retail & Demo 1st GP/ Retail & Demo Unit" = "custom.dashboard.kpis.uv.retail.&.demo.1st.gp.retail.&.demo.unit",
  "UV Retail 1st GP / Retail Unit" = "custom.dashboard.kpis.uv.retail.1st.gp.retail.unit",
  "UV Demo 1st GP / Demo Unit" = "custom.dashboard.kpis.uv.demo.1st.gp.demo.unit",
  "UV Trade 1st GP / Trade Unit" = "custom.dashboard.kpis.uv.trade.1st.gp.trade.unit",
  "UV Indirect Incentives / Unit" = "custom.dashboard.kpis.uv.indirect.incentives.unit",
  "UV GP / Unit" = "custom.dashboard.kpis.uv.gp.unit",
  "UV Retail & Demo 1st GP % Retail & Demo Sales" = "custom.dashboard.kpis.uv.retail.&.demo.1st.gp.retail.&.demo.sales",
  "UV Retail 1st GP % Retail Sales" = "custom.dashboard.kpis.uv.retail.1st.gp.retail.sales",
  "UV Demo 1st GP % Demo Sales" = "custom.dashboard.kpis.uv.demo.1st.gp.demo.sales",
  "UV Trade 1st GP % Trade Sales" = "custom.dashboard.kpis.uv.trade.1st.gp.trade.sales",
  "UV Total Expenses % GP" = "custom.dashboard.kpis.uv.total.expenses.gp",
  "UV Variable Expenses  % GP" = "custom.dashboard.kpis.uv.variable.expenses.gp",
  "UV Personnel  Expenses % GP" = "custom.dashboard.kpis.uv.personnel.expenses.gp",
  "UV Other Semi Variable Expenses % GP" = "custom.dashboard.kpis.uv.other.semi.variable.expenses.gp",
  "UV Fixed Expenses % GP" = "custom.dashboard.kpis.uv.fixed.expenses.gp",
  "UV Interest Expenses% Total Expenses" = "custom.dashboard.kpis.uv.interest.expenses.total.expenses",
  "UV Allocation of Admin  Expenses % GP" = "custom.dashboard.kpis.uv.allocation.of.admin.expenses.gp",
  "UV Variable Expenses% Total Expenses" = "custom.dashboard.kpis.uv.variable.expenses.total.expenses",
  "UV Personnel Expenses% Total Expenses" = "custom.dashboard.kpis.uv.personnel.expenses.total.expenses",
  "UV Other Semi Var.Exp.% Total Expenses" = "custom.dashboard.kpis.uv.other.semi.var.exp.total.expenses",
  "UV Fixed Expenses% Total Expenses" = "custom.dashboard.kpis.uv.fixed.expenses.total.expenses",
  "UV Allocation of Admin Expenses % Total Expenses" = "custom.dashboard.kpis.uv.allocation.of.admin.expenses.total.expenses",
  "UV Variable Expenses / Unit" = "custom.dashboard.kpis.uv.variable.expenses.unit",
  "UV Sales Commission / Unit" = "custom.dashboard.kpis.uv.sales.commission.unit",
  "UV Other Variable / Unit" = "custom.dashboard.kpis.uv.other.variable.unit",
  "UV Floor Plan Interest / Unit" = "custom.dashboard.kpis.uv.floor.plan.interest.unit",
  "UV Floor Plan Interest % UV GP" = "custom.dashboard.kpis.uv.floor.plan.interest.uv.gp",
  "UV Gross profit" = "custom.dashboard.kpis.uv.gross.profit",
  "UV Revenue" = "custom.dashboard.kpis.uv.revenue",
  "UV ROOA incl. F&I & Aftercare" = "custom.dashboard.kpis.uv.rooa.incl.f&i.&.aftercare",
  "UV Variable Expenses" = "custom.dashboard.kpis.uv.variable.expenses",
  "UV Personnel Expenses" = "custom.dashboard.kpis.uv.personnel.expenses",
  "UV Other Semi Variable Expenses" = "custom.dashboard.kpis.uv.other.semi.variable.expenses",
  "UV Fixed Expenses" = "custom.dashboard.kpis.uv.fixed.expenses",
  "UV Interest Expenses" = "custom.dashboard.kpis.uv.interest.expenses",
  "UV Departmental Profit" = "custom.dashboard.kpis.uv.departmental.profit",
  "UV Allocation of Admin Expenses" = "custom.dashboard.kpis.uv.allocation.of.admin.expenses",
  "UV EBIT" = "custom.dashboard.kpis.uv.ebit",
  "UV Dept. Profit % Net Sales" = "custom.dashboard.kpis.uv.dept.profit.net.sales",
  "UV EBIT% (PBIT%)" = "custom.dashboard.kpis.uv.ebit.pbit",
  "UV Variable Selling Gross % Gorss Profit" = "custom.dashboard.kpis.uv.variable.selling.gross.gorss.profit",
  "UV Selling Gross % Gross Profit" = "custom.dashboard.kpis.uv.selling.gross.gross.profit",
  "UV Selling Gross % Net Sales" = "custom.dashboard.kpis.uv.selling.gross.net.sales",
  "UV Gross Profit" = "custom.dashboard.kpis.uv.gross.profit",
  "UV GP / Productive / Month" = "custom.dashboard.kpis.uv.gp.productive.month",
  "UV GP / Employee / Month" = "custom.dashboard.kpis.uv.gp.employee.month",
  "UV Total Staff" = "custom.dashboard.kpis.uv.total.staff",
  "Afterstaff Staff Allocation UV" = "custom.dashboard.kpis.afterstaff.staff.allocation.uv",
  "UV Retail Reconditioning /  UV Retail Unit" = "custom.dashboard.kpis.uv.retail.reconditioning.uv.retail.unit",
  "UV Retail First Gross /   UV Retail Unit" = "custom.dashboard.kpis.uv.retail.first.gross..uv.retail.unit",
  "NV Finance Contract" = "custom.dashboard.kpis.nv.finance.contract",
  "NV Finance Penetration" = "custom.dashboard.kpis.nv.finance.penetration",
  "NV Insurance Contract" = "custom.dashboard.kpis.nv.insurance.contract",
  "NV Insurance Penetration" = "custom.dashboard.kpis.nv.insurance.penetration",
  "F&I Finance Sales" = "custom.dashboard.kpis.f&i.finance.sales",
  "F&I Insurance Sales" = "custom.dashboard.kpis.f&i.insurance.sales",
  "F&I Other Sales" = "custom.dashboard.kpis.f&i.other.sales",
  "F&I Total Expenses % GP" = "custom.dashboard.kpis.f&i.total.expenses.gp",
  "F&I Variable Expenses  % GP" = "custom.dashboard.kpis.f&i.variable.expenses.gp",
  "F&I Personnel Expenses % GP" = "custom.dashboard.kpis.f&i.personnel.expenses.gp",
  "F&I Other Semi Variable Expenses % GP" = "custom.dashboard.kpis.f&i.other.semi.variable.expenses.gp",
  "F&I Fixed Expenses % GP" = "custom.dashboard.kpis.f&i.fixed.expenses.gp",
  "F&I Interest Expenses% GP" = "custom.dashboard.kpis.f&i.interest.expenses.gp",
  "F&I Allocation of Admin Expenses % GP" = "custom.dashboard.kpis.f&i.allocation.of.admin.expenses.gp",
  "F&I Variable Expenses% Total Expenses" = "custom.dashboard.kpis.f&i.variable.expenses.total.expenses",
  "F&I Personnel Expenses% Total Expenses" = "custom.dashboard.kpis.f&i.personnel.expenses.total.expenses",
  "F&I Other Semi Var.Exp.% Total Expenses" = "custom.dashboard.kpis.f&i.other.semi.var.exp.total.expenses",
  "F&I Fixed Expenses% Total Expenses" = "custom.dashboard.kpis.f&i.fixed.expenses.total.expenses",
  "F&I Interest Expenses% Total Expenses" = "custom.dashboard.kpis.f&i.interest.expenses.total.expenses",
  "F&I Allocation of Admin Expenses % Total Expenses" = "custom.dashboard.kpis.f&i.allocation.of.admin.expenses.total.expenses",
  "F&I Variable Expenses" = "custom.dashboard.kpis.f&i.variable.expenses",
  "F&I Personnel Expenses" = "custom.dashboard.kpis.f&i.personnel.expenses",
  "F&I Other Semi Variable Expenses" = "custom.dashboard.kpis.f&i.other.semi.variable.expenses",
  "F&I Fixed Expenses" = "custom.dashboard.kpis.f&i.fixed.expenses",
  "F&I Interest Expenses" = "custom.dashboard.kpis.f&i.interest.expenses",
  "F&I Departmental Profit" = "custom.dashboard.kpis.f&i.departmental.profit",
  "F&I Allocation of Admin Expenses" = "custom.dashboard.kpis.f&i.allocation.of.admin.expenses",
  "F&I EBIT" = "custom.dashboard.kpis.f&i.ebit",
  "F&I Dept. Profit % Net Sales" = "custom.dashboard.kpis.f&i.dept.profit.net.sales",
  "F&I EBIT% (PBIT%)" = "custom.dashboard.kpis.f&i.ebit.pbit",
  "F&I Variable Selling Gross % Gorss Profit" = "custom.dashboard.kpis.f&i.variable.selling.gross.gorss.profit",
  "F&I Selling Gross % Gross Profit" = "custom.dashboard.kpis.f&i.selling.gross.gross.profit",
  "F&I Selling Gross % Net Sales" = "custom.dashboard.kpis.f&i.selling.gross.net.sales",
  "F&I Gross Profit" = "custom.dashboard.kpis.f&i.gross.profit",
  "F&I GP / Productive / Month" = "custom.dashboard.kpis.f&i.gp.productive.month",
  "F&I Productive Staff" = "custom.dashboard.kpis.f&i.productive.staff",
  "F&I GP / Employee / Month" = "custom.dashboard.kpis.f&i.gp.employee.month",
  "NV Protection Window Tint GP / NV Unit " = "custom.dashboard.kpis.nv.protection.window.tint.gp.nv.unit.",
  "NV Aftercare Other GP / NV Unit " = "custom.dashboard.kpis.nv.aftercare.other.gp.nv.unit.",
  "Aftercare ROS" = "custom.dashboard.kpis.aftercare.ros",
  "Parts and Service Sales per Service RO " = "custom.dashboard.kpis.parts.and.service.sales.per.service.ro.",
  "Parts Sales per Service RO " = "custom.dashboard.kpis.parts.sales.per.service.ro.",
  "Service Sales per Service RO " = "custom.dashboard.kpis.service.sales.per.service.ro.",
  "Parts and Body Shop Sales per Body Shop RO " = "custom.dashboard.kpis.parts.and.body.shop.sales.per.body.shop.ro.",
  "Parts Sales per Body Shop RO " = "custom.dashboard.kpis.parts.sales.per.body.shop.ro.",
  "Body Shop Sales per Body Shop RO " = "custom.dashboard.kpis.body.shop.sales.per.body.shop.ro.",
  "Parts Breakeven Sales" = "custom.dashboard.kpis.parts.breakeven.sales",
  "Parts Total Expenses % GP" = "custom.dashboard.kpis.parts.total.expenses.gp",
  "Parts Variable Expenses  % GP" = "custom.dashboard.kpis.parts.variable.expenses.gp",
  "Parts Personnel  Expenses % GP" = "custom.dashboard.kpis.parts.personnel.expenses.gp",
  "Parts Other Semi Variable Expenses % GP" = "custom.dashboard.kpis.parts.other.semi.variable.expenses.gp",
  "Parts Fixed Expenses % GP" = "custom.dashboard.kpis.parts.fixed.expenses.gp",
  "Parts Interest Expenses % GP" = "custom.dashboard.kpis.parts.interest.expenses.gp",
  "Parts Allocation of Admin  Expenses % GP" = "custom.dashboard.kpis.parts.allocation.of.admin.expenses.gp",
  "Parts Variable Expenses% Total Expenses" = "custom.dashboard.kpis.parts.variable.expenses.total.expenses",
  "Parts Personnel Expenses% Total Expenses" = "custom.dashboard.kpis.parts.personnel.expenses.total.expenses",
  "Parts Other Semi Var.Exp.% Total Expenses" = "custom.dashboard.kpis.parts.other.semi.var.exp.total.expenses",
  "Parts Fixed Expenses% Total Expenses" = "custom.dashboard.kpis.parts.fixed.expenses.total.expenses",
  "Parts Interest Expenses % Total Expenses" = "custom.dashboard.kpis.parts.interest.expenses.total.expenses",
  "Parts Allocation of Admin Expenses % Total Expenses" = "custom.dashboard.kpis.parts.allocation.of.admin.expenses.total.expenses",
  "Parts Variable Expenses" = "custom.dashboard.kpis.parts.variable.expenses",
  "Parts Personnel Expenses" = "custom.dashboard.kpis.parts.personnel.expenses",
  "Parts Other Semi Variable Expenses" = "custom.dashboard.kpis.parts.other.semi.variable.expenses",
  "Parts Fixed Expenses" = "custom.dashboard.kpis.parts.fixed.expenses",
  "Parts Interest Expenses" = "custom.dashboard.kpis.parts.interest.expenses",
  "Parts Departmental Profit" = "custom.dashboard.kpis.parts.departmental.profit",
  "Parts Allocation of Admin Expenses" = "custom.dashboard.kpis.parts.allocation.of.admin.expenses",
  "Parts EBIT" = "custom.dashboard.kpis.parts.ebit",
  "Parts Dept. Profit % Net Sales" = "custom.dashboard.kpis.parts.dept.profit.net.sales",
  "Parts EBIT% (PBIT%)" = "custom.dashboard.kpis.parts.ebit.pbit",
  "Parts Variable Selling Gross % Gorss Profit" = "custom.dashboard.kpis.parts.variable.selling.gross.gorss.profit",
  "Parts Selling Gross % Gross Profit" = "custom.dashboard.kpis.parts.selling.gross.gross.profit",
  "Parts Selling Gross % Net Sales" = "custom.dashboard.kpis.parts.selling.gross.net.sales",
  "Parts Gross Profit" = "custom.dashboard.kpis.parts.gross.profit",
  "Parts Non-Productive Staff" = "custom.dashboard.kpis.parts.non.productive.staff",
  "Parts Ratio Productive : Non Productive" = "custom.dashboard.kpis.parts.ratio.productive.non.productive",
  "Parts Total Staff" = "custom.dashboard.kpis.parts.total.staff",
  "Service Total RO" = "custom.dashboard.kpis.service.total.ro",
  "Service RO / Day" = "custom.dashboard.kpis.service.ro.day",
  "Service Hours Sold / RO" = "custom.dashboard.kpis.service.hours.sold.ro",
  "Service Effective Labour Rate / Hour" = "custom.dashboard.kpis.service.effective.labour.rate.hour",
  "Service Labour GP" = "custom.dashboard.kpis.service.labour.gp",
  "Service Non-Labour GP" = "custom.dashboard.kpis.service.non.labour.gp",
  "Service Labour GP% " = "custom.dashboard.kpis.service.labour.gp.perc",
  "Service Labour FEG%" = "custom.dashboard.kpis.service.labour.feg",
  "Service Labour Retail GP%" = "custom.dashboard.kpis.service.labour.retail.gp",
  "Service Labour Warranty GP%" = "custom.dashboard.kpis.service.labour.warranty.gp",
  "Service Labour Internal GP%" = "custom.dashboard.kpis.service.labour.internal.gp",
  "Service Labour Maint. Contract GP%" = "custom.dashboard.kpis.service.labour.maint.contract.gp",
  "Service Labour Quick Service GP%" = "custom.dashboard.kpis.service.labour.quick.service.gp",
  "Service Sublet GP%" = "custom.dashboard.kpis.service.sublet.gp",
  "Service Oil & Lub GP%" = "custom.dashboard.kpis.service.oil.&.lub.gp",
  "Service Extended Warranty GP%" = "custom.dashboard.kpis.service.extended.warranty.gp",
  "Service Other GP%" = "custom.dashboard.kpis.service.other.gp",
  "Service Non-Labour Sales" = "custom.dashboard.kpis.service.non.labour.sales",
  "Service Variable Expenses  % GP" = "custom.dashboard.kpis.service.variable.expenses.gp",
  "Service Personnel  Expenses % GP" = "custom.dashboard.kpis.service.personnel.expenses.gp",
  "Service Other Semi Variable Expenses % GP" = "custom.dashboard.kpis.service.other.semi.variable.expenses.gp",
  "Service Fixed Expenses % GP" = "custom.dashboard.kpis.service.fixed.expenses.gp",
  "Service Interest Expenses % GP" = "custom.dashboard.kpis.service.interest.expenses.gp",
  "Service Allocation of Admin  Expenses % GP" = "custom.dashboard.kpis.service.allocation.of.admin.expenses.gp",
  "Service Variable Expenses% Total Expenses" = "custom.dashboard.kpis.service.variable.expenses.total.expenses",
  "Service Personnel Expenses% Total Expenses" = "custom.dashboard.kpis.service.personnel.expenses.total.expenses",
  "Service Other Semi Var.Exp.% Total Expenses" = "custom.dashboard.kpis.service.other.semi.var.exp.total.expenses",
  "Service Fixed Expenses% Total Expenses" = "custom.dashboard.kpis.service.fixed.expenses.total.expenses",
  "Service Interest Expenses % Total Expenses" = "custom.dashboard.kpis.service.interest.expenses.total.expenses",
  "Service Allocation of Admin Expenses % Total Expenses" = "custom.dashboard.kpis.service.allocation.of.admin.expenses.total.expenses",
  "Service Variable Expenses" = "custom.dashboard.kpis.service.variable.expenses",
  "Service Personnel Expenses" = "custom.dashboard.kpis.service.personnel.expenses",
  "Service Other Semi Variable Expenses" = "custom.dashboard.kpis.service.other.semi.variable.expenses",
  "Service Fixed Expenses" = "custom.dashboard.kpis.service.fixed.expenses",
  "Service Interest Expenses" = "custom.dashboard.kpis.service.interest.expenses",
  "Service Departmental Profit" = "custom.dashboard.kpis.service.departmental.profit",
  "Service Allocation of Admin Expenses" = "custom.dashboard.kpis.service.allocation.of.admin.expenses",
  "Service EBIT" = "custom.dashboard.kpis.service.ebit",
  "Service Dept. Profit % Net Sales" = "custom.dashboard.kpis.service.dept.profit.net.sales",
  "Service EBIT% (PBIT%)" = "custom.dashboard.kpis.service.ebit.pbit",
  "Service Variable Selling Gross % Gorss Profit" = "custom.dashboard.kpis.service.variable.selling.gross.gorss.profit",
  "Service Selling Gross % Gross Profit" = "custom.dashboard.kpis.service.selling.gross.gross.profit",
  "Service Selling Gross % Net Sales" = "custom.dashboard.kpis.service.selling.gross.net.sales",
  "Service Gross Profit" = "custom.dashboard.kpis.service.gross.profit",
  "Service Support Staff incl. SA" = "custom.dashboard.kpis.service.support.staff.incl.sa",
  "Service SA" = "custom.dashboard.kpis.service.sa",
  "Service Ratio Productive : Non Productive" = "custom.dashboard.kpis.service.ratio.productive.non.productive",
  "Service Number of Workbay" = "custom.dashboard.kpis.service.number.of.workbay",
  "Service Total Staff" = "custom.dashboard.kpis.service.total.staff",
  "RO / Workbay / Day" = "custom.dashboard.kpis.ro.workbay.day",
  "Service Labour Sales / RO" = "custom.dashboard.kpis.service.labour.sales.ro",
  "Service Labour GP / Productive / Month" = "custom.dashboard.kpis.service.labour.gp.productive.month",
  "Service Labour Sales / Productive / Month" = "custom.dashboard.kpis.service.labour.sales.productive.month",
  "Service Technician Cost / Productive / Month" = "custom.dashboard.kpis.service.technician.cost.productive.month",
  "Service GP / Productive / Month" = "custom.dashboard.kpis.service.gp.productive.month",
  "Service GP / Employee / Month" = "custom.dashboard.kpis.service.gp.employee.month",
  "Body Shop Total RO" = "custom.dashboard.kpis.body.shop.total.ro",
  "Body Shop RO / Day" = "custom.dashboard.kpis.body.shop.ro.day",
  "Body Shop Other GP %" = "custom.dashboard.kpis.body.shop.other.gp.",
  "Body Shop Labour GP" = "custom.dashboard.kpis.body.shop.labour.gp",
  "Body Shop Non-Labour GP" = "custom.dashboard.kpis.body.shop.non.labour.gp",
  "Body Shop Labour GP%" = "custom.dashboard.kpis.body.shop.labour.gp",
  "Body Shop Non-Labour Sales" = "custom.dashboard.kpis.body.shop.non.labour.sales",
  "Body Shop Variable Expenses  % GP" = "custom.dashboard.kpis.body.shop.variable.expenses.gp",
  "Body Shop Personnel  Expenses % GP" = "custom.dashboard.kpis.body.shop.personnel.expenses.gp",
  "Body Shop Other Semi Variable Expenses % GP" = "custom.dashboard.kpis.body.shop.other.semi.variable.expenses.gp",
  "Body Shop Fixed Expenses % GP" = "custom.dashboard.kpis.body.shop.fixed.expenses.gp",
  "Body Shop Interest Expenses % GP" = "custom.dashboard.kpis.body.shop.interest.expenses.gp",
  "Body Shop Allocation of Admin  Expenses % GP" = "custom.dashboard.kpis.body.shop.allocation.of.admin.expenses.gp",
  "Body Shop Variable Expenses% Total Expenses" = "custom.dashboard.kpis.body.shop.variable.expenses.total.expenses",
  "Body Shop Personnel Expenses% Total Expenses" = "custom.dashboard.kpis.body.shop.personnel.expenses.total.expenses",
  "Body Shop Other Semi Var.Exp.% Total Expenses" = "custom.dashboard.kpis.body.shop.other.semi.var.exp.total.expenses",
  "Body Shop Fixed Expenses% Total Expenses" = "custom.dashboard.kpis.body.shop.fixed.expenses.total.expenses",
  "Body Shop Interest Expenses % Total Expenses" = "custom.dashboard.kpis.body.shop.interest.expenses.total.expenses",
  "Body Shop Allocation of Admin Expenses % Total Expenses" = "custom.dashboard.kpis.body.shop.allocation.of.admin.expenses.total.expenses",
  "Body Shop PBT" = "custom.dashboard.kpis.body.shop.pbt",
  "Body Shop Dept. Profit % Net Sales" = "custom.dashboard.kpis.body.shop.dept.profit.net.sales",
  "Body Shop EBIT% (PBIT%)" = "custom.dashboard.kpis.body.shop.ebit.pbit",
  "Body Shop Variable Selling Gross % Gorss Profit" = "custom.dashboard.kpis.body.shop.variable.selling.gross.gorss.profit",
  "Body Shop Selling Gross % Gross Profit" = "custom.dashboard.kpis.body.shop.selling.gross.gross.profit",
  "Body Shop Selling Gross % Net Sales" = "custom.dashboard.kpis.body.shop.selling.gross.net.sales",
  "Body Shop Gross Profit" = "custom.dashboard.kpis.body.shop.gross.profit",
  "Body Shop Expenses" = "custom.dashboard.kpis.body.shop.expenses",
  "Body Shop Variable Expenses" = "custom.dashboard.kpis.body.shop.variable.expenses",
  "Body Shop Personnel Expenses" = "custom.dashboard.kpis.body.shop.personnel.expenses",
  "Body Shop Other Semi Variable Expenses" = "custom.dashboard.kpis.body.shop.other.semi.variable.expenses",
  "Body Shop Fixed Expenses" = "custom.dashboard.kpis.body.shop.fixed.expenses",
  "Body Shop Interest Expenses" = "custom.dashboard.kpis.body.shop.interest.expenses",
  "Body Shop Departmental Profit" = "custom.dashboard.kpis.body.shop.departmental.profit",
  "Body Shop Allocation of Admin Expenses" = "custom.dashboard.kpis.body.shop.allocation.of.admin.expenses",
  "Body Shop EBIT" = "custom.dashboard.kpis.body.shop.ebit",
  "Body Shop Labour GP / Productive / Month" = "custom.dashboard.kpis.body.shop.labour.gp.productive.month",
  "Body Shop Labour Sales / Productive / Month" = "custom.dashboard.kpis.body.shop.labour.sales.productive.month",
  "Body Shop Technician Cost / Productive / Month" = "custom.dashboard.kpis.body.shop.technician.cost.productive.month",
  "Body Shop GP / Productive / Month" = "custom.dashboard.kpis.body.shop.gp.productive.month",
  "Body Shop GP / Employee / Month" = "custom.dashboard.kpis.body.shop.gp.employee.month",
  "Body Shop RO / Technician / Day" = "custom.dashboard.kpis.body.shop.ro.technician.day",
  "Body Shop Labour Sales / RO" = "custom.dashboard.kpis.body.shop.labour.sales.ro",
  "Body Shop Hours Sold / RO" = "custom.dashboard.kpis.body.shop.hours.sold.ro",
  "Body Shop Effective Labour Rate / Hour" = "custom.dashboard.kpis.body.shop.effective.labour.rate.hour",
  "Body Shop Workbay Utilization" = "custom.dashboard.kpis.body.shop.workbay.utilization",
  "Body Shop Workbay Occupancy" = "custom.dashboard.kpis.body.shop.workbay.occupancy",
  "Body Shop Number of Workbay" = "custom.dashboard.kpis.body.shop.number.of.workbay",
  "Body Shop Total Staff" = "custom.dashboard.kpis.body.shop.total.staff",
  "Body Shop Support Staff incl. SA" = "custom.dashboard.kpis.body.shop.support.staff.incl.sa",
  "Body Shop SA" = "custom.dashboard.kpis.body.shop.sa",
  "Body Shop Ratio Productive : Non Productive" = "custom.dashboard.kpis.body.shop.ratio.productive.non.productive",
  "Body Shop GP (excl Incentives)" = "custom.dashboard.kpis.body.shop.gp.excl.incentives",
  "Body Shop Expenses (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.body.shop.expenses.excl.oem.expenses.subsidy",
  "Body Shop PBT (excl All Supports from OEM)" = "custom.dashboard.kpis.body.shop.pbt.excl.all.supports.from.oem",
  "Body Shop PBT (excl Incentives)" = "custom.dashboard.kpis.body.shop.pbt.excl.incentives",
  "Body Shop PBT (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.body.shop.pbt.excl.oem.expenses.subsidy",
  "Body Shop ROS (excl All Supports from OEM)" = "custom.dashboard.kpis.body.shop.ros.excl.all.supports.from.oem",
  "Body Shop ROS (excl Incentives)" = "custom.dashboard.kpis.body.shop.ros.excl.incentives",
  "Body Shop ROS (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.body.shop.ros.excl.oem.expenses.subsidy",
  "Body Shop MIX (excl Incentive)" = "custom.dashboard.kpis.body.shop.mix.excl.incentive",
  "Body Shop RETAINED" = "custom.dashboard.kpis.body.shop.retained",
  "Body Shop RETAINED (excl All Supports from OEM)" = "custom.dashboard.kpis.body.shop.retained.excl.all.supports.from.oem",
  "Body Shop RETAINED (excl Incentives)" = "custom.dashboard.kpis.body.shop.retained.excl.incentives",
  "Body Shop RETAINED (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.body.shop.retained.excl.oem.expenses.subsidy",
  "Body Shop Expenses % TGP" = "custom.dashboard.kpis.body.shop.expenses.tgp",
  "Body Shop Expenses % TGP (excl All Supports from OEM)" = "custom.dashboard.kpis.body.shop.expenses.tgp.excl.all.supports.from.oem",
  "Body Shop Expenses % TGP (excl Incentives)" = "custom.dashboard.kpis.body.shop.expenses.tgp.excl.incentives",
  "Body Shop Expenses % TGP (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.body.shop.expenses.tgp.excl.oem.expenses.subsidy",
  "Body Shop Support from OEM" = "custom.dashboard.kpis.body.shop.support.from.oem",
  "Body Shop OEM Incentives" = "custom.dashboard.kpis.body.shop.oem.incentives",
  "Body Shop OEM Expenses Subsidy" = "custom.dashboard.kpis.body.shop.oem.expenses.subsidy",
  "Body Shop OEM Direct Advertising Subsidy" = "custom.dashboard.kpis.body.shop.oem.direct.advertising.subsidy",
  "Body Shop OEM Indirect Advertising Subsidy" = "custom.dashboard.kpis.body.shop.oem.indirect.advertising.subsidy",
  "Body Shop OEM Incentives % TGP" = "custom.dashboard.kpis.body.shop.oem.incentives.tgp",
  "Body Shop OEM Incentives % Sales" = "custom.dashboard.kpis.body.shop.oem.incentives.sales",
  "Body Shop OEM Expenses Subsidy % Sales" = "custom.dashboard.kpis.body.shop.oem.expenses.subsidy.sales",
  "Body Shop OEM All Supports % Sales" = "custom.dashboard.kpis.body.shop.oem.all.supports.sales",
  "Driveway Breakeven Days" = "custom.dashboard.kpis.driveway.breakeven.days",
  "Driveway Breakeven Sales" = "custom.dashboard.kpis.driveway.breakeven.sales",
  "Driveway Total Sales" = "custom.dashboard.kpis.driveway.total.sales",
  "Driveway Unleaded Fuel Sales" = "custom.dashboard.kpis.driveway.unleaded.fuel.sales",
  "Driveway Leaded Fuel Sales" = "custom.dashboard.kpis.driveway.leaded.fuel.sales",
  "Driveway Diesel Sales" = "custom.dashboard.kpis.driveway.diesel.sales",
  "Driveway Parrafin Sales" = "custom.dashboard.kpis.driveway.parrafin.sales",
  "Driveway Oil driveway Sales" = "custom.dashboard.kpis.driveway.oil.driveway.sales",
  "Driveway Gas Sales" = "custom.dashboard.kpis.driveway.gas.sales",
  "Driveway Shop - General Sales" = "custom.dashboard.kpis.driveway.shop...general.sales",
  "Driveway Other Sales" = "custom.dashboard.kpis.driveway.other.sales",
  "DRIVEWAY MIX" = "custom.dashboard.kpis.driveway.mix",
  "DRIVEWAY Total Sales" = "custom.dashboard.kpis.driveway.total.sales",
  "DRIVEWAY RETAINED" = "custom.dashboard.kpis.driveway.retained",
  "DRIVEWAY Total GP" = "custom.dashboard.kpis.driveway.total.gp",
  "DRIVEWAY Total Expenses" = "custom.dashboard.kpis.driveway.total.expenses",
  "DRIVEWAY Total PBT" = "custom.dashboard.kpis.driveway.total.pbt",
  "Driveway Total Expenses % GP" = "custom.dashboard.kpis.driveway.total.expenses.gp",
  "Driveway Variable Expenses  % GP" = "custom.dashboard.kpis.driveway.variable.expenses.gp",
  "Driveway Personnel  Expenses % GP" = "custom.dashboard.kpis.driveway.personnel.expenses.gp",
  "Driveway Other Semi Variable Expenses % GP" = "custom.dashboard.kpis.driveway.other.semi.variable.expenses.gp",
  "Driveway Fixed Expenses % GP" = "custom.dashboard.kpis.driveway.fixed.expenses.gp",
  "Driveway Interest Expenses % GP" = "custom.dashboard.kpis.driveway.interest.expenses.gp",
  "Driveway Allocation of Admin  Expenses % GP" = "custom.dashboard.kpis.driveway.allocation.of.admin.expenses.gp",
  "Driveway Variable Expenses% Total Expenses" = "custom.dashboard.kpis.driveway.variable.expenses.total.expenses",
  "Driveway Personnel Expenses% Total Expenses" = "custom.dashboard.kpis.driveway.personnel.expenses.total.expenses",
  "Driveway Other Semi Var.Exp.% Total Expenses" = "custom.dashboard.kpis.driveway.other.semi.var.exp.total.expenses",
  "Driveway Fixed Expenses% Total Expenses" = "custom.dashboard.kpis.driveway.fixed.expenses.total.expenses",
  "Driveway Interest Expenses % Total Expenses" = "custom.dashboard.kpis.driveway.interest.expenses.total.expenses",
  "Driveway Allocation of Admin Expenses % Total Expenses" = "custom.dashboard.kpis.driveway.allocation.of.admin.expenses.total.expenses",
  "DRIVEWAY ACTIVITY" = "custom.dashboard.kpis.driveway.activity",
  "DRIVEWAY Annualised Sales" = "custom.dashboard.kpis.driveway.annualised.sales",
  "DRIVEWAY Average Operational Assets" = "custom.dashboard.kpis.driveway.average.operational.assets",
  "DRIVEWAY ROOA" = "custom.dashboard.kpis.driveway.rooa",
  "Driveway PBT" = "custom.dashboard.kpis.driveway.pbt",
  "Driveway Dept. Profit % Net Sales" = "custom.dashboard.kpis.driveway.dept.profit.net.sales",
  "Driveway EBIT% (PBIT%)" = "custom.dashboard.kpis.driveway.ebit.pbit",
  "Driveway Variable Selling Gross % Gorss Profit" = "custom.dashboard.kpis.driveway.variable.selling.gross.gorss.profit",
  "Driveway Selling Gross % Gross Profit" = "custom.dashboard.kpis.driveway.selling.gross.gross.profit",
  "Driveway Selling Gross % Net Sales" = "custom.dashboard.kpis.driveway.selling.gross.net.sales",
  "Driveway Gross Profit" = "custom.dashboard.kpis.driveway.gross.profit",
  "Driveway Variable Expenses" = "custom.dashboard.kpis.driveway.variable.expenses",
  "Driveway Personnel Expenses" = "custom.dashboard.kpis.driveway.personnel.expenses",
  "Driveway Other Semi Variable Expenses" = "custom.dashboard.kpis.driveway.other.semi.variable.expenses",
  "Driveway Fixed Expenses" = "custom.dashboard.kpis.driveway.fixed.expenses",
  "Driveway Interest Expenses" = "custom.dashboard.kpis.driveway.interest.expenses",
  "Driveway Departmental Profit" = "custom.dashboard.kpis.driveway.departmental.profit",
  "Driveway Allocation of Admin Expenses" = "custom.dashboard.kpis.driveway.allocation.of.admin.expenses",
  "Driveway EBIT" = "custom.dashboard.kpis.driveway.ebit",
  "Driveway GP / Productive / Month" = "custom.dashboard.kpis.driveway.gp.productive.month",
  "Driveway Sales / Productive / Month" = "custom.dashboard.kpis.driveway.sales.productive.month",
  "Driveway GP / Employee / Month" = "custom.dashboard.kpis.driveway.gp.employee.month",
  "Driveway Total Staff" = "custom.dashboard.kpis.driveway.total.staff",
  "Driveway Productive Staff" = "custom.dashboard.kpis.driveway.productive.staff",
  "Driveway Non-Productive Staff" = "custom.dashboard.kpis.driveway.non.productive.staff",
  "Driveway Ratio Productive : Non Productive" = "custom.dashboard.kpis.driveway.ratio.productive.non.productive",
  "Driveway OEM Expenses Subsidy" = "custom.dashboard.kpis.driveway.oem.expenses.subsidy",
  "Driveway OEM Direct Advertising Subsidy" = "custom.dashboard.kpis.driveway.oem.direct.advertising.subsidy",
  "Driveway OEM Indirect Advertising Subsidy" = "custom.dashboard.kpis.driveway.oem.indirect.advertising.subsidy",
  "Driveway OEM Expenses Subsidy % Sales" = "custom.dashboard.kpis.driveway.oem.expenses.subsidy.sales",
  "Driveway MIX" = "custom.dashboard.kpis.driveway.mix",
  "Driveway RETAINED" = "custom.dashboard.kpis.driveway.retained",
  "Driveway RETAINED (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.driveway.retained.excl.oem.expenses.subsidy",
  "Driveway Expenses % TGP" = "custom.dashboard.kpis.driveway.expenses.tgp",
  "Driveway Expenses % TGP (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.driveway.expenses.tgp.excl.oem.expenses.subsidy",
  "Driveway ROS" = "custom.dashboard.kpis.driveway.ros",
  "Driveway ROS (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.driveway.ros.excl.oem.expenses.subsidy",
  "Driveway Sales" = "custom.dashboard.kpis.driveway.sales",
  "Driveway GP" = "custom.dashboard.kpis.driveway.gp",
  "Driveway Expenses" = "custom.dashboard.kpis.driveway.expenses",
  "Driveway Expenses (excl OEM Expenses Subsidy)" = "custom.dashboard.kpis.driveway.expenses.excl.oem.expenses.subsidy",
  "Driveway PBT (excl All Supports from OEM)" = "custom.dashboard.kpis.driveway.pbt.excl.all.supports.from.oem",
  "OTHER DEPT. Total Sales" = "custom.dashboard.kpis.other.dept.total.sales",
  "OTHER DEPT. Total GP" = "custom.dashboard.kpis.other.dept.total.gp",
  "OTHER DEPT. Total Expenses" = "custom.dashboard.kpis.other.dept.total.expenses",
  "OTHER DEPT. Total PBT" = "custom.dashboard.kpis.other.dept.total.pbt",
  "Other Dept. Total Expenses % GP" = "custom.dashboard.kpis.other.dept.total.expenses.gp",
  "Other Dept. Variable Expenses  % GP" = "custom.dashboard.kpis.other.dept.variable.expenses.gp",
  "Other Dept. Personnel  Expenses % GP" = "custom.dashboard.kpis.other.dept.personnel.expenses.gp",
  "Other Dept. Other Semi Variable Expenses % GP" = "custom.dashboard.kpis.other.dept.other.semi.variable.expenses.gp",
  "Other Dept. Fixed Expenses % GP" = "custom.dashboard.kpis.other.dept.fixed.expenses.gp",
  "Other Dept. Interest Expenses % GP" = "custom.dashboard.kpis.other.dept.interest.expenses.gp",
  "Other Dept. Allocation of Admin  Expenses % GP" = "custom.dashboard.kpis.other.dept.allocation.of.admin.expenses.gp",
  "Other Dept. Variable Expenses% Total Expenses" = "custom.dashboard.kpis.other.dept.variable.expenses.total.expenses",
  "Other Dept. Personnel Expenses% Total Expenses" = "custom.dashboard.kpis.other.dept.personnel.expenses.total.expenses",
  "Other Dept. Other Semi Var.Exp.% Total Expenses" = "custom.dashboard.kpis.other.dept.other.semi.var.exp.total.expenses",
  "Other Dept. Fixed Expenses% Total Expenses" = "custom.dashboard.kpis.other.dept.fixed.expenses.total.expenses",
  "Other Dept. Interest Expenses % Total Expenses" = "custom.dashboard.kpis.other.dept.interest.expenses.total.expenses",
  "Other Dept. Allocation of Admin Expenses % Total Expenses" = "custom.dashboard.kpis.other.dept.allocation.of.admin.expenses.total.expenses",
  "OTHER DEPT. ACTIVITY" = "custom.dashboard.kpis.other.dept.activity",
  "OTHER DEPT. Annualised Sales" = "custom.dashboard.kpis.other.dept.annualised.sales",
  "OTHER DEPT. Average Operational Assets" = "custom.dashboard.kpis.other.dept.average.operational.assets",
  "OTHER DEPT. ROOA" = "custom.dashboard.kpis.other.dept.rooa",
  "OTHER DEPT. MIX" = "custom.dashboard.kpis.other.dept.mix",
  "OTHER DEPT. RETAINED" = "custom.dashboard.kpis.other.dept.retained",
  "Other Dept. ROS" = "custom.dashboard.kpis.other.dept.ros",
  "Other Dept. Sales" = "custom.dashboard.kpis.other.dept.sales",
  "Other Dept. PBT" = "custom.dashboard.kpis.other.dept.pbt",
  "Other Dept. Dept. Profit % Net Sales" = "custom.dashboard.kpis.other.dept.dept.profit.net.sales",
  "Other Dept. EBIT% (PBIT%)" = "custom.dashboard.kpis.other.dept.ebit.pbit",
  "Other Dept. Variable Selling Gross % Gorss Profit" = "custom.dashboard.kpis.other.dept.variable.selling.gross.gorss.profit",
  "Other Dept. Selling Gross % Gross Profit" = "custom.dashboard.kpis.other.dept.selling.gross.gross.profit",
  "Other Dept. Selling Gross % Net Sales" = "custom.dashboard.kpis.other.dept.selling.gross.net.sales",
  "Other Dept. Gross Profit" = "custom.dashboard.kpis.other.dept.gross.profit",
  "Other Dept. Expenses" = "custom.dashboard.kpis.other.dept.expenses",
  "Other Dept. Variable Expenses" = "custom.dashboard.kpis.other.dept.variable.expenses",
  "Other Dept. Personnel Expenses" = "custom.dashboard.kpis.other.dept.personnel.expenses",
  "Other Dept. Other Semi Variable Expenses" = "custom.dashboard.kpis.other.dept.other.semi.variable.expenses",
  "Other Dept. Fixed Expenses" = "custom.dashboard.kpis.other.dept.fixed.expenses",
  "Other Dept. Interest Expenses" = "custom.dashboard.kpis.other.dept.interest.expenses",
  "Other Dept. Departmental Profit" = "custom.dashboard.kpis.other.dept.departmental.profit",
  "Other Dept. Allocation of Admin Expenses" = "custom.dashboard.kpis.other.dept.allocation.of.admin.expenses",
  "Other Dept. EBIT" = "custom.dashboard.kpis.other.dept.ebit",
  "Other Dept. GP / Productive / Month" = "custom.dashboard.kpis.other.dept.gp.productive.month",
  "Other Dept. Sales / Productive / Month" = "custom.dashboard.kpis.other.dept.sales.productive.month",
  "Other Dept. GP / Employee / Month" = "custom.dashboard.kpis.other.dept.gp.employee.month",
  "Other Dept. Total Staff" = "custom.dashboard.kpis.other.dept.total.staff",
  "Other Dept. Productive Staff" = "custom.dashboard.kpis.other.dept.productive.staff",
  "Other Dept. Non-Productive Staff" = "custom.dashboard.kpis.other.dept.non.productive.staff",
  "Other Dept. Ratio Productive : Non Productive" = "custom.dashboard.kpis.other.dept.ratio.productive.non.productive",
  "Admin Fees Payable  % TGP" = "custom.metric.label.admin.fees.payable.tgp",
  "Settings" = "settings.label",
  "Change Password" = "change.password.header",
  "Password" = "password.label",
  "Confirm Password" = "confirm.password.label",
  "BS KPIs" = "custom.metric.department.department.bs.kpis",
  "ROCE" = "custom.metric.department.department.roce",
  "NV ROOA" = "custom.metric.label.nv.rooa",
  "NV ROS (Income Statement)" = "custom.metric.label.nv.ros.income.statement",
  "NV Productivity" = "custom.metric.label.nv.productivity",
  "NV PRODUCTIVITY" = "custom.metric.label.nv.productivity.upper",
  "NV RETAIL  &  NV FLEET" = "custom.metric.label.nv.retail.and.nv.fleet",
  "UV ROOA" = "custom.metric.label.uv.rooa",
  "UV ROS (Income Statement)" = "custom.metric.label.uv.ros.income.statement",
  "UV PRODUCTIVITY" = "custom.metric.label.uv.productivity",
  "UV Productivity" = "metric.label.uv.group.productivity",
  "UV Retail & Fleet" = "metric.label.uv.group.retail.fleet.label",
  "Parts Productivity" = "metric.label.uv.group.parts.productivity.total.parts.sales.label",
  "F&I ROOA" = "custom.metric.label.f.and.i.rooa",
  "AFTER SALES ROOA" = "custom.metric.label.aftersales.rooa",
  "Parts ROOA" = "custom.metric.label.parts.rooa",
  "Service ROOA" = "custom.metric.label.service.rooa",
  "Body Shop ROOA" = "custom.metric.label.body.shop.rooa",
  "DRIVEWAY" = "custom.metric.department.driveway",
  "Opera‚Ä¶l Income % Net Sales (excl All Supports from OEM)" = "custom.dashboard.kpis.opera‚Ä¶l.incomenet.sales.excl.all.supports.from.oem",
  "UV (Retail & Demo)" = "custom.dashboard.kpis.uv.retail.demo.nv.ratio",
  "Aftercare Ratio Productive" = "custom.dashboard.kpis.aftercare.ratio.productive.:.non.productive",
  "Parts Ratio Productive" = "custom.dashboard.kpis.parts.ratio.productive.non.productive",
  "Body Shop Ratio Productive" = "custom.dashboard.kpis.body.shop.ratio.productive.non.productive",
  "Driveway Ratio Productive" = "custom.dashboard.kpis.driveway.ratio.productive.non.productive",
  "Other Dept. Ratio Productive" = "custom.dashboard.kpis.other.dept.ratio.productive.non.productive",
  "Breakeven" = "custom.insight.table.headers.breakeven",
  "Overall Dealership - MIX (GP)" = "custom.insight.table.headers.overall.dealership.mix.gp",
  "Overall Dealership - RETAINED (EXPENSES)" = "custom.insight.table.headers.overall.dealership.retained.expenses",
  "Overall Dealership - Variable Expenses" = "custom.insight.table.headers.overall.dealership.variable.expenses",
  "Overall Dealership - Personnel Expenses" = "custom.insight.table.headers.overall.dealership.personnel.expenses",
  "Overall Dealership - Other Semi Var. Expenses" = "custom.insight.table.headers.overall.dealership.other.semi.var.expenses",
  "Overall Dealership - Fixed Expenses" = "custom.insight.table.headers.overall.dealership.fixed.expenses",
  "Overall Dealership - Interest Expenses" = "custom.insight.table.headers.overall.dealership.interest.expenses",
  "Overall Dealership - Admin Fees Payable" = "custom.insight.table.headers.overall.dealership.admin.fees.payable",
  "Overall Dealership - Deduction / (Addition)" = "custom.insight.table.headers.overall.dealership.deduction.addition",
  "Overall Dealership - ACTIVITY" = "custom.insight.table.headers.overall.dealership.activity",
  "Overall Dealership - ROOA" = "custom.insight.table.headers.overall.dealership.rooa",
  "Overall Dealership - Income Statement" = "custom.insight.table.headers.overall.dealership.income.statement",
  "Overall Dealership - PRODUCTIVITY" = "custom.insight.table.headers.overall.dealership.productivity",
  "Overall Dealership - STAFF" = "custom.insight.table.headers.overall.dealership.staff",
  "Overall Dealership - FOA" = "custom.insight.table.headers.overall.dealership.foa",
  "BS KPIs - CURRENT RATIO" = "custom.insight.table.headers.bs.kpis.current.ratio",
  "BS KPIs - WORKING CAPITAL DAYS & Per Unit" = "custom.insight.table.headers.bs.kpis.working.capital.days.and.per.unit",
  "BS KPIs - WORKING CAPITAL AMOUNT" = "custom.insight.table.headers.bs.kpis.working.capital.amount",
  "BS KPIs - CCC (Cash Conversion Cycle)" = "custom.insight.table.headers.bs.kpis.ccc.cash.conversion.cycle",
  "BS KPIs - DEBTOR DAYS" = "custom.insight.table.headers.bs.kpis.debtor.days",
  "BS KPIs - INVENTORY DAYS" = "custom.insight.table.headers.bs.kpis.inventory.days",
  "BS KPIs - DEBT to EQUITY & DEPT to ASSETS" = "custom.insight.table.headers.bs.kpis.debt.to.equity.and.dept.to.assets",
  "BS KPIs - ROCE" = "custom.insight.table.headers.bs.kpis.roce",
  "NV F&I - SALES" = "custom.insight.table.headers.nv.fandi.sales",
  "NV Breakeven" = "custom.insight.table.headers.nv.breakeven",
  "NV F&I - MIX (GP)" = "custom.insight.table.headers.nv.fandi.mix.gp",
  "NV F&I - RETAINED (EXPENSES)" = "custom.insight.table.headers.nv.fandi.retained.expenses",
  "NV F&I - Variable Expenses" = "custom.insight.table.headers.nv.fandi.variable.expenses",
  "NV F&I - Personnel Expenses" = "custom.insight.table.headers.nv.fandi.personnel.expenses",
  "NV F&I - Other Semi Var. Expenses" = "custom.insight.table.headers.nv.fandi.other.semi.var.expenses",
  "NV F&I - Fixed Expenses" = "custom.insight.table.headers.nv.fandi.fixed.expenses",
  "NV F&I - Interest Expenses" = "custom.insight.table.headers.nv.fandi.interest.expenses",
  "NV F&I - ACTIVITY" = "custom.insight.table.headers.nv.fandi.activity",
  "NV F&I - ROOA" = "custom.insight.table.headers.nv.fandi.rooa",
  "NV F&I - Income Statement" = "custom.insight.table.headers.nv.fandi.income.statement",
  "NV F&I - PRODUCTIVITY" = "custom.insight.table.headers.nv.fandi.productivity",
  "NV F&I - RETAIL  & FLEET" = "custom.insight.table.headers.nv.fandi.retail.and.fleet",
  "UV F&I - SALES" = "custom.insight.table.headers.uv.fandi.sales",
  "UV Breakeven" = "custom.insight.table.headers.uv.breakeven",
  "UV F&I - MIX (GP)" = "custom.insight.table.headers.uv.fandi.mix.gp",
  "UV F&I - RETAINED (EXPENSES)" = "custom.insight.table.headers.uv.fandi.retained.expenses",
  "UV F&I - Variable Expenses" = "custom.insight.table.headers.uv.fandi.variable.expenses",
  "UV F&I - Personnel Expenses" = "custom.insight.table.headers.uv.fandi.personnel.expenses",
  "UV F&I - Other Semi Var. Expenses" = "custom.insight.table.headers.uv.fandi.other.semi.var.expenses",
  "UV F&I - Fixed Expenses" = "custom.insight.table.headers.uv.fandi.fixed.expenses",
  "UV F&I - Interest Expenses" = "custom.insight.table.headers.uv.fandi.interest.expenses",
  "UV F&I - ACTIVITY" = "custom.insight.table.headers.uv.fandi.activity",
  "UV F&I - ROOA" = "custom.insight.table.headers.uv.fandi.rooa",
  "UV F&I - Income Statement" = "custom.insight.table.headers.uv.fandi.income.statement",
  "UV F&I - PRODUCTIVITY" = "custom.insight.table.headers.uv.fandi.productivity",
  "UV F&I - RETAIL" = "custom.insight.table.headers.uv.fandi.retail",
  "F&I - SALES" = "custom.insight.table.headers.fandi.sales",
  "F&I - MIX (GP)" = "custom.insight.table.headers.fandi.mix.gp",
  "F&I - RETAINED (EXPENSES)" = "custom.insight.table.headers.fandi.retained.expenses",
  "F&I - Variable Expenses" = "custom.insight.table.headers.fandi.variable.expenses",
  "F&I - Personnel Expenses" = "custom.insight.table.headers.fandi.personnel.expenses",
  "F&I - Other Semi Var. Expenses" = "custom.insight.table.headers.fandi.other.semi.var.expenses",
  "F&I - Fixed Expenses" = "custom.insight.table.headers.fandi.fixed.expenses",
  "F&I - Interest Expenses" = "custom.insight.table.headers.fandi.interest.expenses",
  "F&I - ACTIVITY" = "custom.insight.table.headers.fandi.activity",
  "F&I - ROOA" = "custom.insight.table.headers.fandi.rooa",
  "F&I - Income Statement" = "custom.insight.table.headers.fandi.income.statement",
  "F&I - PRODUCTIVITY" = "custom.insight.table.headers.fandi.productivity",
  "After-Sales - SALES" = "custom.insight.table.headers.after.sales.sales",
  "After-Sales - MIX (GP)" = "custom.insight.table.headers.after.sales.mix.gp",
  "After-Sales - RETAINED (EXPENSES)" = "custom.insight.table.headers.after.sales.retained.expenses",
  "After-Sales - Variable Expenses" = "custom.insight.table.headers.after.sales.variable.expenses",
  "After-Sales - Personnel Expenses" = "custom.insight.table.headers.after.sales.personnel.expenses",
  "After-Sales - Other Semi Var. Expenses" = "custom.insight.table.headers.after.sales.other.semi.var.expenses",
  "After-Sales - Fixed Expenses" = "custom.insight.table.headers.after.sales.fixed.expenses",
  "After-Sales - Interest Expenses" = "custom.insight.table.headers.after.sales.interest.expenses",
  "After-Sales - ACTIVITY" = "custom.insight.table.headers.after.sales.activity",
  "After-Sales - ROOA" = "custom.insight.table.headers.after.sales.rooa",
  "After-Sales - Income Statement" = "custom.insight.table.headers.after.sales.income.statement",
  "After-Sales - PRODUCTIVITY" = "custom.insight.table.headers.after.sales.productivity",
  "After-Sales - FOA" = "custom.insight.table.headers.after.sales.foa",
  "Parts - SALES" = "custom.insight.table.headers.parts.sales",
  "Parts Body Shop" = "custom.insight.table.headers.parts.body.shop",
  "Parts Non-Workshop" = "custom.insight.table.headers.parts.non.workshop",
  "Parts Breakeven" = "custom.insight.table.headers.parts.breakeven",
  "Parts - MIX (GP)" = "custom.insight.table.headers.parts.mix.gp",
  "Parts - RETAINED (EXPENSES)" = "custom.insight.table.headers.parts.retained.expenses",
  "Parts - Variable Expenses" = "custom.insight.table.headers.parts.variable.expenses",
  "Parts - Personnel Expenses" = "custom.insight.table.headers.parts.personnel.expenses",
  "Parts - Other Semi Var. Expenses" = "custom.insight.table.headers.parts.other.semi.var.expenses",
  "Parts - Fixed Expenses" = "custom.insight.table.headers.parts.fixed.expenses",
  "Parts - Interest Expenses" = "custom.insight.table.headers.parts.interest.expenses",
  "Parts - ACTIVITY" = "custom.insight.table.headers.parts.activity",
  "Parts - ROOA" = "custom.insight.table.headers.parts.rooa",
  "Parts - Income Statement" = "custom.insight.table.headers.parts.income.statement",
  "Parts - PRODUCTIVITY" = "custom.insight.table.headers.parts.productivity",
  "Service - SALES" = "custom.insight.table.headers.service.sales",
  "Service Labour, RO and Hours Analysis" = "custom.insight.table.headers.service.labour.ro.and.hours.analysis",
  "Service Breakeven" = "custom.insight.table.headers.service.breakeven",
  "Service Non-Labour" = "custom.insight.table.headers.service.non.labour",
  "Service - MIX (GP)" = "custom.insight.table.headers.service.mix.gp",
  "Service - Labour GP" = "custom.insight.table.headers.service.labour.gp",
  "Service - RETAINED (EXPENSES)" = "custom.insight.table.headers.service.retained.expenses",
  "Service - Variable Expenses" = "custom.insight.table.headers.service.variable.expenses",
  "Service - Personnel Expenses" = "custom.insight.table.headers.service.personnel.expenses",
  "Service - Other Semi Var. Expenses" = "custom.insight.table.headers.service.other.semi.var.expenses",
  "Service - Fixed Expenses" = "custom.insight.table.headers.service.fixed.expenses",
  "Service - Interest Expenses" = "custom.insight.table.headers.service.interest.expenses",
  "Service - ACTIVITY" = "custom.insight.table.headers.service.activity",
  "Service - ROOA" = "custom.insight.table.headers.service.rooa",
  "Service - Income Statement" = "custom.insight.table.headers.service.income.statement",
  "Service - PRODUCTIVITY" = "custom.insight.table.headers.service.productivity",
  "Body Shop - SALES" = "custom.insight.table.headers.body.shop.sales",
  "Body Shop Labour, RO and Hours Analysis" = "custom.insight.table.headers.body.shop.labour.ro.and.hours.analysis",
  "Body Shop Breakeven" = "custom.insight.table.headers.body.shop.breakeven",
  "Body Shop Non-Labour" = "custom.insight.table.headers.body.shop.non.labour",
  "Body Shop - MIX (GP)" = "custom.insight.table.headers.body.shop.mix.gp",
  "Body Shop - Labour GP" = "custom.insight.table.headers.body.shop.labour.gp",
  "Body Shop - RETAINED (EXPENSES)" = "custom.insight.table.headers.body.shop.retained.expenses",
  "Body Shop - Variable Expenses" = "custom.insight.table.headers.body.shop.variable.expenses",
  "Body Shop - Personnel Expenses" = "custom.insight.table.headers.body.shop.personnel.expenses",
  "Body Shop - Other Semi Var. Expenses" = "custom.insight.table.headers.body.shop.other.semi.var.expenses",
  "Body Shop - Fixed Expenses" = "custom.insight.table.headers.body.shop.fixed.expenses",
  "Body Shop - Interest Expenses" = "custom.insight.table.headers.body.shop.interest.expenses",
  "Body Shop - ACTIVITY" = "custom.insight.table.headers.body.shop.activity",
  "Body Shop - ROOA" = "custom.insight.table.headers.body.shop.rooa",
  "Body Shop - Income Statement" = "custom.insight.table.headers.body.shop.income.statement",
  "Body Shop - PRODUCTIVITY" = "custom.insight.table.headers.body.shop.productivity",
  "Driveway - SALES" = "custom.insight.table.headers.driveway.sales",
  "Driveway Breakeven" = "custom.insight.table.headers.driveway.breakeven",
  "Driveway - MIX (GP)" = "custom.insight.table.headers.driveway.mix.gp",
  "Driveway - RETAINED (EXPENSES)" = "custom.insight.table.headers.driveway.retained.expenses",
  "Driveway - Variable Expenses" = "custom.insight.table.headers.driveway.variable.expenses",
  "Driveway - Personnel Expenses" = "custom.insight.table.headers.driveway.personnel.expenses",
  "Driveway - Other Semi Var. Expenses" = "custom.insight.table.headers.driveway.other.semi.var.expenses",
  "Driveway - Fixed Expenses" = "custom.insight.table.headers.driveway.fixed.expenses",
  "Driveway - Interest Expenses" = "custom.insight.table.headers.driveway.interest.expenses",
  "Driveway - ACTIVITY" = "custom.insight.table.headers.driveway.activity",
  "Driveway - ROOA" = "custom.insight.table.headers.driveway.rooa",
  "Driveway - Income Statement" = "custom.insight.table.headers.driveway.income.statement",
  "Driveway - PRODUCTIVITY" = "custom.insight.table.headers.driveway.productivity",
  "Aftercare - SALES" = "custom.insight.table.headers.aftercare.sales",
  "Aftercare - MIX (GP)" = "custom.insight.table.headers.aftercare.mix.gp",
  "Aftercare - RETAINED (EXPENSES)" = "custom.insight.table.headers.aftercare.retained.expenses",
  "Aftercare - Variable Expenses" = "custom.insight.table.headers.aftercare.variable.expenses",
  "Aftercare - Personnel Expenses" = "custom.insight.table.headers.aftercare.personnel.expenses",
  "Aftercare - Other Semi Var. Expenses" = "custom.insight.table.headers.aftercare.other.semi.var.expenses",
  "Aftercare - Fixed Expenses" = "custom.insight.table.headers.aftercare.fixed.expenses",
  "Aftercare - Interest Expenses" = "custom.insight.table.headers.aftercare.interest.expenses",
  "Aftercare - ACTIVITY" = "custom.insight.table.headers.aftercare.activity",
  "Aftercare - ROOA" = "custom.insight.table.headers.aftercare.rooa",
  "Aftercare - Income Statement" = "custom.insight.table.headers.aftercare.income.statement",
  "Aftercare - PRODUCTIVITY" = "custom.insight.table.headers.aftercare.productivity",
  "Other Dept. SALES" = "custom.insight.table.headers.other.dept.sales",
  "Other Dept. MIX" = "custom.insight.table.headers.other.dept.mix",
  "Other Dept. RETAINED (EXPENSES)" = "custom.insight.table.headers.other.dept.retained.expenses",
  "Other Dept. - Variable Expenses" = "custom.insight.table.headers.other.dept.variable.expenses",
  "Other Dept. - Personnel Expenses" = "custom.insight.table.headers.other.dept.personnel.expenses",
  "Other Dept. - Other Semi Var. Expenses" = "custom.insight.table.headers.other.dept.other.semi.var.expenses",
  "Other Dept. - Fixed Expenses" = "custom.insight.table.headers.other.dept.fixed.expenses",
  "Other Dept.- Interest Expenses" = "custom.insight.table.headers.other.dept.interest.expenses",
  "Other Dept. ACTIVITY" = "custom.insight.table.headers.other.dept.activity",
  "Other Dept. - ROOA" = "custom.insight.table.headers.other.dept.rooa",
  "Other Dept. - Income Statement" = "custom.insight.table.headers.other.dept.income.statement",
  "Other Dept.- PRODUCTIVITY" = "custom.insight.table.headers.other.dept.productivity",
  "NV Retail" = "custom.insight.table.headers.nv.retail",
  "Parts - Workshop Sales" = "custom.insight.table.headers.parts.workshop.sales",
  "Service - SALES / RO" = "custom.insight.table.headers.service.sales.ro",
  "BODY SHOP EXPENSES mix" = "custom.insight.table.headers.body.shop.expenses.mix",
  "BODY SHOP Variable Expenses % GP" = "custom.insight.table.headers.body.shop.variable.expenses.percent.gp",
  "BODY SHOP GP" = "custom.insight.table.headers.body.shop.gp",
  "BODY SHOP Variable Expenses% Total Expenses" = "custom.insight.table.headers.body.shop.variable.expenses.percent.total.expenses",
  "BODY SHOP Variable Selling Gross" = "custom.insight.table.headers.body.shop.variable.selling.gross",
  "BODY SHOP Variable Selling Gross % GP" = "custom.insight.table.headers.body.shop.variable.selling.gross.percent.gp",
  "BODY SHOP Personnel Expenses % GP" = "custom.insight.table.headers.body.shop.personnel.expenses.percent.gp",
  "BODY SHOP Personnel Expenses% Total Expenses" = "custom.insight.table.headers.body.shop.personnel.expenses.percent.total.expenses",
  "BODY SHOP Variable Selling Gross net of Personnel Expenses" = "custom.insight.table.headers.body.shop.variable.selling.gross.net.of.personnel.expenses",
  "BODY SHOP Variable Selling Gross net of Personnel Expenses % GP" = "custom.insight.table.headers.body.shop.variable.selling.gross.net.of.personnel.expenses.percent.gp",
  "BODY SHOP Other Semi Var. Expenses % GP" = "custom.insight.table.headers.body.shop.other.semi.var.expenses.percent.gp",
  "BODY SHOP Other Semi Variable Expenses" = "custom.insight.table.headers.body.shop.other.semi.variable.expenses",
  "BODY SHOP Other Semi Var.Exp.% Total Expenses" = "custom.insight.table.headers.body.shop.other.semi.var.exp.percent.total.expenses",
  "BODY SHOP Fixed Expenses % GP" = "custom.insight.table.headers.body.shop.fixed.expenses.percent.gp",
  "BODY SHOP Fixed Expenses% Total Expenses" = "custom.insight.table.headers.body.shop.fixed.expenses.percent.total.expenses",
  "BODY SHOP Interest Expenses % GP" = "custom.insight.table.headers.body.shop.interest.expenses.percent.gp",
  "BODY SHOP Interest Expenses% Total Expenses" = "custom.insight.table.headers.body.shop.interest.expenses.percent.total.expenses",
  "Labour GP / Productive / Month" = "custom.insight.table.headers.labour.gp.productive.month",
  "Total Labour GP" = "custom.insight.table.headers.total.labour.gp",
  "Technician Cost / Productive / Month" = "custom.insight.table.headers.technician.cost.productive.month",
  "Sales mix" = "custom.insight.table.headers.sales.mix",
  "New Vehicle Sales mix" = "custom.insight.table.headers.new.vehicle.sales.mix",
  "Used Vehicle Sales mix" = "custom.insight.table.headers.used.vehicle.sales.mix",
  "F&I Sales mix" = "custom.insight.table.headers.fandi.sales.mix",
  "Aftercare Sales mix" = "custom.insight.table.headers.aftercare.sales.mix",
  "Body Shop Sales mix" = "custom.insight.table.headers.body.shop.sales.mix",
  "Other Sales mix" = "custom.insight.table.headers.other.sales.mix",
  "Operating Expenses" = "custom.insight.table.headers.operating.expenses",
  "Admin Fee" = "custom.insight.table.headers.admin.fee",
  "Variable Selling Gross / Day" = "custom.insight.table.headers.variable.selling.gross.day",
  "GP" = "custom.insight.table.headers.gp",
  "Non-Vehicle Variable Expenses" = "custom.insight.table.headers.non.vehicle.variable.expenses",
  "Non-Vehicle GP" = "custom.insight.table.headers.non.vehicle.gp",
  "Other Det. GP" = "custom.insight.table.headers.other.det.gp",
  "Variable Selling Gross NV" = "custom.insight.table.headers.variable.selling.gross.nv",
  "Variable Selling Gross UV" = "custom.insight.table.headers.variable.selling.gross.uv",
  "Variable Selling Gross F&I" = "custom.insight.table.headers.variable.selling.gross.fandi",
  "Variable Selling Gross Aftercare" = "custom.insight.table.headers.variable.selling.gross.aftercare",
  "Vehicle Variable Selling Gross / NV Unit" = "custom.insight.table.headers.vehicle.variable.selling.gross.nv.unit",
  "GP mix" = "custom.insight.table.headers.gp.mix",
  "Admin Fees Payable" = "custom.insight.table.headers.admin.fees.payable",
  "Deduction / (Addition)" = "custom.insight.table.headers.deduction.addition",
  "EXPENSES mix" = "custom.insight.table.headers.expenses.mix",
  "Demo Expenses" = "custom.insight.table.headers.demo.expenses",
  "Total Variable Selling Gross" = "custom.insight.table.headers.total.variable.selling.gross",
  "Total Variable Selling Gross % TGP" = "custom.insight.table.headers.total.variable.selling.gross.percent.tgp",
  "Total Variable Selling Gross % Net Sales" = "custom.insight.table.headers.total.variable.selling.gross.percent.net.sales",
  "Total Variable Selling Gross net of Personnel Expenses" = "custom.insight.table.headers.total.variable.selling.gross.net.of.personnel.expenses",
  "Total Variable Selling Gross net of Personnel Expenses % TGP" = "custom.insight.table.headers.total.variable.selling.gross.net.of.personnel.expenses.percent.tgp",
  "Lost and Found" = "custom.insight.table.headers.lost.and.found",
  "Amortised Leasehold Improvements" = "custom.insight.table.headers.amortised.leasehold.improvements",
  "Rent & Depreciation Analysis" = "custom.insight.table.headers.rent.and.depreciation.analysis",
  "Rent % Sales" = "custom.insight.table.headers.rent.percent.sales",
  "Rent & Depreciation" = "custom.insight.table.headers.rent.and.depreciation",
  "Rent & Depreciation % Sales" = "custom.insight.table.headers.rent.and.depreciation.percent.sales",
  "Floor Plan Interest" = "custom.insight.table.headers.floor.plan.interest",
  "Admin Interest" = "custom.insight.table.headers.admin.interest",
  "Deduction / (Addition) % Total Expenses" = "custom.insight.table.headers.deduction.addition.percent.total.expenses",
  "Deductions from Income Analysis" = "custom.insight.table.headers.deductions.from.income.analysis",
  "Deductions from Income % Total Expenses" = "custom.insight.table.headers.deductions.from.income.percent.total.expenses",
  "Deductions from Income" = "custom.insight.table.headers.deductions.from.income",
  "Additions to Income Analysis" = "custom.insight.table.headers.additions.to.income.analysis",
  "Additions to Income % Total Expenses" = "custom.insight.table.headers.additions.to.income.percent.total.expenses",
  "Additions to Income" = "custom.insight.table.headers.additions.to.income",
  "Total Staff for Personel Expenses (excl. Technician & PDI)" = "custom.insight.table.headers.total.staff.for.personel.expenses.excl.technician.and.pdi",
  "New Vehicle (Total)" = "custom.insight.table.headers.new.vehicle.total",
  "Productive (SC)" = "custom.insight.table.headers.productive.sc",
  "Non Productive" = "custom.insight.table.headers.non.productive",
  "PDI Staff" = "custom.insight.table.headers.pdi.staff",
  "Used Vehicle (Total)" = "custom.insight.table.headers.used.vehicle.total",
  "F&I (Total)" = "custom.insight.table.headers.fandi.total",
  "Productive" = "custom.insight.table.headers.productive",
  "Aftercare (Total)" = "custom.insight.table.headers.aftercare.total",
  "Parts (Total)" = "custom.insight.table.headers.parts.total",
  "Service (Total)" = "custom.insight.table.headers.service.total",
  "Technician" = "custom.insight.table.headers.technician",
  "Support Staff (incl. SA)" = "custom.insight.table.headers.support.staff.incl.sa",
  "Service Advisor" = "custom.insight.table.headers.service.advisor",
  "Body Shop (Total)" = "custom.insight.table.headers.body.shop.total",
  "Other Department (Total)" = "custom.insight.table.headers.other.department.total",
  "Admin (Total)" = "custom.insight.table.headers.admin.total",
  "CCC Drivers (Average)" = "custom.insight.table.headers.ccc.drivers.average",
  "Inventory (Average)" = "custom.insight.table.headers.inventory.average",
  "NV Operating Expenses" = "custom.insight.table.headers.nv.operating.expenses",
  "NV Admin Expenses Allocation" = "custom.insight.table.headers.nv.admin.expenses.allocation",
  "NV Variable Selling Gross / Day" = "custom.insight.table.headers.nv.variable.selling.gross.day",
  "NV Variable Selling Gross" = "custom.insight.table.headers.nv.variable.selling.gross",
  "NV Variable Selling Gross / NV Unit" = "custom.insight.table.headers.nv.variable.selling.gross.nv.unit",
  "NV Gross Sales / Unit" = "custom.insight.table.headers.nv.gross.sales.unit",
  "Incl. NV Accessory Sales / Unit" = "custom.insight.table.headers.incl.nv.accessory.sales.unit",
  "NV Discount / Unit" = "custom.insight.table.headers.nv.discount.unit",
  "NV Net sales / Unit" = "custom.insight.table.headers.nv.net.sales.unit",
  "NV Give Away / Unit" = "custom.insight.table.headers.nv.give.away.unit",
  "NV License & Registration GP / Unit" = "custom.insight.table.headers.nv.license.and.registration.gp.unit",
  "NV Logistic & Handling GP / Unit" = "custom.insight.table.headers.nv.logistic.and.handling.gp.unit",
  "NV Extended Warranty GP / Unit" = "custom.insight.table.headers.nv.extended.warranty.gp.unit",
  "NV Other GP / Unit" = "custom.insight.table.headers.nv.other.gp.unit",
  "OEM Incentive Analysis (Direct & Indirect)" = "custom.insight.table.headers.oem.incentive.analysis.direct.and.indirect",
  "NV OEM Incentives % Net Sales" = "custom.insight.table.headers.nv.oem.incentives.percent.net.sales",
  "NV incl. F& I & Aftercare" = "custom.insight.table.headers.nv.incl.fand.i.and.aftercare",
  "NV EXPENSES mix" = "custom.insight.table.headers.nv.expenses.mix",
  "NV Variable Expenses % GP" = "custom.insight.table.headers.nv.variable.expenses.percent.gp",
  "NV Variable Selling Gross / Unit" = "custom.insight.table.headers.nv.variable.selling.gross.unit",
  "NV Variable Selling Gross % GP" = "custom.insight.table.headers.nv.variable.selling.gross.percent.gp",
  "NV Variable Selling Gross % Net Sales" = "custom.insight.table.headers.nv.variable.selling.gross.percent.net.sales",
  "NV Other Semi Var. Expenses % GP" = "custom.insight.table.headers.nv.other.semi.var.expenses.percent.gp",
  "Other Interest" = "custom.insight.table.headers.other.interest",
  "RULES NV incl. F&I & Aftercare" = "custom.insight.table.headers.rules.nv.incl.fandi.and.aftercare",
  "UV Operating Expenses" = "custom.insight.table.headers.uv.operating.expenses",
  "UV Admin Expenses Allocation" = "custom.insight.table.headers.uv.admin.expenses.allocation",
  "UV Variable Selling Gross / Day" = "custom.insight.table.headers.uv.variable.selling.gross.day",
  "UV Variable Selling Gross" = "custom.insight.table.headers.uv.variable.selling.gross",
  "UV Variable Selling Gross / UV Unit" = "custom.insight.table.headers.uv.variable.selling.gross.uv.unit",
  "UV OEM Incentives % Net Sales" = "custom.insight.table.headers.uv.oem.incentives.percent.net.sales",
  "UV incl. F& I & Aftercare" = "custom.insight.table.headers.uv.incl.fand.i.and.aftercare",
  "UV EXPENSES mix" = "custom.insight.table.headers.uv.expenses.mix",
  "UV Variable Expenses % GP" = "custom.insight.table.headers.uv.variable.expenses.percent.gp",
  "UV Variable Selling Gross / Retail & Demo Unit" = "custom.insight.table.headers.uv.variable.selling.gross.retail.and.demo.unit",
  "UV Variable Selling Gross / Unit" = "custom.insight.table.headers.uv.variable.selling.gross.unit",
  "UV Variable Selling Gross % GP" = "custom.insight.table.headers.uv.variable.selling.gross.percent.gp",
  "UV Variable Selling Gross % Net Sales" = "custom.insight.table.headers.uv.variable.selling.gross.percent.net.sales",
  "UV Personnel Expenses % GP" = "custom.insight.table.headers.uv.personnel.expenses.percent.gp",
  "UV Other Semi Var. Expenses % GP" = "custom.insight.table.headers.uv.other.semi.var.expenses.percent.gp",
  "UV Interest Expenses % GP" = "custom.insight.table.headers.uv.interest.expenses.percent.gp",
  "RULES UV incl. F&I & Aftercare" = "custom.insight.table.headers.rules.uv.incl.fandi.and.aftercare",
  "F&I New Vehicle Retail" = "custom.insight.table.headers.fandi.new.vehicle.retail",
  "F&I Used Vehicle" = "custom.insight.table.headers.fandi.used.vehicle",
  "F&I RETAINED Drivers (SPLIT Analysis)" = "custom.insight.table.headers.fandi.retained.drivers.split.analysis",
  "F&I EXPENSES mix" = "custom.insight.table.headers.fandi.expenses.mix",
  "F&I Variable Expenses % GP" = "custom.insight.table.headers.fandi.variable.expenses.percent.gp",
  "F&I Variable Selling Gross" = "custom.insight.table.headers.fandi.variable.selling.gross",
  "F&I Variable Selling Gross % GP" = "custom.insight.table.headers.fandi.variable.selling.gross.percent.gp",
  "F&I Variable Selling Gross net of Personnel Expenses" = "custom.insight.table.headers.fandi.variable.selling.gross.net.of.personnel.expenses",
  "F&I Variable Selling Gross net of Personnel Expenses % GP" = "custom.insight.table.headers.fandi.variable.selling.gross.net.of.personnel.expenses.percent.gp",
  "F&I Other Semi Var. Expenses % GP" = "custom.insight.table.headers.fandi.other.semi.var.expenses.percent.gp",
  "F&I Interest Expenses % GP" = "custom.insight.table.headers.fandi.interest.expenses.percent.gp",
  "RULES" = "custom.insight.table.headers.rules",
  "STAFF Allocation (Average)" = "custom.insight.table.headers.staff.allocation.average",
  "Parts Total Sales mix" = "custom.insight.table.headers.parts.total.sales.mix",
  "Parts Quick Service Workshop Sales mix" = "custom.insight.table.headers.parts.quick.service.workshop.sales.mix",
  "Parts Other Sales mix" = "custom.insight.table.headers.parts.other.sales.mix",
  "Parts Workshop Sales / Service RO Retail" = "custom.insight.table.headers.parts.workshop.sales.service.ro.retail",
  "Parts Workshop Sales / Service RO Warranty" = "custom.insight.table.headers.parts.workshop.sales.service.ro.warranty",
  "Parts Workshop Sales / Service RO Internal" = "custom.insight.table.headers.parts.workshop.sales.service.ro.internal",
  "Parts Quick Service Sales / Quick Service RO" = "custom.insight.table.headers.parts.quick.service.sales.quick.service.ro",
  "Parts Service Workshop GP" = "custom.insight.table.headers.parts.service.workshop.gp",
  "Parts Service Workshop Retail GP" = "custom.insight.table.headers.parts.service.workshop.retail.gp",
  "Parts Service Workshop Warranty GP" = "custom.insight.table.headers.parts.service.workshop.warranty.gp",
  "Parts Service Workshop Internal GP" = "custom.insight.table.headers.parts.service.workshop.internal.gp",
  "Parts Quick Service Workshop GP" = "custom.insight.table.headers.parts.quick.service.workshop.gp",
  "Parts Quick Service Workshop GP %" = "custom.insight.table.headers.parts.quick.service.workshop.gp.percent",
  "Parts Body Shop Sales / Body Shop RO Retail" = "custom.insight.table.headers.parts.body.shop.sales.body.shop.ro.retail",
  "Parts Body Shop Sales / Body Shop RO Warranry" = "custom.insight.table.headers.parts.body.shop.sales.body.shop.ro.warranry",
  "Parts Body Shop Sales / Body Shop RO Internal" = "custom.insight.table.headers.parts.body.shop.sales.body.shop.ro.internal",
  "Parts Body Shop GP" = "custom.insight.table.headers.parts.body.shop.gp",
  "Parts Body Shop Retail GP" = "custom.insight.table.headers.parts.body.shop.retail.gp",
  "Parts Body Shop Warranty GP" = "custom.insight.table.headers.parts.body.shop.warranty.gp",
  "Parts Body Shop Internal GP" = "custom.insight.table.headers.parts.body.shop.internal.gp",
  "Parts Accessories GP" = "custom.insight.table.headers.parts.accessories.gp",
  "Parts Intercompany GP" = "custom.insight.table.headers.parts.intercompany.gp",
  "Parts Oil & Lubricant GP" = "custom.insight.table.headers.parts.oil.and.lubricant.gp",
  "Parts Counter Retail GP" = "custom.insight.table.headers.parts.counter.retail.gp",
  "Parts Wholesale GP" = "custom.insight.table.headers.parts.wholesale.gp",
  "Parts Other GP" = "custom.insight.table.headers.parts.other.gp",
  "Total Labour Sales (Service & Body Shop)" = "custom.insight.table.headers.total.labour.sales.service.and.body.shop",
  "Parts Operating Expenses" = "custom.insight.table.headers.parts.operating.expenses",
  "Parts Admin Expenses Allocation" = "custom.insight.table.headers.parts.admin.expenses.allocation",
  "Parts Variable Selling Gross / Day" = "custom.insight.table.headers.parts.variable.selling.gross.day",
  "Parts Variable Selling Gross" = "custom.insight.table.headers.parts.variable.selling.gross",
  "Parts Variable Selling Gross % Sales" = "custom.insight.table.headers.parts.variable.selling.gross.percent.sales",
  "Parts Expenses Analysis" = "custom.insight.table.headers.parts.expenses.analysis",
  "Parts Variable Expenses % GP" = "custom.insight.table.headers.parts.variable.expenses.percent.gp",
  "PARTS EXPENSES mix" = "custom.insight.table.headers.parts.expenses.mix",
  "PARTS GP" = "custom.insight.table.headers.parts.gp",
  "PARTS Variable Expenses% Total Expenses" = "custom.insight.table.headers.parts.variable.expenses.percent.total.expenses",
  "PARTS Variable Selling Gross % GP" = "custom.insight.table.headers.parts.variable.selling.gross.percent.gp",
  "PARTS Variable Selling Gross % Net Sales" = "custom.insight.table.headers.parts.variable.selling.gross.percent.net.sales",
  "PARTS Personnel Expenses % GP" = "custom.insight.table.headers.parts.personnel.expenses.percent.gp",
  "PARTS Personnel Expenses% Total Expenses" = "custom.insight.table.headers.parts.personnel.expenses.percent.total.expenses",
  "PARTS Variable Selling Gross net of Personnel Expenses" = "custom.insight.table.headers.parts.variable.selling.gross.net.of.personnel.expenses",
  "PARTS Variable Selling Gross net of Personnel Expenses % GP" = "custom.insight.table.headers.parts.variable.selling.gross.net.of.personnel.expenses.percent.gp",
  "PARTS Other Semi Var. Expenses % GP" = "custom.insight.table.headers.parts.other.semi.var.expenses.percent.gp",
  "PARTS Other Semi Variable Expenses" = "custom.insight.table.headers.parts.other.semi.variable.expenses",
  "PARTS Other Semi Var.Exp.% Total Expenses" = "custom.insight.table.headers.parts.other.semi.var.exp.percent.total.expenses",
  "PARTS Fixed Expenses % GP" = "custom.insight.table.headers.parts.fixed.expenses.percent.gp",
  "PARTS Fixed Expenses% Total Expenses" = "custom.insight.table.headers.parts.fixed.expenses.percent.total.expenses",
  "PARTS Interest Expenses % GP" = "custom.insight.table.headers.parts.interest.expenses.percent.gp",
  "PARTS Interest Expenses% Total Expenses" = "custom.insight.table.headers.parts.interest.expenses.percent.total.expenses",
  "Parts AVG Operational Analysis" = "custom.insight.table.headers.parts.avg.operational.analysis",
  "Parts AVG Inventories" = "custom.insight.table.headers.parts.avg.inventories",
  "Parts AVG Receivables" = "custom.insight.table.headers.parts.avg.receivables",
  "Parts AVG Cash on Hand" = "custom.insight.table.headers.parts.avg.cash.on.hand",
  "Parts AVG Other Current Assets" = "custom.insight.table.headers.parts.avg.other.current.assets",
  "Parts AVG Equipment and Other Fixed Assets" = "custom.insight.table.headers.parts.avg.equipment.and.other.fixed.assets",
  "Parts Selling Gross Analysis" = "custom.insight.table.headers.parts.selling.gross.analysis",
  "Parts Workshop & Body Shop Sales / Productive / Month" = "custom.insight.table.headers.parts.workshop.and.body.shop.sales.productive.month",
  "Parts Workshop & Body Shop Sales" = "custom.insight.table.headers.parts.workshop.and.body.shop.sales",
  "Parts STAFF (Average)" = "custom.insight.table.headers.parts.staff.average",
  "Service RO and Hours Analysis" = "custom.insight.table.headers.service.ro.and.hours.analysis",
  "Service Breakeven Analysis" = "custom.insight.table.headers.service.breakeven.analysis",
  "RO / Day" = "custom.insight.table.headers.ro.day",
  "Service RO" = "custom.insight.table.headers.service.ro",
  "Service Hours Sold / RO Retail" = "custom.insight.table.headers.service.hours.sold.ro.retail",
  "Service Hours Sold / RO Warranty" = "custom.insight.table.headers.service.hours.sold.ro.warranty",
  "Service Hours Sold / RO Internal" = "custom.insight.table.headers.service.hours.sold.ro.internal",
  "Service Hours Sold / RO Maintenance Plan" = "custom.insight.table.headers.service.hours.sold.ro.maintenance.plan",
  "Service Hours Sold / RO Quick Service" = "custom.insight.table.headers.service.hours.sold.ro.quick.service",
  "Service Total Hours Sold" = "custom.insight.table.headers.service.total.hours.sold",
  "Service Hours Sold Retail" = "custom.insight.table.headers.service.hours.sold.retail",
  "Service Hours Sold Warranty" = "custom.insight.table.headers.service.hours.sold.warranty",
  "Service Hours Sold Internal" = "custom.insight.table.headers.service.hours.sold.internal",
  "Service Hours Sold Maintenance Plan" = "custom.insight.table.headers.service.hours.sold.maintenance.plan",
  "Service Hours Sold Quick Service" = "custom.insight.table.headers.service.hours.sold.quick.service",
  "Service Effective Labour Rate / Hour Retail" = "custom.insight.table.headers.service.effective.labour.rate.hour.retail",
  "Service Effective Labour Rate / Hour Warranty" = "custom.insight.table.headers.service.effective.labour.rate.hour.warranty",
  "Service Effective Labour Rate / Hour Internal" = "custom.insight.table.headers.service.effective.labour.rate.hour.internal",
  "Service Effective Labour Rate / Hour Maintenance Plan" = "custom.insight.table.headers.service.effective.labour.rate.hour.maintenance.plan",
  "Service Effective Labour Rate / Hour Quick Service" = "custom.insight.table.headers.service.effective.labour.rate.hour.quick.service",
  "Service Operating Expenses" = "custom.insight.table.headers.service.operating.expenses",
  "Service Admin Expenses Allocation" = "custom.insight.table.headers.service.admin.expenses.allocation",
  "Service Variable Selling Gross / Day" = "custom.insight.table.headers.service.variable.selling.gross.day",
  "Service Variable Selling Gross" = "custom.insight.table.headers.service.variable.selling.gross",
  "Service Variable Selling Gross / RO" = "custom.insight.table.headers.service.variable.selling.gross.ro",
  "Service Sublet GP" = "custom.insight.table.headers.service.sublet.gp",
  "Service Oil & Lubricant GP" = "custom.insight.table.headers.service.oil.and.lubricant.gp",
  "Service Extended Warranty GP" = "custom.insight.table.headers.service.extended.warranty.gp",
  "Service Other Sales mix (% TSS)" = "custom.insight.table.headers.service.other.sales.mix.percent.tss",
  "Service Other GP" = "custom.insight.table.headers.service.other.gp",
  "Service Other GP %" = "custom.insight.table.headers.service.other.gp.percent",
  "Service Labour Cost Analysis" = "custom.insight.table.headers.service.labour.cost.analysis",
  "Service Labour Cost" = "custom.insight.table.headers.service.labour.cost",
  "Service Labour Cost / Tech. / Month" = "custom.insight.table.headers.service.labour.cost.tech.month",
  "Service Labour FEG" = "custom.insight.table.headers.service.labour.feg",
  "Service Idle Time" = "custom.insight.table.headers.service.idle.time",
  "Service Expenses Analysis" = "custom.insight.table.headers.service.expenses.analysis",
  "Service Variable Expenses % GP" = "custom.insight.table.headers.service.variable.expenses.percent.gp",
  "SERVICE EXPENSES mix" = "custom.insight.table.headers.service.expenses.mix",
  "SERVICE GP" = "custom.insight.table.headers.service.gp",
  "SERVICE Variable Expenses% Total Expenses" = "custom.insight.table.headers.service.variable.expenses.percent.total.expenses",
  "SERVICE Variable Selling Gross % GP" = "custom.insight.table.headers.service.variable.selling.gross.percent.gp",
  "SERVICE Variable Selling Gross % Net Sales" = "custom.insight.table.headers.service.variable.selling.gross.percent.net.sales",
  "SERVICE Personnel Expenses % GP" = "custom.insight.table.headers.service.personnel.expenses.percent.gp",
  "SERVICE Personnel Expenses% Total Expenses" = "custom.insight.table.headers.service.personnel.expenses.percent.total.expenses",
  "SERVICE Variable Selling Gross net of Personnel Expenses" = "custom.insight.table.headers.service.variable.selling.gross.net.of.personnel.expenses",
  "SERVICE Variable Selling Gross net of Personnel Expenses % GP" = "custom.insight.table.headers.service.variable.selling.gross.net.of.personnel.expenses.percent.gp",
  "SERVICE Other Semi Var. Expenses % GP" = "custom.insight.table.headers.service.other.semi.var.expenses.percent.gp",
  "SERVICE Other Semi Variable Expenses" = "custom.insight.table.headers.service.other.semi.variable.expenses",
  "SERVICE Other Semi Var.Exp.% Total Expenses" = "custom.insight.table.headers.service.other.semi.var.exp.percent.total.expenses",
  "SERVICE Fixed Expenses % GP" = "custom.insight.table.headers.service.fixed.expenses.percent.gp",
  "SERVICE Fixed Expenses% Total Expenses" = "custom.insight.table.headers.service.fixed.expenses.percent.total.expenses",
  "SERVICE Interest Expenses % GP" = "custom.insight.table.headers.service.interest.expenses.percent.gp",
  "SERVICE Interest Expenses% Total Expenses" = "custom.insight.table.headers.service.interest.expenses.percent.total.expenses",
  "Service AVG Operational Analysis" = "custom.insight.table.headers.service.avg.operational.analysis",
  "Service AVG Inventories" = "custom.insight.table.headers.service.avg.inventories",
  "Service AVG Receivables" = "custom.insight.table.headers.service.avg.receivables",
  "Service AVG Cash on Hand" = "custom.insight.table.headers.service.avg.cash.on.hand",
  "Service AVG Other Current Assets" = "custom.insight.table.headers.service.avg.other.current.assets",
  "Service AVG Equipment and Other Fixed Assets" = "custom.insight.table.headers.service.avg.equipment.and.other.fixed.assets",
  "Service Selling Gross Analysis" = "custom.insight.table.headers.service.selling.gross.analysis",
  "Service Hours Available" = "custom.insight.table.headers.service.hours.available",
  "Service Hours Clocked (Worked)" = "custom.insight.table.headers.service.hours.clocked.worked",
  "Service Hours Sold" = "custom.insight.table.headers.service.hours.sold",
  "Service Number of Hoists" = "custom.insight.table.headers.service.number.of.hoists",
  "Service STAFF (Average)" = "custom.insight.table.headers.service.staff.average",
  "Body Shop RO and Hours Analysis" = "custom.insight.table.headers.body.shop.ro.and.hours.analysis",
  "Body Shop Breakeven Analysis" = "custom.insight.table.headers.body.shop.breakeven.analysis",
  "Body Shop Labour Sales mix (% TBS)" = "custom.insight.table.headers.body.shop.labour.sales.mix.percent.tbs",
  "Body Shop Labour Retail Sales mix (%TBLS)" = "custom.insight.table.headers.body.shop.labour.retail.sales.mix.percenttbls",
  "Body Shop Labour Warranty Sales mix (%TBLS)" = "custom.insight.table.headers.body.shop.labour.warranty.sales.mix.percenttbls",
  "Body Shop Labour Internal Sales mix (%TBLS)" = "custom.insight.table.headers.body.shop.labour.internal.sales.mix.percenttbls",
  "Body Shop RO" = "custom.insight.table.headers.body.shop.ro",
  "Body Shop Hours Sold / RO Retail" = "custom.insight.table.headers.body.shop.hours.sold.ro.retail",
  "Body Shop Hours Sold / RO Warranty" = "custom.insight.table.headers.body.shop.hours.sold.ro.warranty",
  "Body Shop Hours Sold / RO Internal" = "custom.insight.table.headers.body.shop.hours.sold.ro.internal",
  "Body Shop Total Hours Sold" = "custom.insight.table.headers.body.shop.total.hours.sold",
  "Body Shop Hours Sold Retail" = "custom.insight.table.headers.body.shop.hours.sold.retail",
  "Body Shop Hours Sold Warranty" = "custom.insight.table.headers.body.shop.hours.sold.warranty",
  "Body Shop Hours Sold Internal" = "custom.insight.table.headers.body.shop.hours.sold.internal",
  "Body Shop Effective Labour Rate / Hour Retail" = "custom.insight.table.headers.body.shop.effective.labour.rate.hour.retail",
  "Body Shop Effective Labour Rate / Hour Warranty" = "custom.insight.table.headers.body.shop.effective.labour.rate.hour.warranty",
  "Body Shop Effective Labour Rate / Hour Internal" = "custom.insight.table.headers.body.shop.effective.labour.rate.hour.internal",
  "Body Shop Operating Expenses" = "custom.insight.table.headers.body.shop.operating.expenses",
  "Body Shop Admin Expenses Allocation" = "custom.insight.table.headers.body.shop.admin.expenses.allocation",
  "Body Shop Variable Selling Gross / Day" = "custom.insight.table.headers.body.shop.variable.selling.gross.day",
  "Body Shop Variable Selling Gross / RO" = "custom.insight.table.headers.body.shop.variable.selling.gross.ro",
  "Body Shop Paint & Material Sales mix (% TBS)" = "custom.insight.table.headers.body.shop.paint.and.material.sales.mix.percent.tbs",
  "Body Shop Paint & Material GP" = "custom.insight.table.headers.body.shop.paint.and.material.gp",
  "Body Shop Sublet Sales mix (% TBS)" = "custom.insight.table.headers.body.shop.sublet.sales.mix.percent.tbs",
  "Body Shop Sublet GP" = "custom.insight.table.headers.body.shop.sublet.gp",
  "Body Shop Oil & Lubricant Sales mix (% TBS)" = "custom.insight.table.headers.body.shop.oil.and.lubricant.sales.mix.percent.tbs",
  "Body Shop Oil & Lubricant GP" = "custom.insight.table.headers.body.shop.oil.and.lubricant.gp",
  "Body Shop Other Sales mix (% TBS)" = "custom.insight.table.headers.body.shop.other.sales.mix.percent.tbs",
  "Body Shop Other GP" = "custom.insight.table.headers.body.shop.other.gp",
  "Body Shop Labour FEG%" = "custom.insight.table.headers.body.shop.labour.feg.percent",
  "Body Shop Labour Cost Analysis" = "custom.insight.table.headers.body.shop.labour.cost.analysis",
  "Body Shop Labour Cost" = "custom.insight.table.headers.body.shop.labour.cost",
  "Body Shop Labour Cost / Tech. / Month" = "custom.insight.table.headers.body.shop.labour.cost.tech.month",
  "Body Shop Labour GP Analysis" = "custom.insight.table.headers.body.shop.labour.gp.analysis",
  "Body Shop Labour FEG" = "custom.insight.table.headers.body.shop.labour.feg",
  "Body Shop Idle Time" = "custom.insight.table.headers.body.shop.idle.time",
  "Body Shop Prod. Benefits & Incentives" = "custom.insight.table.headers.body.shop.prod.benefits.and.incentives",
  "Body Shop Labour Retail GP%" = "custom.insight.table.headers.body.shop.labour.retail.gp.percent",
  "Body Shop Labour Warranty GP%" = "custom.insight.table.headers.body.shop.labour.warranty.gp.percent",
  "Body Shop Labour Internal GP%" = "custom.insight.table.headers.body.shop.labour.internal.gp.percent",
  "Body Shop Expenses Analysis" = "custom.insight.table.headers.body.shop.expenses.analysis",
  "BODY SHOP Variable Selling Gross % Net Sales" = "custom.insight.table.headers.body.shop.variable.selling.gross.percent.net.sales",
  "Body Shop AVG Operational Analysis" = "custom.insight.table.headers.body.shop.avg.operational.analysis",
  "Body Shop AVG Inventories" = "custom.insight.table.headers.body.shop.avg.inventories",
  "Body Shop AVG Receivables" = "custom.insight.table.headers.body.shop.avg.receivables",
  "Body Shop AVG Cash on Hand" = "custom.insight.table.headers.body.shop.avg.cash.on.hand",
  "Body Shop AVG Other Current Assets" = "custom.insight.table.headers.body.shop.avg.other.current.assets",
  "Body Shop AVG Equipment and Other Fixed Assets" = "custom.insight.table.headers.body.shop.avg.equipment.and.other.fixed.assets",
  "Body Shop Selling Gross Analysis" = "custom.insight.table.headers.body.shop.selling.gross.analysis",
  "Body Shop Hours Available" = "custom.insight.table.headers.body.shop.hours.available",
  "Body Shop Hours Clocked (Worked)" = "custom.insight.table.headers.body.shop.hours.clocked.worked",
  "Body Shop Hours Sold" = "custom.insight.table.headers.body.shop.hours.sold",
  "Body Shop STAFF (Average)" = "custom.insight.table.headers.body.shop.staff.average",
  "Driveway Sales Breakdown" = "custom.insight.table.headers.driveway.sales.breakdown",
  "Driveway Operating Expenses" = "custom.insight.table.headers.driveway.operating.expenses",
  "Driveway Admin Expenses Allocation" = "custom.insight.table.headers.driveway.admin.expenses.allocation",
  "Driveway Variable Selling Gross / Day" = "custom.insight.table.headers.driveway.variable.selling.gross.day",
  "Driveway Variable Selling Gross" = "custom.insight.table.headers.driveway.variable.selling.gross",
  "Driveway Expenses Analysis" = "custom.insight.table.headers.driveway.expenses.analysis",
  "DRIVEWAY RETAINED Drivers (SPLIT Analysis)" = "custom.insight.table.headers.driveway.retained.drivers.split.analysis",
  "Driveway Variable Expenses % GP" = "custom.insight.table.headers.driveway.variable.expenses.percent.gp",
  "Driveway EXPENSES mix" = "custom.insight.table.headers.driveway.expenses.mix",
  "DRIVEWAY GP" = "custom.insight.table.headers.driveway.gp",
  "DRIVEWAY Variable Expenses% Total Expenses" = "custom.insight.table.headers.driveway.variable.expenses.percent.total.expenses",
  "DRIVEWAY Variable Selling Gross % GP" = "custom.insight.table.headers.driveway.variable.selling.gross.percent.gp",
  "DRIVEWAY Variable Selling Gross % Net Sales" = "custom.insight.table.headers.driveway.variable.selling.gross.percent.net.sales",
  "DRIVEWAY Personnel Expenses % GP" = "custom.insight.table.headers.driveway.personnel.expenses.percent.gp",
  "DRIVEWAY Personnel Expenses% Total Expenses" = "custom.insight.table.headers.driveway.personnel.expenses.percent.total.expenses",
  "DRIVEWAY Variable Selling Gross net of Personnel Expenses" = "custom.insight.table.headers.driveway.variable.selling.gross.net.of.personnel.expenses",
  "DRIVEWAY Variable Selling Gross net of Personnel Expenses % GP" = "custom.insight.table.headers.driveway.variable.selling.gross.net.of.personnel.expenses.percent.gp",
  "DRIVEWAY Other Semi Var. Expenses % GP" = "custom.insight.table.headers.driveway.other.semi.var.expenses.percent.gp",
  "DRIVEWAY Other Semi Variable Expenses" = "custom.insight.table.headers.driveway.other.semi.variable.expenses",
  "DRIVEWAY Other Semi Var.Exp.% Total Expenses" = "custom.insight.table.headers.driveway.other.semi.var.exp.percent.total.expenses",
  "DRIVEWAY Fixed Expenses % GP" = "custom.insight.table.headers.driveway.fixed.expenses.percent.gp",
  "DRIVEWAY Fixed Expenses% Total Expenses" = "custom.insight.table.headers.driveway.fixed.expenses.percent.total.expenses",
  "DRIVEWAY Interest Expenses % GP" = "custom.insight.table.headers.driveway.interest.expenses.percent.gp",
  "DRIVEWAY Interest Expenses% Total Expenses" = "custom.insight.table.headers.driveway.interest.expenses.percent.total.expenses",
  "Driveway AVG Operational Analysis" = "custom.insight.table.headers.driveway.avg.operational.analysis",
  "Driveway AVG Inventories" = "custom.insight.table.headers.driveway.avg.inventories",
  "Driveway AVG Receivables" = "custom.insight.table.headers.driveway.avg.receivables",
  "Driveway AVG Cash on Hand" = "custom.insight.table.headers.driveway.avg.cash.on.hand",
  "Driveway AVG Other Current Assets" = "custom.insight.table.headers.driveway.avg.other.current.assets",
  "Driveway AVG Equipment and Other Fixed Assets" = "custom.insight.table.headers.driveway.avg.equipment.and.other.fixed.assets",
  "Driveway Selling Gross Analysis" = "custom.insight.table.headers.driveway.selling.gross.analysis",
  "Total Driveway Staff" = "custom.insight.table.headers.total.driveway.staff",
  "Driveway STAFF (Average)" = "custom.insight.table.headers.driveway.staff.average",
  "AFTERCARE Contract" = "custom.insight.table.headers.aftercare.contract",
  "Aftercare Protection / Window Tint Sales" = "custom.insight.table.headers.aftercare.protection.window.tint.sales",
  "Aftercare GP / Unit Analysis" = "custom.insight.table.headers.aftercare.gp.unit.analysis",
  "Aftercare Expenses Analysis" = "custom.insight.table.headers.aftercare.expenses.analysis",
  "AFTERCARE RETAINED Drivers (SPLIT Analysis)" = "custom.insight.table.headers.aftercare.retained.drivers.split.analysis",
  "Aftercare Variable Expenses % GP" = "custom.insight.table.headers.aftercare.variable.expenses.percent.gp",
  "AFTERCARE EXPENSES mix" = "custom.insight.table.headers.aftercare.expenses.mix",
  "AFTERCARE GP" = "custom.insight.table.headers.aftercare.gp",
  "AFTERCARE Variable Expenses% Total Expenses" = "custom.insight.table.headers.aftercare.variable.expenses.percent.total.expenses",
  "AFTERCARE Variable Selling Gross" = "custom.insight.table.headers.aftercare.variable.selling.gross",
  "AFTERCARE Variable Selling Gross % GP" = "custom.insight.table.headers.aftercare.variable.selling.gross.percent.gp",
  "AFTERCARE Variable Selling Gross % Net Sales" = "custom.insight.table.headers.aftercare.variable.selling.gross.percent.net.sales",
  "AFTERCARE Personnel Expenses % GP" = "custom.insight.table.headers.aftercare.personnel.expenses.percent.gp",
  "AFTERCARE Personnel Expenses% Total Expenses" = "custom.insight.table.headers.aftercare.personnel.expenses.percent.total.expenses",
  "AFTERCARE Variable Selling Gross net of Personnel Expenses" = "custom.insight.table.headers.aftercare.variable.selling.gross.net.of.personnel.expenses",
  "AFTERCARE Variable Selling Gross net of Personnel Expenses % GP" = "custom.insight.table.headers.aftercare.variable.selling.gross.net.of.personnel.expenses.percent.gp",
  "AFTERCARE Other Semi Var. Expenses % GP" = "custom.insight.table.headers.aftercare.other.semi.var.expenses.percent.gp",
  "AFTERCARE Other Semi Variable Expenses" = "custom.insight.table.headers.aftercare.other.semi.variable.expenses",
  "AFTERCARE Other Semi Var.Exp.% Total Expenses" = "custom.insight.table.headers.aftercare.other.semi.var.exp.percent.total.expenses",
  "AFTERCARE Fixed Expenses % GP" = "custom.insight.table.headers.aftercare.fixed.expenses.percent.gp",
  "AFTERCARE Fixed Expenses% Total Expenses" = "custom.insight.table.headers.aftercare.fixed.expenses.percent.total.expenses",
  "AFTERCARE Interest Expenses % GP" = "custom.insight.table.headers.aftercare.interest.expenses.percent.gp",
  "AFTERCARE Interest Expenses% Total Expenses" = "custom.insight.table.headers.aftercare.interest.expenses.percent.total.expenses",
  "Aftercare AVG Operational Analysis" = "custom.insight.table.headers.aftercare.avg.operational.analysis",
  "Aftercare AVG Inventories" = "custom.insight.table.headers.aftercare.avg.inventories",
  "Aftercare AVG Receivables" = "custom.insight.table.headers.aftercare.avg.receivables",
  "Aftercare AVG Cash on Hand" = "custom.insight.table.headers.aftercare.avg.cash.on.hand",
  "Aftercare AVG Other Current Assets" = "custom.insight.table.headers.aftercare.avg.other.current.assets",
  "Aftercare AVG Equipment and Other Fixed Assets" = "custom.insight.table.headers.aftercare.avg.equipment.and.other.fixed.assets",
  "Aftercare" = "custom.insight.table.headers.aftercare",
  "Aftercare Selling Gross Analysis" = "custom.insight.table.headers.aftercare.selling.gross.analysis",
  "Aftercare STAFF (Average)" = "custom.insight.table.headers.aftercare.staff.average",
  "Other Dept. Expenses Analysis" = "custom.insight.table.headers.other.dept.expenses.analysis",
  "Other Dept. RETAINED Drivers (SPLIT Analysis)" = "custom.insight.table.headers.other.dept.retained.drivers.split.analysis",
  "Other Dept. Variable Expenses % GP" = "custom.insight.table.headers.other.dept.variable.expenses.percent.gp",
  "Other Dept. EXPENSES mix" = "custom.insight.table.headers.other.dept.expenses.mix",
  "OTHER DEPT. GP" = "custom.insight.table.headers.other.dept.gp",
  "OTHER DEPT. Variable Expenses% Total Expenses" = "custom.insight.table.headers.other.dept.variable.expenses.percent.total.expenses",
  "OTHER DEPT. Variable Selling Gross" = "custom.insight.table.headers.other.dept.variable.selling.gross",
  "OTHER DEPT. Variable Selling Gross % GP" = "custom.insight.table.headers.other.dept.variable.selling.gross.percent.gp",
  "OTHER DEPT. Variable Selling Gross % Net Sales" = "custom.insight.table.headers.other.dept.variable.selling.gross.percent.net.sales",
  "OTHER DEPT. Personnel Expenses % GP" = "custom.insight.table.headers.other.dept.personnel.expenses.percent.gp",
  "OTHER DEPT. Personnel Expenses% Total Expenses" = "custom.insight.table.headers.other.dept.personnel.expenses.percent.total.expenses",
  "OTHER DEPT. Variable Selling Gross net of Personnel Expenses" = "custom.insight.table.headers.other.dept.variable.selling.gross.net.of.personnel.expenses",
  "OTHER DEPT. Variable Selling Gross net of Personnel Expenses % GP" = "custom.insight.table.headers.other.dept.variable.selling.gross.net.of.personnel.expenses.percent.gp",
  "OTHER DEPT. OTHER DEPT. Semi Var. Expenses % GP" = "custom.insight.table.headers.other.dept.other.dept.semi.var.expenses.percent.gp",
  "OTHER DEPT. OTHER DEPT. Semi Variable Expenses" = "custom.insight.table.headers.other.dept.other.dept.semi.variable.expenses",
  "OTHER DEPT. OTHER DEPT. Semi Var.Exp.% Total Expenses" = "custom.insight.table.headers.other.dept.other.dept.semi.var.exp.percent.total.expenses",
  "OTHER DEPT. Fixed Expenses % GP" = "custom.insight.table.headers.other.dept.fixed.expenses.percent.gp",
  "OTHER DEPT. Fixed Expenses% Total Expenses" = "custom.insight.table.headers.other.dept.fixed.expenses.percent.total.expenses",
  "OTHER DEPT. Interest Expenses % GP" = "custom.insight.table.headers.other.dept.interest.expenses.percent.gp",
  "OTHER DEPT. Interest Expenses% Total Expenses" = "custom.insight.table.headers.other.dept.interest.expenses.percent.total.expenses",
  "Other Dept. AVG Operational Analysis" = "custom.insight.table.headers.other.dept.avg.operational.analysis",
  "Other Dept. AVG Inventories" = "custom.insight.table.headers.other.dept.avg.inventories",
  "Other Dept. AVG Receivables" = "custom.insight.table.headers.other.dept.avg.receivables",
  "Other Dept. AVG Cash on Hand" = "custom.insight.table.headers.other.dept.avg.cash.on.hand",
  "Other Dept. AVG Other Current Assets" = "custom.insight.table.headers.other.dept.avg.other.current.assets",
  "Other Dept. AVG Equipment and Other Fixed Assets" = "custom.insight.table.headers.other.dept.avg.equipment.and.other.fixed.assets",
  "Other Dept. Selling Gross Analysis" = "custom.insight.table.headers.other.dept.selling.gross.analysis",
  "Total Other Dept. Staff" = "custom.insight.table.headers.total.other.dept.staff",
  "Other Dept. STAFF (Average)" = "custom.insight.table.headers.other.dept.staff.average",
  "NV OEM centives % Net Sales" = "custom.insight.table.headers.nv.oem.centives.percent.net.sales",
  "NV Floor Plan Interest  % NV GP" = "custom.insight.table.headers.nv.floor.plan.interest.percent.nv.gp",
  "Units Sold mix (% Total Retail Units)" = "custom.insight.table.headers.units.sold.mix.percent.total.retail.units",
  "Gross Sales" = "custom.insight.table.headers.gross.sales",
  "Discount" = "custom.insight.table.headers.discount",
  "Net sales" = "custom.insight.table.headers.net.sales",
  "Give Away" = "custom.insight.table.headers.give.away",
  "Front End Gross" = "custom.insight.table.headers.front.end.gross",
  "Direct Incentives" = "custom.insight.table.headers.direct.incentives",
  "First Gross" = "custom.insight.table.headers.first.gross",
  "Margins" = "custom.insight.table.headers.margins",
  "GP Structure (Avg / Unit)" = "custom.insight.table.headers.gp.structure.avg.unit",
  "Gross Sales / Unit" = "custom.insight.table.headers.gross.sales.unit",
  "Discount / Unit" = "custom.insight.table.headers.discount.unit",
  "Net sales / Unit" = "custom.insight.table.headers.net.sales.unit",
  "Give Away / Unit" = "custom.insight.table.headers.give.away.unit",
  "Sales Effort (Disc.+ Give Away) / Unit" = "custom.insight.table.headers.sales.effort.disc.+.give.away.unit",
  "Front End Gross / Unit" = "custom.insight.table.headers.front.end.gross.unit",
  "Direct Incentives / Unit" = "custom.insight.table.headers.direct.incentives.unit",
  "Units Sold mix (% Total Fleet Units)" = "custom.insight.table.headers.units.sold.mix.percent.total.fleet.units",
  "Income Statement" = "custom.insight.table.headers.income.statement",
  "NV OEM Supports Analysis" = "custom.insight.table.headers.nv.oem.supports.analysis",
  "NV Income Statement" = "custom.insight.table.headers.nv.income.statement",
  "UV OEM Supports Analysis" = "custom.insight.table.headers.uv.oem.supports.analysis",
  "UV Income Statement" = "custom.insight.table.headers.uv.income.statement",
  "F&I OEM Supports Analysis" = "custom.insight.table.headers.fandi.oem.supports.analysis",
  "Aftersales OEM Supports Analysis" = "custom.insight.table.headers.aftersales.oem.supports.analysis",
  "Parts OEM Supports Analysis" = "custom.insight.table.headers.parts.oem.supports.analysis",
  "Service OEM Supports Analysis" = "custom.insight.table.headers.service.oem.supports.analysis",
  "Body Shop OEM Supports Analysis" = "custom.insight.table.headers.body.shop.oem.supports.analysis",
  "Driveway OEM Supports Analysis" = "custom.insight.table.headers.driveway.oem.supports.analysis",
  "Labour Sales (Service & Body Shop)" = "custom.insight.table.headers.labour.sales.service.and.body.shop",
  "UV Floor Plan Interest  % UV GP" = "custom.insight.table.headers.uv.floor.plan.interest.percent.uv.gp",
  "Service Ratio Productive" = "custom.dashboard.kpis.service.ratio.productive.non.productive",
  "CAR LINE ANALYSIS" = "custom.metric.label.nv.car.line.analysis",
  "Retail 1st GP %" = "custom.metric.label.nv.car.line.analysis.retail.gp",
  "Driver" = "custom.metric.table.header",
  "Achievement" = "metric.label.achievement",
  "Admin Fees Payable % Sales" = "metric.label.admin.fees.payable.sales",
  "B.Shop% Total GP" = "metric.label.b.shop.total.gp",
  "B.Shop% Total Sales" = "metric.label.b.shop.total.sales",
  "Body Shop Labour Internal Sales % Total B.shop Sales" = "metric.label.body.shop.labour.internal.sales.total.b.shop.sales",
  "Body Shop Labour Retail Sales % Total B.shop Sales" = "metric.label.body.shop.labour.retail.sales.total.b.shop.sales",
  "Body Shop Labour Sales % Total B.shop Sales" = "metric.label.body.shop.labour.sales.total.b.shop.sales",
  "Body Shop Labour Warranty Sales % Total B.shop Sales" = "metric.label.body.shop.labour.warranty.sales.total.b.shop.sales",
  "Body Shop Oil & Lubricant Sales % Total B.shop Sales" = "metric.label.body.shop.oil.lubricant.sales.total.b.shop.sales",
  "Body Shop Other Sales % Total B.shop Sales" = "metric.label.body.shop.other.sales.total.b.shop.sales",
  "Body Shop Paint & Material Sales % Total B.shop Sales" = "metric.label.body.shop.paint.material.sales.total.b.shop.sales",
  "Body Shop Sublet Sales % Total B.shop Sales" = "metric.label.body.shop.sublet.sales.total.b.shop.sales",
  "Body Shop Total Sales % Total B.shop Sales" = "metric.label.body.shop.total.sales.total.b.shop.sales",
  "Deduction / (Addition) % Sales" = "metric.label.deduction.addition.sales",
  "Expenses %GP" = "metric.label.expenses.gp",
  "Fixed Expenses % Sales" = "metric.label.fixed.expenses.sales",
  "Floor Plan % GP" = "metric.label.floor.plan.gp",
  "Interest Expenses % Sales" = "metric.label.interest.expenses.sales",
  "Internal service sales / internal RO" = "metric.label.internal.service.sales.internal.ro",
  "mix of expenses" = "metric.label.mix.of.expenses",
  "mix of GP" = "metric.label.mix.of.gp",
  "mix of sales" = "metric.label.mix.of.sales",
  "NV incl.F&I% Total GP" = "metric.label.nv.incl.f.i.total.gp",
  "NV incl.F&I% Total Sales" = "metric.label.nv.incl.f.i.total.sales",
  "NV" = "metric.label.nv",
  "Operational Income %" = "metric.label.operational.income",
  "Other Dept.% Total GP" = "metric.label.other.dept.total.gp",
  "Other Dept.% Total Sales" = "metric.label.other.dept.total.sales",
  "Other Semi Var. Expenses % Sales" = "metric.label.other.semi.var.expenses.sales",
  "Parts Accessories Sales % Total Parts Sales" = "metric.label.parts.accessories.sales.total.parts.sales",
  "Parts Body Shop Internal Sales % Total Parts Sales" = "metric.label.parts.body.shop.internal.sales.total.parts.sales",
  "Parts Body Shop Retail Sales % Total Parts Sales" = "metric.label.parts.body.shop.retail.sales.total.parts.sales",
  "Parts Body Shop Sales % Total Parts Sales" = "metric.label.parts.body.shop.sales.total.parts.sales",
  "Parts Body Shop Warranty Sales % Total Parts Sales" = "metric.label.parts.body.shop.warranty.sales.total.parts.sales",
  "Parts Counter Retail Sales % Total Parts Sales" = "metric.label.parts.counter.retail.sales.total.parts.sales",
  "Parts Intercompany Sales % Total Parts Sales" = "metric.label.parts.intercompany.sales.total.parts.sales",
  "Parts Oil & Lubricant Sales % Total Parts Sales" = "metric.label.parts.oil.lubricant.sales.total.parts.sales",
  "Parts Other Sales % Total Parts Sales" = "metric.label.parts.other.sales.total.parts.sales",
  "Parts Quick Service Workshop Sales % Total Parts Sales" = "metric.label.parts.quick.service.workshop.sales.total.parts.sales",
  "Parts Service Workshop Internal Sales % Total Parts Sales" = "metric.label.parts.service.workshop.internal.sales.total.parts.sales",
  "Parts Service Workshop Retail Sales % Total Parts Sales" = "metric.label.parts.service.workshop.retail.sales.total.parts.sales",
  "Parts Service Workshop Sales % Total Parts Sales" = "metric.label.parts.service.workshop.sales.total.parts.sales",
  "Parts Service Workshop Warranty Sales % Total Parts Sales" = "metric.label.parts.service.workshop.warranty.sales.total.parts.sales",
  "Parts% Total GP" = "metric.label.parts.total.gp",
  "Parts Total Sales % Total Parts Sales" = "metric.label.parts.total.sales.total.parts.sales",
  "Parts% Total Sales" = "metric.label.parts.total.sales",
  "Parts Wholesale Sales % Total Sales" = "metric.label.parts.wholesale.sales.total.parts.sales",

  "Parts Retail RO" = "metric.label.parts.retail.ro",
  "Parts Revenue '000" = "metric.label.parts.revenue",
  "Parts Sales / RO" = "metric.label.parts.sales.ro",

  "Personnel Expenses % Sales" = "metric.label.personnel.expenses.sales",
  "Quick Service Sales / Quick Service RO" = "metric.label.quick.service.sales.quick.service.ro",
  "Quick Service% Total GP" = "metric.label.quick.service.total.gp",
  "Quick Service% Total Sales" = "metric.label.quick.service.total.sales",
  "Ratio N :U" = "metric.label.ratio.n.u",
  "Retail RO" = "metric.label.retail.ro",
  "Total Service RO" = "metric.label.retail.service.ro",
  "Total Body Shop RO" = "metric.label.retail.bodyshop.ro",
  "Retail Sales / RO" = "metric.label.retail.sales.ro",
  "Retail service sales / retail RO" = "metric.label.retail.service.sales.retail.ro",
  "Sales $" = "metric.label.sales",

  "Service Labour GP%" = "metrics.standard.view.service.labour.gp.analysis",
  "Service Revenue '000" = "metric.label.service.revenue",
  "Service Workshop - Service Workshop Sales % Total Sales" = "metric.label.service.workshop.sales.total.sales",

  "Service Extended Warranty Sales % Total Service Sales" = "metric.label.service.extended.warranty.sales.total.service.sales",
  "Service Labour Internal Sales % Total Service Sales" = "metric.label.service.labour.internal.sales.total.service.sales",
  "Service Labour Maint. Contract Sales % Total Service Sales" = "metric.label.service.labour.maint.contract.sales.total.service.sales",
  "Service Labour Quick Service Sales % Total Service Sales" = "metric.label.service.labour.quick.service.sales.total.service.sales",
  "Service Labour Retail Sales % Total Service Sales" = "metric.label.service.labour.retail.sales.total.service.sales",
  "Service Labour Sales % Total Service Sales" = "metric.label.service.labour.sales.total.service.sales",
  "Service Labour Warranty Sales % Total Service Sales" = "metric.label.service.labour.warranty.sales.total.service.sales",
  "Service Oil & Lubricant Sales % Total Service Sales" = "metric.label.service.oil.lubricant.sales.total.service.sales",
  "Service Other Sales % Total Service Sales" = "metric.label.service.other.sales.total.service.sales",
  "Service Sublet Sales % Total Service Sales" = "metric.label.service.sublet.sales.total.service.sales",
  "Service% Total GP" = "metric.label.service.total.gp",
  "Service Total Sales % Total Service Sales" = "metric.label.service.total.sales.total.service.sales",
  "Service% Total Sales" = "metric.label.service.total.sales",
  "Total Expenses % Sales" = "metric.label.total.expenses.sales",
  "Units sold" = "metric.label.units.sold",
  "UV incl.F&I % Total GP" = "metric.label.uv.incl.f.i.total.gp",
  "UV incl.F&I % Total Sales" = "metric.label.uv.incl.f.i.total.sales",
  "UV" = "metric.label.uv",
  "Var %GP" = "metric.label.var.gp",
  "Variable Expenses % Sales" = "metric.label.variable.expenses.sales",
  "Warranty service sales / warranty RO" = "metric.label.warranty.service.sales.warranty.ro",
  "Wholesale Sales % Total Sales" = "metric.label.wholesale.sales.total.sales",
  "WIP Value" = "metric.label.wip.value",
  "Workshop Sales % Total Sales" = "metric.label.workshop.sales.total.sales",
  "Wshop Sales % Total Sales" = "metric.label.workshop.sales.total.sales",
  "Batch Server" = "reports.batchserver.subheader",

  "admin.users.header" = "admin.users.header",
  "admin.dealers.header" = "admin.dealers.header",
  "admin.manage.upload.subheader" = "admin.manage.upload.subheader",
  "admin.manage.oem.subheader" = "admin.manage.oem.subheader",
  "admin.manage.dealergroups.subheader" = "admin.manage.dealergroups.subheader",
  "admin.manage.dealers.subheader" = "admin.manage.dealers.subheader",
  "admin.manage.users.subheader" = "admin.manage.users.subheader",
  "admin.manage.subheader" = "admin.manage.subheader",
  "admin.roles.subheader" = "admin.roles.subheader",
  "admin.mappings.subheader" = "admin.mappings.subheader",
  "admin.dealer.groups.header" = "admin.dealer.groups.header",
  "admin.dealer.set.subheader" = "admin.dealer.set.subheader",
  "admin.data.reload.subheader" = "admin.data.reload.subheader",
  "admin.oem.setup.header" = "admin.oem.setup.header",
  "admin.input.templates.subheader" = "admin.input.templates.subheader",
  "admin.upload.schedule.header" = "admin.upload.schedule.header",
  "admin.ratio.engine.header" = "admin.ratio.engine.header",
  "admin.coa.indicators.subheader" = "admin.coa.indicators.subheader",
  "admin.ratios.subheader" = "admin.ratios.subheader",
  "admin.quality.gates.subheader" = "admin.quality.gates.subheader",
  "admin.master.coa.subheader" = "admin.master.coa.subheader",
  "admin.measure.subheader" = "admin.measure.subheader",
  "admin.base.measure.subheader" = "admin.base.measure.subheader",
  "admin.system.admin.header" = "admin.system.admin.header",
  "admin.system.admin.subheader" = "admin.system.admin.subheader",
  "admin.report.subheader" = "admin.report.subheader",
  "admin.dashboards.subheader" = "admin.dashboards.subheader",
  "admin.datasets.subheader" = "admin.datasets.subheader",
  "admin.senarioplanner.subheader" = "admin.senarioplanner.subheader",
  "admin.reportmenu.subheader" = "admin.reportmenu.subheader",
  "admin.batch.server.thresholds.subheader" = "admin.batch.server.thresholds.subheader",
  "admin.audit.subheader" = "admin.audit.subheader",
  "admin.options.subheader" = "admin.options.subheader",
  "admin.properties.subheader" = "admin.properties.subheader",
  "admin.cube.properties.subheader" = "admin.cube.properties.subheader",
  "admin.log.entries.subheader" = "admin.log.entries.subheader",
  "admin.sql.statement.subheader" = "admin.sql.statement.subheader",
  "admin.currency.subheader" = "admin.currency.subheader",
  "admin.master.data.subdata" = "admin.master.data.subdata",
  "admin.market.consolidation.header" = "admin.market.consolidation.header",
  "admin.benchmarks.header" = "admin.benchmarks.header",
  "admin.report.writer.subheader" = "admin.report.writer.subheader",
  "admin.data.admin.header" = "admin.data.admin.header",
  "admin.upload.market.share.data.subheader" = "admin.upload.market.share.data.subheader",
  "Dealer Count" = "metric.card.dealer.count.label",
}

export const translate = (title: string) => {
  return translation[title] ? translation[title] : title;
};

export enum mraSummaryTranslation {
  "You have an opportunity to improve your MIX compared to Median." = "mra.summary.header.mix.improve",
  "You have an opportunity to improve your MIX compared to Benchmark." = "mra.summary.header.mix.improve.compared.to.bm",
  "You have an opportunity to improve your RETAINED compared to Median." = "mra.summary.header.retained.improve",
  "You have an opportunity to improve your ACTIVITY compared to Median." = "mra.summary.header.activity.improve",
  "look at" = "mra.summary.header.look.at",
  "Your MIX is optimized." = "mra.summary.header.mix.optimized",
  "You are optimizing your RETAINED." = "mra.summary.header.retained.optimizing",
  "Your expenses are in control in relation to Gross Profit" = "mra.summary.header.retained.optimizing.sub",
  "You are optimizing your ACTIVITY." = "mra.summary.header.activity.optimizing",
  "You are optimizing your ROOA." = "mra.summary.header.rooa.optimizing",
  "The value of your Dealership could be further optimised." = "mra.summary.header.dealership.improve",
  "The value of your Dealership is not optimised." = "mra.summary.header.dealership.optimized",
  "Work on the above opportunities" = "mra.summary.header.dealership.optimized.sub",

  "Gross Profit contribution from your sales and after sales departments" = "mra.summary.gross.profit.contribution",
  "How you are protecting your margins in each department" = "mra.summary.protecting.margins.departments",
  "Look at Gross Profit contribution from your sales and after sales departments." = "mra.summary.look.at.gross.profit.contribution",
  "Sales contribution from your sales and after sales departments." = "mra.summary.sales.contribution",
  "Expenses percentage of Gross Profit by type of expenses category" = "mra.summary.expenses.percentage.gross.profit",
  "Look at Sales contribution from your sales and after sales departments." = "mra.summary.look.at.sales.contribution",
  " Expenses percentage of Gross Profit by type of expenses category" = "mra.summary.indent.expenses.percentage.gross.profit",
  "Inventory Days" = "mra.summary.inventory.days",
  "Debtor Days" = "mra.summary.debtor.days",
  "After Sales" = "mra.summary.after.sales",
  "Work In Progress (open RO)" = "mra.summary.work.in.progress.open.ro",
  "Cash Flow Management" = "mra.summary.cash.flow.management",
  " Work on the above opportunities" = "mra.summary.indent.work.above.opportunities",

  "Look at" = "mra.summary.look.at",
}

export enum otherSummaryTranslation {
  "monthly basis" = "other.summary.monthly.basis",
  "3 rolling month basis" = "other.summary.3.rolling.month.basis",
  "calendar year to date month basis" = "other.summary.calendar.year.to.date",
  "financial year to date month basis" = "other.summary.financial.year.to.date",
  "improved" = "other.summary.improved",
  "dropped" = "other.summary.dropped",
  "nodata" = "other.summary.nodata",
  "nodatastable" = "other.summary.nodatastable",
  "stable" = "other.summary.stable",
  "a declining" = "other.summary.declining",
  "an improving" = "other.summary.improving",
}

export enum dashboardTablesTranslation {
  "Admin Fees" = "metrics.standard.view.admin.fees",
  "Admin Fees Payable % Sales" = "metrics.standard.view.admin.fees.payable.sales",
  "Admin Fees Payable % TGP" = "metrics.standard.view.admin.fees.payable.tgp",
  "After Sales GP" = "metrics.standard.view.after.sales.gp",
  "Aftercare GP% (NV & UV)" = "metrics.standard.view.aftercare.gp.nv.uv",
  "Aftercare Sales" = "metrics.standard.view.aftercare.sales",
  "Aftercare Staff Allocation" = "metrics.standard.view.aftercare.staff.allocation",
  "Afterstaff Staff Allocation" = "metrics.standard.view.afterstaff.staff.allocation",
  "Allocation of Admin Expenses % GP" = "metrics.standard.view.allocation.of.admin.expenses.gp",
  "Annualised PBT" = "metrics.standard.view.annualised.pbt",
  "ASSETS" = "metrics.standard.view.assets",
  "Average Total Current Assets" = "metrics.standard.view.average.total.current.assets",
  "Average Total Current Liabilities" = "metrics.standard.view.average.total.current.liabilities",
  "Average Total Shareholders Fund" = "metrics.standard.view.average.total.shareholders.fund",
  "B.Shop% Total GP" = "metrics.standard.view.b.shop.total.gp",
  "B.Shop% Total Sales" = "metrics.standard.view.b.shop.total.sales",
  "BALANCE SHEET" = "metrics.standard.view.balance.sheet",
  "BODY SHOP ACTIVITY" = "metrics.standard.view.body.shop.activity",
  "BODY SHOP Annualised Sales" = "metrics.standard.view.body.shop.annualised.sales",
  "BODY SHOP Average Operational Assets" = "metrics.standard.view.body.shop.average.operational.assets",
  "Body Shop GP" = "metrics.standard.view.body.shop.gp",
  "Body Shop GP Analysis" = "metrics.standard.view.body.shop.gp.analysis",
  "Body Shop GP%" = "metrics.standard.view.body.shop.gp.perc",
  "Body Shop Labour Internal Sales" = "metrics.standard.view.body.shop.labour.internal.sales",
  "Body Shop Labour Internal Sales % Total B.shop Sales" = "metrics.standard.view.body.shop.labour.internal.sales.total.b.shop.sales",
  "Body Shop Labour Retail Sales" = "metrics.standard.view.body.shop.labour.retail.sales",
  "Body Shop Labour Retail Sales % Total B.shop Sales" = "metrics.standard.view.body.shop.labour.retail.sales.total.b.shop.sales",
  "Body Shop Labour Sales" = "metrics.standard.view.body.shop.labour.sales",
  "Body Shop Labour Sales % Total B.shop Sales" = "metrics.standard.view.body.shop.labour.sales.total.b.shop.sales",
  "Body Shop Labour Sales Breakdown" = "metrics.standard.view.body.shop.labour.sales.breakdown",
  "Body Shop Labour Warranty Sales" = "metrics.standard.view.body.shop.labour.warranty.sales",
  "Body Shop Labour Warranty Sales % Total B.shop Sales" = "metrics.standard.view.body.shop.labour.warranty.sales.total.b.shop.sales",
  "Body Shop Oil & Lubricant Sales" = "metrics.standard.view.body.shop.oil.lubricant.sales",
  "Body Shop Oil & Lubricant Sales % Total B.shop Sales" = "metrics.standard.view.body.shop.oil.lubricant.sales.total.b.shop.sales",
  "Body Shop Other Sales" = "metrics.standard.view.body.shop.other.sales",
  "Body Shop Other Sales % Total B.shop Sales" = "metrics.standard.view.body.shop.other.sales.total.b.shop.sales",
  "Body Shop Paint & Material Sales" = "metrics.standard.view.body.shop.paint.material.sales",
  "Body Shop Paint & Material Sales % Total B.shop Sales" = "metrics.standard.view.body.shop.paint.material.sales.total.b.shop.sales",
  "Body Shop RO Internal" = "metrics.standard.view.body.shop.ro.internal",
  "Body Shop RO Retail" = "metrics.standard.view.body.shop.ro.retail",
  "Body Shop RO Warranty" = "metrics.standard.view.body.shop.ro.warranty",
  "Body Shop Sales" = "metrics.standard.view.body.shop.sales",
  "Body Shop Sales / RO" = "metrics.standard.view.body.shop.sales.ro",
  "Body Shop Sales Breakdown" = "metrics.standard.view.body.shop.sales.breakdown",
  "Body Shop Sales mix" = "metrics.standard.view.body.shop.sales.mix",
  "Body Shop Sublet Sales" = "metrics.standard.view.body.shop.sublet.sales",
  "Body Shop Sublet Sales % Total B.shop Sales" = "metrics.standard.view.body.shop.sublet.sales.total.b.shop.sales",
  "Body Shop Total GP" = "metrics.standard.view.body.shop.total.gp",
  "Body Shop Total Sales" = "metrics.standard.view.body.shop.total.sales",
  "Body Shop Total Sales % Total B.shop Sales" = "metrics.standard.view.body.shop.total.sales.total.b.shop.sales",
  "Body Shop WIP Analysis" = "metrics.standard.view.body.shop.wip.analysis",
  "Body Shop WIP RO" = "metrics.standard.view.body.shop.wip.ro",
  "Body Shop WIP Value" = "metrics.standard.view.body.shop.wip.value",
  "Breakeven Analysis" = "metrics.standard.view.breakeven.analysis",
  "Breakeven Days" = "metrics.standard.view.breakeven.days",
  "Breakeven RO" = "metrics.standard.view.breakeven.ro",
  "Breakeven Sales" = "metrics.standard.view.breakeven.sales",
  "Breakeven Units" = "metrics.standard.view.breakeven.units",
  "Cash and Cash equivalents" = "metrics.standard.view.cash.and.cash.equivalents",
  "CCC = DIO + DSO - DPO" = "metrics.standard.view.ccc.dio.dso.dpo",
  "Creditors & Other Current Liabilities" = "metrics.standard.view.creditors.other.current.liabilities",
  "Current Assets" = "metrics.standard.view.current.assets",
  "Current Assets (Average)" = "metrics.standard.view.current.assets.average",
  "Current Liabilities" = "metrics.standard.view.current.liabilities",
  "Current Liabilities (Average)" = "metrics.standard.view.current.liabilities.average",
  "Current Ratio" = "metrics.standard.view.current.ratio",
  "DEBT to ASSET" = "metrics.standard.view.debt.to.asset",
  "DEBT to Assets Drivers" = "metrics.standard.view.debt.to.assets.drivers",
  "DEBT to EQUITY" = "metrics.standard.view.debt.to.equity",
  "Deduction / (Addition) % Sales" = "metrics.standard.view.deduction.addition.sales",
  "Deduction / (Addition) % TGP" = "metrics.standard.view.deduction.addition.tgp",
  "Deferred Tax" = "metrics.standard.view.deferred.tax",
  "Demo GP / Unit" = "metrics.standard.view.demo.gp.unit",
  "Demo Sales / Demo Unit" = "metrics.standard.view.demo.sales.demo.unit",
  "Demo Vehicle Units" = "metrics.standard.view.demo.vehicle.units",
  "Demo Vehicle Units Sold" = "metrics.standard.view.demo.vehicle.units.sold",
  "Deposits" = "metrics.standard.view.deposits",
  "DIO (Total Inventory Days)" = "metrics.standard.view.dio.total.inventory.days",
  "Direct Advertising / Unit" = "metrics.standard.view.direct.advertising.unit",
  "Directors Accounts" = "metrics.standard.view.directors.accounts",
  "Distributable Reserves" = "metrics.standard.view.distributable.reserves",
  "DPO (Total Payable Days)" = "metrics.standard.view.dpo.total.payable.days",
  "DSO (Total Debtor Days)" = "metrics.standard.view.dso.total.debtor.days",
  "EBIT% (PBIT%)" = "metrics.standard.view.ebit.pbit",
  "Effective Labour Rate / Hour" = "metrics.standard.view.effective.labour.rate.hour",
  "Effective Labour Rate / Hour Internal" = "metrics.standard.view.effective.labour.rate.hour.internal",
  "Effective Labour Rate / Hour Maintenance Plan" = "metrics.standard.view.effective.labour.rate.hour.maintenance.plan",
  "Effective Labour Rate / Hour Quick Service" = "metrics.standard.view.effective.labour.rate.hour.quick.service",
  "Effective Labour Rate / Hour Retail" = "metrics.standard.view.effective.labour.rate.hour.retail",
  "Effective Labour Rate / Hour Warranty" = "metrics.standard.view.effective.labour.rate.hour.warranty",
  "Effectiveness" = "metrics.standard.view.effectiveness",
  "Efficiency" = "metrics.standard.view.efficiency",
  "Equipment and Other Fixed Assets" = "metrics.standard.view.equipment.and.other.fixed.assets",
  "Expenses / Unit Analysis" = "metrics.standard.view.expenses.unit.analysis",
  "Expenses %GP" = "metrics.standard.view.expenses.gp",
  "Export" = "metrics.standard.view.export",
  "Extended Warranty GP%" = "metrics.standard.view.extended.warranty.gp",
  "F&I GP% (NV & UV)" = "metrics.standard.view.fi.gp.nv.uv",
  "F&I Sales" = "metrics.standard.view.fi.sales",
  "F&I Staff Allocation" = "metrics.standard.view.fi.staff.allocation",
  "FEG % Gross Sales" = "metrics.standard.view.feg.gross.sales",
  "Finance Penetration Retail" = "metrics.standard.view.finance.penetration.retail",
  "First Gross / Unit" = "metrics.standard.view.first.gross.unit",
  "First Gross % Net Sales" = "metrics.standard.view.first.gross.net.sales",
  "Fixed Expenses" = "metrics.standard.view.fixed.expenses",
  "Fixed Expenses % GP" = "metrics.standard.view.fixed.expenses.gp",
  "Fixed Expenses % Sales" = "metrics.standard.view.fixed.expenses.sales",
  "Fixed Expenses % TGP" = "metrics.standard.view.fixed.expenses.tgp",
  "Fixed Ops Sales" = "metrics.standard.view.fixed.ops.sales",
  "FLEET" = "metrics.standard.view.fleet",
  "Fleet Direct Incentives / Unit" = "metrics.standard.view.fleet.direct.incentives.unit",
  "Fleet FEG % Gross Sales" = "metrics.standard.view.fleet.feg.gross.sales",
  "Fleet First Gross / Unit" = "metrics.standard.view.fleet.first.gross.unit",
  "Fleet First Gross % Net Sales" = "metrics.standard.view.fleet.first.gross.net.sales",
  "Fleet Front End Gross / Unit" = "metrics.standard.view.fleet.front.end.gross.unit",
  "Fleet Give Away / Unit" = "metrics.standard.view.fleet.give.away.unit",
  "Fleet GP % Analysis" = "metrics.standard.view.fleet.gp.analysis",
  "Fleet Net Sales / Unit (excl. Acc)" = "metrics.standard.view.fleet.net.sales.unit.excl.acc",
  "Fleet Sales Effort % Gross Sales" = "metrics.standard.view.fleet.sales.effort.gross.sales",
  "Fleet Units Sold" = "metrics.standard.view.fleet.units.sold",
  "Floor Plan Interest / Unit" = "metrics.standard.view.floor.plan.interest.unit",
  "Floor Plan Interest % GP" = "metrics.standard.view.floor.plan.interest.gp",
  "Floor Plan Interest % NV GP" = "metrics.standard.view.floor.plan.interest.nv.gp",
  "Floor Plan Interest % UV GP" = "metrics.standard.view.floor.plan.interest.uv.gp",
  "Floor Plan Interest Expenses Analysis" = "metrics.standard.view.floor.plan.interest.expenses.analysis",
  "FOA" = "metrics.standard.view.foa",
  "FOA Drivers" = "metrics.standard.view.foa.drivers",
  "Goodwill" = "metrics.standard.view.goodwill",
  "GP / Employee / Month" = "metrics.standard.view.gp.employee.month",
  "GP / Productive / Month" = "metrics.standard.view.gp.productive.month",
  "GP / Productive / Month Aftercare" = "metrics.standard.view.gp.productive.month.aftercare",
  "GP / Productive / Month Body Shop" = "metrics.standard.view.gp.productive.month.body.shop",
  "GP / Productive / Month F&I" = "metrics.standard.view.gp.productive.month.fi",
  "GP / Productive / Month NV Incl. F&I & Aftercare" = "metrics.standard.view.gp.productive.month.nv.incl.fi.aftercare",
  "GP / Productive / Month NV only" = "metrics.standard.view.gp.productive.month.nv.only",
  "GP / Productive / Month Others" = "metrics.standard.view.gp.productive.month.others",
  "GP / Productive / Month Parts" = "metrics.standard.view.gp.productive.month.parts",
  "GP / Productive / Month Quick Service" = "metrics.standard.view.gp.productive.month.quick.service",
  "GP / Productive / Month Serv. & Quick Service" = "metrics.standard.view.gp.productive.month.serv.quick.service",
  "GP / Productive / Month Service" = "metrics.standard.view.gp.productive.month.service",
  "GP / Productive / Month UV Incl. F&I & Aftercare" = "metrics.standard.view.gp.productive.month.uv.incl.fi.aftercare",
  "GP / Productive / Month UV only" = "metrics.standard.view.gp.productive.month.uv.only",
  "GP / Unit Analysis" = "metrics.standard.view.gp.unit.analysis",
  "GP % Analysis" = "metrics.standard.view.gp.analysis",
  "GP%" = "metrics.standard.view.gp",
  "GP% By Departments" = "metrics.standard.view.gp.by.departments",
  "Hours Sold / RO" = "metrics.standard.view.hours.sold.ro",
  "Insurance Penetration Retail" = "metrics.standard.view.insurance.penetration.retail",
  "Interest Expenses" = "metrics.standard.view.interest.expenses",
  "Interest Expenses % Sales" = "metrics.standard.view.interest.expenses.sales",
  "Interest Expenses % TGP" = "metrics.standard.view.interest.expenses.tgp",
  "Internal service sales / internal RO" = "metrics.standard.view.internal.service.sales.internal.ro",
  "Inventory" = "metrics.standard.view.inventory",
  "Inventory Analysis" = "metrics.standard.view.inventory.analysis",
  "Investments" = "metrics.standard.view.investments",
  "Investments excl. Parent Child Balance" = "metrics.standard.view.investments.excl.parent.child.balance",
  "Issued Share Capital" = "metrics.standard.view.issued.share.capital",
  "L/T Loan Inter Company" = "metrics.standard.view.lt.loan.inter.company",
  "L/T Loan Mortgage" = "metrics.standard.view.lt.loan.mortgage",
  "L/T Loan Other" = "metrics.standard.view.lt.loan.other",
  "L/T Loan Shareholders" = "metrics.standard.view.lt.loan.shareholders",
  "Labour FEG%" = "metrics.standard.view.labour.feg",
  "Labour GP" = "metrics.standard.view.labour.gp",
  "Labour Internal GP %" = "metrics.standard.view.labour.internal.gp.sp",
  "Labour Internal GP%" = "metrics.standard.view.labour.internal.gp.perc",
  "Labour Maint. Contract GP%" = "metrics.standard.view.labour.maint.contract.gp",
  "Labour Quick Service GP%" = "metrics.standard.view.labour.quick.service.gp",
  "Labour Retail GP %" = "metrics.standard.view.labour.retail.gp.sp",
  "Labour Retail GP%" = "metrics.standard.view.labour.retail.gp.perc",
  "Labour Sales" = "metrics.standard.view.labour.sales",
  "Labour Sales / Productive / Month" = "metrics.standard.view.labour.sales.productive.month",
  "Labour Sales / RO" = "metrics.standard.view.labour.sales.ro",
  "Labour Sales mix of Total Service Sales" = "metrics.standard.view.labour.sales.mix.of.total.service.sales",
  "Labour Warranty GP %" = "metrics.standard.view.labour.warranty.gp.sp",
  "Labour Warranty GP%" = "metrics.standard.view.labour.warranty.gp.perc",
  "Land & Buildings" = "metrics.standard.view.land.buildings",
  "Liabilities" = "metrics.standard.view.liabilities",
  "Mix of expenses" = "metrics.standard.view.mix.of.expenses",
  "Mix of GP" = "metrics.standard.view.mix.of.gp",
  "mix of sales" = "metrics.standard.view.mix.of.sales",
  "New Vehicle : Used Vehicle Ratio" = "metrics.standard.view.new.vehicle.used.vehicle.ratio",
  "Non Current Assets" = "metrics.standard.view.non.current.assets",
  "Non Distributable Reserves" = "metrics.standard.view.non.distributable.reserves",
  "Non-Current Liabilities" = "metrics.standard.view.noncurrent.liabilities",
  "Non-Labour GP" = "metrics.standard.view.nonlabour.gp",
  "Non-Labour Sales" = "metrics.standard.view.nonlabour.sales",
  "Non-Productive Staff" = "metrics.standard.view.nonproductive.staff",
  "Number of SC (Average)" = "metrics.standard.view.number.of.sc.average",
  "Number of Workbay" = "metrics.standard.view.number.of.workbay",
  "NV : UV Ratio" = "metrics.standard.view.nv.uv.ratio",
  "NV Accessories Net Sales / Unit" = "metrics.standard.view.nv.accessories.net.sales.unit",
  "NV ACTIVITY" = "metrics.standard.view.nv.activity",
  "NV Aftercare GP" = "metrics.standard.view.nv.aftercare.gp",
  "NV Aftercare GP / Unit" = "metrics.standard.view.nv.aftercare.gp.unit",
  "NV Aftercare Sales" = "metrics.standard.view.nv.aftercare.sales",
  "NV Aftercare Sales Analysis" = "metrics.standard.view.nv.aftercare.sales.analysis",
  "NV Annualised Sales" = "metrics.standard.view.nv.annualised.sales",
  "NV Average Operational Assets" = "metrics.standard.view.nv.average.operational.assets",
  "NV Extended Warranty Sales / Contract" = "metrics.standard.view.nv.extended.warranty.sales.contract",
  "NV F&I GP" = "metrics.standard.view.nv.fi.gp",
  "NV F&I GP / Unit" = "metrics.standard.view.nv.fi.gp.unit",
  "NV F&I Penetration Analysis" = "metrics.standard.view.nv.fi.penetration.analysis",
  "NV F&I Sales" = "metrics.standard.view.nv.fi.sales",
  "NV Finance Penetration Retail" = "metrics.standard.view.nv.finance.penetration.retail",
  "NV First Gross / Unit" = "metrics.standard.view.nv.first.gross.unit",
  "NV Fleet Sales / NV Fleet Unit" = "metrics.standard.view.nv.fleet.sales.nv.fleet.unit",
  "NV Fleet Units" = "metrics.standard.view.nv.fleet.units",
  "NV Floor Plan Interest Analysis" = "metrics.standard.view.nv.floor.plan.interest.analysis",
  "NV Front End Gross / Unit" = "metrics.standard.view.nv.front.end.gross.unit",
  "NV GP" = "metrics.standard.view.nv.gp",
  "NV GP % excl. F&I Aftercare" = "metrics.standard.view.nv.gp.excl.fi.aftercare",
  "NV GP % incl. F&I & Aftercare" = "metrics.standard.view.nv.gp.incl.fi.aftercare.sp",
  "NV GP excl. F&I / Unit" = "metrics.standard.view.nv.gp.excl.fi.unit",
  "NV GP incl. F&I / Unit" = "metrics.standard.view.nv.gp.incl.fi.unit",
  "NV GP incl. F&I & Aftercare" = "metrics.standard.view.nv.gp.incl.fi.aftercare",
  "NV GP incl. F&I & Aftercare / Unit" = "metrics.standard.view.nv.gp.incl.fi.aftercare.unit",
  "NV GP%" = "metrics.standard.view.nv.gp.perc",
  "NV GP% (NV Only)" = "metrics.standard.view.nv.gp.nv.only",
  "NV GP% incl. F&I & Aftercare" = "metrics.standard.view.nv.gp.incl.fi.aftercare.perc",
  "NV incl. F&I & Aftercare" = "metrics.standard.view.nv.incl.fi.aftercare",
  "NV incl.F&I% Total GP" = "metrics.standard.view.nv.incl.fi.total.gp",
  "NV incl.F&I% Total Sales" = "metrics.standard.view.nv.incl.fi.total.sales",
  "NV Insurance Penetration Retail" = "metrics.standard.view.nv.insurance.penetration.retail",
  "NV Inventory" = "metrics.standard.view.nv.inventory",
  "NV Inventory Analysis" = "metrics.standard.view.nv.inventory.analysis",
  "NV Inventory Days" = "metrics.standard.view.nv.inventory.days",
  "NV Inventory Units (ending)" = "metrics.standard.view.nv.inventory.units.ending",
  "NV Inventory Value (ending)" = "metrics.standard.view.nv.inventory.value.ending",
  "NV Inventory Value >90 days mix" = "metrics.standard.view.nv.inventory.value.90.days.mix",
  "NV Inventory Value 0-30 days mix" = "metrics.standard.view.nv.inventory.value.030.days.mix",
  "NV Inventory Value 31-60 days mix" = "metrics.standard.view.nv.inventory.value.3160.days.mix",
  "NV Inventory Value 61-90 days mix" = "metrics.standard.view.nv.inventory.value.6190.days.mix",
  "NV Inventory value mix (% Total Inv.)" = "metrics.standard.view.nv.inventory.value.mix.total.inv",
  "NV Net Sales / Unit (excl. Acc)" = "metrics.standard.view.nv.net.sales.unit.excl.acc",
  "NV Non-Productive Staff" = "metrics.standard.view.nv.nonproductive.staff",
  "NV PDI Staff" = "metrics.standard.view.nv.pdi.staff",
  "NV Productive Staff" = "metrics.standard.view.nv.productive.staff",
  "NV Retail Sales / NV Retail Unit" = "metrics.standard.view.nv.retail.sales.nv.retail.unit",
  "NV Retail Units" = "metrics.standard.view.nv.retail.units",
  "NV RETAINED" = "metrics.standard.view.nv.retained",
  "NV RETAINED Drivers (SPLIT Analysis)" = "metrics.standard.view.nv.retained.drivers.split.analysis",
  "NV Sales" = "metrics.standard.view.nv.sales",
  "NV Sales Analysis" = "metrics.standard.view.nv.sales.analysis",
  "NV Sales incl. F&I & Aftercare" = "metrics.standard.view.nv.sales.incl.fi.aftercare",
  "NV Target Achievement (Factory)" = "metrics.standard.view.nv.target.achievement.factory",
  "NV Total Expenses" = "metrics.standard.view.nv.total.expenses",
  "NV Total GP" = "metrics.standard.view.nv.total.gp",
  "NV Total PBT" = "metrics.standard.view.nv.total.pbt",
  "NV Units" = "metrics.standard.view.nv.units",
  "NV Unit Sold" = "metric.table.nv.units.sold",
  "Oil & Lub GP%" = "metrics.standard.view.oil.lub.gp",
  "Oil & Lubricant GP %" = "metrics.standard.view.oil.lubricant.gp",
  "Operational Income % Sales" = "metrics.standard.view.opertational.income.sales",
  "Other Current Assets" = "metrics.standard.view.other.current.assets",
  "Other Departments GP%" = "metrics.standard.view.other.departments.gp",
  "Other Dept.% Total GP" = "metrics.standard.view.other.dept.total.gp",
  "Other Dept.% Total Sales" = "metrics.standard.view.other.dept.total.sales",
  "Other GP %" = "metrics.standard.view.other.gp.sp",
  "Other GP%" = "metrics.standard.view.other.gp.perc",
  "Other Inventory" = "metrics.standard.view.other.inventory",
  "Other Semi Var. Expenses % Sales" = "metrics.standard.view.other.semi.var.expenses.sales",
  "Other Semi Var. Expenses % TGP" = "metrics.standard.view.other.semi.var.expenses.tgp",
  "Other Semi Variable Expenses" = "metrics.standard.view.other.semi.variable.expenses",
  "Other Semi Variable Expenses % GP" = "metrics.standard.view.other.semi.variable.expenses.gp",
  "Other Variable / Unit" = "metrics.standard.view.other.variable.unit",
  "Other Variable Exp. / Unit" = "metrics.standard.view.other.variable.exp.unit",
  "Others Sales" = "metrics.standard.view.others.sales",
  "Overdraft" = "metrics.standard.view.overdraft",
  "Overhead Expenses" = "metrics.standard.view.overhead.expenses",
  "Paint & Material GP %" = "metrics.standard.view.paint.material.gp",
  "Parts 1st GP %" = "metrics.standard.view.parts.1st.gp",
  "Parts Accessories GP %" = "metrics.standard.view.parts.accessories.gp",
  "Parts Accessories Sales" = "metrics.standard.view.parts.accessories.sales",
  "Parts Accessories Sales % Total Parts Sales" = "metrics.standard.view.parts.accessories.sales.total.parts.sales",
  "PARTS ACTIVITY" = "metrics.standard.view.parts.activity",
  "Parts Adj. (Pricing & Buying Penalties & Obs.)" = "metrics.standard.view.parts.adj.pricing.buying.penalties.obs",
  "PARTS Annualised Sales" = "metrics.standard.view.parts.annualised.sales",
  "PARTS Average Operational Assets" = "metrics.standard.view.parts.average.operational.assets",
  "Parts Body Shop GP %" = "metrics.standard.view.parts.body.shop.gp",
  "Parts Body Shop Internal GP %" = "metrics.standard.view.parts.body.shop.internal.gp",
  "Parts Body Shop Internal Sales" = "metrics.standard.view.parts.body.shop.internal.sales",
  "Parts Body Shop Internal Sales % Total Parts Sales" = "metrics.standard.view.parts.body.shop.internal.sales.total.parts.sales",
  "Parts Body Shop Retail GP %" = "metrics.standard.view.parts.body.shop.retail.gp",
  "Parts Body Shop Retail Sales" = "metrics.standard.view.parts.body.shop.retail.sales",
  "Parts Body Shop Retail Sales % Total Parts Sales" = "metrics.standard.view.parts.body.shop.retail.sales.total.parts.sales",
  "Parts Body Shop Sales" = "metrics.standard.view.parts.body.shop.sales",
  "Parts Body Shop Sales / Body Shop RO" = "metrics.standard.view.parts.body.shop.sales.body.shop.ro",
  "Parts Body Shop Sales % Total Parts Sales" = "metrics.standard.view.parts.body.shop.sales.total.parts.sales",
  "Parts Body Shop Warranty GP %" = "metrics.standard.view.parts.body.shop.warranty.gp",
  "Parts Body Shop Warranty Sales" = "metrics.standard.view.parts.body.shop.warranty.sales",
  "Parts Body Shop Warranty Sales % Total Parts Sales" = "metrics.standard.view.parts.body.shop.warranty.sales.total.parts.sales",
  "Parts Counter Retail GP %" = "metrics.standard.view.parts.counter.retail.gp",
  "Parts Counter Retail Sales" = "metrics.standard.view.parts.counter.retail.sales",
  "Parts Counter Retail Sales % Total Parts Sales" = "metrics.standard.view.parts.counter.retail.sales.total.parts.sales",
  "Parts First GP" = "metrics.standard.view.parts.first.gp",
  "Parts GP" = "metrics.standard.view.parts.gp",
  "Parts GP Analysis" = "metrics.standard.view.parts.gp.analysis",
  "Parts GP%" = "metrics.standard.view.parts.gp.perc",
  "Parts Intercompany GP %" = "metrics.standard.view.parts.intercompany.gp",
  "Parts Intercompany Sales" = "metrics.standard.view.parts.intercompany.sales",
  "Parts Intercompany Sales % Total Parts Sales" = "metrics.standard.view.parts.intercompany.sales.total.parts.sales",
  "Parts Inventory" = "metrics.standard.view.parts.inventory",
  "Parts Inventory Age . Days mix" = "metrics.standard.view.parts.inventory.age.days.mix",
  "Parts Inventory Age 181-365 Days mix" = "metrics.standard.view.parts.inventory.age.181365.days.mix",
  "Parts Inventory Age 365+ Days mix" = "metrics.standard.view.parts.inventory.age.365.days.mix",
  "Parts Inventory Age 91-180 Days mix" = "metrics.standard.view.parts.inventory.age.91180.days.mix",
  "Parts Inventory Analysis" = "metrics.standard.view.parts.inventory.analysis",
  "Parts Inventory Days" = "metrics.standard.view.parts.inventory.days",
  "Parts Inventory Value (average)" = "metrics.standard.view.parts.inventory.value.average",
  "Parts Inventory Value (ending)" = "metrics.standard.view.parts.inventory.value.ending",
  "Parts Inventory value mix (% Total Inv.)" = "metrics.standard.view.parts.inventory.value.mix.total.inv",
  "Parts OEM Incentives" = "metrics.standard.view.parts.oem.incentives",
  "Parts Oil & Lubricant GP %" = "metrics.standard.view.parts.oil.lubricant.gp",
  "Parts Oil & Lubricant Sales" = "metrics.standard.view.parts.oil.lubricant.sales",
  "Parts Oil & Lubricant Sales % Total Parts Sales" = "metrics.standard.view.parts.oil.lubricant.sales.total.parts.sales",
  "Parts Other GP %" = "metrics.standard.view.parts.other.gp",
  "Parts Other Sales" = "metrics.standard.view.parts.other.sales",
  "Parts Other Sales % Total Parts Sales" = "metrics.standard.view.parts.other.sales.total.parts.sales",
  "Parts Productive Staff" = "metrics.standard.view.parts.productive.staff",
  "Parts Quick Service GP %" = "metrics.standard.view.parts.quick.service.gp",
  "Parts Quick Service Workshop Sales" = "metrics.standard.view.parts.quick.service.workshop.sales",
  "Parts Quick Service Workshop Sales % Total Parts Sales" = "metrics.standard.view.parts.quick.service.workshop.sales.total.parts.sales",
  "PARTS ROSI" = "metrics.standard.view.parts.rosi",
  "Parts Sales" = "metrics.standard.view.parts.sales",
  "Parts Sales / RO Analysis" = "metrics.standard.view.parts.sales.ro.analysis",
  "Parts Sales Analysis" = "metrics.standard.view.parts.sales.analysis",
  "Parts Sales Breakdown" = "metrics.standard.view.parts.sales.breakdown",
  "Parts Sales mix" = "metrics.standard.view.parts.sales.mix",
  "Parts Service Workshop GP %" = "metrics.standard.view.parts.service.workshop.gp",
  "Parts Service Workshop Internal GP %" = "metrics.standard.view.parts.service.workshop.internal.gp",
  "Parts Service Workshop Internal Sales" = "metrics.standard.view.parts.service.workshop.internal.sales",
  "Parts Service Workshop Internal Sales % Total Parts Sales" = "metrics.standard.view.parts.service.workshop.internal.sales.total.parts.sales",
  "Parts Service Workshop Retail GP %" = "metrics.standard.view.parts.service.workshop.retail.gp",
  "Parts Service Workshop Retail Sales" = "metrics.standard.view.parts.service.workshop.retail.sales",
  "Parts Service Workshop Retail Sales % Total Parts Sales" = "metrics.standard.view.parts.service.workshop.retail.sales.total.parts.sales",
  "Parts Service Workshop Sales" = "metrics.standard.view.parts.service.workshop.sales",
  "Parts Service Workshop Sales % Total Parts Sales" = "metrics.standard.view.parts.service.workshop.sales.total.parts.sales",
  "Parts Service Workshop Warranty GP %" = "metrics.standard.view.parts.service.workshop.warranty.gp",
  "Parts Service Workshop Warranty Sales" = "metrics.standard.view.parts.service.workshop.warranty.sales",
  "Parts Service Workshop Warranty Sales % Total Parts Sales" = "metrics.standard.view.parts.service.workshop.warranty.sales.total.parts.sales",
  "Parts Total GP" = "metrics.standard.view.parts.total.gp",
  "Parts Total Sales" = "metrics.standard.view.parts.total.sales",
  "Parts Total Sales % Total Parts Sales" = "metrics.standard.view.parts.total.sales.total.parts.sales",
  "Parts Wholesale GP %" = "metrics.standard.view.parts.wholesale.gp",
  "Parts Wholesale Sales" = "metrics.standard.view.parts.wholesale.sales",
  "Parts Wholesale Sales % Total Sales" = "metrics.standard.view.parts.wholesale.sales.total.parts.sales",
  "Parts Workshop Sales / Productive / Month" = "metrics.standard.view.parts.workshop.sales.productive.month",
  "Parts Workshop Sales / Service RO" = "metrics.standard.view.parts.workshop.sales.service.ro",
  "Parts Workshop Sales % Labour Sales" = "metrics.standard.view.parts.workshop.sales.labour.sales",
  "Parts% Total GP" = "metrics.standard.view.parts.total.gp.perc",
  "Parts% Total Sales" = "metrics.standard.view.parts.total.sales.perc",
  "PBT" = "metrics.standard.view.pbt",
  "PDI & Free Service / Unit" = "metrics.standard.view.pdi.free.service.unit",
  "Per Fleet Unit Analysis" = "metrics.standard.view.per.fleet.unit.analysis",
  "Per Retail Unit Analysis" = "metrics.standard.view.per.retail.unit.analysis",
  "Personnel Expenses" = "metrics.standard.view.personnel.expenses",
  "Personnel Expenses % GP" = "metrics.standard.view.personnel.expenses.gp",
  "Personnel Expenses % Sales" = "metrics.standard.view.personnel.expenses.sales",
  "Personnel Expenses % TGP" = "metrics.standard.view.personnel.expenses.tgp",
  "Productive Staff" = "metrics.standard.view.productive.staff",
  "Productivity" = "metrics.standard.view.productivity",
  "PRODUCTIVITY by Departments" = "metrics.standard.view.productivity.by.departments",
  "Provisions" = "metrics.standard.view.provisions",
  "Quick Service GP%" = "metrics.standard.view.quick.service.gp",
  "Quick Service Sales" = "metrics.standard.view.quick.service.sales",
  "Quick Service Sales / Quick Service RO" = "metrics.standard.view.quick.service.sales.quick.service.ro",
  "Quick Service% Total GP" = "metrics.standard.view.quick.service.total.gp",
  "Quick Service% Total Sales" = "metrics.standard.view.quick.service.total.sales",
  "Ratio Productive : Non Productive" = "metrics.standard.view.ratio.productive.non.productive",
  "Receivables" = "metrics.standard.view.receivables",
  "Receivables Non Vehicle" = "metrics.standard.view.receivables.non.vehicle",
  "Receivables Other" = "metrics.standard.view.receivables.other",
  "Receivables Vehicle" = "metrics.standard.view.receivables.vehicle",
  "Reconditioning / Unit" = "metrics.standard.view.reconditioning.unit",
  "RETAIL" = "metrics.standard.view.retail",
  "Retail Direct Incentives / Unit" = "metrics.standard.view.retail.direct.incentives.unit",
  "Retail FEG % Gross Sales" = "metrics.standard.view.retail.feg.gross.sales",
  "Retail First Gross / Unit" = "metrics.standard.view.retail.first.gross.unit",
  "Retail First Gross % Net Sales" = "metrics.standard.view.retail.first.gross.net.sales",
  "Retail Front End Gross / Unit" = "metrics.standard.view.retail.front.end.gross.unit",
  "Retail Give Away / Unit" = "metrics.standard.view.retail.give.away.unit",
  "Retail GP % Analysis" = "metrics.standard.view.retail.gp.analysis",
  "Retail GP/ Unit" = "metrics.standard.view.retail.gp.unit",
  "Retail Net Sales / Unit (excl. Acc)" = "metrics.standard.view.retail.net.sales.unit.excl.acc",
  "Retail Sales Effort % Gross Sales" = "metrics.standard.view.retail.sales.effort.gross.sales",
  "Retail service sales / retail RO" = "metrics.standard.view.retail.service.sales.retail.ro",
  "Retail Units Sold" = "metrics.standard.view.retail.units.sold",
  "RETAINED Drivers (SPLIT Analysis)" = "metrics.standard.view.retained.drivers.split.analysis",
  "Retained Earnings" = "metrics.standard.view.retained.earnings",
  "Revaluation of Fixed Assets" = "metrics.standard.view.revaluation.of.fixed.assets",
  "RO / Technician / Day" = "metrics.standard.view.ro.technician.day",
  "RO and Hours Analysis" = "metrics.standard.view.ro.and.hours.analysis",
  "ROI" = "metrics.standard.view.roi",
  "ROS" = "metrics.standard.view.ros",
  "S/T Loan Inter Company" = "metrics.standard.view.st.loan.inter.company",
  "S/T Loan Other" = "metrics.standard.view.st.loan.other",
  "Sales" = "metrics.standard.view.sales",
  "Sales Commission / Unit" = "metrics.standard.view.sales.commission.unit",
  "SC Productivity" = "metrics.standard.view.sc.productivity",
  "SC Productivity Analysis" = "metrics.standard.view.sc.productivity.analysis",
  "Selling Gross % Gross Profit" = "metrics.standard.view.selling.gross.gross.profit",
  "Selling Gross % Net Sales" = "metrics.standard.view.selling.gross.net.sales",
  "Selling Gross Analysis" = "metrics.standard.view.selling.gross.analysis",
  "SERVICE ACTIVITY" = "metrics.standard.view.service.activity",
  "SERVICE Annualised Sales" = "metrics.standard.view.service.annualised.sales",
  "SERVICE Average Operational Assets" = "metrics.standard.view.service.average.operational.assets",
  "Service Extended Warranty Sales" = "metrics.standard.view.service.extended.warranty.sales",
  "Service Extended Warranty Sales % Total Service Sales" = "metrics.standard.view.service.extended.warranty.sales.total.service.sales",
  "Service GP" = "metrics.standard.view.service.gp",
  "Service GP Analysis" = "metrics.standard.view.service.gp.analysis",
  "Service GP%" = "metrics.standard.view.service.gp.perc",
  "Service Hours Sold per Service RO Internal" = "metrics.standard.view.service.hours.sold.per.service.ro.internal",
  "Service Hours Sold per Service RO Maintenance Plan" = "metrics.standard.view.service.hours.sold.per.service.ro.maintenance.plan",
  "Service Hours Sold per Service RO Quick Service" = "metrics.standard.view.service.hours.sold.per.service.ro.quick.service",
  "Service Hours Sold per Service RO Retail" = "metrics.standard.view.service.hours.sold.per.service.ro.retail",
  "Service Hours Sold per Service RO Warranty" = "metrics.standard.view.service.hours.sold.per.service.ro.warranty",
  "Service Labour GP Analysis" = "metrics.standard.view.service.labour.gp.analysis",
  "Service Labour Internal Sales" = "metrics.standard.view.service.labour.internal.sales",
  "Service Labour Internal Sales % Total Service Sales" = "metrics.standard.view.service.labour.internal.sales.total.service.sales",
  "Service Labour Internal Sales mix (%TLS)" = "metrics.standard.view.service.labour.internal.sales.mix.tls",
  "Service Labour Maint. Contaract Sales" = "metrics.standard.view.service.labour.maint.contaract.sales",
  "Service Labour Maint. Contract Sales % Total Service Sales" = "metrics.standard.view.service.labour.maint.contract.sales.total.service.sales",
  "Service Labour Maint. Contract Sales mix (%TLS)" = "metrics.standard.view.service.labour.maint.contract.sales.mix.tls",
  "Service Labour Quick Service Sales" = "metrics.standard.view.service.labour.quick.service.sales",
  "Service Labour Quick Service Sales % Total Service Sales" = "metrics.standard.view.service.labour.quick.service.sales.total.service.sales",
  "Service Labour Quick Service Sales mix (%TLS)" = "metrics.standard.view.service.labour.quick.service.sales.mix.tls",
  "Service Labour Retail Sales" = "metrics.standard.view.service.labour.retail.sales",
  "Service Labour Retail Sales % Total Service Sales" = "metrics.standard.view.service.labour.retail.sales.total.service.sales",
  "Service Labour Retail Sales mix (%TLS)" = "metrics.standard.view.service.labour.retail.sales.mix.tls",
  "Service Labour Sales" = "metrics.standard.view.service.labour.sales",
  "Service Labour Sales % Total Service Sales" = "metrics.standard.view.service.labour.sales.total.service.sales",
  "Service Labour Sales Breakdown" = "metrics.standard.view.service.labour.sales.breakdown",
  "Service Labour Sales mix (% TSS)" = "metrics.standard.view.service.labour.sales.mix.tss",
  "Service Labour Warranty Sales" = "metrics.standard.view.service.labour.warranty.sales",
  "Service Labour Warranty Sales % Total Service Sales" = "metrics.standard.view.service.labour.warranty.sales.total.service.sales",
  "Service Labour Warranty Sales mix (%TLS)" = "metrics.standard.view.service.labour.warranty.sales.mix.tls",
  "Service Oil & Lubricant Sales" = "metrics.standard.view.service.oil.lubricant.sales",
  "Service Oil & Lubricant Sales % Total Service Sales" = "metrics.standard.view.service.oil.lubricant.sales.total.service.sales",
  "Service Other Sales" = "metrics.standard.view.service.other.sales",
  "Service Other Sales % Total Service Sales" = "metrics.standard.view.service.other.sales.total.service.sales",
  "Service RO Internal" = "metrics.standard.view.service.ro.internal",
  "Service RO Maintenance Plan" = "metrics.standard.view.service.ro.maintenance.plan",
  "Service RO Quick Service" = "metrics.standard.view.service.ro.quick.service",
  "Service RO Retail" = "metrics.standard.view.service.ro.retail",
  "Service RO Warranty" = "metrics.standard.view.service.ro.warranty",
  "Service Sales" = "metrics.standard.view.service.sales",
  "Service Sales / RO" = "metrics.standard.view.service.sales.ro",
  "Service Sales Breakdown" = "metrics.standard.view.service.sales.breakdown",
  "Service Sales mix" = "metrics.standard.view.service.sales.mix",
  "Service Sublet Sales" = "metrics.standard.view.service.sublet.sales",
  "Service Sublet Sales % Total Service Sales" = "metrics.standard.view.service.sublet.sales.total.service.sales",
  "Service Total GP" = "metrics.standard.view.service.total.gp",
  "Service Total Sales" = "metrics.standard.view.service.total.sales",
  "Service Total Sales % Total Service Sales" = "metrics.standard.view.service.total.sales.total.service.sales",
  "Service WIP Analysis" = "metrics.standard.view.service.wip.analysis",
  "Service WIP RO" = "metrics.standard.view.service.wip.ro",
  "Service WIP Value" = "metrics.standard.view.service.wip.value",
  "Service% Total GP" = "metrics.standard.view.service.total.gp.perc",
  "Service% Total Sales" = "metrics.standard.view.service.total.sales.perc",
  "Share Premium" = "metrics.standard.view.share.premium",
  "SHAREHOLDER FUNDS" = "metrics.standard.view.shareholder.funds",
  "Shareholders Fund" = "metrics.standard.view.shareholders.fund",
  "STAFF" = "metrics.standard.view.staff",
  "STAFF (Average)" = "metrics.standard.view.staff.average",
  "Sublet GP %" = "metrics.standard.view.sublet.gp.sp",
  "Sublet GP%" = "metrics.standard.view.sublet.gp.perc",
  "Support Service Advisor" = "metrics.standard.view.support.service.advisor",
  "Support Staff incl. SA" = "metrics.standard.view.support.staff.incl.sa",
  "Target Achievement (Factory)" = "metrics.standard.view.target.achievement.factory",
  "Total Admin Fees Payable" = "metrics.standard.view.total.admin.fees.payable",
  "Total Assets" = "metrics.standard.view.total.assets",
  "Total Assets excl. Goodwill & Parent Child Balance" = "metrics.standard.view.total.assets.excl.goodwill.parent.child.balance",
  "Total Body Shop Sales" = "metrics.standard.view.total.body.shop.sales",
  "Total Cost of Goods Sold (COGS)" = "metrics.standard.view.total.cost.of.goods.sold.cogs",
  "Total Dealership Breakeven Days" = "metrics.standard.view.total.dealership.breakeven.days",
  "Total Dealership Breakeven NV Units" = "metrics.standard.view.total.dealership.breakeven.nv.units",
  "Total Dealership Expenses" = "metrics.standard.view.total.dealership.expenses",
  "Total Dealership GP" = "metrics.standard.view.total.dealership.gp",
  "Total Dealership Income Statement" = "metrics.standard.view.total.dealership.income.statement",
  "Total Dealership PBT" = "metrics.standard.view.total.dealership.pbt",
  "Total Dealership Sales" = "metrics.standard.view.total.dealership.sales",
  "Total Debtor Days (DSO)" = "metrics.standard.view.total.debtor.days.dso",
  "Total Deduction / (Addition)" = "metrics.standard.view.total.deduction.addition",
  "Total EBIT" = "metrics.standard.view.total.ebit",
  "Total Equity & Liabilities" = "metrics.standard.view.total.equity.liabilities",
  "Total Expenses" = "metrics.standard.view.total.expenses",
  "Total Expenses % GP" = "metrics.standard.view.total.expenses.gp",
  "Total Expenses % Sales" = "metrics.standard.view.total.expenses.sales",
  "Total Expenses % TGP" = "metrics.standard.view.total.expenses.tgp",
  "Total Fixed Expenses" = "metrics.standard.view.total.fixed.expenses",
  "Total Gross Profit / Productive / Month" = "metrics.standard.view.total.gross.profit.productive.month",
  "Total Interest Expenses" = "metrics.standard.view.total.interest.expenses",
  "Total Inventory" = "metrics.standard.view.total.inventory",
  "Total Inventory Days (DIO)" = "metrics.standard.view.total.inventory.days.dio",
  "Total Labour Sales" = "metrics.standard.view.total.labour.sales",
  "Total Liabilities" = "metrics.standard.view.total.liabilities",
  "Total Operational Income" = "metrics.standard.view.total.operational.income",
  "Total Other Semi Variable Expenses" = "metrics.standard.view.total.other.semi.variable.expenses",
  "Total Parts Sales" = "metrics.standard.view.total.parts.sales",
  "Total Parts Sales / Productive / Month" = "metrics.standard.view.total.parts.sales.productive.month",
  "Total PBT" = "metrics.standard.view.total.pbt",
  "Total PBT / Employee / Month" = "metrics.standard.view.total.pbt.employee.month",
  "Total Personnel Expenses" = "metrics.standard.view.total.personnel.expenses",
  "Total Receivable" = "metrics.standard.view.total.receivable",
  "Total Receivables (Average)" = "metrics.standard.view.total.receivables.average",
  "Total RO" = "metrics.standard.view.total.ro",
  "Total Service Sales" = "metrics.standard.view.total.service.sales",
  "Total Shareholder Funds" = "metrics.standard.view.total.shareholder.funds",
  "Total Shareholders Fund" = "metrics.standard.view.total.shareholders.fund",
  "Total Staff" = "metrics.standard.view.total.staff",
  "Total Staff Non Productives" = "metrics.standard.view.total.staff.non.productives",
  "Total Staff Productives" = "metrics.standard.view.total.staff.productives",
  "Total Units Sold" = "metrics.standard.view.total.units.sold",
  "Total Units Target" = "metrics.standard.view.total.units.target",
  "Total Variable Expenses" = "metrics.standard.view.total.variable.expenses",
  "Trade Units Sold" = "metrics.standard.view.trade.units.sold",
  "Units Sold" = "metrics.standard.view.units.sold",
  "UV ACTIVITY" = "metrics.standard.view.uv.activity",
  "UV Aftercare GP" = "metrics.standard.view.uv.aftercare.gp",
  "UV Aftercare GP / Unit" = "metrics.standard.view.uv.aftercare.gp.unit",
  "UV Aftercare Sales" = "metrics.standard.view.uv.aftercare.sales",
  "UV Aftercare Sales Analysis" = "metrics.standard.view.uv.aftercare.sales.analysis",
  "UV Annualised Sales" = "metrics.standard.view.uv.annualised.sales",
  "UV Average Operational Assets" = "metrics.standard.view.uv.average.operational.assets",
  "UV Extended Warranty Sales / Contract" = "metrics.standard.view.uv.extended.warranty.sales.contract",
  "UV F&I GP" = "metrics.standard.view.uv.fi.gp",
  "UV F&I GP / Unit" = "metrics.standard.view.uv.fi.gp.unit",
  "UV F&I Penetration Analysis" = "metrics.standard.view.uv.fi.penetration.analysis",
  "UV F&I Sales" = "metrics.standard.view.uv.fi.sales",
  "UV Finance Penetration" = "metrics.standard.view.uv.finance.penetration",
  "UV Floor Plan Interest Analysis" = "metrics.standard.view.uv.floor.plan.interest.analysis",
  "UV GP" = "metrics.standard.view.uv.gp",
  "UV GP % excl. F&I Aftercare" = "metrics.standard.view.uv.gp.excl.fi.aftercare.sp",
  "UV GP % incl. F&I Aftercare" = "metrics.standard.view.uv.gp.incl.fi.aftercare.sp",
  "UV GP excl. F&I / Unit" = "metrics.standard.view.uv.gp.excl.fi.unit",
  "UV GP incl. F&I / Unit" = "metrics.standard.view.uv.gp.incl.fi.unit",
  "UV GP incl. F&I & Aftercare" = "metrics.standard.view.uv.gp.incl.fi.aftercare",
  "UV GP incl. F&I & Aftercare / Unit" = "metrics.standard.view.uv.gp.incl.fi.aftercare.unit",
  "UV GP%" = "metrics.standard.view.uv.gp.perc",
  "UV GP% (UV Only)" = "metrics.standard.view.uv.gp.uv.only",
  "UV GP% incl. F&I & Aftercare" = "metrics.standard.view.uv.gp.incl.fi.aftercare.perc",
  "UV incl. F&I & Aftercare" = "metrics.standard.view.uv.incl.fi.aftercare",
  "UV incl.F&I % Total GP" = "metrics.standard.view.uv.incl.fi.total.gp",
  "UV incl.F&I % Total Sales" = "metrics.standard.view.uv.incl.fi.total.sales",
  "UV Insurance Penetration" = "metrics.standard.view.uv.insurance.penetration",
  "UV Inventory" = "metrics.standard.view.uv.inventory",
  "UV Inventory Analysis" = "metrics.standard.view.uv.inventory.analysis",
  "UV Inventory Days" = "metrics.standard.view.uv.inventory.days",
  "UV Inventory Units (ending)" = "metrics.standard.view.uv.inventory.units.ending",
  "UV Inventory Value (ending)" = "metrics.standard.view.uv.inventory.value.ending",
  "UV Inventory Value >90 days mix" = "metrics.standard.view.uv.inventory.value.90.days.mix",
  "UV Inventory Value 0-30 days mix" = "metrics.standard.view.uv.inventory.value.030.days.mix",
  "UV Inventory Value 31-60 days mix" = "metrics.standard.view.uv.inventory.value.3160.days.mix",
  "UV Inventory Value 61-90 days mix" = "metrics.standard.view.uv.inventory.value.6190.days.mix",
  "UV Inventory value mix (% Total Inv.)" = "metrics.standard.view.uv.inventory.value.mix.total.inv",
  "UV Non-Productive Staff" = "metrics.standard.view.uv.nonproductive.staff",
  "UV Productive Staff" = "metrics.standard.view.uv.productive.staff",
  "UV Retail Analysis" = "metrics.standard.view.uv.retail.analysis",
  "UV Retail First Gross / UV Retail Unit" = "metrics.standard.view.uv.retail.first.gross.uv.retail.unit",
  "UV Retail Reconditioning / UV Retail Unit" = "metrics.standard.view.uv.retail.reconditioning.uv.retail.unit",
  "UV Retail Sales / UV Retail Unit" = "metrics.standard.view.uv.retail.sales.uv.retail.unit",
  "UV Retail Units" = "metrics.standard.view.uv.retail.units",
  "UV RETAINED" = "metrics.standard.view.uv.retained",
  "UV RETAINED Drivers (SPLIT Analysis)" = "metrics.standard.view.uv.retained.drivers.split.analysis",
  "UV ROSI" = "metrics.standard.view.uv.rosi",
  "UV Sales" = "metrics.standard.view.uv.sales",
  "UV Sales / UV Unit (excl. Trade)" = "metrics.standard.view.uv.sales.uv.unit.excl.trade",
  "UV Sales Analysis" = "metrics.standard.view.uv.sales.analysis",
  "UV Sales incl. F&I & Aftercare" = "metrics.standard.view.uv.sales.incl.fi.aftercare",
  "UV Total Expenses" = "metrics.standard.view.uv.total.expenses",
  "UV Total GP" = "metrics.standard.view.uv.total.gp",
  "UV Total PBT" = "metrics.standard.view.uv.total.pbt",
  "UV Trade Units" = "metrics.standard.view.uv.trade.units",
  "UV Units" = "metrics.standard.view.uv.units",
  "UV Units Sold" = "metrics.standard.view.uv.units.sold",
  "Variable Expenses (Non Vehicle)" = "metrics.standard.view.variable.expenses.non.vehicle",
  "Variable Expenses / Unit" = "metrics.standard.view.variable.expenses.unit",
  "Variable Expenses / Unit Analysis" = "metrics.standard.view.variable.expenses.unit.analysis",
  "Variable Expenses % GP" = "metrics.standard.view.variable.expenses.gp",
  "Variable Expenses % Sales" = "metrics.standard.view.variable.expenses.sales",
  "Variable Expenses % TGP" = "metrics.standard.view.variable.expenses.tgp",
  "Variable Selling Gross % Gross Profit" = "metrics.standard.view.variable.selling.gross.gross.profit",
  "Vehicle Sales" = "metrics.standard.view.vehicle.sales",
  "Warranty service sales / warranty RO" = "metrics.standard.view.warranty.service.sales.warranty.ro",
  "Workbay Occupancy" = "metrics.standard.view.workbay.occupancy",
  "Workbay Utilization" = "metrics.standard.view.workbay.utilization",
  "Working Capital / Unit" = "metrics.standard.view.working.capital.unit",
  "Working Capital Amount" = "metrics.standard.view.working.capital.amount",
  "Working Capital in Days of Turnover" = "metrics.standard.view.working.capital.in.days.of.turnover",
  "Service & Quick Service Sales" = "metric.table.service.and.quick.service.sales",
  "GP / Productive / Month NV only " = "metric.table.gp.productive.month.nv.only",
  "GP / Productive / Month UV only " = "metric.table.gp.productive.month.uv.only",
  "Parts Adjustment Analysis" = "metric.table.parts.adjustment.analysis",
  "Parts Adjustments % Sales" = "metric.table.parts.adjustments.sales",
  "Parts OEM Incentives % Sales" = "metric.table.parts.oem.incentives.sales",
  "Parts Inventory Age 0-90 Days mix" = "metric.table.parts.inventory.age.0.90.days.mix",
  "Labour GP%" = "metric.table.labour.gp",
  "Service Idle Time % TLS" = "metric.table.service.idle.time.tls",
  "Service Prod. Benefits & Incentives" = "metric.table.service.prod.benefits.and.incentives",

  "ACTIVITY" = "metrics.mra.view.activity",
  "ACTIVITY Drivers" = "metrics.mra.view.activity.drivers",
  "Aftercare MIX (NV & UV)" = "metrics.mra.view.aftercare.mix.nv.uv",
  "Allocation of Admin Expenses" = "metrics.mra.view.allocation.of.admin.expenses",
  "Annualised Sales" = "metrics.mra.view.annualised.sales",
  "Average Operational Assets" = "metrics.mra.view.average.operational.assets",
  "AVG Cash on Hand" = "metrics.mra.view.avg.cash.on.hand",
  "AVG Equipment and Other Fixed Assets" = "metrics.mra.view.avg.equipment.and.other.fixed.assets",
  "AVG Inventories" = "metrics.mra.view.avg.inventories",
  "AVG Other Current Assets" = "metrics.mra.view.avg.other.current.assets",
  "AVG Receivables" = "metrics.mra.view.avg.receivables",
  "Body Shop MIX" = "metrics.mra.view.body.shop.mix",
  "BODY SHOP RETAINED" = "metrics.mra.view.body.shop.retained",
  "BODY SHOP RETAINED Drivers (SPLIT Analysis)" = "metrics.mra.view.body.shop.retained.drivers.split.analysis",
  "BODY SHOP Total Expenses" = "metrics.mra.view.body.shop.total.expenses",
  "BODY SHOP Total PBT" = "metrics.mra.view.body.shop.total.pbt",
  "Debtors Days" = "metrics.mra.view.debtors.days",
  "Demo Stock Days" = "metrics.mra.view.demo.stock.days",
  "Departmental Income Statement" = "metrics.mra.view.departmental.income.statement",
  "Departmental Profit" = "metrics.mra.view.departmental.profit",
  "Dept. Profit % Net Sales" = "metrics.mra.view.dept.profit.net.sales",
  "EBIT" = "metrics.mra.view.ebit",
  "Expenses" = "metrics.mra.view.expenses",
  "F&I MIX (NV & UV)" = "metrics.mra.view.fi.mix.nv.uv",
  "Gross Profit" = "metrics.mra.view.gross.profit",
  "Interest Expenses % GP" = "metrics.mra.view.interest.expenses.gp",
  "MIX" = "metrics.mra.view.mix",
  "MIX By Departments" = "metrics.mra.view.mix.by.departments",
  "NV MIX" = "metrics.mra.view.nv.mix",
  "NV MIX (NV Only)" = "metrics.mra.view.nv.mix.nv.only",
  "NV MIX incl. F&I & Aftercare" = "metrics.mra.view.nv.mix.incl.fi.aftercare",
  "NV Stock Days" = "metrics.mra.view.nv.stock.days",
  "Other Departments MIX" = "metrics.mra.view.other.departments.mix",
  "Parts MIX" = "metrics.mra.view.parts.mix",
  "PARTS RETAINED" = "metrics.mra.view.parts.retained",
  "PARTS RETAINED Drivers (SPLIT Analysis)" = "metrics.mra.view.parts.retained.drivers.split.analysis",
  "Parts Sales per Body Shop RO Internal" = "metrics.mra.view.parts.sales.per.body.shop.ro.internal",
  "Parts Sales per Body Shop RO Retail" = "metrics.mra.view.parts.sales.per.body.shop.ro.retail",
  "Parts Sales per Body Shop RO Warranry" = "metrics.mra.view.parts.sales.per.body.shop.ro.warranry",
  "Parts Sales per Service RO Internal" = "metrics.mra.view.parts.sales.per.service.ro.internal",
  "Parts Sales per Service RO Retail" = "metrics.mra.view.parts.sales.per.service.ro.retail",
  "Parts Sales per Service RO Warranty" = "metrics.mra.view.parts.sales.per.service.ro.warranty",
  "Parts Stock Days" = "metrics.mra.view.parts.stock.days",
  "PARTS Total Expenses" = "metrics.mra.view.parts.total.expenses",
  "PARTS Total PBT" = "metrics.mra.view.parts.total.pbt",
  "Parts Workshop Sales" = "metrics.mra.view.parts.workshop.sales",
  "Parts Workshop Sales (Service & B. Shop) % Total Sales" = "metrics.mra.view.parts.workshop.sales.service.b.shop.total.sales",
  "Parts Workshop Sales Breakdown" = "metrics.mra.view.parts.workshop.sales.breakdown",
  "Quick Service MIX" = "metrics.mra.view.quick.service.mix",
  "RETAINED" = "metrics.mra.view.retained",
  "Service & Quick Service MIX" = "metrics.mra.view.service.quick.service.mix",
  "Service MIX" = "metrics.mra.view.service.mix",
  "SERVICE RETAINED" = "metrics.mra.view.service.retained",
  "SERVICE RETAINED Drivers (SPLIT Analysis)" = "metrics.mra.view.service.retained.drivers.split.analysis",
  "SERVICE Total Expenses" = "metrics.mra.view.service.total.expenses",
  "SERVICE Total PBT" = "metrics.mra.view.service.total.pbt",
  "Service WIP Days" = "metrics.mra.view.service.wip.days",
  "UV MIX" = "metrics.mra.view.uv.mix",
  "UV MIX (UV Only)" = "metrics.mra.view.uv.mix.uv.only",
  "UV MIX incl. F&I & Aftercare" = "metrics.mra.view.uv.mix.incl.fi.aftercare",
  "UV Stock Days" = "metrics.mra.view.uv.stock.days",
  "Variable Expenses" = "metrics.mra.view.variable.expenses",
  "Variable Selling Gross % Gorss Profit" = "metrics.mra.view.variable.selling.gross.gorss.profit",

  "TOTAL LIABILITIES" = "metric.table.total.liabilities.big",
  "LIABILITIES" = "metric.table.liabilities.big",
  "PARTS Total Sales" = "metric.table.parts.total.sales",
  "PARTS Total GP" = "metric.table.parts.total.gp",
  "SERVICE Total GP" = "metric.table.service.total.gp.big",
  "BODY SHOP MIX" = "metric.table.body.shop.mix.big",
  "BODY SHOP Total GP" = "metric.table.body.shop.total.gp.big",

  "PARTS MIX" = "metrics.mra.view.parts.mix",
  "SERVICE MIX" = "metric.table.service.mix.big",

  "Distribution" = "custom.distribution.subheader",
  "Create New Dashboard" = "custom.table.create.new.dash",
  "MS > Sales Target % Achieved - New Total Units" = "home.chart.target",
  "G > Gross Profit" = "home.chart.gp",
  "TE > Overhead Expenses FOA" = "home.chart.exp",
  "FE > Rent & Depreciation % Sales" = "home.chart.rent",
  "targetach" = "home.chart.target",
  "aftergp" = "home.chart.gp",
  "overheadexp" = "home.chart.exp",
  "factcost" = "home.chart.rent",
  "NV Target achievement" = "home.chart.target",
  // "After Sales GP" = "home.chart.gp",
  // "Overhead Expenses" = "home.chart.exp",
  "Facility cost % Sales" = "home.chart.rent",
}
