import { CustomDashboardType, getMeasure } from "../CustomDashReports";


export const Service: { [index: string]: CustomDashboardType } = {
  "SERVICE > SALES > Service Total Sales": {
  name: "SERVICE > SALES > Service Total Sales",
  department: "SERVICE",
  category: "SALES",
  label: "Service Total Sales",
  tableNumber: 97,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > SALES > Total RO": {
  name: "SERVICE > SALES > Total RO",
  department: "SERVICE",
  category: "SALES",
  label: "Service Total RO",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Repair Orders | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > SALES > RO / Day": {
  name: "SERVICE > SALES > RO / Day",
  department: "SERVICE",
  category: "SALES",
  label: "Service RO / Day",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Repair Orders pd | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > SALES > RO / Technician / Day": {
  name: "SERVICE > SALES > RO / Technician / Day",
  department: "SERVICE",
  category: "SALES",
  label: "Service RO / Technician / Day",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Repair Orders pd p Productive | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > SALES > Service Sales / RO": {
  name: "SERVICE > SALES > Service Sales / RO",
  department: "SERVICE",
  category: "SALES",
  label: "Service Sales / RO",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales p RO | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > SALES > Hours Sold / RO": {
  name: "SERVICE > SALES > Hours Sold / RO",
  department: "SERVICE",
  category: "SALES",
  label: "Service Hours Sold / RO",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Hours Sold p RO | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > SALES > Effective Labour Rate / Hour": {
  name: "SERVICE > SALES > Effective Labour Rate / Hour",
  department: "SERVICE",
  category: "SALES",
  label: "Service Effective Labour Rate / Hour",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Effective Labour Rate | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > SALES > Breakeven Days": {
  name: "SERVICE > SALES > Breakeven Days",
  department: "SERVICE",
  category: "SALES",
  label: "Service Breakeven Days",
  tableNumber: 99,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Dept. Breakeven Days | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > SALES > Breakeven RO": {
  name: "SERVICE > SALES > Breakeven RO",
  department: "SERVICE",
  category: "SALES",
  label: "Service Breakeven RO",
  tableNumber: 99,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Breakeven ROs | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > SALES > Service Labour Sales": {
  name: "SERVICE > SALES > Service Labour Sales",
  department: "SERVICE",
  category: "SALES",
  label: "Service Labour Sales",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Labour Sales (TLS) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > SALES > Service Labour Retail Sales": {
  name: "SERVICE > SALES > Service Labour Retail Sales",
  department: "SERVICE",
  category: "SALES",
  label: "Service Labour Retail Sales",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Retail Labour sales | Service | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > SALES > Service Labour Warranty Sales": {
  name: "SERVICE > SALES > Service Labour Warranty Sales",
  department: "SERVICE",
  category: "SALES",
  label: "Service Labour Warranty Sales",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Warranty Labour sales | Service | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > SALES > Service Labour Internal Sales": {
  name: "SERVICE > SALES > Service Labour Internal Sales",
  department: "SERVICE",
  category: "SALES",
  label: "Service Labour Internal Sales",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Internal Labour sales | Service | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > SALES > Service Labour Maint. Contaract Sales": {
  name: "SERVICE > SALES > Service Labour Maint. Contaract Sales",
  department: "SERVICE",
  category: "SALES",
  label: "Service Labour Maint. Contaract Sales",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Maintenance Plan Labour sales | Service | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > SALES > Service Labour Quick Service Sales": {
  name: "SERVICE > SALES > Service Labour Quick Service Sales",
  department: "SERVICE",
  category: "SALES",
  label: "Service Labour Quick Service Sales",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Labour Sales (TLS) | Quick Service | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > SALES > Service Sublet Sales": {
  name: "SERVICE > SALES > Service Sublet Sales",
  department: "SERVICE",
  category: "SALES",
  label: "Service Sublet Sales",
  tableNumber: 100,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Sublet net sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > SALES > Service Oil & Lubricant Sales": {
  name: "SERVICE > SALES > Service Oil & Lubricant Sales",
  department: "SERVICE",
  category: "SALES",
  label: "Service Oil & Lubricant Sales",
  tableNumber: 100,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Oil Lubricants net sale | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > SALES > Service Extended Warranty Sales": {
  name: "SERVICE > SALES > Service Extended Warranty Sales",
  department: "SERVICE",
  category: "SALES",
  label: "Service Extended Warranty Sales",
  tableNumber: 100,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Extended Warranties | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > SALES > Service Other Sales": {
  name: "SERVICE > SALES > Service Other Sales",
  department: "SERVICE",
  category: "SALES",
  label: "Service Other Sales",
  tableNumber: 100,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Aftersales Other Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > MIX > SERVICE MIX": {
  name: "SERVICE > MIX > SERVICE MIX",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "SERVICE MIX",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > MIX > Service Total Sales": {
  name: "SERVICE > MIX > Service Total Sales",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Total Sales",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > MIX > Labour Sales": {
  name: "SERVICE > MIX > Labour Sales",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Labour Sales",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Labour Sales (TLS) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > MIX > Non-Labour Sales": {
  name: "SERVICE > MIX > Non-Labour Sales",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Non-Labour Sales",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Non Labour Sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > MIX > Service Total GP": {
  name: "SERVICE > MIX > Service Total GP",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Total GP",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > MIX > Labour GP": {
  name: "SERVICE > MIX > Labour GP",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Labour GP",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Labour GP | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > MIX > Non-Labour GP": {
  name: "SERVICE > MIX > Non-Labour GP",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Non-Labour GP",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Non Labour GP | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > MIX > Labour GP%": {
  name: "SERVICE > MIX > Labour GP%",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Labour GP%",
  tableNumber: 102,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Labour GP % of TLS | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > MIX > Labour FEG%": {
  name: "SERVICE > MIX > Labour FEG%",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Labour FEG%",
  tableNumber: 102,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Labour FEG % of TLS | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > MIX > Labour Retail GP%": {
  name: "SERVICE > MIX > Labour Retail GP%",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Labour Retail GP%",
  tableNumber: 102,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Retail Labour GP % of RLS | Service | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > MIX > Labour Warranty GP%": {
  name: "SERVICE > MIX > Labour Warranty GP%",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Labour Warranty GP%",
  tableNumber: 102,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Warranty Labour GP % of WLS | Service | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > MIX > Labour Internal GP%": {
  name: "SERVICE > MIX > Labour Internal GP%",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Labour Internal GP%",
  tableNumber: 102,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Internal Labour GP % of ILS | Service | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > MIX > Labour Maint. Contract GP%": {
  name: "SERVICE > MIX > Labour Maint. Contract GP%",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Labour Maint. Contract GP%",
  tableNumber: 102,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Maintenance Plan Labour GP % of MLS | Service | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > MIX > Labour Quick Service GP%": {
  name: "SERVICE > MIX > Labour Quick Service GP%",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Labour Quick Service GP%",
  tableNumber: 102,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Labour FEG % of TLS | Quick Service | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > MIX > Sublet GP%": {
  name: "SERVICE > MIX > Sublet GP%",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Sublet GP%",
  tableNumber: 100,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Sublet GP % of Sublet net sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > MIX > Oil & Lub GP%": {
  name: "SERVICE > MIX > Oil & Lub GP%",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Oil & Lub GP%",
  tableNumber: 100,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Oil Lubricants GP % of Net Oil Lubricants sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > MIX > Extended Warranty GP%": {
  name: "SERVICE > MIX > Extended Warranty GP%",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Extended Warranty GP%",
  tableNumber: 100,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Extended Warranties Gross % of Net Sale | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > MIX > Other GP%": {
  name: "SERVICE > MIX > Other GP%",
  department: "SERVICE",
  category: "GROSS PROFIT",
  label: "Service Other GP%",
  tableNumber: 100,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Aftersales Other GP % of Sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > SERVICE RETAINED": {
  name: "SERVICE > RETAINED > SERVICE RETAINED",
  department: "SERVICE",
  category: "EXPENSES",
  label: "SERVICE RETAINED",
  tableNumber: 103,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > SERVICE Total GP": {
  name: "SERVICE > RETAINED > SERVICE Total GP",
  department: "SERVICE",
  category: "EXPENSES",
  label: "SERVICE Total GP",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > RETAINED > SERVICE Total Expenses": {
  name: "SERVICE > RETAINED > SERVICE Total Expenses",
  department: "SERVICE",
  category: "EXPENSES",
  label: "SERVICE Total Expenses",
  tableNumber: 103,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > RETAINED > SERVICE Total PBT": {
  name: "SERVICE > RETAINED > SERVICE Total PBT",
  department: "SERVICE",
  category: "EXPENSES",
  label: "SERVICE Total PBT",
  tableNumber: 111,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > RETAINED > Total Expenses % GP": {
  name: "SERVICE > RETAINED > Total Expenses % GP",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Total Expenses % GP",
  tableNumber: 103,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses % of TGP | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Variable Expenses % GP": {
  name: "SERVICE > RETAINED > Variable Expenses % GP",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Variable Expenses  % GP",
  tableNumber: 104,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of TGP | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Personnel Expenses % GP": {
  name: "SERVICE > RETAINED > Personnel Expenses % GP",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Personnel  Expenses % GP",
  tableNumber: 105,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of TGP | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Other Semi Variable Expenses % GP": {
  name: "SERVICE > RETAINED > Other Semi Variable Expenses % GP",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Other Semi Variable Expenses % GP",
  tableNumber: 106,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of TGP | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Fixed Expenses % GP": {
  name: "SERVICE > RETAINED > Fixed Expenses % GP",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Fixed Expenses % GP",
  tableNumber: 107,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of TGP | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Interest Expenses % GP": {
  name: "SERVICE > RETAINED > Interest Expenses % GP",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Interest Expenses % GP",
  tableNumber: 108,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of TGP | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Allocation of Admin Expenses % GP": {
  name: "SERVICE > RETAINED > Allocation of Admin Expenses % GP",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Allocation of Admin  Expenses % GP",
  tableNumber: 103,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of TGP | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Variable Expenses% Total Expenses": {
  name: "SERVICE > RETAINED > Variable Expenses% Total Expenses",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Variable Expenses% Total Expenses",
  tableNumber: 104,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of Total Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Personnel Expenses% Total Expenses": {
  name: "SERVICE > RETAINED > Personnel Expenses% Total Expenses",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Personnel Expenses% Total Expenses",
  tableNumber: 105,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of Total Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Other Semi Var.Exp.% Total Expenses": {
  name: "SERVICE > RETAINED > Other Semi Var.Exp.% Total Expenses",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Other Semi Var.Exp.% Total Expenses",
  tableNumber: 106,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of Total Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Fixed Expenses% Total Expenses": {
  name: "SERVICE > RETAINED > Fixed Expenses% Total Expenses",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Fixed Expenses% Total Expenses",
  tableNumber: 107,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of Total Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Interest Expenses % Total Expenses": {
  name: "SERVICE > RETAINED > Interest Expenses % Total Expenses",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Interest Expenses % Total Expenses",
  tableNumber: 108,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of Total Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Allocation of Admin Expenses % Total Expenses": {
  name: "SERVICE > RETAINED > Allocation of Admin Expenses % Total Expenses",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Allocation of Admin Expenses % Total Expenses",
  tableNumber: 103,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of Total Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > RETAINED > Service Cost Recovery": {
  name: "SERVICE > RETAINED > Service Cost Recovery",
  department: "SERVICE",
  category: "EXPENSES",
  label: "Service Cost Recovery",
  tableNumber: 177,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Cost Recovery | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > ACTIVITY > SERVICE ACTIVITY": {
  name: "SERVICE > ACTIVITY > SERVICE ACTIVITY",
  department: "SERVICE",
  category: "OPERATIONAL ASSETS",
  label: "SERVICE ACTIVITY",
  tableNumber: 109,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > ACTIVITY > SERVICE Annualised Sales": {
  name: "SERVICE > ACTIVITY > SERVICE Annualised Sales",
  department: "SERVICE",
  category: "OPERATIONAL ASSETS",
  label: "SERVICE Annualised Sales",
  tableNumber: 97,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Projected Annualised Sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > ACTIVITY > SERVICE Average Operational Assets": {
  name: "SERVICE > ACTIVITY > SERVICE Average Operational Assets",
  department: "SERVICE",
  category: "OPERATIONAL ASSETS",
  label: "SERVICE Average Operational Assets",
  tableNumber: 109,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Average Operational Assets | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: true
},
"SERVICE > ACTIVITY > Service WIP Value": {
  name: "SERVICE > ACTIVITY > Service WIP Value",
  department: "SERVICE",
  category: "OPERATIONAL ASSETS",
  label: "Service WIP Value",
  tableNumber: 109,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > WIP Value | ServiceQuickService | All Brand | All Model | | CLOSE",
  canAverage: true
},
"SERVICE > ACTIVITY > Service WIP RO": {
  name: "SERVICE > ACTIVITY > Service WIP RO",
  department: "SERVICE",
  category: "OPERATIONAL ASSETS",
  label: "Service WIP RO",
  tableNumber: 109,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > WIP RO | ServiceQuickService | All Brand | All Model | | CLOSE",
  canAverage: true
},
"SERVICE > ROOA > ROOA": {
  name: "SERVICE > ROOA > ROOA",
  department: "SERVICE",
  category: "MRA MODEL",
  label: "SERVICE ROOA",
  tableNumber: 110,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Return on Operational Assets (ROOA) | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > ROOA > MIX": {
  name: "SERVICE > ROOA > MIX",
  department: "SERVICE",
  category: "MRA MODEL",
  label: "SERVICE MIX",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > ROOA > RETAINED": {
  name: "SERVICE > ROOA > RETAINED",
  department: "SERVICE",
  category: "MRA MODEL",
  label: "SERVICE RETAINED",
  tableNumber: 103,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > ROOA > ACTIVITY": {
  name: "SERVICE > ROOA > ACTIVITY",
  department: "SERVICE",
  category: "MRA MODEL",
  label: "SERVICE ACTIVITY",
  tableNumber: 109,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > ROS (Income Statement) > ROS": {
  name: "SERVICE > ROS (Income Statement) > ROS",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service ROS",
  tableNumber: 111,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of Sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > ROS (Income Statement) > Sales": {
  name: "SERVICE > ROS (Income Statement) > Sales",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Sales",
  tableNumber: 97,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > ROS (Income Statement) > PBT": {
  name: "SERVICE > ROS (Income Statement) > PBT",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service PBT",
  tableNumber: 111,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > ROS (Income Statement) > Dept. Profit % Net Sales": {
  name: "SERVICE > ROS (Income Statement) > Dept. Profit % Net Sales",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Dept. Profit % Net Sales",
  tableNumber: 111,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest % of TNS | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > ROS (Income Statement) > EBIT% (PBIT%)": {
  name: "SERVICE > ROS (Income Statement) > EBIT% (PBIT%)",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service EBIT% (PBIT%)",
  tableNumber: 111,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT % of Sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > ROS (Income Statement) > Variable Selling Gross % Gorss Profit": {
  name: "SERVICE > ROS (Income Statement) > Variable Selling Gross % Gorss Profit",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Variable Selling Gross % Gorss Profit",
  tableNumber: 104,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Variable Selling Gross % of TGP | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > ROS (Income Statement) > Selling Gross % Gross Profit": {
  name: "SERVICE > ROS (Income Statement) > Selling Gross % Gross Profit",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Selling Gross % Gross Profit",
  tableNumber: 111,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TGP | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > ROS (Income Statement) > Selling Gross % Net Sales": {
  name: "SERVICE > ROS (Income Statement) > Selling Gross % Net Sales",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Selling Gross % Net Sales",
  tableNumber: 111,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TNS | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > ROS (Income Statement) > Gross Profit": {
  name: "SERVICE > ROS (Income Statement) > Gross Profit",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Gross Profit",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > ROS (Income Statement) > Expenses": {
  name: "SERVICE > ROS (Income Statement) > Expenses",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Expenses",
  tableNumber: 103,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > ROS (Income Statement) > Variable Expenses": {
  name: "SERVICE > ROS (Income Statement) > Variable Expenses",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Variable Expenses",
  tableNumber: 104,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > ROS (Income Statement) > Personnel Expenses": {
  name: "SERVICE > ROS (Income Statement) > Personnel Expenses",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Personnel Expenses",
  tableNumber: 105,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > ROS (Income Statement) > Other Semi Variable Expenses": {
  name: "SERVICE > ROS (Income Statement) > Other Semi Variable Expenses",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Other Semi Variable Expenses",
  tableNumber: 106,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > ROS (Income Statement) > Fixed Expenses": {
  name: "SERVICE > ROS (Income Statement) > Fixed Expenses",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Fixed Expenses",
  tableNumber: 107,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > ROS (Income Statement) > Interest Expenses": {
  name: "SERVICE > ROS (Income Statement) > Interest Expenses",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Interest Expenses",
  tableNumber: 108,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > ROS (Income Statement) > Departmental Profit": {
  name: "SERVICE > ROS (Income Statement) > Departmental Profit",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Departmental Profit",
  tableNumber: 111,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest (EAIT) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > ROS (Income Statement) > Allocation of Admin Expenses": {
  name: "SERVICE > ROS (Income Statement) > Allocation of Admin Expenses",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service Allocation of Admin Expenses",
  tableNumber: 103,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > ROS (Income Statement) > EBIT": {
  name: "SERVICE > ROS (Income Statement) > EBIT",
  department: "SERVICE",
  category: "ROS (Income Statement)",
  label: "Service EBIT",
  tableNumber: 111,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT (Profit Before Interest & Tax) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"SERVICE > PRODUCTIVITY > Labour GP / Productive / Month": {
  name: "SERVICE > PRODUCTIVITY > Labour GP / Productive / Month",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Labour GP / Productive / Month",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Labour Gross Profit per productive | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Labour Sales / Productive / Month": {
  name: "SERVICE > PRODUCTIVITY > Labour Sales / Productive / Month",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Labour Sales / Productive / Month",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Average Labour Sales per productive | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Technician Cost / Productive / Month": {
  name: "SERVICE > PRODUCTIVITY > Technician Cost / Productive / Month",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Technician Cost / Productive / Month",
  tableNumber: 102,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Labour COS p Technician | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Total Service GP / Productive / Month": {
  name: "SERVICE > PRODUCTIVITY > Total Service GP / Productive / Month",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service GP / Productive / Month",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Productive | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Total Service GP / Employee / Month": {
  name: "SERVICE > PRODUCTIVITY > Total Service GP / Employee / Month",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service GP / Employee / Month",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Employee | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > RO / Technician / Day": {
  name: "SERVICE > PRODUCTIVITY > RO / Technician / Day",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service RO / Technician / Day",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Repair Orders pd p Productive | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Labour Sales / RO": {
  name: "SERVICE > PRODUCTIVITY > Labour Sales / RO",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Labour Sales / RO",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Labour Sales (TLS) p RO | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Hours Sold / RO": {
  name: "SERVICE > PRODUCTIVITY > Hours Sold / RO",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Hours Sold / RO",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Hours Sold p RO | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Effective Labour Rate / Hour": {
  name: "SERVICE > PRODUCTIVITY > Effective Labour Rate / Hour",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Effective Labour Rate / Hour",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Effective Labour Rate | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Service Productivity": {
  name: "SERVICE > PRODUCTIVITY > Service Productivity",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Productivity",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Productivity % (Non Factorised) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Efficiency": {
  name: "SERVICE > PRODUCTIVITY > Efficiency",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Efficiency",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Efficiency % | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > RO / Workbay / Day": {
  name: "SERVICE > PRODUCTIVITY > RO / Workbay / Day",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "RO / Workbay / Day",
  measure: "STAT > Ave Repair Orders pd per bay | ServiceQuickService | All Brand | All Model | | AVG",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 99,
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Effectiveness": {
  name: "SERVICE > PRODUCTIVITY > Effectiveness",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Effectiveness",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Effectiveness % | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Service Workbay Utilization": {
  name: "SERVICE > PRODUCTIVITY > Service Workbay Utilization",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Workbay Utilization",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Workbay utilization | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Workbay Occupancy": {
  name: "SERVICE > PRODUCTIVITY > Workbay Occupancy",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Workbay Occupancy",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Workbay Occupancy | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"SERVICE > PRODUCTIVITY > Number of Workbay": {
  name: "SERVICE > PRODUCTIVITY > Number of Workbay",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Number of Workbay",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Number of Workbays | ServiceQuickService | All Brand | All Model | | CLOSE",
  canAverage: true
},
"SERVICE > PRODUCTIVITY > Total Staff": {
  name: "SERVICE > PRODUCTIVITY > Total Staff",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Total Staff",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Total Employees | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: true
},
"SERVICE > PRODUCTIVITY > Service Productive Staff": {
  name: "SERVICE > PRODUCTIVITY > Service Productive Staff",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Productive Staff",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Workshop Productives | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: true
},
"SERVICE > PRODUCTIVITY > Support Staff incl. SA": {
  name: "SERVICE > PRODUCTIVITY > Support Staff incl. SA",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Support Staff incl. SA",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Non-Productives | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: true
},
"SERVICE > PRODUCTIVITY > Service SA": {
  name: "SERVICE > PRODUCTIVITY > Service SA",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service SA",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Service Advisor | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: true
},
"SERVICE > PRODUCTIVITY > Service Ratio Productive : Non Productive": {
  name: "SERVICE > PRODUCTIVITY > Service Ratio Productive : Non Productive",
  department: "SERVICE",
  category: "PRODUCTIVITY",
  label: "Service Ratio Productive : Non Productive",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ratio Productive to Non-Productive staff | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
};

export const ServiceOEMSupportsAnalysis: { [index: string]: CustomDashboardType } = {
  "SERVICE > OEM Supports Analysis > Service Support from OEM": {
  name: "SERVICE > OEM Supports Analysis > Service Support from OEM",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service Support from OEM",
  measure: "G > OEM Incentives & Expenses Subsidy | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service OEM Incentives": {
  name: "SERVICE > OEM Supports Analysis > Service OEM Incentives",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service OEM Incentives",
  measure: "G > OEM Incentives | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service OEM Expenses Subsidy": {
  name: "SERVICE > OEM Supports Analysis > Service OEM Expenses Subsidy",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service OEM Expenses Subsidy",
  measure: "TE > OEM Expenses Subsidy | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service OEM Direct Advertising Subsidy": {
  name: "SERVICE > OEM Supports Analysis > Service OEM Direct Advertising Subsidy",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service OEM Direct Advertising Subsidy",
  measure: "VE > Advertising Subsidy | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service OEM Indirect Advertising Subsidy": {
  name: "SERVICE > OEM Supports Analysis > Service OEM Indirect Advertising Subsidy",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service OEM Indirect Advertising Subsidy",
  measure: "SVE > Indirect Advertising Subsidy | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service OEM Incentives % TGP": {
  name: "SERVICE > OEM Supports Analysis > Service OEM Incentives % TGP",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service OEM Incentives % TGP",
  measure: "G > OEM Incentives % TGP UsedF&IAftercare | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service OEM Incentives % Sales": {
  name: "SERVICE > OEM Supports Analysis > Service OEM Incentives % Sales",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service OEM Incentives % Sales",
  measure: "G > OEM Incentives % Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service OEM Expenses Subsidy % Sales": {
  name: "SERVICE > OEM Supports Analysis > Service OEM Expenses Subsidy % Sales",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service OEM Expenses Subsidy % Sales",
  measure: "TE > OEM Expenses Subsidy % Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service OEM All Supports % Sales": {
  name: "SERVICE > OEM Supports Analysis > Service OEM All Supports % Sales",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service OEM All Supports % Sales",
  measure: "G > OEM Incentives & Expenses Subsidy % Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service MIX": {
  name: "SERVICE > OEM Supports Analysis > Service MIX",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service MIX",
  measure: "G > Gross Profit % of Sales (MIX) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service MIX (excl Incentive)": {
  name: "SERVICE > OEM Supports Analysis > Service MIX (excl Incentive)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service MIX (excl Incentive)",
  measure: "G > Gross Profit (excl Incentive) % of Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service RETAINED": {
  name: "SERVICE > OEM Supports Analysis > Service RETAINED",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service RETAINED",
  measure: "PBT > PBT % of TGP: RETAINED | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service RETAINED (excl All Supports from OEM)": {
  name: "SERVICE > OEM Supports Analysis > Service RETAINED (excl All Supports from OEM)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service RETAINED (excl All Supports from OEM)",
  measure: "PBT > PBT % of TGP: RETAINED (excl OEM Incentives & Expenses Subsidy) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service RETAINED (excl Incentives)": {
  name: "SERVICE > OEM Supports Analysis > Service RETAINED (excl Incentives)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service RETAINED (excl Incentives)",
  measure: "PBT > PBT % of TGP: RETAINED (excl Incentive) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service RETAINED (excl OEM Expenses Subsidy)": {
  name: "SERVICE > OEM Supports Analysis > Service RETAINED (excl OEM Expenses Subsidy)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service RETAINED (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT % of TGP: RETAINED (excl OEM Expenses Subsidy) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service Expenses % TGP": {
  name: "SERVICE > OEM Supports Analysis > Service Expenses % TGP",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service Expenses % TGP",
  measure: "TE > Total Expenses % of TGP | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service Expenses % TGP (excl All Supports from OEM)": {
  name: "SERVICE > OEM Supports Analysis > Service Expenses % TGP (excl All Supports from OEM)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service Expenses % TGP (excl All Supports from OEM)",
  measure: "TE > Total Expenses % of TGP (excl OEM Incentives & Expenses Subsidy) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service Expenses % TGP (excl Incentives)": {
  name: "SERVICE > OEM Supports Analysis > Service Expenses % TGP (excl Incentives)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service Expenses % TGP (excl Incentives)",
  measure: "TE > Total Expenses % of TGP (excl Incentive) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service Expenses % TGP (excl OEM Expenses Subsidy)": {
  name: "SERVICE > OEM Supports Analysis > Service Expenses % TGP (excl OEM Expenses Subsidy)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service Expenses % TGP (excl OEM Expenses Subsidy)",
  measure: "TE > Total Expenses % of TGP (excl OEM Expenses Subsidy) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service ROS": {
  name: "SERVICE > OEM Supports Analysis > Service ROS",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service ROS",
  measure: "PBT > PBT % of Sales | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service ROS (excl All Supports from OEM)": {
  name: "SERVICE > OEM Supports Analysis > Service ROS (excl All Supports from OEM)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service ROS (excl All Supports from OEM)",
  measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) % of Sales | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service ROS (excl Incentives)": {
  name: "SERVICE > OEM Supports Analysis > Service ROS (excl Incentives)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service ROS (excl Incentives)",
  measure: "PBT > PBT (excl OEM Incentive) % of Sales | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service ROS (excl OEM Expenses Subsidy)": {
  name: "SERVICE > OEM Supports Analysis > Service ROS (excl OEM Expenses Subsidy)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service ROS (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT (excl OEM Expenses Subsidy) % of Sales | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: false
},
"SERVICE > OEM Supports Analysis > Service Sales": {
  name: "SERVICE > OEM Supports Analysis > Service Sales",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service Sales",
  measure: "TNS > Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service GP": {
  name: "SERVICE > OEM Supports Analysis > Service GP",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service GP",
  measure: "G > Gross Profit | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service GP (excl Incentives)": {
  name: "SERVICE > OEM Supports Analysis > Service GP (excl Incentives)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service GP (excl Incentives)",
  measure: "G > Gross Profit (excl Incentive) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service Expenses": {
  name: "SERVICE > OEM Supports Analysis > Service Expenses",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service Expenses",
  measure: "TE > Total Expenses | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service Expenses (excl OEM Expenses Subsidy)": {
  name: "SERVICE > OEM Supports Analysis > Service Expenses (excl OEM Expenses Subsidy)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service Expenses (excl OEM Expenses Subsidy)",
  measure: "TE > Total Expenses (excl Expenses Subsidy) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service PBT": {
  name: "SERVICE > OEM Supports Analysis > Service PBT",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service PBT",
  measure: "PBT > PBT (Profit Before Tax) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service PBT (excl All Supports from OEM)": {
  name: "SERVICE > OEM Supports Analysis > Service PBT (excl All Supports from OEM)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service PBT (excl All Supports from OEM)",
  measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service PBT (excl Incentives)": {
  name: "SERVICE > OEM Supports Analysis > Service PBT (excl Incentives)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service PBT (excl Incentives)",
  measure: "PBT > PBT (excl OEM Incentive) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
"SERVICE > OEM Supports Analysis > Service PBT (excl OEM Expenses Subsidy)": {
  name: "SERVICE > OEM Supports Analysis > Service PBT (excl OEM Expenses Subsidy)",
  department: "SERVICE",
  category: "OEM Supports Analysis",
  label: "Service PBT (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT (excl OEM Expenses Subsidy) | ServiceQuickService | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 532,
  canAverage: true
},
};
