import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import grey from "@material-ui/core/colors/grey";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, withStyles, WithStyles, withTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";
import classnames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import {
  Bar as StackedChartBar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  Text,
  XAxis,
  YAxis,
  Legend,
  PieChart,
  Pie,
} from "recharts";
import { Loading } from "../components/Loading";
import { Query, Report, Series, GroupSeries } from "../lib/api/charts";
import { ChartMenu } from "./ChartMenu";
import { OemContext } from "../contexts/OemContext";
import numeral from "numeral";
import { BenchmarkContext } from "../contexts/BenchmarkContext";
import { CustomGroupBarChartContext } from "../contexts/Custom/GroupBarChartContext";
import * as d3Scale from "d3-scale-chromatic";
import { ReportSelector } from "./GroupReportSelector";
import { useMediaQuery } from "react-responsive";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import History from "@material-ui/icons/History";
import { ChartDialog } from "../lib/shared/ChartDialog";
import { DealerContext } from "../contexts/DealerContext";
import Grid from "@material-ui/core/Grid";
import { KeyboardArrowDown } from "@material-ui/icons";
import { CustomizedXAxisTick } from "../components/CustomXAxisTick";
import { overflow } from "html2canvas/dist/types/css/property-descriptors/overflow";
import { GroupBarChartContext } from "../contexts/GroupBarChartContext";
import { getChartColors } from "./GroupSeriesChart";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: "1",
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
    chartPaperRoot: {
      display: "flex",
      flexDirection: "column",
      padding: `${theme.spacing.unit * 2}px`,
      flex: "1",
      backgroundColor: "#FFF",
      border: "solid 1px #e0e0e0",
      borderRadius: "3px",
      ["@media (max-width:450px)"]: {
        padding: "0px",
      },
    },
    headingContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    chart: {
      flex: "1",
    },
    menu: {
      // margin: "-0.85rem"
      display: "flex",
    },
    generalNews: {
      background: "#800080",
    },
    dataQualityNews: {
      background: "#ADD8E6",
    },
    dealerPerformanceNews: {
      background: "#003366",
    },
  });

export type ChartProps = {
  barColor?: string;
  lineColor?: string;
  query: Query;
  title?: string;
  report: Report;
  theme: Theme;
  handleSelection?(key: Report): void;
  showBenchmark?: boolean;
  showMedian?: boolean;
  showTarget?: boolean;
  measureAgainst?: string;
  forcedHeight?: string;
  renderMenu?: () => React.ReactElement<any>;
  custom: any;
  openDialog?: boolean;
  setOpenDialog?: (value: boolean) => void;
  isDesktopOrLaptop?: boolean;
  chartType?: "bar" | "line" | "pie";
  handleChartType?: (type: string) => void;
  chartOptions?: any;
} & WithStyles<typeof styles>;

const BarTooltip = ({ active, payload, query, dealers }: any) => {
  if (active && payload && payload[0]) {
    return (
      <Paper style={{ padding: "0.5rem" }}>
        <Typography variant="caption" key={payload[0].payload.dealer} component="span">
          <b>{payload[0].payload.dealerName}:</b> {payload[0].payload[`${payload[0].payload.dealer} actualFormatted`]}
        </Typography>
        {/* {payload[0].payload.benchmarkFormatted && (
          <Typography variant="caption" component="span">
            <b>Benchmark:</b> {payload[0].payload.benchmarkFormatted}
          </Typography>
        )}
        {payload[0].payload.medianFormatted && (
          <Typography variant="caption" component="span">
            <b>Median:</b> {payload[0].payload.medianFormatted}
          </Typography>
        )} */}
      </Paper>
    );
  }
  return null;
};

const renderLegend = (props: any) => {
  const { payload } = props;
  // These styles have been obtained from inspecting the default rechart rendering.
  return (
    <div style={{ maxHeight: "auto" }}>
      {/* I dont know what month they want here - as in mock up */}
      {/* <Typography aria-haspopup="true">{`add month here`}</Typography> */}
      <ul className="recharts-default-legend" style={{ padding: "0px", margin: "0px", listStyleType: "none" }}>
        {(payload || []).map((entry: any, index: any) => {
          // if (entry.value.toLocaleLowerCase() == "actual") return null; // We never want to display the legend for Actual, this was requested by the Customer.

          return (
            <li
              key={`recharts-legend-item legend-item-${index}`}
              className={`recharts-legend-item legend-item-${index}`}
              // style={{ display: "inline-block", marginRight: "10px" }}
            >
              <svg
                className="recharts-surface"
                width="14"
                height="14"
                viewBox="0 0 32 32"
                version="1.1"
                style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
              >
                {entry.type == "line" ? (
                  <path
                    strokeWidth="4"
                    fill="none"
                    stroke={entry.color}
                    d="M0,16h10.666666666666666
  A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
  H32M21.333333333333332,16
  A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                    className="recharts-legend-icon"
                  />
                ) : (
                  <path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
                )}
              </svg>
              <span className={"recharts-legend-item-text"}>{`${entry.payload.dealerName}: ${entry.payload[`${entry.payload.dealer} actualFormatted`]}`}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const renderCustomizedLabel = ({ cx, cy, endAngle, startAngle, midAngle, innerRadius, outerRadius, payload, index }: any) => {
  if (payload.actual == 0 || payload.actual == undefined || payload.actual == null) return null;

  const RADIAN = Math.PI / 180;
  // eslint-disable-next-line
  const radius = 20 + innerRadius + (outerRadius - innerRadius);
  // eslint-disable-next-line
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // eslint-disable-next-line
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      // x={x > cx ? x - 20 : x + 20}
      x={x}
      y={y}
      fill={payload.fill}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      style={{ fontSize: "10px", fontWeight: "bold" }}
    >
      {payload.labelText}
    </text>
  );
};

export type ChartInnerProps = {
  theme: Theme;
  series: GroupSeries;
  report: Report;
  title: string;
  handleSelection?(key: Report): void;
  showBenchmark?: boolean;
  showMedian?: boolean;
  showTarget?: boolean;
  measureAgainst?: string;
  menu: React.ReactElement<any>;
  forcedHeight: string;
  query: Query;
  selectedDealers: { name: string; active: boolean }[];
  chartOptions?: any;
} & WithStyles<typeof styles>;

// Setting the width to the responsive container to 99% as per this issue that is found with recharts
// https://github.com/recharts/recharts/issues/172
const ChartInner: React.FunctionComponent<ChartInnerProps> = ({
  theme,
  series,
  classes,
  report,
  handleSelection,
  measureAgainst,
  showBenchmark,
  showMedian,
  showTarget,
  menu,
  forcedHeight,
  title,
  query,
  selectedDealers,
  chartOptions,
}) => {
  const { oems } = useContext(OemContext);
  const { selected: benchmarks, loading } = useContext(BenchmarkContext);
  const [allowPie, setAllowPie] = useState(false);
  const benchmarkLine = "#20639B";
  const medianLine = "#3cAEA3";
  const targetsLine = "#F6D55C";

  if (!loading.loaded) {
    return <Loading />;
  }

  const dealers = ((selectedDealers || []).filter(dealer => dealer.active) || []).map(dealer => dealer.name);
  const rows = series.rows
    .filter(row => {
      return (selectedDealers.find(e => e.name == (row as any).dealerName) || ({} as any)).active;
    })
    .map(r => {
      return {
        ...r,
        labelText: `${(r as any).dealer}: ${r[`${(r as any).dealer} actualFormatted`]} `,
      };
    });

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1280px)",
  });

  const chartColors: string[] = getChartColors();

  return (
    <Paper className={classnames(classes.chartPaperRoot)} style={{ height: forcedHeight }}>
      <div className={classnames(classes.headingContainer)}>
        <ReportSelector
          handleSelection={handleSelection}
          report={report}
          title={series.name}
          chartOptions={chartOptions}
          isPieChart={true}
          setAllowPie={setAllowPie}
        />
        <div className={classnames(classes.menu)}>{menu || null}</div>
      </div>
      <div className={classes.chart}>
        <ResponsiveContainer width={"99%"} minHeight={300} className={classes.root}>
          <PieChart>
            <Tooltip content={<BarTooltip dealers={dealers} query={query} />} position={isDesktopOrLaptop ? { x: 0, y: 0 } : { x: 0, y: 0 }} />
            <Pie
              hide={allowPie}
              dataKey="actual"
              data={rows}
              cx="51%"
              cy="50%"
              outerRadius={110}
              labelLine={false}
              label={undefined}
              isAnimationActive={false}
              startAngle={-180}
            >
              {rows.map((value: any, key: number) => (
                <Cell key={key} stroke={d3Scale.schemeDark2[key]} fill={d3Scale.schemeDark2[key]} />
              ))}
            </Pie>
            <Legend height={250} width={300} wrapperStyle={{ overflow: "auto" }} layout="vertical" align="right" content={renderLegend} verticalAlign="top" />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};

type ReportMenuProps = {
  query: Query;
  handleShowToggle: (attr: string) => (e: React.SyntheticEvent<any>) => void;
  state: {
    showBenchmark: boolean;
    showMedian: boolean;
    showTarget: boolean;
    forcedHeight: string;
  };
  hide?: boolean;
  selectedDealers?: any[];
  setSelectedDealers?: any;
};

const ReportMenu: React.FunctionComponent<ReportMenuProps> = ({ query, handleShowToggle, state, hide, selectedDealers, setSelectedDealers }) => {
  const toggleBenchmark = handleShowToggle("showBenchmark");
  const toggleMedian = handleShowToggle("showMedian");
  const toggleTarget = handleShowToggle("showTarget");
  const { oems } = useContext(OemContext);
  const { selected: benchmarks } = useContext(BenchmarkContext);

  const handleSelectedDealers = (value: string) => {
    const filtered = (selectedDealers || []).filter(dealer => dealer.name != value);
    const dealer = (selectedDealers || []).find(d => d.name == value);
    setSelectedDealers([...filtered, { ...dealer, active: !dealer.active }]);
  };

  const compare = (a, b) => {
    if (a.pos > b.pos) {
      return 1;
    }
    if (a.pos < b.pos) {
      return -1;
    }
    return 0;
  };

  const sortedDealers = React.useMemo(() => {
    return selectedDealers.sort(compare);
  }, [selectedDealers]);

  return (
    <div hidden={hide}>
      <ChartMenu>
        <Grid container justify="flex-end" style={{ padding: "0.5em 1em" }}>
          <Grid item>
            <Button
              size="small"
              onClick={() => {
                setSelectedDealers(
                  selectedDealers.map(d => {
                    return { ...d, active: true };
                  }),
                );
              }}
            >
              Select All
            </Button>
            &nbsp;
            <Button
              size="small"
              onClick={() => {
                setSelectedDealers(
                  selectedDealers.map(d => {
                    return { ...d, active: false };
                  }),
                );
              }}
            >
              Deselect All
            </Button>
          </Grid>
        </Grid>
        {/* {benchmarks.filter(bm => bm.length > 0).length > 0 && (
          <>
            <MenuItem onClick={toggleBenchmark}>
              <Checkbox onChange={toggleBenchmark} checked={state.showBenchmark} disableRipple />
              {benchmarks[0]}
            </MenuItem>
            {benchmarks.length > 1 && (
              <MenuItem onClick={toggleMedian}>
                <Checkbox onChange={toggleMedian} checked={state.showMedian} disableRipple />
                {benchmarks[1]}
              </MenuItem>
            )}
          </>
        )} */}
        {(sortedDealers || []).map(dealer => (
          <MenuItem onClick={() => handleSelectedDealers(dealer.name)} disabled={dealer.active && sortedDealers.filter(d => d.active).length <= 1}>
            <Checkbox checked={dealer.active} disableRipple />
            {dealer.name}
          </MenuItem>
        ))}
      </ChartMenu>
    </div>
  );
};
const ChartUnstyled: React.FunctionComponent<ChartProps> = ({
  classes,
  theme,
  report,
  title,
  query,
  showBenchmark,
  handleSelection,
  showMedian,
  showTarget,
  measureAgainst,
  renderMenu,
  forcedHeight,
  custom,
  openDialog,
  setOpenDialog,
  isDesktopOrLaptop,
  chartType,
  handleChartType,
  chartOptions,
}) => {
  const { getGroupBarSeries } = React.useContext(GroupBarChartContext);
  const series = getGroupBarSeries(report, query, custom);
  const { selectedDealers: dealerList } = useContext(DealerContext);
  const [selectedDealers, setSelectedDealers] = React.useState<any[]>(dealerList.map((dealer, indx) => ({ name: dealer.dealer, active: true, pos: indx })));
  useEffect(() => setSelectedDealers(dealerList.map((dealer, indx) => ({ name: dealer.dealer, active: true, pos: indx }))), [dealerList]);
  const [state, setState] = React.useState({
    showMedian: showMedian || false,
    showTarget: showTarget || false,
    showBenchmark: showBenchmark || false,
    forcedHeight: forcedHeight,
  });

  const handleShowToggle = attr => event => {
    setState({
      ...state,
      [attr]: !state[attr],
    });
  };

  if (!series.loading.loaded) {
    return <Loading />;
  }

  return (
    <>
      <ChartInner
        measureAgainst={measureAgainst}
        showBenchmark={state.showBenchmark}
        showMedian={state.showMedian}
        showTarget={state.showTarget}
        handleSelection={handleSelection}
        report={report}
        theme={theme}
        title={title || series.series.name}
        series={series.series}
        selectedDealers={selectedDealers}
        classes={classes}
        chartOptions={chartOptions}
        menu={
          <>
            {isDesktopOrLaptop && !openDialog && (
              <Button onClick={() => setOpenDialog(openDialog ? false : true)}>{openDialog ? <ZoomOut /> : <ZoomIn />}</Button>
            )}
            {renderMenu && renderMenu()}
            <ReportMenu
              state={state}
              handleShowToggle={handleShowToggle}
              query={query}
              selectedDealers={selectedDealers}
              setSelectedDealers={setSelectedDealers}
            />
          </>
        }
        forcedHeight={state.forcedHeight}
        query={query}
      />
    </>
  );
};

export const GroupBarChart = withTheme()(withStyles(styles)(ChartUnstyled));

export type SelectableChartProps = {
  query: Query;
  title?: string;
  report: Report;
  handleSelection?(key: Report): void;
  period?: string;
  custom?: any;
  handleChartType?: (type: "line" | "bar" | "pie") => void;
  chartType?: "bar" | "line" | "pie";
  chartOptions?: any;
  renderMenu?: any;
  measure?: any;
};

export const SelectablePieChart: React.FC<SelectableChartProps> = ({
  handleSelection,
  report,
  title,
  query,
  period = "Monthly",
  custom,
  handleChartType,
  chartType = "bar",
  chartOptions,
  renderMenu,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedChart, setSelectedChart] = React.useState(period);
  const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [chartTypeMenuOpen, setChartTypeMenuOpen] = React.useState(false);
  const [chartTypeMenuAnchor, setChartTypeMenuAnchor] = React.useState(null);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1280px)",
  });

  const variantMap = {
    Monthly: "monthly",
    Quarterly: "quarter",
    Yearly: "yearly",
    "Full Year": "fullyear",
    YTD: "ytd",
  };

  return (
    <>
      <GroupBarChart
        handleSelection={handleSelection}
        handleChartType={handleChartType}
        chartType={chartType}
        report={report}
        title={title}
        custom={custom}
        // forcedHeight={"375px"}
        query={{ ...query, meta: variantMap[selectedChart] ? { ...query.meta, variant: variantMap[selectedChart] } : { ...query.meta } }}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        chartOptions={chartOptions}
        isDesktopOrLaptop={isDesktopOrLaptop}
        renderMenu={() => (
          <>
            <Button
              onClick={e => {
                setChartTypeMenuOpen(true);
                setChartTypeMenuAnchor(e.currentTarget);
              }}
            >
              {chartType == "bar" && <span>Bar</span>}
              {chartType == "line" && <span>Line</span>}
              {chartType == "pie" && <span>Pie</span>}
            </Button>
            <Button
              onClick={e => {
                setMenuOpen(true);
                setMenuAnchor(e.currentTarget);
              }}
            >
              <History /> ({selectedChart})
            </Button>
          </>
        )}
      />
      {
        <>
          <Menu anchorEl={menuAnchor} open={menuOpen} onClose={() => setMenuOpen(false)}>
            <MenuItem
              selected={selectedChart == "YTD"}
              onClick={() => {
                setMenuOpen(false);
                setSelectedChart("YTD");
              }}
            >
              YTD
            </MenuItem>
            <MenuItem
              selected={selectedChart == "Monthly"}
              onClick={() => {
                setMenuOpen(false);
                setSelectedChart("Monthly");
              }}
            >
              Monthly
            </MenuItem>
            <MenuItem
              selected={selectedChart == "Quarterly"}
              onClick={() => {
                setMenuOpen(false);
                setSelectedChart("Quarterly");
              }}
            >
              Quarterly
            </MenuItem>
            <MenuItem
              selected={selectedChart == "Yearly"}
              onClick={() => {
                setMenuOpen(false);
                setSelectedChart("Yearly");
              }}
            >
              Yearly
            </MenuItem>
            <MenuItem
              selected={selectedChart == "Full Year"}
              onClick={() => {
                setMenuOpen(false);
                setSelectedChart("Full Year");
              }}
            >
              Full Year
            </MenuItem>
          </Menu>
          <Menu anchorEl={chartTypeMenuAnchor} open={chartTypeMenuOpen} onClose={() => setChartTypeMenuOpen(false)}>
            <MenuItem
              selected={chartType === "bar"}
              onClick={() => {
                setChartTypeMenuOpen(false);
                handleChartType("bar");
              }}
            >
              Bar Chart
            </MenuItem>
            <MenuItem
              selected={chartType === "line"}
              onClick={() => {
                setChartTypeMenuOpen(false);
                handleChartType("line");
              }}
            >
              Line Chart
            </MenuItem>
            <MenuItem
              selected={chartType === "pie"}
              onClick={() => {
                setChartTypeMenuOpen(false);
                handleChartType("pie");
              }}
            >
              Pie Chart
            </MenuItem>
          </Menu>
        </>
      }
      {isDesktopOrLaptop && (
        <ChartDialog
          handleSelection={handleSelection}
          report={report}
          title={title}
          custom={custom}
          query={{ ...query, meta: variantMap[selectedChart] ? { ...query.meta, variant: variantMap[selectedChart] } : { ...query.meta } }}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          isDesktopOrLaptop={isDesktopOrLaptop}
          renderMenu={() => (
            <Button
              onClick={e => {
                setMenuOpen(true);
                setMenuAnchor(e.currentTarget);
              }}
            >
              <History /> ({selectedChart})
            </Button>
          )}
          chart={props => (
            <GroupBarChart
              handleSelection={handleSelection}
              report={report}
              title={title}
              custom={custom}
              chartOptions={chartOptions}
              query={{ ...query, meta: variantMap[selectedChart] ? { ...query.meta, variant: variantMap[selectedChart] } : { ...query.meta } }}
              renderMenu={() => (
                <Button
                  onClick={e => {
                    setMenuOpen(true);
                    setMenuAnchor(e.currentTarget);
                  }}
                >
                  <History /> ({selectedChart})
                </Button>
              )}
            />
          )}
        />
      )}
    </>
  );
};
